import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'

export default {
    waiting: 
    {
        setStatus : false,
        associateAlarm : false,
        associateRemoteAlarm : false,
        validateRemoteAlarm : false
    },
    setStatus: function (coal,parameterKey,parameterValue, commandId, status) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.setStatus == true)return false;
                axios.post(Global.getBackendUrl()+'alarm/security/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+coal+'/parameter/'+parameterKey+'/value/'+parameterValue,
                    {
                        'status' :
                        [
                            {
                                "capability": "protection",
                                "value": status
                            }
                        ],
                        'commandId': commandId
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.setStatus = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.setStatus = false;
                        return reject(error);
                    });
            }
        );
    },

    diagnostic: function (coal,parameterKey,parameterValue) {
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'alarm/security/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+coal+'/parameter/'+parameterKey+'/value/'+parameterValue+'/diagnostic',
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    associateAlarm: function (submodel, bearerId, commandId, locationId) {
        var _this = this;     
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.associateAlarm == true)return false;
                var body = 
                {
                    'bearerId' : bearerId,
                    'commandId': commandId,
                    'submodel': submodel
                };
                if(locationId)
                    body.locationId = locationId;  
                axios.post(Global.getBackendUrl()+'alarm/security/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/association',
                    body,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.associateAlarm = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.associateAlarm = false;
                        return reject(error);
                    });
            }
        );
    },
    associateRemoteAlarm: function (bearerId,commandId,alarm) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.associateRemoteAlarm == true)return false;
                axios.post(Global.getBackendUrl()+'alarm/security/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/association/alarmRemote',
                    {
                        "bearerId": bearerId,
                        "alarmId": alarm.id,
                        "commandId": commandId
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.associateRemoteAlarm = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.associateRemoteAlarm = false;
                        return reject(error);
                    });
            }
        );
    },
    validateAlarm: function (commandId, alarmId, alarmRemoteId) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.validateAlarm == true)return false;
                var body =
                {
                    'commandId': commandId
                }
                if(alarmRemoteId)
                    body.alarmRemoteId = alarmRemoteId;
                axios.post(Global.getBackendUrl()+'alarm/security/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+alarmId+'/validation',
                    body,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.validateAlarm = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.validateAlarm = false;
                        return reject(error);
                    });
            }
        );
    },
    validateRemoteAlarm: function (commandId, alarmId) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.validateRemoteAlarm == true)return false;
                axios.post(Global.getBackendUrl()+'alarm/security/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+alarmId+'/validation/alarmRemote',
                    {
                        'commandId': commandId
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.validateRemoteAlarm = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.validateRemoteAlarm = false;
                        return reject(error);
                    });
            }
        );
    }
}
