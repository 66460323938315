export default {
  props:
    {
      title: {
          type: String,
          default: ''
      },
      elements: {
          type: Array,
          default: []
      },
      compareElements: {
        type: Array,
        default: []
      },
      displayAddBtn: {
        type: Boolean,
        default: false
      }
  },
  data:
		function () {
			return {
        CONSTANTS:{
        },
			}
	},
  created: function () {

  },
  mounted: function () {
    var _this = this;
    if(this.$refs['elementTxt'])
    {
      this.$refs['elementTxt'].forEach(function(elt) {
        _this.lineClamp(elt, 2);
        //_this.clampjs(elt, {clamp: 2});
      });
    }
  },
	computed: {
		filteredElements: function () {
      var _this = this;
      this.elements.forEach(function(elt) {
        elt.translateName = _upperFirst(_this.$gettext(elt.displayName?elt.displayName:elt.name));
        elt.add = (_find(_this.compareElements, { 'id': elt.id})!=undefined);
      });
      return this.elements;
		}
	},
	methods: {
  }
}
