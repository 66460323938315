import Emitter from './Emitter'
import Mqtt from 'mqtt'
import LogService from 'common/services/log.js'

const TAG = 'MqttObserver';
export default class {

    constructor(connection, options, eventHub) {
        if (typeof connection === 'string') {
            this.Mqtt = Mqtt.connect(connection, options);
        } else {
            this.Mqtt = connection;
        }

        this.Mqtt.on('connect', (connack) => {
            LogService.consoleLogTag(TAG,'connect','this.Mqtt',this.Mqtt);
            var msg = "MQTT CONNECTION SUCCESS<br/><br/>"+
            "-------------------------------------<br/>"+
            "<b><u>host:</u> </b>"+this.Mqtt.options.host+"<br/>"+
            "<b><u>port:</u> </b>"+this.Mqtt.options.port+"<br/>"+
            "<b><u>username:</u> </b>"+this.Mqtt.options.username+"<br/>"+
            "<b><u>password:</u> </b>"+this.Mqtt.options.password+"<br/>"+
            "<b><u>clientId:</u> </b>"+this.Mqtt.options.clientId+"<br/><br/>"+
            "-------------------------------------";
            LogService.consoleLogTag(TAG,'connect','options',this.Mqtt.options);
            
            eventHub.$emit('mqttConnected');
        });

        
        this.Mqtt.on('message', (topic, payload, packet) => {
            LogService.consoleLogTag(TAG,'onMessage','topic',topic);
            LogService.consoleLogTag(TAG,'onMessage','payload',payload);
            Emitter.emit(topic, payload);
        });

        this.Mqtt.on('error', (error) => {
            LogService.consoleLogTag(TAG,'onError','error',error);
            //eventHub.$emit('displayAlert', error.message);
        });
        
        this.Mqtt.stream.on('error', function (error) {
            LogService.consoleLogTag(TAG,'onStreamError','error',error);
            //eventHub.$emit('displayAlert', 'Connection error on '+error.target.url);
        });
        
        this.Mqtt.on('offline', function () {
            LogService.consoleLogTag(TAG,'onOffline');
            eventHub.$emit('mqttOffline');
        });
        
    }

}
