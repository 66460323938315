import AlarmResource from 'common/services/resources/alarm.js'
export default {
    data: function () {
        return {
            CONSTANTS:
            {
                COMMAND_ERROR : this.$gettext('Unable to send the command'),
                COMMAND_SUCCESS : this.$gettext('The command has been successfully sent'),
                ADD_ERROR : this.$gettext('Unable to add the device'),
                ADD_SUCCESS : this.$gettext('The device has been successfully added')
            },
            loading:false
        }
    },
    created: function () {
    },
	methods: {
		cancel: function(){
            this.eventHub.$emit('setInstallStep', 1);
            this.eventHub.$emit('setInternalMainStep', 4);
		},
		setStatus: function(status){
            var commandId = this.uuidV4();
            var _this = this;
            AlarmResource.setStatus('modules','id',this.$parent.alarm.id, commandId, status).then(
                function(response) {
                _this.eventHub.$emit('displayAlert',  _this.CONSTANTS.COMMAND_SUCCESS,'success');
                }).catch(
                function() {
                _this.eventHub.$emit('displayAlert',  _this.CONSTANTS.COMMAND_ERROR);
            });
        },
        next: function(){
            this.eventHub.$emit('nextInternalMainStep');
            this.eventHub.$emit('nextHeaderStep');
        },
	}
}
