/** 
 * 
 * EspPlugin Class
 * */ 

import StringService from 'common/services/string.js'
import LogService from 'common/services/log.js'


const TAG = 'EspPlugin';

const GENERAL_ERROR = 'PLUGIN_EXECUTE_ERROR';

export default class EspPlugin {

    constructor(eventHub,ssid,bssid,password,transmissionMethod = 'BROADCAST') {
    
      this.eventHub=eventHub;
      this.ssid=ssid;
      this.bssid=bssid;
      this.password=password;
      this.transmissionMethod=transmissionMethod;
    }

    startSmartConfig(){
        var _this = this;
        const method = 'startSmartConfig'; 
        try {
            cordova.exec(
                function(callback) {
                    LogService.consoleLogTag(TAG,method,'success',callback);
                    try{
                        var json = StringService.toJson(callback);
                        if(json.event){
                            LogService.consoleLogTag(TAG,method,TAG+'_'+json.event+' - success',json);
                            _this.eventHub.$emit(TAG+'_'+json.event, json);    
                        }
                    }
                    catch(error)
                    {
                        _this.returnGeneralErrorObj(method,error);   
                    }

                },
                function(error) {
                    _this.returnGeneralErrorObj(method,error);   

                },
                'EspPlugin',
                method,
                [
                    this.ssid,
                    this.bssid,
                    this.password,
                    this.transmissionMethod
                ]);
        }
        catch (error) {
            _this.returnGeneralErrorObj(method,error);
        }
    }
   
    stopSmartConfig(){
        var _this = this;
        const method = 'stopSmartConfig'; 
        try {
            cordova.exec(
                function(callback) {
                    LogService.consoleLogTag(TAG,method,'success',callback);
                    try{
                        var json = StringService.toJson(callback);
                        if(json.event){
                            LogService.consoleLogTag(TAG,method,TAG+'_'+json.event+' - success',json);
                            _this.eventHub.$emit(TAG+'_'+json.event, json);    
                        }
                    }
                    catch(error)
                    {
                        _this.returnGeneralErrorObj(method,error);
                    }

                },
                function(error) {
                    _this.returnGeneralErrorObj(method,error);
                },
                'EspPlugin',
                method,
                []);
        }
        catch (error) {
            _this.returnGeneralErrorObj(method,error);
        }
    }

    returnGeneralErrorObj(method, error){

        LogService.consoleLogTag(TAG,method,TAG+'_onError - error',error);
        this.eventHub.$emit(TAG+'_onError',this.getGeneralErrorObj());
    }

    getGeneralErrorObj(){
        return {
            "event": "onError",
            "error": GENERAL_ERROR,
            "result":{
                "apSsid":this.ssid,
                "apBssid":this.bssid,
                "apPassword":this.password,
                "transmissionMethod":this.transmissionMethod
            }
        }
    }

}     