import AddDeviceChooseType from './add-device-choose-type.vue'
import AddDevice1 from './add-device1.vue'
import AddDevice2 from './add-device2.vue'
import AddDevice3 from './add-device3.vue'
import AddDevice5 from './add-device5.vue'
import AddDevice7 from './add-device7.vue'

export default {
	props: {
		wizardStep: {
			type: Number
		},
		plant: {
			type: Object
		},
		models: {
			type: Array
		},
		homepilots: {
			type: Array
		},
		selectedHomepilot: {
			type: Object
		},
		brand: {
			type: Object
		},
		brands: {
			type: Array
		},
		defaultRooms: {
			type: Array
		},
		systems: {
			type: Array
		},
		icons: {
			type: Array
		},
		availableModels: {
			type: Array,
			default: undefined
		},
		user: {
			type: Object,
			default:{}
		},
		devices: {
			type: Array,
			default:[]
		},
		externalAccounts: {
			type: Object,
			default:{}
		},
		qrCodeElts: {
			type: Array,
			default:[]
		},
		model: {
			type: String,
			default:''
		},
		submodel: {
			type: Object,
			default:{}
		},
		prefilledMac: {
			type: String,
			default:''
		},
		chooseType: {
			type: String,
			default:''
		}
	},
	components: {AddDeviceChooseType,AddDevice1,AddDevice2,AddDevice3,AddDevice5,AddDevice7},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						ADD_SUCCESS : this.$gettext('The device has been successfully added'),
						ADD_ERROR : this.$gettext('Unable to add the device'),
						SCAN_PROMPT : this.$gettext('Place a QRCODE inside the scan area'),
						SCAN_ERROR : this.$gettext('Unrecognized product, please select it from the list'),
						NUMBER_SET_INTERVAL : 12,
						TIME_SET_INTERVAL : 10000
					},
					installStep : 1,
					installOnlyApp : ['cw','hz','lm','smart_camera','vdp','battery_vdp','t21'],
					//mainStepTable store internal step
					mainStepTable : [],
					normal_device : [],
					on_off_device : [],
					/*brand: '',*/
					deviceId: '',
					validation: false,
					homepilot: undefined,
					displayAdd: true,
					installType:'standard',
					deviceObj:{
						"identifier":undefined,
						"model": '',
						"submodel": {},
						"brand": '',
						"systems": [],
						"bearerId": '',
						"name": '',
						"locationId": '',
						"icon": '',
						"notificationLevel": 'INFO',
						"alert" : []
					},
					installObject: 
					{
					  wifi: 
					  {
						ssid: '',
						bssid:'',
						password:''
					  },
					  plug: 
					  {
						ssid: '',
						host: '',
						port: 0
					  },
					  device: 
					  {
						id:'',
						uid: '',
						password: '',
						identifier: ''
					  },
					  associationToken:''
					}
			}
		},
	created: function () {
		this.eventHub.$on('initDeviceObj', this.initDeviceObj);
		this.eventHub.$on('initInstallObject', this.initInstallObject);
		this.eventHub.$on('setInstallObject', this.setInstallObject);
		this.eventHub.$on('nextInstallStep', this.nextInstallStep);
		this.eventHub.$on('backInstallStep', this.backInstallStep);
		this.eventHub.$on('setInstallStep', this.setInstallStep);
		this.eventHub.$on('setInternalMainStep', this.setInternalMainStep);
		this.eventHub.$on('nextInternalMainStep', this.nextInternalMainStep);
		this.eventHub.$on('backInternalMainStep', this.backInternalMainStep);
		this.eventHub.$on('pushMainStepTable', this.pushMainStepTable);
		this.eventHub.$on('setDeviceId', this.setDeviceId);
		this.eventHub.$on('setValidation', this.setValidation);
		this.eventHub.$on('setInstallType', this.setInstallType);
		this.eventHub.$on('addDeviceFinished', this.addDeviceFinished);
		this.eventHub.$on('wizardPrevious', this.wizardPrevious);
		this.eventHub.$on('setDeviceObj', this.setDeviceObj);
		this.eventHub.$on('addAssociateDevice', this.addAssociateDevice);
		this.eventHub.$on('retryInternalInstall', this.retryInternalInstall);
		this.eventHub.$emit('setStepName', 'wizard-internal');
		this.deviceTable();
	},
	destroyed: function () {
		this.eventHub.$off('initDeviceObj', this.initDeviceObj);
		this.eventHub.$off('initInstallObject', this.initInstallObject);
		this.eventHub.$off('setInstallObject', this.setInstallObject);
		this.eventHub.$off('nextInstallStep', this.nextInstallStep);
		this.eventHub.$off('backInstallStep', this.backInstallStep);
		this.eventHub.$off('setInstallStep', this.setInstallStep);
		this.eventHub.$off('setInternalMainStep', this.setInternalMainStep);
		this.eventHub.$off('nextInternalMainStep', this.nextInternalMainStep);
		this.eventHub.$off('backInternalMainStep', this.backInternalMainStep);
		this.eventHub.$off('pushMainStepTable', this.pushMainStepTable);
		this.eventHub.$off('setDeviceId', this.setDeviceId);
		this.eventHub.$off('setValidation', this.setValidation);
		this.eventHub.$off('setInstallType', this.setInstallType);
		this.eventHub.$off('addDeviceFinished', this.addDeviceFinished);
		this.eventHub.$off('wizardPrevious', this.wizardPrevious);
		this.eventHub.$off('setDeviceObj', this.setDeviceObj);
		this.eventHub.$off('addAssociateDevice', this.addAssociateDevice);
		this.eventHub.$off('retryInternalInstall', this.retryInternalInstall);
	},
	methods: {
		initDeviceObj: function(){
			this.deviceObj = {
				"identifier":undefined,
				"model": '',
				"submodel": {},
				"brand": '',
				"systems": [],
				"bearerId": '',
				"name": '',
				"locationId": '',
				"icon": '',
				"notificationLevel": 'INFO',
				"alert" : []
			};
		},

		initInstallObject: function(){
			this.installObject = {
				wifi: 
				{
				  ssid: '',
				  bssid:'',
				  password:''
				},
				plug: 
				{
				  ssid: '',
				  host: '',
				  port: 0
				},
				device: 
				{
				  id:'',
				  uid: '',
				  password: '',
				  identifier: ''
				},
				associationToken:''
			  };
		},
		setInternalMainStep: function(value,installType){
			this.eventHub.$emit('setWizardStep',value);
			
			/*if(installType)
			{
			  this.setInstallType(installType);
			}
			this.setInstallStep(1);
			this.setInternalMainStep(4);
			this.eventHub.$emit('setMainStep', 3);*/
		},
		nextInternalMainStep: function(){
			this.eventHub.$emit('nextWizardStep');
		},
		backInternalMainStep: function(){
			this.eventHub.$emit('backWizardStep');
		},
		setInstallStep: function(value){
			this.installStep = value;
			this.$nextTick(function() {
				this.eventHub.$emit('setHeaderStep', value);
			});
		},
		nextInstallStep: function(){
			this.installStep=parseInt(this.installStep)+1;
			this.eventHub.$emit('nextHeaderStep');
		},
		backInstallStep: function(){
			this.installStep=parseInt(this.installStep)-1;
			this.eventHub.$emit('backHeaderStep');
		},
		setDeviceId: function(id){
			this.deviceId = id;
		},
		
		setValidation: function(value){
			this.validation = value;
		},
		setInstallType: function(value){
			this.installType = value;
		},
		
		setInstallObject: function(prop, value){
			this.installObject[prop] = value;
		},

		addAssociateDevice: function(device){
			var brand = _find(this.brands, {'name': device.device.brand});
			if(brand)
			{
				device.device.brandObj = brand;
			}
			this.devices.push(device);
		},
		addDeviceFinished: function(){
			if(this.$route.name=='device-install')
				this.$router.push('/home/rooms');
			else
				this.eventHub.$emit('closeInstallModal','installModal');
		},
		wizardPrevious: function(){

			if(((this.mainStepTable.length == 0 && this.installStep == 1 ) || this.wizardStep == 1) && this.inApp==true){
				this.eventHub.$emit('initMainStepFromInternal');
			}
			else if(this.mainStepTable.length == 0 && this.installStep == 1 && this.inApp==false){
				this.eventHub.$emit('initMainStepFromInternal');
			}
			else
			{
				/* Zone 1 : Revenir au wizardStep d'avant en sautant si il y en a qu'un seul */
				if(this.wizardStep <= 4 && this.installStep == 1){
					this.setInstallType('standard');
					var mainStepTableIndex = 1;
					if(this.mainStepTable.length>0)
					{
						if(this.wizardStep==4)
						{
							mainStepTableIndex = this.mainStepTable.length - 1;
						}
						else
						{
							this.mainStepTable.pop();
							mainStepTableIndex = this.mainStepTable.length - 1;
						}
						if(this.mainStepTable[mainStepTableIndex]==1)
						{
							this.eventHub.$emit('initMainStepFromInternal');
						}
						this.setInternalMainStep(this.mainStepTable[mainStepTableIndex]);
					}
					else
					{
						this.eventHub.$emit('initMainStepFromInternal');
					}

				}
				/* Zone 2 : Revenir au step d'avant dans l'installation */
				if(this.wizardStep == 4 && this.installStep != 1 && this.installStep != 100){
					this.backInstallStep();
				}

				/* Zone HP : Revenir au cable quand sur choose */
				if(this.wizardStep == 5 && this.installStep == 100){
					this.eventHub.$emit('nextChooseStep', 'cable', 'back');
				}

				if(this.wizardStep >= 6){
					this.eventHub.$emit('backHeaderStep');
					this.backInternalMainStep();
					if(this.model.name == 'homepilot'){
						this.installStep = 1;
					}
				}
			}
		},
		deviceTable : function(){
			for(var i =0; i<this.models.length; i++){
				if(this.models[i].submodels)
				{
					for(var j =0; j<this.models[i].submodels.length; j++){
						let submodel = this.models[i].submodels[j];
						if(submodel.feedback != undefined){
							if(submodel.feedback)
								this.normal_device.push(submodel.name);
							else{
								this.on_off_device.push(submodel.name);
							}
						}
					}
				}
			}
		},
		setDeviceObj : function(deviceObj){
			this.deviceObj = deviceObj;
		},

		isInstallOnlyApp : function(model, submodel){
			if(!this.inApp && 
			(model.type=="WIFI" || ( model.gateway && (submodel.name.toLowerCase()  != 'homepilot' && submodel.name.toLowerCase()  != 'hp-300'))
			))
			{
				this.eventHub.$emit('displayAlert', this.$gettext('This action is only available on mobil app'));
				return false;
			}
			return true;
		},

		retryInternalInstall : function(installType,installStep){
			if(installType)
			{
			  this.setInstallType(installType);
			}
			this.setInstallStep((installStep && installStep > 1?installStep-1:1));
			this.setInternalMainStep(4);
			this.eventHub.$emit('setMainStep', 3);
		},



   }
}
