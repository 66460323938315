import AddRoom1 from './add-room1.vue'
import AddRoom2 from './add-room2.vue'

export default {
	props:['title','plant','selectedRoom', 'device', 'defaultRooms', 'page', 'noCloseDialog'],
	components: {AddRoom1,AddRoom2},
	data:
		function () {
			return {
				CONSTANTS:
				{
					ROOM_ERROR_LEVEL : this.$gettext('You can\'t create a room at this level because it\'s already the smaller level'),
					ROOM_ERROR : this.$gettext('Unable to add the room'),
					ROOM_SUCCESS : this.$gettext('The room has been successfully added'),
					ROOM_EDIT_ERROR : this.$gettext('Unable to edit the room'),
					ROOM_EDIT_SUCCESS : this.$gettext('The room has been successfully edited'),
					BACKGROUND_ERROR : this.$gettext('Please add a background'),
					INPUT_ERROR : this.$gettext('You need to give a name'),
					BACKGROUND_INPUT_TITLE : this.$gettext('Modify the background'),
				},
				step : 'default',
				loadingValidate: false,
				blocking: false
			}
		},
	created: function () {
		this.init();
		this.eventHub.$on('validate', this.validate);
		this.eventHub.$on('setLoadingValidate',  this.setLoadingValidate);
		this.eventHub.$on('setLoadingDelete',  this.setLoadingDelete);
		this.eventHub.$emit('changeOrientation', 'portrait');
	},
	destroyed: function () {
		this.eventHub.$off('validate', this.validate);
		this.eventHub.$off('setLoadingValidate',  this.setLoadingValidate);
		this.eventHub.$off('setLoadingDelete',  this.setLoadingDelete);
		//this.eventHub.$emit('changeOrientation', 'user');
	},
	methods: {
		init: function(){
			if(this.selectedRoom)
				this.step = 'create';
		},
		changeStep: function(step){
			this.step = step;
		},
		validate: function(){
			this.blocking = true;
			this.loadingValidate = true;
			this.$refs['createStep'].validate();
		},
		setLoadingValidate: function(value){
			this.blocking = value;
			this.loadingValidate = value;
		},
		deleteRoom: function(){
			this.blocking = true;
			this.loadingDelete = true;
			this.eventHub.$emit('deleteRoom', this.selectedRoom);
		}
    }
}
