import Swiper from 'swiper/bundle';
export default {
    props:{
    },
    data:
        function () {
            return {
                swiper: null,
                pages:
                [
                    {
                        title:this.$gettext('Page d’accueil'),
                        texts: [
                            this.$gettext('Accèdez directement à chaque pièce de votre maison et utilisez le menu principal pour naviguer dans l’App et lancer des actions.')
                        ],
                        img: require('@/assets/images/tutorial/Intro-Home.png')
                    },
                    {
                        title:this.$gettext('Les widgets'),
                        texts: [
                            this.$gettext('Visualisez et pilotez tous vos équipements grâce aux widgets.'),
                            this.$gettext('La zone du haut = action rapide,'),
                            this.$gettext('la zone du bas = fonctionnalités complètes.'),
                        ],
                        img: require('@/assets/images/tutorial/Intro-Widgets.png')
                    },
                    {
                        title:this.$gettext('Menu « Ajouter »'),
                        texts: [
                            this.$gettext('Ajoutez de nouveaux équipements, connectez des applications tierces, créez des scénarios ou partagez votre maison avec d’autres utilisateurs !')
                        ],
                        img: require('@/assets/images/tutorial/Intro-AddMenu.png')
                    },
                    {
                        title:this.$gettext('Contrôle vocal'),
                        texts: [
                            this.$gettext('Pilotez à la voix tous vos équipements et scénarios depuis l’application (intégre la commande vocale hors ligne) ou depuis les assistants vocaux existants.')
                        ],
                        img: require('@/assets/images/tutorial/Intro-VoiceControl.png')
                    },
                    {
                        title:this.$gettext('Scénarios'),
                        texts: [
                            this.$gettext('Réalisez un scénario facilement, créez des routines et connectez vos équipements entre eux.'),
                            this.$gettext('Activez-les / Désactivez-les ou lancez-les manuellement.'),
                        ],
                        img: require('@/assets/images/tutorial/Intro-Scenario.png')
                    },
                    {
                        title:this.$gettext('Profil'),
                        texts: [
                            this.$gettext('Gérez votre compte, accédez aux paramètres complets de votre maison et de vos équipements.'),
                            this.$gettext('La boite à outils de votre compte Comfort Life !'),
                        ],
                        img: require('@/assets/images/tutorial/Intro-Profil.png')
                    },
                    {
                        title:this.$gettext('Notifications'),
                        texts: [
                            this.$gettext('Activez les notifications sur chacun de vos équipements pour être alerté à tout moment des événements concernant votre maison.'),
                        ],
                        img: require('@/assets/images/tutorial/Intro-Notifications.png')
                    },
                ]
            }
        },

    mounted: function () {
       // window.setTimeout(()=>{
            this.$nextTick(function() {
                this.initSwiper();
            });
        //}, 5000);

    },
    methods: {
		initSwiper: function()
		{
			this.swiper = new Swiper('.tutorial-swiper',{
				pagination: {
					el: '.tutorial-swiper-pagination',
					clickable: true
				},
				mousewheel: true,
				slidesPerView: 1,
              });
 
        },
        previous: function()
		{
            this.swiper.slidePrev();
        },
        next: function()
		{
            this.swiper.slideNext();
        },
        start: function()
		{
            this.$emit('onStart');
		},
    }
}
  