import CordovaService from 'common/services/cordova.js'
export default class PushRegister {

    constructor(eventHub) {
      this.subscriptions=[];

      var _this = this;
      
      eventHub.$on('pushRegister_onNewToken', 
      function(json)
      {
      });

      eventHub.$on('pushRegister_onSubscribeSuccess', 
      function(json)
      {
        if(!_this.checkSubscriptionExists(json.topic))
        {
          _this.subscriptions.push(json.topic);
        }
      });

      eventHub.$on('pushRegister_onSubscribeFail', 
      function(json)
      {

      });

      eventHub.$on('pushRegister_onUnsubscribeSuccess', 
      function(json)
      {

      });

      eventHub.$on('pushRegister_onUnsubscribeFail', 
      function(json)
      {

      });

      eventHub.$on('pushRegister_onMessageReceived', 
      function(json)
      {

      });

      eventHub.$on('pushRegister_onNotificationTap', 
      function(json)
      {

      });

    }

    clear(){
      this.subscriptions.splice(0);
    }

    checkSubscriptionExists(subscription){
      return (this.subscriptions.indexOf(subscription)!=-1);
    }

    unsubscribePush(_this) {
      this.subscriptions.forEach(function(subscription){
        CordovaService.pushUnregister(_this, undefined, undefined, subscription);
      });
    }
}