export default {
    props: ['scrollableElement'],
    data:
        function () {
                    return {
                                CONSTANTS:
                                {
                                },
                                isNotDown: false,
                                show: false
                    }
                },
    created:
        function () {
            this.$nextTick(function() {
                window.addEventListener('resize', this.setDisplay);
                this.scrollableElement.addEventListener('scroll', this.setIsNotDown);
                this.scrollableElement.style.scrollBehavior = 'smooth';
                this.init();
            });
    },
    destroyed: function () {
        window.removeEventListener('resize', this.setDisplay);
        this.scrollableElement.removeEventListener('scroll', this.setIsNotDown);
        this.scrollableElement.style.scrollBehavior = 'auto';
    },

    methods: {
		init : function(){
            this.$nextTick(function() {
                this.scrollableElement.scrollTop = 0;
                this.setDisplay();
            });
        },

        setDisplay : function(){
            var _this = this;
            setTimeout(function() {
                _this.setShow();
                if(_this.show)
                {
                    _this.setIsNotDown();
                }
            }, 1);
        },  

        setShow : function(){
            this.show = (this.scrollableElement.clientHeight<this.scrollableElement.scrollHeight);
        }, 

        setIsNotDown : function(){
            this.isNotDown = (this.scrollableElement.scrollHeight - this.scrollableElement.scrollTop > this.scrollableElement.offsetHeight);
        },        

        onClick : function(){
            if(this.isNotDown)
            {
                this.scrollableElement.scrollTop = this.scrollableElement.scrollHeight;
            }
            else
            {
                this.scrollableElement.scrollTop = 0;
            }
		},
    }

}
