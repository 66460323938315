import Device2_4InstallConfigDevice from './device-2_4-install-config-device.vue';
import Device2_4InstallConnect from './device-2_4-install-connect.vue';
import Device2_4InstallAssoc from './device-2_4-install-assoc.vue';

export default {
	props: ['homepilot', 'model', 'submodel', 'step', 'qrCodeElts', 'installType','installObject'],
	components: {
    Device2_4InstallConfigDevice,
    Device2_4InstallConnect,
    Device2_4InstallAssoc,
  },
    data: function () {
        return {
          	CONSTANTS:
            {
            },
            installSteps:{
              'default' : 
              [
                {
                  name: 'device2_4-install-connect',
                  props:
                  {
                    txt: this.$gettext('Please enter the identifier of the device')
                  }
                },
                {
                  name: 'device2_4-install-config-device',
                  props:
                  {
                    steps: [
                      {
                        'txt': this.$gettext('Turn on the power and reset the device according to your manual'),
                        'img': '<img :src="require(\'@/assets/images/device/cw/cl-install-'+this.submodel.name+'-connection.png\')" onError="this.src = require(\'@/assets/images/device/install/generic-connection.png\')"/>',
                      }
                    ]
                  }
                },
                {
                  name: 'device2_4-install-assoc',
                  props:
                  {
                    txt: this.$gettext('Please wait, your device is being associated with your account.'),
                    steps:
                    [
                      {
                        loading: false,
                        isValid: true,
                        txt: this.$gettext('Registering your equipment on the Cloud')
                      }
                    ]
                  }
                }
              ]
            },
            installStepsSelected:[],
            deviceInstallObject: 
            {
              device: 
              {
                identifier: '',
              },
            }
        }
    },
    created: function () {
      this.init();
    },
	  methods: { 
      init : function(){
        // Init HZ model and LM model like CW
        //this.installSteps['hz'] = this.installSteps['lm'] = this.installSteps['cw'];
        var installType = this.installType;

        this.installStepsSelected = JSON.parse(JSON.stringify(this.installSteps['default']));

        this.initInstallType(installType);
        
        //If QRCode 
        if(this.qrCodeElts[2])
        {
          this.installObject.device.identifier = this.qrCodeElts[2];
          this.eventHub.$emit('nextInstallStep');
        }
      },
      
      initInstallType : function(installType){
        this.eventHub.$emit('setStepper', [
          {'title' : 'Device configuration'},
          {'title' : 'Device configuration'},
          {'title' : 'Association'},
          {'title' : 'Notifications'},
          {'title' : 'Name'},
          {'title' : 'Room'}
        ], 1);
      }
	}
}
