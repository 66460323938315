import Rb1 from './rb1.vue';
import Rb2 from './rb2.vue';
import Rb3 from './rb3.vue';

export default {
	props: ['homepilot','model', 'submodel', 'step', 'deviceObj'],
	components: {Rb1,Rb2,Rb3},
    data: function () {
        return {
            CONSTANTS: {
						  'IMG_PATH' : 'assets/images/device/rb/'
                       }
        }
    },
    created: function () {
		this.eventHub.$emit('setStepper', [
			{'number' : 1, 'title' : 'Connection'},
			{'number' : 2, 'title' : 'Association'},
			{'number' : 3, 'title' : 'Test'},
			{'number' : 4, 'title' : 'Notifications'},
			{'number' : 5, 'title' : 'Name'},
			{'number' : 6, 'title' : 'Room'}
		], 1);
    },
	  methods: {
	}
}
