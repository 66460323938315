
import ElementsList from 'common/components/elements-list/elements-list.vue'

export default {
	props: {
		serviceModels:{
			type: Array,
			default:[]
		}
	},
	components: {ElementsList},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						TITLE: {
							service : 
							{
								main: this.$gettext('add a kit'),
								sub: this.$gettext('What would you like to add?'),
								header: this.$gettext('Kit')
							},
							heating : 
							{
								main: this.$gettext('Add a heating kit'),
								sub: this.$gettext('What is your type of heating?'),
								header: this.$gettext('Type')
							}
						}
					},
					title:'',
					elementsListObj:
					{
						title: '',
						parentObj:{},
						elements: []
					},
					serviceParent:undefined,
					selected: []
			}
		},
	created: function () {
		this.setTitle(this.CONSTANTS.TITLE['service']);
		this.setElements(this.serviceModels);
		this.eventHub.$emit('setServiceDisplayPrevious', false);
	},
	destroyed: function () {
	},
	mounted: function () {
	},
	computed: {
		filteredElements: function () {
			var _this = this;
			this.elementsListObj.elements.forEach(function(elt) {
				elt.translateName = _upperFirst(_this.$gettext(elt.displayName?elt.displayName:elt.name));
			});
			return this.elementsListObj.elements;
		}
	},
	watch: {
  	},
	methods: {
		setTitle: function(title){
			this.title =  _upperFirst(title.main);
			this.elementsListObj.title = _upperFirst(title.sub);
			this.elementsListObj.header = title.header;
		},
		setElements: function(elements){
			this.elementsListObj.elements = elements;
		},
		setParent: function(parentObj){
			this.elementsListObj.parentObj.title = parentObj.title;
			this.elementsListObj.parentObj.elements = parentObj.elements;
		},
		servicePrevious: function(){
			this.elementsListObj.title = this.elementsListObj.parentObj.title;
			this.elementsListObj.header = this.elementsListObj.parentObj.header;
			this.setElements(this.elementsListObj.parentObj.elements);
			this.setTitle(this.CONSTANTS.TITLE['service']);
			this.serviceParent = undefined;
		},
		chooseService: function(s){
			if(this.serviceParent==undefined)
				this.serviceParent = s;
			/*if(s.submodels)
			{
				this.setParent(this.elementsListObj);
				this.setTitle(this.CONSTANTS.TITLE[s.name]);
				this.setElements(s.submodels);
			}
			else
			{*/
				s.serviceParent = this.serviceParent;
				this.$emit('onClick',s);
			//}
		}
	}
}
