import Camera from 'common/classes/Camera.js'
import Global from 'common/services/resources/global.js'
import StorageService from 'common/services/storage.js'
export default {
    props:['selectedHomepilot','homepilots', 'model', 'submodel'],
    data: function () {
        return {
          CONSTANTS:
          {
              'TITLE' : this.$gettext('To which <strong>homepilot</strong> do you want to associate the device ?'),
              'DEVICES_NOT_NEED_HP' :  ['cw','hz','lm'],
              'RF_HOMEPILOT' :  ['hp-300','homepilot'],
              'HOMEPILOT_REQUIRE' :  this.$gettext('This device requires a homepilot'),
              'BRAND_NEED_HP' :  ['semac','honeywell','iringer','home_confort','koda', 'neo','gstar_chip','navigator'],
          }
        }
    },
    created: function () {
      this.$parent.mainStepTable = [1,2,3];
      this.oneHomepilot();
      this.eventHub.$emit('setDisplayTitle', true, 'Add a device');
    },	
    computed: {
      computedHomepilots: function () {
        var plantId = StorageService.getValue(localStorage, 'plantId');
        return _filter(this.$store.state.homepilots, (hp) => {
          return (hp.plantId  == plantId); 
        });
      }
    },

	  methods: {
      getBrandSrc: function (brand) {
        return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
      },
      oneHomepilot: function(){
        this.$parent.homepilot = undefined;
        if(this.model.type.toLowerCase() == 'wifi')
        {
          this.$parent.mainStepTable = [1,2];
          this.eventHub.$emit('nextInternalMainStep');
        }
        else 
        {
          if(this.computedHomepilots.length == 0)
          {
            this.eventHub.$emit('displayAlert',  this.CONSTANTS.HOMEPILOT_REQUIRE);
            this.eventHub.$emit('previous');
          }
          else if(this.computedHomepilots.length == 1 || this.selectedHomepilot)
          {
            this.$parent.mainStepTable = [1,2];
            this.$parent.homepilot = (this.selectedHomepilot?this.selectedHomepilot:this.computedHomepilots[0]);
            this.eventHub.$emit('nextInternalMainStep');
          }
        }
      },
      chooseHomepilot : function(homepilot){
  			this.$parent.homepilot = homepilot;
        this.eventHub.$emit('nextInternalMainStep');
  		}
	  }
}
