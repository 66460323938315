export default {
  props:['result'],
  data: function () {
    return {
      CONSTANTS:{
        INSTALLATION_SUCCESS: this.$gettext('The installation of your Home Pilot was successful.<br/>Click Next to set up your device.'),
        INSTALLATION_ERROR: this.$gettext('<strong>Unable to add the device</strong></br>Please check your device and try again.'),
      },
    }
  },
  created: function () {
  },
	methods: {
		next : function(status){
      if(status){
        this.eventHub.$emit('nextInternalMainStep');
        this.eventHub.$emit('nextHeaderStep');
      }
      else
      {
        this.eventHub.$emit('setMac', '');
        this.eventHub.$emit('setInstallStep', 1);
        this.eventHub.$emit('setHeaderStep', 1);
      }
    }
	}
}
