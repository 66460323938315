import store from 'vuex_path/store';

export default {
    props: ['module'],
    data: function () {
        return {
            status: "0000000000000000"
        }
    },
	created: function () {
        this.status = store.state.outdoorlights.value;
	},
	methods: {
		setStatus : function(value){
            var hexaValue = "FF080201"
            if(value == 'g1'){
                if(this.status.substr(0, 8) == ("11111111")){
                    for(var i=0; i<8; i++){
                        this.status = this.status.substr(0, i) + "0" + this.status.substr(i + 1);
                    }
                    hexaValue += "01FF0000000000";
                }
                else{
                    for(var i=0; i<8; i++){
                        this.status = this.status.substr(0, i) + "1" + this.status.substr(i + 1);
                    }
                    hexaValue += "01FF646464FFFF";
                }
            }
            else if(value == 'g2'){
                if(this.status.substr(8, 17) == ("11111111")){
                    for(var i=8; i<16; i++){
                        this.status = this.status.substr(0, i) + "0" + this.status.substr(i + 1);
                    }
                    hexaValue += "02FF0000000000";
                }
                else{
                    for(var i=8; i<16; i++){
                        this.status = this.status.substr(0, i) + "1" + this.status.substr(i + 1);
                    }
                    hexaValue += "02FF646464FFFF";
                }
            }
            else if(value == 'all'){
                if(this.status.indexOf(0) != -1){
                    this.status = "1111111111111111";
                    hexaValue += "FFFF646464FFFF";
                }
                else{
                    this.status = "0000000000000000";
                    hexaValue += "FFFF0000000000";
                }
            }
            else{
                this.status = this.status.substr(0, value) + (this.status.charAt(value)=="0"?"1":"0") + this.status.substr(value + 1);   
                hexaValue += (value<8?"01":"02");
                hexaValue += (value+1>9?value+1:"0"+(value+1));;
                hexaValue += (this.status.charAt(value)=="0"?"0000000000":"646464FFFF");
            }
            hexaValue += this.getFcs(hexaValue);
            store.state.outdoorlights.value = this.status;
            this.$parent.$emit('set-status', hexaValue);
        },
        getFcs: function(value){
            var total = 0;
            for(var i = 0; i <= value.length - 2; i=i+2){
                var hexa = value.substr(i, 2);
                var deci = parseInt(hexa,16);
                total = total + deci;
            }
            var fcs = total.toString(16).substr(total.toString(16).length - 2, 2).toUpperCase();
            return fcs;
        }
	}
}
