import moment from 'moment'

import PayloadManagerService from 'common/services/payloadManager.js'
import CameraErrorCode from 'common/classes/CameraErrorCode.js';
import UserResource from 'common/services/resources/user.js'
import StorageService from 'common/services/storage.js'
import ClientResource from 'common/services/resources/client.js'
import SystemService from 'common/services/system.js'
import StringService from 'common/services/string.js'
import LanguageService from 'common/services/language.js'
import LogService from 'common/services/log.js'
import CommonResource from 'common/services/resources/common.js'
import UartService from 'common/services/uart.js'
import { consoleError } from 'vuetify/es5/util/console';

const TAG = 'CORDOVA';

export default {
    CONSTANTS:
    {
        PROCESS_NB_TRIES:5
    },
    //this.$gettextInterpolate(this.$gettext('Are you sure you want to delete the scenario \'%{s}\'?'), {s: this.selected.name});
    displayCameraImg: [],
    displayFullscreenTimeout : undefined,
    process:[],
    /* HOMEPILOT */

    pushSubscription: [],

    setStatus: function (json, port, repeatCount, delayBetweenRepeats) {
        LogService.consoleLogTag(TAG,'setStatus');
        LogService.consoleLogTag(TAG,'setStatus','json',json);
        return new Promise(
            function(resolve, reject){
                try {
                    cordova.exec(
                        function(winParam) {
                            console.log("wincallback @HomePilotDriver service");
                            return resolve(winParam);

                        },
                        function(error) {
                            console.log("error @HomePilotDriver service");
                            return reject(error);

                        },
                        "HomePilotDriver",
                            "send_cmd",
                        [
                            json,
                            (port?port:'8000'),
                            (repeatCount?repeatCount:'3'),
                            (delayBetweenRepeats?delayBetweenRepeats:'50')
                        ]);
                }
                catch (e) {
                    console.log('cordova.js - setStatus - error');
                }
            }
        );
    },

    triggerScenario: function (json){
      return new Promise(
       function(resolve, reject){
           try {
               cordova.exec(
                   function(winParam) {
                       console.log("wincallback @HomePilotDriver service");
                       return resolve(winParam);

                   },
                   function(error) {
                       console.log("error @HomePilotDriver service");
                       return reject(error);

                   },
                   "HomePilotDriver",
                       "send_cmd",
                   [json]);
           }
           catch (e) {
               console.log('cordova.js - triggerScenario - error');
           }
        }
      );
    },

    getInfoPlugEliot: function (deviceUid, commandId) {
        return new Promise(
            function(resolve, reject){
                var json =  {
                                "cmd": "get_info",
                                "device_id": [deviceUid],
                                "id" : commandId
                            };
                try {
                    cordova.exec(
                        function(winParam) {
                            console.log("wincallback @HomePilotDriver service");
                            return resolve(winParam);

                        },
                        function(error) {
                            console.log("error @HomePilotDriver service");
                            return reject(error);

                        },
                        "HomePilotDriver",
                            "send_cmd",
                        [json]);
                }
                catch (e) {
                    console.log('cordova.js - getInfoPlugEliot - error');
                }
            }
        );
    },


    /* UDP */

    startUdp: function(eventHub,app){
      try {
        cordova.exec(
                function(winParam) {
                    console.log('UDP CALLBACK :');
                    console.log(winParam);
                    try{
                        var payload = StringService.toJson(winParam);  
                        /*console.log('payload');
                        console.log(payload);*/
                        if(payload.event && payload.event=='onError')
                        {
                            eventHub.$emit('udpServerPlugin_'+payload.event,payload);
                        }
                        else
                        {
                            eventHub.$emit('callbackManagement', payload, 'udp');
                        }
                    }
                    catch(e)
                    {
                        //console.log(e);
                    }  
                },
                function(error) {
                    console.log("startUdp - error");
                    console.log(error);
                },
                "UserLoginStatus",
                "user_logged_in",
                []);
        }
        catch (e) {
            console.log(e);
            console.log('cordova.js - startUdp - error');
        }
    },

    stopUdp: function(){
        try {
          cordova.exec(
                  function(winParam) {
                  },
                  function(error) {
                      console.log("stopUdp - error");
                  },
                  "UserLoginStatus",
                  "user_logged_out",
                  []);
          }
          catch (e) {
              console.log('cordova.js - stopUdp - error');
          }
      },

      restartUdp: function(eventHub){
        var _this = this;
        try {
            cordova.exec(
                    function(winParam) {
                        console.log('restartUdp');
                        _this.startUdp(eventHub);
                    },
                    function(error) {
                        console.log("restartUdp - error");
                        console.log(error);
                    },
                    "UserLoginStatus",
                    "user_logged_out",
                    []);
            }
            catch (e) {
                console.log(e);
                console.log('cordova.js - restartUdp - error');
            }
      },

    /* ORIENTATION */

    changeOrientation: function(orientation){
        if(orientation==undefined)
        {
            orientation = 'portrait';
        }
        try {
            cordova.exec(
            function(winParam) {
                console.log("wincallback @ScreenRotationPlugin");

            },
            function(error) {
                console.log("error @ScreenRotationPlugin" + error);

            },
            "ScreenRotationPlugin",
                "rotate_screen",
            [orientation]);
        }
        catch (e) {
            console.log(e);
            console.log('cordova.js - changeOrientation - error');
        }
    },

    /* BARCODE */

    barcodeScan : function (eventHub, prompt) {
        cordova.plugins.barcodeScanner.scan(
            function (result)
            {
                console.log("We got a barcode\n" +
                      "Result: " + result.text + "\n" +
                      "Format: " + result.format + "\n" +
                      "Cancelled: " + result.cancelled);
                eventHub.$emit('barcodeScanSuccess',result);
            },
            function (error)
            {
                console.log("Scanning failed: " + error);
            },
            {
                showFlipCameraButton : true, // iOS and Android
                prompt : prompt, // Android
                resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                formats : "QR_CODE", // default: all but PDF_417 and RSS_EXPANDED
                disableAnimations : true, // iOS
                disableSuccessBeep: false // iOS and Android
            }
         );
    },

    /* EZVIZ */

    cameraError: function (error,eventHub,module,uuidV4) {
        var cameraErrorCode = new CameraErrorCode(error);
        this.process[uuidV4].tries++;
        if(this.process[uuidV4].tries<=this.CONSTANTS.PROCESS_NB_TRIES)
        {
            if(this.process[uuidV4].tries==this.CONSTANTS.PROCESS_NB_TRIES)
            {
                cameraErrorCode.initializeToGeneral();
            }
            eventHub.$emit('cameraError',cameraErrorCode,module,uuidV4);
        }  
    },

    playCamera : function(_this,externalAccounts,module,frameRate, uuidV4)
    {
        try {
            var externalAccount = _find(externalAccounts, {external:{name:module.device.brand.toLowerCase()}});
            var accessToken = (externalAccount && externalAccount.account? externalAccount.account.accessToken:'');
            var appKey = (externalAccount && externalAccount.external? externalAccount.external.appKey:'');
            this.displayCameraImg['dci-'+_this._uid] = true;
            if(this.process[uuidV4]==undefined)
            {
                this.process[uuidV4] = {tries:0}; 
            }
            var _t = this;
            cordova.exec(
            function(winParam) {
                //var imgData =
                /*console.log('WINPARAM');
                console.log(winParam);*/
                if(winParam != 'OK' )
                {
                    
                    console.log(imgData);
                    console.log(_this);
                    console.log('dci-'+_this._uid);
                    console.log(_t.displayCameraImg['dci-'+_this._uid]);
                    try{
                        var json = StringService.toJson(winParam);

                        if(_t.displayCameraImg['dci-'+json.uid])
                        {
                            var imgData = 'data:image/jpeg;base64,' + json.base64Img;
                            _this.src = imgData;
                        }
                        if(frameRate && _t.displayCameraImg['dci-'+json.uid])
                        {
                            _t.displayCameraImg['dci-'+json.uid] = false;
                            setTimeout(function() {
                                _t.displayCameraImg['dci-'+json.uid] = true;
                            }, (1000/frameRate));
                        }
                          
                    }
                    catch(e)
                    {
                        console.log('playCamera error');
                        console.log(e);
                    }
                }
            },
            function(error) {
                _t.cameraError(error,_this.eventHub,module,uuidV4);
                console.log("playCamera error");
                console.log(error);
            },
            "EZPlugin",
            "play_camera",
            [
                accessToken,
                module.device.deviceSerial,
                module.device.channelNo,
                _this._uid,
                (module.device.verifyCode?module.device.verifyCode:''),
                appKey,
                frameRate
            ]);
        }
        catch (e) {
            console.log('cordova.js - playCamera - error');
            console.log(e);
        }            
    },
    
    stopCamera: function(_this){
        var _t = this;
        try {
            cordova.exec(
                function(winParam) {
                },
                function(error) {
                    console.log("error @stopCamera plugin");
                },
                "EZPlugin",
                "stop_camera",
                [_this._uid]);
        }
        catch (e) {
            console.log('cordova.js - stopCamera - error');
        }  
    },

    launchFullscreen : function(eventHub, externalAccounts,module,orientation,uuidV4, displayDuration)
    {
        try {
            if(orientation==undefined)
            {
                orientation='portrait';
            }
            if(this.process[uuidV4]==undefined)
            {
                this.process[uuidV4] = {tries:0}; 
            }
            var externalAccount = _find(externalAccounts, {external:{name:module.device.brand.toLowerCase()}});
            var accessToken = (externalAccount && externalAccount.account? externalAccount.account.accessToken:'');
            var appKey = (externalAccount && externalAccount.external? externalAccount.external.appKey:'');
            var _this= this;
            cordova.exec(
                function(winParam) {
                    console.log('launchFullscreen');
                    console.log(winParam);
                    if(winParam == 'OK')
                    {
                        eventHub.$emit('launchEZFullscreenFinished');
                        console.log(displayDuration);
                        if(displayDuration)
                        {
                            clearTimeout(_this.displayFullscreenTimeout);
                            _this.displayFullscreenTimeout = setTimeout(function() {
                                eventHub.$emit('stopEZFullscreen');
                             }, displayDuration*1000);
                        }
                    }
                    else{
                        try{
                            var json = StringService.toJson(winParam);
                            if(json.event=="onBackPressed")
                            {
                                eventHub.$emit('stopEZFullscreenFinished');
                            }
                              
                        }
                        catch(e)
                        {
                            console.log('launchFullscreen error');
                            console.log(e)
                        }
                    }
                    console.log(winParam);
                    console.log("winParam @fullscreen plugin");
                    
                },
                function(error) {
                    console.log(error);
                    console.log("launchFullscreen error");
                    _this.cameraError(error,eventHub,module,uuidV4);
                },
                "EZPlugin",
                "show_fullscreen",
                [
                    accessToken,
                    module.device.deviceSerial,
                    module.device.channelNo,
                    orientation,
                    (module.device.verifyCode?module.device.verifyCode:''),
                    appKey
                ]);
        }
        catch (e) {
            console.log(e);
            console.log('cordova.js - launchFullscreen - error');
        }      
    },

    stopEZFullscreen: function(_this, noEmit){
        try {
            cordova.exec(
                function(winParam) {
                    console.log("winParam stop_fullscreen");
                    console.log(winParam);  
                    if(winParam == 'OK' && !noEmit)
                    {
                        _this.eventHub.$emit('stopEZFullscreenFinished');
                    }
                    
                },
                function(error) {
                    console.log("stop_fullscreen error");
                },
                "EZPlugin",
                "stop_fullscreen",
                []);
        }
        catch (e) {
            console.log('cordova.js - stopEZFullscreen - error');
        }  
    },

    logoutEZ: function(){
        try {
            cordova.exec(
                function(winParam) {
                    LogService.consoleLogTag(TAG,'EZLogout','success - winParam',winParam);
                },
                function(error) {
                    LogService.consoleLogTag(TAG,'EZLogout','error - error',error);
                },
                "EZPlugin",
                "logout",
                []);
        }
        catch (e) {
            console.log('cordova.js - EZLogout - error');
        }  
    },

    getHikImgData: function(_this){
        try {
          cordova.exec(
                  function(winParam) {
                      //var imgData =
                      var imgData = 'data:image/jpeg;base64,' + winParam;
                      _this.hikVisionSrc=imgData;
                      //document.getElementById('imgview').setAttribute( 'src', imgData);
                  },
                  function(error) {
                      console.log("error @smntservice");
                  },
                  "EZPlugin",
                  "show_login",
                  []);
              }
          catch (e) {
              console.log('cordova.js - getHikImgData - error');
          }
      },

    /* PUSH */

    pushRegister: function(_this,subscription){
        subscription = (subscription?subscription:StorageService.getValue(localStorage, 'plantId'));
        if(subscription)
        {
            if(_this.inApp)
            {
                try {
                    cordova.exec(
                            function(callback) {
                                console.log('PUSH CALLBACK pushRegister:');
                                console.log(callback);
                                if(callback.token && !callback.event)
                                {
                                    if(subscription==StorageService.getValue(localStorage, 'plantId'))
                                    {
                                        ClientResource.register(callback.token).then(
                                            function(response) {
                                            }).catch(
                                            function(e) {
                                                console.log('pushRegister - client register - error');
                                            });
                                    }
                                }
                                else
                                {
                                    try{
                                        var json = StringService.toJson(callback);
                                        if(json.payload){
                                            if(json.event=='onNotificationTap')
                                            {
                                                if(PayloadManagerService.runServiceLinkedToPayload(_this.eventHub,json.payload,_this.$store.state.allDevices,_this.$store.state.services,json.event,_this.inApp))return false;
                                            }
                                            _this.eventHub.$emit('setEventPayload',json.payload, json.event);
                                        }   
                                        else
                                        {
                                            console.log('pushRegister_'+json.event);
                                            _this.eventHub.$emit('pushRegister_'+json.event, json);     
                                        }
                                    }
                                    catch(e)
                                    {
                                        console.log('pushRegister - callback JSON parse - error : '+e);
                                    }
                                }

                            },
                            function(error) {
                                console.log("pushRegister - error");
                            },
                            "PushPlugin",
                            "push_register",
                            [subscription]);
                    }
                    catch (e) {
                        console.log(e);
                        console.log('cordova.js - pushRegister - error');
                    }
            }
            else
            {
                if(subscription==StorageService.getValue(localStorage, 'plantId'))
                {
                    
                    ClientResource.register().then(
                        function(response) {
                        }).catch(
                        function(e) {
                            console.log("pushRegister - client register - error");
                        });
                }
            }
        }
      },
      pushUnregister: function(_this, authorization, systemInfos, subscription){
        subscription = (subscription?subscription:StorageService.getValue(localStorage, 'plantId'));
        if(subscription)
        {
            if(_this.inApp)
            {
                try {
                    cordova.exec(
                            function(callback) {
                                console.log('PUSH CALLBACK push_unregister:');
                                console.log(callback);
                                var json = StringService.toJson(callback);
                                if(json.payload){
                                    if(json.event=='onNotificationTap')
                                    {
                                        if(PayloadManagerService.runServiceLinkedToPayload(_this.eventHub,json.payload,_this.$store.state.allDevices,_this.$store.state.services,json.event,_this.inApp))return false;
                                    }
                                    _this.eventHub.$emit('setEventPayload',json.payload, json.event);
                                }
                            },
                            function(error) {
                                console.log("pushUnregister - error");
                            },
                            "PushPlugin",
                            "push_unregister",
                            [subscription]);
                    }
                    catch (e) {
                        console.log(e);
                        console.log('cordova.js - pushUnregister - error');
                    }
            }
            if(authorization && systemInfos)
            {
                ClientResource.unregister(authorization, systemInfos).then(
                    function(response) {
                    }).catch(
                    function(e) {
                        console.log("pushUnregister - client unregister - error");
                        throw e;
                });
            }
        }
      },

    /* KVS */
    startKVSStream: function(_this, channelName, region){
        try {
            cordova.exec(
                function(winParam) {
                    console.log('startKVSStream success');
                    console.log(winParam);
                    
                },
                function(error) {
                    console.log('startKVSStream error');
                    console.log(error);
                },
                'KVSPlugin',
                'startFullscreen',
                [
                    channelName,
                    region
                ]);
        }
        catch (e) {
            console.log('startKVSStream error catch');
            console.log(e);
        }  
      },

}
