import Sthi1 from './sthi1.vue';
import Sthi2 from './sthi2.vue';

export default {
	props: ['homepilot', 'model', 'submodel', 'step'],
	components: {Sthi1,Sthi2},
    data: function () {
        return {
            CONSTANTS: {
						  'IMG_PATH' : 'assets/images/device/sthi/'
                       }
        }
    },
    created: function () {
			this.eventHub.$emit('setStepper', [
				{'number' : 1, 'title' : 'Operational'},
				{'number' : 2, 'title' : 'Association'},
				{'number' : 3, 'title' : 'Notifications'},
				{'number' : 4, 'title' : 'Name'},
				{'number' : 5, 'title' : 'Room'}
			], 1);
    },
	  methods: {
	}
}
