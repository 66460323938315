import LocationResource from 'common/services/resources/location.js'
import IconList from 'common/components/icon-list/icon-list.vue'
import NotificationsSelect from 'common/components/notifications-select/notifications-select.vue'
import RoomsSelect from 'common/components/rooms-select/rooms-select.vue'

export default {
  props: ['deviceId', 'model', 'icons', 'user','plant','deviceId','defaultRooms'],
  components:{IconList,NotificationsSelect,RoomsSelect},
  data:
		function () {
			return {
          name: '',
          CONSTANTS:{
                      INPUT_ERROR : this.$gettext('Please enter a name for your device'),
                      ROOM_ERROR : this.$gettext('Please select a room'),
                      ADD_ERROR : this.$gettext('Unable to add the device'),
                      TITLE : this.$gettext('What <strong>name</strong> do you want to give to your device ?'),
                      ICON_TITLE : _upperFirst(this.$gettext('Icon')),
                      NOTIFICATION_TITLE : _upperFirst(this.$gettext('notification')),
                      ROOMS_TITLE : _upperFirst(this.$gettext('rooms')),
                      NOTIFICATION : {
                        "notification_level" : "INFO",
                        "alert" : []
                      }
                    },
          loading : false,
          displayIconListModal : false,
          iconTab : [],
          iconModel : {},
          deviceIcon : '',
          notification: undefined,
          roomId: ''
			}
	},
  created: function () {
    this.eventHub.$emit('setDisplayPrevious', false);
    this.eventHub.$on('setDeviceIcon', this.setDeviceIcon);
    this.initIcon();
    this.initNotification();
    if(this.model.name != 'homepilot'){
      this.eventHub.$emit('setDisplayPrevious', true);
    }
  },
  destroyed: function () {
		this.eventHub.$off('setDeviceIcon', this.setDeviceIcon);
  },
	methods: {

        onClickRoom : function(zone){
          this.roomId = zone.id;
        },

        validate : function(){
          var _this = this;
          if(this.name==''){
						_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.INPUT_ERROR);
          }
          else if(this.roomId==''){
						_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.ROOM_ERROR);
          }
          else{
            if(this.deviceIcon != undefined){
              this.$parent.deviceObj.icon = this.deviceIcon;
            }
            if(this.$refs["notifications-select"])
            {
              this.$parent.deviceObj.notificationLevel = this.$refs["notifications-select"].notification.notification_level;
              if(this.$refs["notifications-select"].notification.notification_level == 'ALERT')
                this.$parent.deviceObj.alert = this.$refs["notifications-select"].notification.alert;
            }

            this.$parent.deviceObj.name = this.name;

            var _this = this;

            this.loading = true;

            var obj = {
              name:this.$parent.deviceObj.name,
              containerId:this.roomId,
              notificationLevel: this.$parent.deviceObj.notificationLevel,
              alert: this.$parent.deviceObj.alert
            }
            if(this.$parent.deviceObj.icon != '')
              obj.icon = this.$parent.deviceObj.icon;

            LocationResource.edit('module',this.deviceId, obj).then(
              function(obj) {
                _this.loading = false;
                _this.eventHub.$emit('setValidation', true);
                _this.eventHub.$emit('nextInternalMainStep');
              }).catch(
              function() {
                _this.loading = false;
              });
          }
        },
        setDeviceIcon : function(icon){
          this.deviceIcon = icon;
          this.displayIconListModal = false;
        },
        initNotification: function(){
          this.notification = (this.$parent.deviceObj.notification_level && this.$parent.deviceObj.alert ? this.$parent.deviceObj : this.CONSTANTS.NOTIFICATION)
        },
        initIcon: function(){
          this.iconTab = LocationResource.getIconsName(this.icons);
          this.iconModel = LocationResource.getKeyForModel();
          this.deviceIcon = this.iconModel[this.model.name];
        },
        isModelIcon: function(){
          if(this.iconModel[this.model.name] != undefined){
            return true;
          }
        }
   }
}

