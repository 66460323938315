import store from 'vuex_path/store'
import Global from 'common/services/resources/global.js'

export default {
	props:{
		brand: {
			type: Object
		},
		externalAccounts: {
			type: Object
		},
		availableModels: {
			type: Array
		}
	},
	data:
		function () {
			return {
				    CONSTANTS:
					{
					},
					selected: [],
					search:''
			}
	},
    computed: {
        filteredBrands() {
			if(this.brand && this.brand.brands.length>0)
			{
				return this.brand.brands.filter(brand => {
					return brand.displayName.toLowerCase().includes(this.search.toLowerCase());
				  });
			}
			else{
				return [];
			}

        }
    },	
	created: function () {
		if(this.brand.wizard)
		{
			this.eventHub.$emit('setChooseWizard',this.brand.wizard.type);
		}
		this.eventHub.$emit('setWizardStep',1);
		this.eventHub.$emit('setSelectedHomepilot',undefined);
		this.eventHub.$emit('setTitle', this.$gettext('Add a device'));
		if((this.brand.brands && this.brand.brands.length == 1) && (_find(this.externalAccounts.externalAccounts, {external:{name:this.brand.name}}) || this.availableModels!=undefined))
		{
			this.choose('deviceInstall');
		}
        /*if(store.state.variantManager.getBrandGroupName() != 'comfortlife'){
            this.eventHub.$emit('setDisplayPrevious', false);
        }*/
	},
	methods: {
		getBrandSrc: function (brand) {
			return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
		},
		choose: function (type) {
			this.$emit('onClick',type)
		},
		chooseBrand: function(brand){
			this.eventHub.$emit('setBrand', brand);
			this.eventHub.$emit('setMainStep', (brand.wizard.type=='INTERNAL'?3:2));
			this.eventHub.$emit('setChooseWizard', brand.wizard.type);
			this.eventHub.$emit('setDisplayPrevious', true);
			this.eventHub.$emit('initTxt', brand);
		},
  	}
}
