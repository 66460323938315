export default {
    formatStringToPhoneNumber: function(m) {
        m = m.replace(/(.{2})/g, "$1 ");
		m = m.replace('+3 36', '+33 6');
		m = m.replace('+3 37', '+33 7');
        return m;
    },
    checkPhoneNumber: function(str) {
        if(str.match(/^(0|\+33)[6-7]([-. ]?[0-9]{2}){4}$/g) != null)
            return true;
        else
            return false;
    }
}
