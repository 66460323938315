import Global from 'common/services/resources/global.js'
import RoomAdd from 'app/home/components/section/add-room/add-room.vue'

export default {
	components:{RoomAdd},
	props: {
		plant:{
			type: Object
		},
		defaultRooms:{
			type: Array
		},
		style:{
			type: String
		},
		zoneId:{
			type: String
		},
		device:{
			type: Object
		},
		noCloseDialog:{
			type: Boolean
		}
	},
	data:
		function () {
			return {
				displayRoomAddModal: false
			}
		},
	created: function ()
	{
		this.eventHub.$on('addRoomFinished', this.addRoomFinished);
	},

	destroyed: function () {
		this.eventHub.$off('addRoomFinished', this.addRoomFinished);
	},
	methods: {
		addRoom: function()
		{
			this.displayRoomAddModal = true;
		},
		zoneClass: function (zone)
		{
			var obj = {active:this.zoneId==zone.id};
			obj['room-select__'+zone.name] = true;
			return obj;
		},

		img: function (zone)
		{
			var style = {};
			try 
			{
				if(zone.image!=undefined)
				{
					if(zone.image.full.key !=undefined && zone.image.thumb !=undefined)
					{
						style.backgroundImage = 'url('+require('@/assets/'+this.$store.state.variantManager.class.defaultRoomsPath+'/'+zone.image.full.key+'_thumb.jpg')+')';
					}
					else
					{
						style.backgroundImage = 'url('+Global.getBackendUrl()+zone.image.full.path+')';
					}
				}
				else
				{
					style.backgroundImage = 'url('+require('@/assets/images/rooms/empty.jpg')+')';
				}
			}
			catch(e)
			{
				style.backgroundImage = 'url('+require('@/assets/images/rooms/empty.jpg')+')';
			}
			style.backgroundSize = 'cover';
			return style;
		},
		addRoomFinished: function (zone)
		{
			this.onclickItem(zone);
			this.displayRoomAddModal = false;
		},
		onclickItem: function (zone)
		{
			this.$emit('onclickItem',zone);
		},
	}
}
