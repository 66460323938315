import NotificationsSelect from 'common/components/notifications-select/notifications-select.vue'
import RoomsSelect from 'common/components/rooms-select/rooms-select.vue'
var stringify = require('json-stringify-safe');
export default {
	props: {
		service:{
			type: Object,
			default:{}
		},
		heatersConfig:{
			type: Object,
			default:{}
		},
		sensorsConfig:{
			type: Object,
			default:{}
		},
		devices:{
			type: Array,
		},
		step:{
			type: Number,
		},
		editedService:{
			type: Object,
			default:undefined
		},
		plant:{
			type: Object,
			default:{}
		},
		user:{
			type: Object,
			default:{}
		},
		defaultRooms:{
			type: Array,
			default:[]
		},
	},
	components: {NotificationsSelect,RoomsSelect},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						TITLE : this.$gettext('Configure your heating kit'),
						ADD_RELAY_ALERT : this.$gettext('Please add a relay'),
						TITLE_SENSORS : this.$gettext('Add all the temperature sensors that trigger the central boiler'),
						ADD_SENSOR_ALERT : this.$gettext('Please add a sensor'),
						NOTIFICATION_TITLE : _upperFirst(this.$gettext('notification')),
						ROOMS_TITLE : _upperFirst(this.$gettext('rooms')),
						INPUT_ERROR : this.$gettext('Please enter a name for your kit'),
						ROOM_ERROR : this.$gettext('Please select a room'),
						ELECTRIC_HEATING : this.$gettext('Electric heating'),
						CENTRAL_HEATING : this.$gettext('Central heating')
						
					},
					sensors:[],
					heaters:[],
					centralHeating:
					{
						relay:undefined,
						sensorsExpansionList: 
						{
							title: this.$gettext('Temperature sensors'),
							active: false,
							onClick:(d)=> {
								d.selected = !d.selected;
							},
							list: 
							[
								{
									title: this.$gettext('Add a new device'),
									icon:'cl-icon cl-icon-plus',
									onClick: ()=> {
										this.eventHub.$emit('addDevice');
									}	
								}
							]
						},
					},
					electricHeating:
					{
						sensor:undefined,
						heatersExpansionList: 
						{
							title: this.$gettext('Heaters'),
							active: false,
							onClick:(d)=> {
								d.selected = !d.selected;
							},
							list: 
							[
								{
									title: this.$gettext('Add a new device'),
									icon:'cl-icon cl-icon-plus',
									onClick: ()=> {
										this.eventHub.$emit('addDevice');
									}	
								}
							]
						},
					},
					typeHeating:'central_heating',
					name: '',
					notification : {
						"notification_level" : "INFO",
						"alert" : []
					}
			}
		},
	created: function () {
		this.initElements();
		this.init();
	},
	watch: {
		devices: function (val, oldVal) {
			this.initElements();
		},
	},
	destroyed: function () {
	}, 	
	methods: {
		init: function(){
			this.centralHeating.relay = undefined;
			this.electricHeating.sensor = undefined;
			if(this.service.name)
			{
				this.name = this.service.name;
			}

			if(_isEmpty(this.editedService)){
				this.notification.notification_level = 'INFO';
				this.notification.alert = [];
			}
			else{
				this.eventHub.$emit('setServiceDisplayPrevious', false);
				this.typeHeating = this.service.submodel;
				this.notification.notification_level = this.editedService.notification_level;
				this.notification.alert = (this.editedService.alert?this.editedService.alert:[]);
			}
			if(this.typeHeating=='central_heating')
			{
				if(this.editedService)
				{
					if(this.editedService.service.infos)
					{
						var d = _find(this.devices, { 'id': this.editedService.service.infos.heaters[0].moduleId});
						this.centralHeating.relay = d;
						this.sensors.forEach((s)=>{
							s.selected = (this.editedService.service.infos.heaters[0].associatedSensors.indexOf(s.id)!=-1)
						});
					}
				}
				else
				{
					this.sensors.forEach(function(s){
						s.selected = false;
					});
				}
			}
			else
			{
				if(this.editedService)
				{
					if(this.editedService.service.infos)
					{
						var d = _find(this.devices, { 'id': this.editedService.service.infos.sensors[0].moduleId});
						this.electricHeating.sensor = d;
						this.heaters.forEach((h)=>{
							h.selected = (this.editedService.service.infos.sensors[0].associatedHeaters.indexOf(h.id)!=-1)
						});
					}
				}
			}
		},

		initElements: function(){
			var _this = this;
			let heatersDevices = _filter(this.devices, function(d) { return (d.device && _this.heatersConfig.availableModels.indexOf(d.device.model)!=-1)});
			
			var heatersDevicesCopy = JSON.parse(stringify(heatersDevices));

			heatersDevicesCopy.forEach(function(d) {
				var deviceFrom = _find(_this.heaters, {'id':d.id});  
				if(deviceFrom)
				{
					d.selected = deviceFrom.selected;
				}
			});
			
			this.heaters = heatersDevicesCopy;

			let sensorsDevices = _filter(this.devices, function(d) { 
				if(d.device && _this.sensorsConfig.availableModels.indexOf(d.device.model)!=-1)
				{
					return true;
				}
				return false; 
			});
			var sensorsDevicesCopy = JSON.parse(stringify(sensorsDevices));
			sensorsDevicesCopy.forEach(function(d) {
				var deviceFrom = _find(_this.sensors, {'id':d.id});  
				if(deviceFrom)
				{
					d.selected = deviceFrom.selected;
				}
			});
			this.sensors = sensorsDevicesCopy;
		},

		onClickRoom : function(zone){
			this.service.locationId = zone.id;
		},

		onTypeHeatingChange : function(typeHeating){
			if(typeHeating)
			{
				this.typeHeating = typeHeating;
			}
			this.initElements();
			this.init();
		},

		validate: function(){
			if(this.name=='')
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.INPUT_ERROR);
				return false;
			}
			this.service.name = this.name;

			this.service.submodel=this.typeHeating;


			if(this.typeHeating == 'central_heating')
			{
				if(this.centralHeating.relay==undefined)
				{
					this.eventHub.$emit('displayAlert',  this.CONSTANTS.ADD_RELAY_ALERT);
					return false;
				}
				this.service.setHeaterModuleIdAt(this.centralHeating.relay.id,0);
	
				let devices = _filter(this.sensors, function(m) { return m.selected == true; });
				this.service.addAssociatedSensorsAtFromElements(0,devices);
				if(this.service.getHeaters()[0].associatedSensors.length==0)
				{
					this.eventHub.$emit('displayAlert',  this.CONSTANTS.ADD_SENSOR_ALERT);
					return false;
				}
				this.service.deleteSensors();
			}

			else
			{
				if(this.electricHeating.sensor==undefined)
				{
					this.eventHub.$emit('displayAlert',  this.CONSTANTS.ADD_SENSOR_ALERT);
					return false;
				}
				let devices = _filter(this.heaters, function(m) { return m.selected == true; });
				if(devices.length==0)
				{
					this.eventHub.$emit('displayAlert',  this.CONSTANTS.ADD_HEATER_ALERT);
					return false;
				}
				this.electricHeating.sensor.associatedHeaters = [];
				this.service.deleteHeaters();
				this.service.addSensorsFromElements(this.electricHeating.sensor,devices);
			}

			this.service.notificationLevel = this.$refs["notifications-select"].notification.notification_level;
			if(this.$refs["notifications-select"].notification.notification_level == 'ALERT')
			{
				this.service.alert = this.$refs["notifications-select"].notification.alert;
			}
			if(this.service.locationId=='')
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.ROOM_ERROR);
				return false;
			}
			
			this.eventHub.$emit('addEditServiceWizard');
		},
  	}
}