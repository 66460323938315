import ConsumptionBar from 'common/components/consumption/consumption.vue'

export default {
    components: {ConsumptionBar},
    props: ['module'],
    data: function () {
            return {
           }
    },
	created: function () {
	},
	methods: {
		setStatus : function(){
            status = (this.module.device.status[0].value=='on'?'off':'on');
            this.$parent.$emit('set-status', status);
		}
	}
}
