<template>
    <div id="main" :class="(displaySubMenuModal?'displaySubMenuModal':'')">
        <main-modal></main-modal>
        <speech-recognition-modal
        :plant="plant"
        :devices-by-capability="devicesByCapability"
        :scenarios="scenarios"
        :scenarios-by-capability="scenariosByCapability"
        :shares-locations="sharesLocations"
        :external-accounts="externalAccounts"
        ></speech-recognition-modal>
        <div id="main-app">
            <div id="app-router" :class="classObject" :style="marketStyle('app-router')" >
                <!--<button id="test-button" @click="test()">TEST</button>-->
                    <v-app>
                            <!--<div v-if="plant && plant.zones && plant.zones[3]">
                                {{plant.zones[3].groups}}
                                {{plant.zones[3].groupTest}}
                            </div>-->

                            <div v-if="showDisplayMainControlDeviceModal">
                                <main-control-device-modal ref="mainControlDeviceModal"></main-control-device-modal>
                            </div>
                            <div v-if="$route.name=='login' || $route.name=='subscribe'" class="app-router__background"></div>
                            <v-dialog style="z-index:1000" content-class="modalSection tutorial max-width-50-percent" v-model="displayModalTutorial" persistent>
                                <!--<span class="closeEditor cl-icon cl-icon-cross" @click="closeModalDisplayModalTutorial()"></span>-->
                                <tutorial v-if="displayModalTutorial && !$store.state.variantManager.class.tutorial.hide" @onStart="closeModalDisplayModalTutorial"></tutorial>
                            </v-dialog>
                            <v-dialog style="z-index:1000"  :content-class="'modalSection device-control main-control__'+mainControlDeviceModule.module.device.model" v-model="displayModalMainControlDevice" persistent>
                                <span class="closeEditor cl-icon cl-icon-cross" @click="closeModalMainControlDevice()"></span>
                                <div class="control-device custom" v-if="displayModalMainControlDevice">
                                    <call-control v-if="CONSTANTS.MODALMAINCONTROLDEVICE_CALL_CAPABILITIES.indexOf(mainControlDeviceModule.status.capability)!=-1" :module="mainControlDeviceModule.module" ></call-control>
                                </div>
                            </v-dialog>

                            <!--ADD DEVICE DIALOG-->
                            <v-dialog content-class="rooms modalSection add-device max-width-50-percent" v-model="installModal" scrollable persistent
                            style="z-index:1000"
                            >
                                <span class="closeEditor cl-icon cl-icon-cross" v-if="displayCloseIconInstallModal" @click="addDevicecloseConfirmDialog = true"></span>
                                <DeviceInstall v-if="installModal===true"
                                :devices="devices"
                                :defaultRooms="defaultRooms"
                                :plant="plant"
                                :models="models"
                                :homepilots="homepilots"
                                :brands="brands"
                                :brand-groups="brandGroups"
                                :systems="systems"
                                :external-accounts="externalAccounts"
                                :external-name="externalName"
                                :external-route="externalRoute"
                                :icons="icons"
                                :homepilot="homepilot"
                                :brand-name="brandName"
                                :available-models="availableAddDeviceModels"
                                :user="eventHub.user"
                                :version-infos="versionInfos"
                                ></DeviceInstall>
                            </v-dialog>
                            <v-dialog content-class="modalSection confirm add-device__closeConfirmDialog no-resize" v-model="addDevicecloseConfirmDialog" scrollable persistent>
                                <span class="closeEditor cl-icon cl-icon-cross" @click="addDevicecloseConfirmDialog = false"></span>
                                <delete-modal 
                                    type="warning"
                                    title="Warning" 
                                    text="Are you sure you want to exit the product installation?" 
                                    @onclickItem="closeInstallModal(true)" 
                                    @close="addDevicecloseConfirmDialog = false">
                                </delete-modal>	
                            </v-dialog>

                            <!--ADD SERVICE DIALOG-->
                            <v-dialog content-class="rooms modalSection add-service max-width-50-percent" v-model="serviceAddModal" scrollable persistent
                            style="z-index:1000">
                                <span class="closeEditor cl-icon cl-icon-cross"  @click="addServicecloseConfirmDialog = true"></span>
                                <service-add v-if="serviceAddModal"
                                :serviceModels="serviceModels"
                                :devices="devices"
                                :defaultRooms="defaultRooms"
                                :plant="plant"
                                :app-constants="appConstants"
                                :user="eventHub.user"
                                :edited-service="editedService"
                                :services="services"
                                :version-infos="versionInfos"
                                :brands="brands"
                                :capabilities="capabilities"
                                :devicesByCapability="devicesByCapability"
                                :models="models"
                                ></service-add>
                            </v-dialog>
                            <v-dialog :content-class="$route.name+' modalSection confirm no-resize'" v-model="addServicecloseConfirmDialog" scrollable persistent>
                                <span class="closeEditor cl-icon cl-icon-cross" @click="addServicecloseConfirmDialog = false"></span>
                                <delete-modal 
                                    type="warning" 
                                    title="Warning" 
                                    text="By closing this dialog box, you will lose all your changes in progress." 
                                    subText="Do you really want to close this dialog?"
                                    warning="true"
                                    @onclickItem="closeServiceInstallModal()" 
                                    @close="addServicecloseConfirmDialog = false">
                                </delete-modal>
                            </v-dialog>
                            <!--ADD SHARE DIALOG-->
                            <v-dialog style="z-index:1000" content-class="modalSection" v-model="shareAddModal" scrollable persistent>
                                <span class="closeEditor cl-icon cl-icon-cross"  @click="closeShareAddModal()"></span>
                                <share-add v-if="shareAddModal"
                                :plant="plant"
                                :own-shares="ownShares"
                                @close="closeShareAddModal()"
                                ></share-add>
                            </v-dialog>
                            <div id="cl-debug" v-if="showDebugLog" v-html="debugLog" @click="showDebugLog=false">
                            </div>
                            <navbar-drawer id="mobile-drawer-container" ref="mobile-drawer-container" 
                            v-show="['invite','activate','login','subscribe','forgot-password','reset-password'].indexOf($route.name)==-1 && !navbar.hide"
                            :user="eventHub.user" 
                            :users="users"
                            :shares-locations="sharesLocations" 
                            :external-accounts="externalAccounts" 
                            :notifications-modules="notificationsModules" 
                            :hc-url="hcUrl" 
                            :version-infos="versionInfos"
                            :app-constants="CONSTANTS" 
                            ></navbar-drawer>
                            <div class="app-router__wrapper" v-show="show">
                                <cl-menu v-show="CONSTANTS.ONLY_MAIN_VIEW_NAMES.indexOf($route.name)==-1" 
                                :config-menu="configMenu" 
                                :notifications-modules="notificationsModules" 
                                location="main-panel" 
                                :sub-menu-shares-locations="subMenuSharesLocations"
                                refresh-button></cl-menu>
                                <router-view id="app-router-content" :style="{paddingLeft:(['invite','activate','login','subscribe','forgot-password','reset-password'].indexOf($route.name)!=-1)}"
                                :read-only="readOnly"
                                :key="$route.fullPath"
                                :shares-locations="sharesLocations"
                                :own-shares="ownShares"
                                :all-devices="allDevices"
                                :routerObj="routerObj" :default-rooms = "defaultRooms"
                                :devices="devices" :sub-devices="subDevices" :devices-by-capability="devicesByCapability"
                                :users="users" :plant="plant" :scenarios="scenarios" :systems="systems" :models="models" :external-accounts="externalAccounts"
                                :user-plant="userPlant" 
                                :templates="templates" :widget-models="widgetModels"
                                :service-models="serviceModels"
                                :user="eventHub.user"
                                :homepilots="homepilots" 
                                :notifications-modules="notificationsModules" 
                                :dashboard="dashboard" 
                                :brands="brands" 
                                :brand-groups="brandGroups"
                                :people="CONSTANTS.PEOPLE" 
                                :app-constants="CONSTANTS" 
                                :all-topology-finished="allTopologyFinished" 
                                :icons="icons" 
                                :services="services" 
                                :version-infos="versionInfos" 
                                :capabilities="capabilities"
                                :settings="settings">
                                </router-view>
                            </div>
                    </v-app>
                    <notification 
                    v-show="show"
                    :sub-menu-shares-locations="subMenuSharesLocations"
                    :all-devices="allDevices"
                    :devices="devices" 
                    :sub-devices="subDevices" 
                    :services="services" 
                    :scenarios="scenarios" 
                    :notifications-modules="notificationsModules" 
                    :external-accounts="externalAccounts" 
                    :user="eventHub.user"
                    :shares-locations="sharesLocations"
                ></notification>
            </div>
            <div v-show="show" class="main-panel right" :class="classRightPanelObject" v-if="!rightPanel.hide && !inApp" align="center">
                    <div class="main-right-panel-content">
                        <cl-menu location="main-right-panel"></cl-menu>
                        <market :scrollbar="true" :url="rightPanel.url" :hide="rightPanel.hideContent" :init="rightPanel.init"></market>
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'

    import User from 'common/classes/User.js';
    import SmartCamera from 'common/classes/SmartCamera.js'
    import Camera from 'common/classes/Camera.js'
    import PushRegister from 'common/classes/PushRegister.js'

    import GlobalResource from 'common/services/resources/global.js'
    import StringService from 'common/services/string.js'
    import ToastService from 'common/services/toast.js'
    import WindowService from 'common/services/window.js'
    import CordovaService from 'common/services/cordova.js'
    import LinkwilPluginService from 'common/services/cordova/linkwilPlugin.js'

    import PayloadManagerService from 'common/services/payloadManager.js'
    import ArrayService from 'common/services/array.js'
    import StorageService from 'common/services/storage.js'
    import SystemService from 'common/services/system.js'
    import LogService from 'common/services/log.js'

    import DashboardService from 'common/services/resources/dashboard.js'
    import DeviceResource from 'common/services/resources/device.js'
    import CommonResource from 'common/services/resources/common.js'
    import ServiceResource from 'common/services/resources/service.js'
    import LocationResources from 'common/services/resources/location.js'
    import StorageResource from 'common/services/resources/storage.js'
    import ExternalResource from 'common/services/resources/external.js'
    import UserResource from 'common/services/resources/user.js'
    import LocationResource from 'common/services/resources/location.js'
    import GroupResource from 'common/services/resources/group.js'
    import FamilyResource from 'common/services/resources/family.js'
    import ScenarioResource from 'common/services/resources/scenario.js'
    import SystemResource from 'common/services/resources/system.js'
    import NotificationResource from 'common/services/resources/notification.js'
    import RBResource from 'common/services/resources/rb.js'
    import SettingsResource from 'common/services/resources/settings.js'
    import ShareResource from 'common/services/resources/share.js'

    import LanguageService from 'common/services/language.js'

    import axios from 'axios'

    import VersionInfos from 'common/classes/VersionInfos';

    var stringify = require('json-stringify-safe');

    import Tutorial from 'common/components/tutorial/tutorial.vue';
    import Market from 'common/components/market/market.vue';
    import MainModal from 'common/components/main-modal/main-modal.vue';
    import Notification from 'common/components/notification/notification.vue';
    import NavbarDrawer from 'common/components/navbar-drawer/navbar-drawer.vue';
    import ClMenu from 'common/components/menu/menu.vue';
    import CallControl from 'common/components/widget/control-device/call/call.vue';
    import ShareAdd from 'app/home/components/section/add-share/add-share.vue';
    import SpeechRecognitionModal from 'common/components/speech-recognition-modal/speech-recognition-modal.vue';
    import MainControlDeviceModal from 'common/components/main-control-device-modal/main-control-device-modal.vue';
    
    import DeviceInstall from 'app/home/components/section/add-device/add-device.vue';
    import DeleteModal from 'common/components/delete-modal/delete-modal.vue';
    import ServiceAdd from 'app/home/components/section/add-service/add-service.vue';



var getLocalStorageSize = function() {
    var total = 0;
    for (var x in localStorage) {
        // Value is multiplied by 2 due to data being stored in `utf-16` format, which requires twice the space.
        var amount = (localStorage[x].length * 2) / 1024 / 1024;
        if (!isNaN(amount) && localStorage.hasOwnProperty(x)) {
            // console.log(x, localStorage.getItem(x), amount);
            total += amount;
        }
    }
    return total.toFixed(2);
};
         

/**
 * INFOS : The plant modules (this.plant) are synchronized with the devices (this.devices)
 *         If a value changes on one side, it will be changed on to the other
 *
 */


function getDefaultAppData() {
    return {
        CONSTANTS:{
            TAG : 'app',
            ACTIONS : {
                'off':
                    {
                        title: this.$gettext('Power OFF'),
                        subTitle: this.$gettext('This action will turn off your device')
                    },
                'on':
                    {
                        title: this.$gettext('Power ON'),
                        subTitle: this.$gettext('This action will turn on your device')
                    },
                'disarm':
                    {
                        title: this.$gettext('disarm'),
                        subTitle: this.$gettext('This action will disarm your device')
                    },
                'arm':
                    {
                        title: this.$gettext('arm'),
                        subTitle: this.$gettext('This action will arm your device')
                    },
                'partial':
                    {
                        title: this.$gettext('partial'),
                        subTitle: this.$gettext('This action will set up your device in partial mode')
                    },
                'up':
                    {
                        title: this.$gettext('up'),
                        subTitle: this.$gettext('This action will go up your device')
                    },
                'down':
                    {
                        title: this.$gettext('down'),
                        subTitle: this.$gettext('This action will go down your device')
                    },
                'instant_up':
                    {
                        title: this.$gettext('instant up'),
                        subTitle: this.$gettext('This action will instantly go up your device')
                    },
                'instant_down':
                    {
                        title: this.$gettext('instant down'),
                        subTitle: this.$gettext('This action will instantly go down your device')
                    },      
                'scroll_up':
                    {
                        title: this.$gettext('scroll up'),
                        subTitle: this.$gettext('This action will scroll up your device')
                    },
                'scroll_down':
                    {
                        title: this.$gettext('scroll down'),
                        subTitle: this.$gettext('This action will scroll down your device')
                    },                         
                'open':
                    {
                        title: this.$gettext('open'),
                        subTitle: this.$gettext('This action will open your device')
                    },
                'close':
                    {
                        title: this.$gettext('close'),
                        subTitle: this.$gettext('This action will close your device')
                    },
                'trigger':
                    {
                        title: this.$gettext('trigger'),
                        subTitle: this.$gettext('This action will go trigger your device')
                    },
                'alert':
                    {
                        title: this.$gettext('alert')
                    },
                'enable':
                    {
                        title: this.$gettext('enable'),
                        subTitle: this.$gettext('This action will enable your device')
                    },
                'disable':
                    {
                        title: this.$gettext('disable'),
                        subTitle: this.$gettext('This action will disable your device')
                    },
                'speed':
                    {
                        title: this.$gettext('speed'),
                        subTitle: this.$gettext('This action will change the speed of your motor')
                    },
                'detect':
                    {
                        title: this.$gettext('detect')
                    },
                'temperature':
                    {
                        title: this.$gettext('temperature')
                    },
                'ring':
                    {
                        title: this.$gettext('ring')
                    },
                'show':
                    {
                        title: this.$gettext('show'),
                        subTitle: this.$gettext('This action will show your camera')
                    },
                /*NEW in 1.33.0*/ 
                'dim':
                {
                    title: this.$gettext('change the intensity of the light'),
                    subTitle: this.$gettext('This action will change the intensity of a light')
                },  
                'position-up':
                {
                    title: this.$gettext('pilot to the up'),
                    subTitle: this.$gettext('This action will pilot to the up your device')
                },   
                'position-down':
                {
                    title: this.$gettext('pilot to the down'),
                    subTitle: this.$gettext('This action will pilot to the down your device')
                },
                'position-left':
                {
                    title: this.$gettext('pilot to the left'),
                    subTitle: this.$gettext('This action will pilot to the left your device')
                },   
                'position-right':
                {
                    title: this.$gettext('pilot to the right'),
                    subTitle: this.$gettext('This action will pilot to the right your device')
                },   
                'position-steady':
                {
                    title: this.$gettext('go steady'),
                    subTitle: this.$gettext('This action will go steady your device')
                },  
                'position-origin':
                {
                    title: this.$gettext('Put in the original position'),
                    subTitle: this.$gettext('This action will put the device in its original position')
                },  
                'unlock':
                {
                    title: this.$gettext('unlock'),
                    subTitle: this.$gettext('This action will unlock your device')
                }, 
                'record-start':
                {
                    title: this.$gettext('start the record'),
                    subTitle: this.$gettext('This action will start the record')
                }, 
                'record-stop':
                {
                    title: this.$gettext('stop the record'),
                    subTitle: this.$gettext('This action will stop the record')
                },  
                'snapshot':
                {
                    title: this.$gettext('take a snapshot'),
                    subTitle: this.$gettext('This action will trigger a snapshot from the device')
                },  
                'mode':
                {
                    title: this.$gettext('mode'),
                    subTitle: this.$gettext('This action will change the function mode')
                },  
                'dock':
                {
                    title: this.$gettext('dock'),
                    subTitle: this.$gettext('This action will command a device to return to its dock to charge')
                },  
                'rgb':
                {
                    title: this.$gettext('rgb'),
                    subTitle: this.$gettext('This action will change the color of a light using the RGB scheme')
                },  
                'hue':
                {
                    title: this.$gettext('hue'),
                    subTitle: this.$gettext('This action will change the color of a light using the HUE scheme')
                },  
                'saturation':
                {
                    title: this.$gettext('saturation'),
                    subTitle: this.$gettext('This action will change a saturation value for a light')
                },  
                'brightness':
                {
                    title: this.$gettext('change the brightness'),
                    subTitle: this.$gettext('This action will change a brightness value for a light')
                },
                'talk-start':
                {
                    title: this.$gettext('start the talk'),
                    subTitle: this.$gettext('This action will start the talk')
                },
                'talk-stop':
                {
                    title: this.$gettext('stop the talk'),
                    subTitle: this.$gettext('This action will stop the talk')
                },               
                'audio-on':
                {
                    title: this.$gettext('turn on audio'),
                    subTitle: this.$gettext('This action will turn on the audio for this device')
                },
                'audio-off':
                {
                    title: this.$gettext('turn off audio'),
                    subTitle: this.$gettext('This action will turn off the audio for this device')
                },
                'microphone-on':
                {
                    title: this.$gettext('turn on the microphone'),
                    subTitle: this.$gettext('This action will turn on the microphone for this device')
                },
                'microphone-off':
                {
                    title: this.$gettext('turn off the microphone'),
                    subTitle: this.$gettext('This action will turn off the microphone for this device')
                },
                'pending':
                {
                    title: this.$gettext('start a call'),
                    subTitle: this.$gettext('This action will started a call')
                },   
                'timeout':
                {
                    title: this.$gettext('stop an ongoing call'),
                    subTitle: this.$gettext('This action will stopped an ongoing call')
                },   
                'answer':
                {
                    title: this.$gettext('answer'),
                    subTitle: this.$gettext('This action will answer to the call')
                },                                     
                'hang_up':
                {
                    title: this.$gettext('hang up'),
                    subTitle: this.$gettext('This action will hang up the call')
                },
                'blink':
                {
                    title: this.$gettext('blink'),
                    subTitle: this.$gettext('This action will blink the light')
                },
                'color_temperature':
                {
                    title: this.$gettext('color temperature'),
                    subTitle: this.$gettext('This action will change the color temperature')
                },
                'wake_up':
                {
                    title: this.$gettext('wake up'),
                    subTitle: this.$gettext('This action will wake up the device')
                },
                'auto':
                {
                    title: this.$gettext('auto'),
                    subTitle: this.$gettext('This action will set to automatic your device')
                },
                'cool':
                {
                    title: this.$gettext('forced shutdown'),
                    subTitle: this.$gettext('This action will force shutdown your device')
                },
                'heat':
                {
                    title: this.$gettext('forced operation'),
                    subTitle: this.$gettext('This action will force start your device')
                },
            },
            ACTIONS_TRIGGER : {
                'access':
                    {
                        title: this.$gettext('access'),
                        subTitle: this.$gettext('Triggered on opening')
                    },
                'alert':
                    {
                        title: this.$gettext('alert'),
                        subTitle: this.$gettext('Triggered on alert')
                    },
                'ring':
                    {
                        title: this.$gettext('ring'),
                        subTitle: this.$gettext('Triggered on ring')
                    },
                'off':
                    {
                        title: this.$gettext('Power OFF'),
                        subTitle: this.$gettext('Trigger the action if the device turn off')
                    },
                'on':
                    {
                        title: this.$gettext('Power ON'),
                        subTitle: this.$gettext('Trigger the action if the device turn on')
                    },
                'temperature':
                    {
                        title: this.$gettext('temperature'),
                        subTitle: this.$gettext('Triggered on temperature')
                    },
                'detect':
                    {
                        title: this.$gettext('detect'),
                        subTitle: this.$gettext('Triggered on detect')
                    },
                'open':
                    {
                        title: this.$gettext('open'),
                        subTitle: this.$gettext('Triggered on opening')
                    },
                'close':
                    {
                        title: this.$gettext('close'),
                        subTitle: this.$gettext('Triggered on closing')
                    },
                'up':
                    {
                        title: this.$gettext('up'),
                        subTitle: this.$gettext('Trigger the action if the device go up')
                    },
                'down':
                    {
                        title: this.$gettext('down'),
                        subTitle: this.$gettext('Trigger the action if the device go down')
                    },
                'instant_up':
                    {
                        title: this.$gettext('instant up'),
                        subTitle: this.$gettext('Trigger the action if the device instantly go up')
                    },
                'instant_down':
                    {
                        title: this.$gettext('instant down'),
                        subTitle: this.$gettext('Trigger the action if the device instantly go down')
                    },      
                'scroll_up':
                    {
                        title: this.$gettext('scroll up'),
                        subTitle: this.$gettext('Trigger the action if the device scroll up')
                    },
                'scroll_down':
                    {
                        title: this.$gettext('scroll down'),
                        subTitle: this.$gettext('Trigger the action if the device scroll down')
                    },
                'trigger':
                    {
                        title: this.$gettext('trigger'),
                        subTitle: this.$gettext('Trigger the action if the device is triggered')
                    },
                'disarm':
                    {
                        title: this.$gettext('disarm'),
                        subTitle: this.$gettext('Trigger the action if you disarm your device')
                    },
                'arm':
                    {
                        title: this.$gettext('arm'),
                        subTitle: this.$gettext('Trigger the action if you arm your device')
                    },
                'partial':
                    {
                        title: this.$gettext('partial'),
                        subTitle: this.$gettext('Trigger the action if you partial your device')
                    },
                'enable':
                    {
                        title: this.$gettext('enable'),
                        subTitle: this.$gettext('Trigger the action if the device is enabled')
                    },
                'disable':
                    {
                        title: this.$gettext('disable'),
                        subTitle: this.$gettext('Trigger the action if the device is disabled')
                    },
                'speed':
                    {
                        title: this.$gettext('speed'),
                        subTitle: this.$gettext('Trigger the action if the motor speed is changed')
                    },
                'show':
                    {
                        title: this.$gettext('show'),
                        subTitle: this.$gettext('Trigger the action if you show your camera')
                    },
                /*NEW in 1.33.0*/ 
                    'dim':
                    {
                        title: this.$gettext('change the intensity of the light'),
                        subTitle: this.$gettext('Trigger the action if the intensity of a light is changed')
                    }, 
                    'position-up':
                    {
                        title: this.$gettext('pilot to the up'),
                        subTitle: this.$gettext('Trigger the action if the device go up')
                    },   
                    'position-down':
                    {
                        title: this.$gettext('pilot to the down'),
                        subTitle: this.$gettext('Trigger the action if the device go down')
                    },                    
                    'position-left':
                    {
                        title: this.$gettext('pilot to the left'),
                        subTitle: this.$gettext('Trigger the action if the device go left')
                    },   
                    'position-right':
                    {
                        title: this.$gettext('pilot to the right'),
                        subTitle: this.$gettext('Trigger the action if the device go right')
                    },   
                    'position-steady':
                    {
                        title: this.$gettext('go steady'),
                        subTitle: this.$gettext('Trigger the action if the device go steady')
                    },  
                    'position-origin':
                    {
                        title: this.$gettext('Put in the original position'),
                        subTitle: this.$gettext('Trigger the action if the device return to its original position')
                    },  
                    'humidity':
                    {
                        title: this.$gettext('humidity'),
                        subTitle: this.$gettext('Trigger the action if the device humidity is changed')
                    }, 
                    'unlock':
                    {
                        title: this.$gettext('unlock'),
                        subTitle: this.$gettext('Trigger the action if the device is unlocked')
                    }, 
                    'record-start':
                    {
                        title: this.$gettext('start the record'),
                        subTitle: this.$gettext('Trigger the action if the record is started')
                    }, 
                    'record-stop':
                    {
                        title: this.$gettext('stop the record'),
                        subTitle: this.$gettext('Trigger the action if the record is stopped')
                    },  
                    'snapshot':
                    {
                        title: this.$gettext('take a snapshot'),
                        subTitle: this.$gettext('Trigger the action if a snapshot is triggered from the device')
                    },  
                    'mode':
                    {
                        title: this.$gettext('mode'),
                        subTitle: this.$gettext('Trigger the action if function mode is changed')
                    }, 
                    'dock':
                    {
                        title: this.$gettext('dock'),
                        subTitle: this.$gettext('Trigger the action if a device is returned to its dock')
                    },  
                    'rgb':
                    {
                        title: this.$gettext('rgb'),
                        subTitle: this.$gettext('Trigger the action if the color of a light using the RGB scheme is changed')
                    },  
                    'hue':
                    {
                        title: this.$gettext('hue'),
                        subTitle: this.$gettext('Trigger the action if the color of a light using the HUE scheme is changed')
                    },
                    'saturation':
                    {
                        title: this.$gettext('saturation'),
                        subTitle: this.$gettext('Trigger the action if the saturation value for a light is changed')
                    },  
                    'brightness':
                    {
                        title: this.$gettext('change the brightness'),
                        subTitle: this.$gettext('Trigger the action if the brightness value for a light is changed')
                    },   
                    'pressure':
                    {
                        title: this.$gettext('pressure'),
                        subTitle: this.$gettext('Trigger the action if the pressure is changed')
                    },   
                    'wind_strength':
                    {
                        title: this.$gettext('wind strength'),
                        subTitle: this.$gettext('Trigger the action if the wind strength is changed')
                    },
                    'wind_angle':
                    {
                        title: this.$gettext('wind angle'),
                        subTitle: this.$gettext('Trigger the action if the wind angle is changed')
                    }, 
                    'talk-start':
                    {
                        title: this.$gettext('start the talk'),
                        subTitle: this.$gettext('Trigger the action if the talk is started')
                    },
                    'talk-stop':
                    {
                        title: this.$gettext('stop the talk'),
                        subTitle: this.$gettext('Trigger the action if the talk is stopped')
                    },                
                    'audio-on':
                    {
                        title: this.$gettext('turn on audio'),
                        subTitle: this.$gettext('Trigger the action if the audio is turned on')
                    },
                    'audio-off':
                    {
                        title: this.$gettext('turn off audio'),
                        subTitle: this.$gettext('Trigger the action if the audio is turned off')
                    },
                    'microphone-on':
                    {
                        title: this.$gettext('turn on the microphone'),
                        subTitle: this.$gettext('Trigger the action if the microphone is turned on')
                    },
                    'microphone-off':
                    {
                        title: this.$gettext('turn off the microphone'),
                        subTitle: this.$gettext('Trigger the action if the microphone is turned off')
                    },      
                    'pending':
                    {
                        title: this.$gettext('A call from doorbell'),
                        subTitle: this.$gettext('Trigger the action if the call is started')
                    },   
                    'timeout':
                    {
                        title: this.$gettext('stop an ongoing call'),
                        subTitle: this.$gettext('Trigger the action if the ongoing call is stopped')
                    },   
                    'answer':
                    {
                        title: this.$gettext('answer'),
                        subTitle: this.$gettext('Trigger the action if someone answered')
                    },                                     
                    'hang_up':
                    {
                        title: this.$gettext('hang up'),
                        subTitle: this.$gettext('Trigger the action if someone hung up')
                    },
                    'blink':
                    {
                        title: this.$gettext('blink'),
                        subTitle: this.$gettext('Trigger the action if the light blink')
                    },
                    'color_temperature':
                    {
                        title: this.$gettext('color temperature'),
                        subTitle: this.$gettext('Trigger the action if the color temperature is changed')
                    },
                    'wake_up':
                    {
                        title: this.$gettext('wake up'),
                        subTitle: this.$gettext('Trigger action if a device is waked up')
                    },
                    'auto':
                    {
                        title: this.$gettext('auto'),
                        subTitle: this.$gettext('Trigger the action if the device is set to automatic')
                    },
                    'cool':
                    {
                        title: this.$gettext('forced shutdown'),
                        subTitle: this.$gettext('Trigger the action if the device is in forced shutdown')
                    },
                    'heat':
                    {
                        title: this.$gettext('forced operation'),
                        subTitle: this.$gettext('Trigger the action if the device is in forced operation')
                    }
            },
            NOT_DISPLAY_MODEL : ['alarmRemote', 'alarmRemote2'],
            DEVICE_TXT : _upperFirst(this.$gettext('add a device')),
            SCENARIO_TXT : _upperFirst(this.$gettext('add a scenario')),
            GROUP_TXT : _upperFirst(this.$gettext('add a device group')),
            ROOM_TXT : _upperFirst(this.$gettext('add a room')),
            SERVICE_TXT : _upperFirst(this.$gettext('add a kit')),
            SHARE_TXT : _upperFirst(this.$gettext('add a share')),
            USER_TXT : _upperFirst(this.$gettext('add a user')),
            FAQ_MENU:  _upperFirst(this.$gettext('Frequently Asked Questions (FAQ)')),
            LOCAL_CONTROL_ONLY_TXT: this.$gettext('Local control only available' ),
            CONTACT_MENU: _upperFirst(this.$gettext('Contact us')),
            SUGGESTIONS_MENU:  _upperFirst(this.$gettext('Do you have suggestions?')),
            ABOUT_TITLE : _upperFirst(this.$gettext('About')),
            DOWNLOAD_APP_TITLE : _upperFirst(this.$gettext('download app')),  
            MODALMAINCONTROLDEVICE_CLOSE_TIMEOUT : 15000,
            MODALMAINCONTROLDEVICE_CALL_CAPABILITIES : ['ring','call'],
            ONLY_MAIN_VIEW_NAMES : ['privateInvite','invite','webview','view','login','subscribe','reset-password','activate'],
            SELECT_REGION_ERROR: this.$gettext('Please select a region'),
            LOGIN_ERROR_MAIL_OR_PASSWORD : this.$gettext('Incorrect email or password'),
            LOGIN_ERROR : this.$gettext('Unable to login'),
            SHARE_SUCCESS : this.$gettext('Sharing has been successfully added'),
            SHARE_ERROR : this.$gettext('Failed to add sharing'),
            EMPTY_FIELD : _upperFirst(this.$gettext('you must enter an email and a password')),
            GROUP_WIDGET_TITLE :
            {
                'light' : this.$gettext('All lights'),
                'energy' : this.$gettext('All sockets'),
            }
        },
        rightPanel:{
            hide: true,
            extend :false,
            minimize :false,
            url :undefined,
            hideContent: false,
            init: false
        },
        debugLog: '',
        showDebugLog: false,
        auth : StorageService.getValue(localStorage, 'authorization'),
        regular : UserResource.isRegular(),

        show: true,
        readOnly: false,

        /*CACHE*/
        cacheDevices : [],
        cachePlant : [],

        subDevices : [],
        devicesByCapability : {},
        scenariosByCapability : [],
        

        // Current display plant (user plant, share plant, etc)
        userPlant : {zones:[]},

        // Current display plant (user plant, share plant, etc)
        plant : {zones:[]},

        // All user devices (user devices + shares devices)
        allDevices : [],
    
        // User devices (user devices + shares devices)
        devices : [],

        smartCameras : [],

        users : undefined,
        versionInfos: new VersionInfos(),
        scenarios : [],
        systems : [],
        manufacturers : [],
        brands : [],
        regions : [],
        selectedRegion : undefined,
        brandGroups : [],
        icons : {},
        dashboard : [],
        models : [],
        capabilities : {},
        externalAccounts : {
            externalAccounts: [],
            sharedExternalAccounts: []
        },
        hcUrl:'',
        defaultRooms : [],
        configMenu : {},
        templates : [],
        widgetModels : [],
        homepilots : [],
        services : [],
        serviceModels:[],
        externalUrl: 'about:blank',
        notificationsModules : [],
        navbar:
        {
              hide:false,
              extend:true
          },
          touchWrapper : {
              touchstartX: 0,
              touchstartY: 0,
              touchendX: 0,
              touchendY: 0,
              touchmoveX: 0,
              touchmoveY: 0
          },
          startUdp : false,
          firstInit: true,
          allTopologyFinished: true,
          onlineState: navigator.onLine,
          settings:SettingsResource.settings,
          cordovaIsinitialized:false,

          displayModalMainControlDevice:false,
          modalMainControlDeviceCloseTimeout:undefined,
          mainControlDeviceModule : 
          {
              module : 
              {
                  device: {}
              },
              status : {}
          },
          getAllTopologyInprogress:false,

          sharesLocations : [],

          menuHomesList : [],

          sharePlantSelected : {zones:[]},
          shareAddModal:false,

          ownShares : [],

          pushRegister : undefined,
          startSubscribePushPlant: false,
          cancelSource:undefined,

          displaySubMenuModal: false,


            installModal:false,
            displayCloseIconInstallModal:true,
            addDevicecloseConfirmDialog:false,
			externalName:'',
            externalRoute:'',
            brandName:undefined,
            availableAddDeviceModels:undefined,
            homepilot:null,

            serviceAddModal: false,
            editedService:undefined,
            addServicecloseConfirmDialog:false,

            displayModalTutorial:false,
            showModalTutorial:false,

            showDisplayMainControlDeviceModal: false
      }
  }

  export default {
    name: 'App',

    components: {
        Market,
        MainModal,
        NavbarDrawer, 
        Notification, 
        ClMenu, 
        CallControl, 
        ShareAdd,
        SpeechRecognitionModal,
        DeviceInstall,
        DeleteModal,
        ServiceAdd,
        Tutorial,
        MainControlDeviceModal
    },
    data: getDefaultAppData,
    created: function () {
        //loadingPage.setHide(true);
        this.pushRegister = new PushRegister(this.eventHub);


        document.addEventListener('deviceready', this.initCordova.bind(this), false);
        var _this = this;
        if(this.inApp)
        {
            window.setTimeout(function() {
                console.log('check initCordova after 5 seconds');
                _this.initCordova();
            }, 10000);
        }

        /*document.addEventListener('touchstart', e => this.touchstart(e), false);
        document.addEventListener('touchend', e => this.touchend(e), false);
        document.addEventListener('touchmove', e => this.touchmove(e), false);*/
        this.eventHub.$on('loadingPageShow', this.loadingPageShow);

        this.eventHub.$on('displayDebugLog', this.displayDebugLog);
        this.eventHub.$on('hideDebugLog', this.hideDebugLog);
        this.eventHub.$on('changeOrientation', this.changeOrientation);
        this.eventHub.$on('setUser', this.setRegular);
        this.eventHub.$on('getUsers', this.getUsers);
        this.eventHub.$on('getAllTopology', this.getAllTopology);
        this.eventHub.$on('setPlant', this.setPlant);
        this.eventHub.$on('getScenarios', this.getScenariosResource);
        this.eventHub.$on('getAllAccount', this.getManufacturersAndExternalAccounts);
        this.eventHub.$on('launchGlobalWs', this.launchGlobalWs);
        this.eventHub.$on('launchAllElements', this.launchAllElements);
        this.eventHub.$on('hideNavbar', this.hideNavbar);
        this.eventHub.$on('extendNavDrawer', this.minimizeNavbar);
        this.eventHub.$on('minimizeNavDrawer', this.extendNavbar);
        this.eventHub.$on('setUser', this.setUser);
        this.eventHub.$on('login', this.login);
        this.eventHub.$on('logout', this.logout);
        this.eventHub.$on('initCells', this.initCells);
        this.eventHub.$on('setDeviceStatus', this.setDeviceStatus);
        this.eventHub.$on('setDevice', this.setDevice);
        this.eventHub.$on('refreshInfoFromEvent', this.refreshInfoFromEvent);
        this.eventHub.$on('getResourcesAccounts', this.getResourcesAccounts);
        this.eventHub.$on('initConfigMenu', this.initConfigMenu);
        this.eventHub.$on('initSharesLocationsMenu', this.initSharesLocationsMenu);
        this.eventHub.$on('getCapabilities', this.getCapabilities);
        this.eventHub.$on('showCamera', this.showCamera);
        this.eventHub.$on('displayMainControlDevice', this.displayMainControlDevice);
        this.eventHub.$on('closeModalMainControlDevice', this.closeModalMainControlDevice);
        this.eventHub.$on('setPlantShareSelected', this.setPlantShareSelected);
        this.eventHub.$on('addOwnShares', this.addOwnShares);
        this.eventHub.$on('stopEZFullscreen', this.stopEZFullscreen);
        this.eventHub.$on('callbackManagement', this.callbackManagement);

        this.eventHub.$on('initRightPanel', this.initRightPanel);
        this.eventHub.$on('marketOnload', this.marketOnload);
        this.eventHub.$on('setExtendRightPanel', this.setExtendRightPanel);
        this.eventHub.$on('setMinimizeRightPanel', this.setMinimizeRightPanel);
        this.eventHub.$on('setUrlRightPanel', this.setUrlRightPanel);
        this.eventHub.$on('setHideContentRightPanel', this.setHideContentRightPanel);
        this.eventHub.$on('setInitRightPanel', this.setInitRightPanel);
        this.eventHub.$on('setHideRightPanel', this.setHideRightPanel);
        this.eventHub.$on('setHideContent', this.setHideContent);
        this.eventHub.$on('setCacheDevices', this.setCacheDevices);
        this.eventHub.$on('launchDisplaySubMenuModal', this.launchDisplaySubMenuModal);

        this.eventHub.$on('addDeviceFromRedirect', this.addDeviceFromRedirect);
		this.eventHub.$on('changeDisplayCloseIconInstallModal', this.changeDisplayCloseIconInstallModal);
        this.eventHub.$on('addDevice', this.addDevice);
        this.eventHub.$on('closeInstallModal', this.closeInstallModal);
        this.eventHub.$on('closeDialog',this.closeDialog);
        this.eventHub.$on('loadElements', this.loadElements);

		this.eventHub.$on('addServiceFinished', this.addServiceFinished);
		this.eventHub.$on('addService', this.addService);
        this.eventHub.$on('openEditService', this.openEditService);

        this.eventHub.$on('displayTutorial', this.displayTutorial);

        this.setInitRightPanel(true);

        this.initConfigMenu();
        var reconnect = false;

        //TO IOS QUOTA ISSUE (TO REMOVE AFTER!)
        //this.$store.state.noCache = true;
        //StorageService.clear(localStorage);
        //StorageService.clear(sessionStorage);
        
        if(this.eventHub.to.name=='invite')
        {
            this.show = false;
            this.readOnly= true;
            this.$store.state.noCache = true;
            reconnect = true;
        }
        if(reconnect)
        {
            this.auth = null;
            this.launchPublicShare();
        }

        else if(this.auth != null && (['invite','activate','login','subscribe','reset-password'].indexOf(this.eventHub.to.name)==-1)){

            //this.$store.state.selectedPlantId = StorageService.getValue(localStorage, 'plantId');
            UserResource.setUser(this.eventHub.user);
            // MQTT + clientId
            SystemService.initSystemInfos();
            StorageService.persist((sessionStorage.getItem('authorization')?sessionStorage:localStorage), 'mqttInfos', JSON.stringify(SystemService.setMqttInfos()));
            this.eventHub.$emit('mqttConnect');
            this.launchGlobalWs();
            if(this.eventHub.to.name != 'home' && this.eventHub.to.name != 'rooms')
            {
                this.getAllTopology('all',undefined, undefined, undefined, true);
            }

            SettingsResource.initSettings(this.eventHub.user);
        }
        else if (['privateInvite','invite','activate','login','subscribe','reset-password'].indexOf(this.eventHub.to.name)!=-1)
        {
            this.eventHub.$emit('loadingPageShow',false);
        }

        this.eventHub.user.setVersionInfos(this.versionInfos);
        //this.eventHub.$emit('showMainControlDevice');
        
    },

    mounted: function ()
    {
        this.displayTutorial();  
    },

    destroyed: function ()
    {
        document.removeEventListener('deviceready', this.initCordova.bind(this), false);
        document.removeEventListener('pause', this.pauseCordova.bind(this), false);
        document.removeEventListener('resume', this.resumeCordova.bind(this), false);
        this.eventHub.$off('loadingPageShow', this.loadingPageShow);
        this.eventHub.$off('displayDebugLog', this.displayDebugLog);
        this.eventHub.$off('hideDebugLog', this.hideDebugLog);
        this.eventHub.$off('changeOrientation', this.changeOrientation);
        this.eventHub.$off('setUser', this.setRegular);
        this.eventHub.$off('getUsers', this.getUsers);
        this.eventHub.$off('getAllTopology', this.getAllTopology);
        this.eventHub.$off('setPlant', this.setPlant);
        this.eventHub.$off('getScenarios', this.getScenariosResource);
        this.eventHub.$off('getAllAccount', this.getManufacturersAndExternalAccounts);
        this.eventHub.$off('launchGlobalWs', this.launchGlobalWs);
        this.eventHub.$off('launchAllElements', this.launchAllElements);
        this.eventHub.$off('hideNavbar', this.hideNavbar);
        this.eventHub.$off('extendNavDrawer', this.minimizeNavbar);
        this.eventHub.$off('minimizeNavDrawer', this.extendNavbar);
        this.eventHub.$off('setUser', this.setUser);
        this.eventHub.$off('login', this.login);
        this.eventHub.$off('logout', this.logout);
        this.eventHub.$off('initCells', this.initCells);
        this.eventHub.$off('setDeviceStatus', this.setDeviceStatus);
        this.eventHub.$off('setDevice', this.setDevice);
        this.eventHub.$off('refreshInfoFromEvent', this.refreshInfoFromEvent);
        this.eventHub.$off('getResourcesAccounts', this.getResourcesAccounts);
        this.eventHub.$off('initConfigMenu', this.initConfigMenu);
        this.eventHub.$off('initSharesLocationsMenu', this.initSharesLocationsMenu);
        this.eventHub.$off('getCapabilities', this.getCapabilities);
        this.eventHub.$off('showCamera', this.showCamera);
        this.eventHub.$off('displayMainControlDevice', this.displayMainControlDevice);
        this.eventHub.$off('closeModalMainControlDevice', this.closeModalMainControlDevice);
        this.eventHub.$off('setPlantShareSelected', this.setPlantShareSelected);
        this.eventHub.$off('addOwnShares', this.addOwnShares);
        this.eventHub.$off('stopEZFullscreen', this.stopEZFullscreen);
        this.eventHub.$off('callbackManagement', this.callbackManagement);

        this.eventHub.$off('initRightPanel', this.initRightPanel);
        this.eventHub.$off('marketOnload', this.marketOnload);
        this.eventHub.$off('setExtendRightPanel', this.setExtendRightPanel);
        this.eventHub.$off('setMinimizeRightPanel', this.setMinimizeRightPanel);
        this.eventHub.$off('setUrlRightPanel', this.setUrlRightPanel);
        this.eventHub.$off('setHideContentRightPanel', this.setHideContentRightPanel);
        this.eventHub.$off('setInitRightPanel', this.setInitRightPanel);
        this.eventHub.$off('setHideRightPanel', this.setHideRightPanel);
        this.eventHub.$off('setHideContent', this.setHideContent);
        this.eventHub.$off('setCacheDevices', this.setCacheDevices);

        this.eventHub.$off('launchDisplaySubMenuModal', this.launchDisplaySubMenuModal);

        this.eventHub.$off('addDeviceFromRedirect', this.addDeviceFromRedirect);
		this.eventHub.$off('changeDisplayCloseIconInstallModal', this.changeDisplayCloseIconInstallModal);
        this.eventHub.$off('addDevice', this.addDevice);
        this.eventHub.$off('closeInstallModal', this.closeInstallModal);
        this.eventHub.$off('closeDialog',this.closeDialog);
        this.eventHub.$off('loadElements', this.loadElements);

		this.eventHub.$off('addServiceFinished', this.addServiceFinished);
        this.eventHub.$off('addService', this.addService);
        this.eventHub.$off('openEditService', this.openEditService);

        this.eventHub.$off('displayTutorial', this.displayTutorial);
    },
    computed: {
        classObject: function () {
            return {
                'regular-mode': this.regular,
                'extend':
                this.$route.name == 'view' ||
                this.$route.name == 'activate' ||
                this.$route.name == 'privateInvite' ||
                this.$route.name == 'invite' ||
                this.$route.name == 'subscribe' ||
                this.$route.name == 'forgot-password' ||
                this.$route.name == 'reset-password' ||
                this.$route.name == 'login' ||
                this.$route.name == 'marketplace',
                'minimize':this.rightPanel.extend && this.$route.name != 'marketplace'
            }
        },
        classRightPanelObject: function () {
            return {
                /*'regular-mode': UserResource.isRegular(),*/
                'extend':this.rightPanel.extend,
                'minimize':this.rightPanel.minimize,
                'init': this.rightPanel.init
            }
        },
        subMenuSharesLocations: function () {
            var _this = this;
            var subMenu = [];
            this.sharesLocations.forEach(function(sl,index){
                if(sl.plants && sl.plants.length > 0)
                {
                    
                    subMenu.push({
                        title: (sl.user.firstname?sl.user.firstname:'')+(sl.user.lastname?' '+sl.user.lastname:''),
                        zones: (sl.plants[0].zones?_map(sl.plants[0].zones, 'id'):[]),
                        name: 'shared-location-'+index,
                        icon: 'cl-icon cl-icon-home-share',
                        display: function(){return true},
                        cls: function(){
                            var cls = {};
                            cls['subMenu__'+this.name] = true;
                            return cls;
                        },
                        click: function(){
                            _this.eventHub.$emit('initSwiperRoom');
                            if((['rooms','sharesLocations'].indexOf(_this.$route.name)!=-1)&&sl.plants[0].id==_this.$store.state.selectedPlantId )return false;
                            _this.eventHub.loadingPage.setHide(false);
                            _this.$store.state.selectedPlantId = sl.plants[0].id;
                            _this.$store.state.plantUser = sl.user;
                            _this.setPlant(sl.plants[0]);
                            _this.sharePlantSelected = sl.plants[0];
                            _this.$router.push({ name:'sharesLocations', params: {uid : sl.uid, plantUser:sl.user}});
                        },
                    });
                }
            });
            subMenu.push({
                    title: _upperFirst(_this.$gettext('My home')),
                    name: 'home',
                    icon: 'cl-icon cl-icon-home',
                    zones: (_this.userPlant.zones?_map(_this.userPlant.zones, 'id'):[]),
                    display: function(){return true},
                    cls: function(){
                        var cls = {};
                        cls['subMenu__'+this.name] = true;
                        return cls;
                    },
                    click: function(){
                        var defaultView = StorageService.getValue(localStorage, 'defaultView');
                        _this.eventHub.$emit('initSwiperRoom');
                        _this.eventHub.$emit('goTo',(defaultView?'view':'rooms'));
                    },
            }); 
            return subMenu;
        },
    },
    watch: {
        dashboard: function (val, oldVal) {
            if(JSON.stringify(val)!=JSON.stringify(oldVal))
            {
                //StorageResource.setStoreElt(this,'dashboard');
            }
        },
        scenarios: function (val, oldVal) {
            if(JSON.stringify(val)!=JSON.stringify(oldVal))
            {
                //StorageResource.setStoreElt(this,'scenarios');
            }
        }
    },
    methods: {    

        test: function()
        {
        let payload = {
            "event": "alert",
            "ip": "192.168.51.51",
            "uptime": 5057,
            "version": "1.8.0+07",
            "region": "eu",
            "timestamp": "2021-06-04 13:44:30",
            "uid": this.uuidV4(),
            "modules": [
                {
                "device": {
                    "code": "c",
                    "model": 11,
                    "unit": 5,
                    "rf": "c5800045",
                    "value": "",
                    "status": [
                    {
                        "capability": "ring"
                    }
                    ],
                    "switchs": [],
                    "bearerId": "334259f6753737570c43ef07aa705d9a",
                    "associateStatus": "ASSOCIATED"
                },
                "id": "35580f2aae0b93a2bf0792491ef3422e"
                }
            ],
            "clientId": "homepilot_334259f6753737570c43ef07aa705d9a"
            };
            this.callbackManagement(payload, 'mqtt');   
        },

        launchDisplaySubMenuModal: function(displaySubMenuModal){
            this.displaySubMenuModal = displaySubMenuModal;
        },
        resetData: function(){
            Object.assign(this.$data, this.$options.data.apply(this));
            this.pushRegister = new PushRegister(this.eventHub);
        },

        loadingPageShow: function(b){
            this.eventHub.loadingPage.setHide(!b);
            //this.showModalTutorial=true;
        },

        displayDebugLog: function(debugLog){
            this.showDebugLog = true;
            this.debugLog = debugLog;
        },
        hideDebugLog: function(){
            this.showDebugLog = false;
            this.debugLog = '';
        },
        refresh: function(loaded){
            this.eventHub.$emit('launchAllElements',loaded);
        },
        
        initConfigMenu: function(){
            var _this = this;
            this.configMenu = {
                id:'config',
                launchClick:function(clickFunc){
                    _this.eventHub.$emit('setPlant',JSON.parse(StorageService.getValue(localStorage, 'storage - userPlant')));
                    clickFunc();
                },
			  	      subMenu : [
                    {
                        title: this.CONSTANTS.DEVICE_TXT,
                        name: 'add-device',
                        display: function(){return true},
                        cls: function(){
                            var cls = {};
                            cls['subMenu__'+this.name] = true;
                            return cls;
                        },
                        click: function(){
                            _this.eventHub.$emit('setClickOnAdd',false);
                            _this.eventHub.$emit('addDevice');
                        },
						icon: 'cl-icon cl-icon-add-device',
                    },
                    {
                        title: this.CONSTANTS.SERVICE_TXT,
                        name: 'add-service',
                        display: function(){return true},
                        cls: function(){
                            var cls = {};
                            cls['subMenu__'+this.name] = true;
                            return cls;
                        },
                        click: function(){
                            if(_this.versionInfos.serviceCount != 'INF' && _this.services.length >= _this.versionInfos.serviceCount){
                                _this.eventHub.$emit('displayAlertKey', 'unauthorized');
                            }
                            else{
                                //if(_this.$route.name=='actions'){
                                    _this.eventHub.$emit('setClickOnAdd',false);
                                    _this.eventHub.$emit('addService');
                                /*}
                                else{
                                    _this.$router.push({ name: 'actions', params: {displayServiceAddModal: true }});
                                }*/
                            }
                                    },
                        icon: 'cl-icon cl-icon-add-kit',
                    },
					{
                        title: this.CONSTANTS.SCENARIO_TXT,
                        name: 'add-scenario',
                        display: function(){return true},
                        cls: function(){
                            var cls = {};
                            cls['subMenu__'+this.name] = true;
                            return cls;
                        },
						click: function(){
                            _this.eventHub.$emit('setClickOnAdd',false);
                            _this.configMenu.launchClick(
                                function(){
                                    if(_this.versionInfos.scenarioCount != 'INF' && _this.scenarios.length >= _this.versionInfos.scenarioCount){
                                        _this.eventHub.$emit('displayAlertKey', 'unauthorized');
                                    }
                                    else{
                                        if(_this.$route.name=='actions'){
                                            _this.eventHub.$emit('addScenario');
                                        }
                                        else{
                                            _this.$router.push({ name: 'actions', params: {displayScenariosModal: true }});
                                        }
                                    }
                                }
                            );
						},
						icon: 'cl-icon cl-icon-add-scenario',
					},



                    {
                        title: this.CONSTANTS.ROOM_TXT,
                        name: 'add-room',
                        display: function(){return true},
                        cls: function(){
                            var cls = {};
                            cls['subMenu__'+this.name] = true;
                            return cls;
                        },
                        click: function(){
                            _this.eventHub.$emit('setClickOnAdd',false);
                            _this.configMenu.launchClick(
                                function()
                                {
                                    if(_this.versionInfos.roomCount != 'INF' && _this.plant.zones.length >= _this.versionInfos.roomCount){
                                        _this.eventHub.$emit('displayAlertKey', 'unauthorized');
                                    }
                                    else{
                                        if(_this.$route.name=='rooms')
                                            _this.eventHub.$emit('addRoom');
                                        else
                                            _this.$router.push({ name: 'rooms', params: {displayRoomAddModal: true }});
                                    }
                                }
                            );
                        },
                        icon: 'cl-icon cl-icon-add-room',
                    },             

					{
                        title: this.CONSTANTS.SHARE_TXT,
                        name: 'add-share',
                        display: function(){return true},
                        cls: function(){
                            var cls = {};
                            cls['subMenu__'+this.name] = true;
                            return cls;
                        },
						click: function(){
                            _this.eventHub.$emit('setClickOnAdd',false);
                            _this.configMenu.launchClick(
                                function(){
                                    _this.shareAddModal = true;
                                    _this.$router.push({ name: 'rooms'});
                                }
                            );
						            },
						            icon: 'cl-icon cl-icon-add-share',
                    }
				        ]
            };
            /*if(StorageService.getValue(localStorage, 'role') == 'ADMIN'){
                var obj = {
                    title: this.CONSTANTS.USER_TXT,
                    name: 'add-user',
                    display: function(){return true},
                    cls: function(){
                        var cls = {};
                        cls['subMenu__'+this.name] = true;
                        return cls;
                    },
                    click: function(){
                        _this.configMenu.launchClick(
                            function(){
                                if(_this.versionInfos.userCount != 'INF' && _this.users.members.length >= _this.versionInfos.userCount){
                                    _this.eventHub.$emit('displayAlertKey', 'unauthorized');
                                }
                                else{
                                    if(_this.$route.name=='settings')
                                        _this.eventHub.$emit('openDialog', 'addUser-dialog');
                                    else
                                        _this.$router.push({ name: 'settings', params: {displayUserAddModal: true }});
                                }
                            }
                        );
                    },
                    icon: 'cl-icon cl-icon-add-user1',
                };
                this.configMenu.subMenu.push(obj);
            }*/
        },
        initSharesLocationsMenu: function(){
            this.configMenu = {
                id:'sharesLocations',
                subMenu:this.subMenuSharesLocations
            };
        },

        initSharesNotifications: function(){
            var actualSharesLocationsPlanId = ShareResource.getSharesLocationsPlanId(this.sharesLocations);
            ShareResource.subscribeMqttPlant(this.sharesLocations, this.eventHub, actualSharesLocationsPlanId);
            if(this.cordovaIsinitialized)
            {
                this.startSubscribePushPlant = true;
                ShareResource.subscribePushPlant(this,actualSharesLocationsPlanId); 
            }
        },

       movePlantModulesToRoom: function (plant, room) {
            if(plant.modules==undefined)return false;
            if(room.modules==undefined)
            {
                room.modules = []; 
            }         
            room.modules = room.modules.concat(plant.modules);
       },
       initCordova: function (orientation) {
           if(this.cordovaIsinitialized)return false;
           this.cordovaIsinitialized=true;
            document.addEventListener('backbutton', function (e) {
                window.history.back();
                //e.preventDefault();
            }, false );
            document.addEventListener('pause', this.pauseCordova.bind(this), false);
            document.addEventListener('resume', this.resumeCordova.bind(this), false);
            this.changeOrientation((this.eventHub.to.name=='view'?'landscape':'portrait'));
            if(!this.startUdp)
            {
                CordovaService.startUdp(this.eventHub,this);
                this.startUdp = true;
            }

            if(!this.eventHub.startPushRegister && StorageService.getValue(localStorage, 'authorization'))
            {
                CordovaService.pushRegister(this);
                this.eventHub.startPushRegister = true;
            }

            if(!this.startSubscribePushPlant)
            {
                var actualSharesLocationsPlanId = ShareResource.getSharesLocationsPlanId(this.sharesLocations);
                ShareResource.subscribePushPlant(this,actualSharesLocationsPlanId); 
            }
            if (['privateInvite','invite','activate','login','subscribe','reset-password'].indexOf(this.eventHub.to.name)==-1)
            {
                this.loadElementInStorage();
            }
            
       },

       pauseCordova: function () {
           LogService.consoleLogTag(this.CONSTANTS.TAG,'cordova pauseCordova');
           this.eventHub.foreground = false;
           this.eventHub.$emit('pauseCordova');
       },

       resumeCordova: function () {
           LogService.consoleLogTag(this.CONSTANTS.TAG,'cordova resumeCordova');
            this.eventHub.foreground = true;
            this.eventHub.$emit('resumeCordova');
       },

       changeOrientation: function (orientation) {
            //if(orientation==undefined)
                //orientation = (this.$route.name=='view'?'landscape':'user');   
            CordovaService.changeOrientation(orientation);
       },
       touchWrapperInit: function (event) {
           this.touchWrapper = {
            touchstartX: 0,
            touchstartY: 0,
            touchendX: 0,
            touchendY: 0,
            touchmoveX: 0,
            touchmoveY: 0
        };
       },
       touchstart: function (event) {
            var touch = (event.changedTouches?event.changedTouches[0]:event);
            this.touchWrapper.touchmoveY = touch.clientY;
       },
       touchmove: function (event) {
        var touch = (event.changedTouches?event.changedTouches[0]:event);
        if(touch.clientY<this.touchWrapper.touchmoveY)
        {
            this.eventHub.$emit('setMenu', false);
        }
        else
        {
            this.eventHub.$emit('setMenu', true);
        }
        this.touchWrapper.touchmoveY = touch.clientY;
       },
       touchend: function (event) {
           this.touchWrapperInit();
       },
       hideNavbar: function (b) {
        this.navbar.hide = b;
       },

       extendNavbar: function () {
        this.navbar.extend = true;
       },

       minimizeNavbar: function () {
        this.navbar.extend = false;
       },

       setRegular: function () {
         this.regular = UserResource.isRegular();
       },

       launchGlobalWs : function(){
        if(!this.startUdp)
        {    
            CordovaService.startUdp(this.eventHub,app);
        }
        if(!this.eventHub.startPushRegister)
        {
            CordovaService.pushRegister(this);
            //this.eventHub.startPushRegister = true;
        }
        this.getUsers();
        //this.getModels();
        //this.getSystems();
        this.getIcons();
        this.getManufacturersAndExternalAccounts();
        this.getTemplates();
        this.getWidgetModels();
        this.getDefaultRooms();
        this.getServiceModels();
        this.getResourcesAccounts();
       },

       launchAllElements: function(refresh){
        this.launchGlobalWs();
        this.getAllTopology('all',undefined, undefined, refresh, true);
       },

        setUser: function () {
            UserResource.setUser(this.eventHub.user);
        },
        getUsers : function(){
         var _this = this;
         StorageResource.setStoreElt(this,'users');
         if(this.users)
         {
            _this.versionInfos.setVersionsInfos(this.users.version.model);
         }
         FamilyResource.getFamily().then(
           function(response){
             response.data.families[0].members = (response.data.families[0].members?_orderBy(response.data.families[0].members, 'login'):[]);
             _this.users = response.data.families[0];
             StorageResource.storeElt('users',_this.users);
             _this.versionInfos.setVersionsInfos(response.data.families[0].version.model);
             StorageResource.storeElt('versionInfos', _this.versionInfos);
           }).catch(
           function(e) {
             console.log(e);
             StorageResource.storeElt('versionInfos', _this.versionInfos);
           });
        },

        loadElementInStorage : function(){
            StorageResource.setStoreElt(this,'sharesLocations');
            var actualSharesLocationsPlanId = ShareResource.getSharesLocationsPlanId(this.sharesLocations);
            ShareResource.subscribeMqttPlant(this.sharesLocations, this.eventHub, actualSharesLocationsPlanId);

            StorageResource.setStoreElt(this,'manufacturers');
            StorageResource.setStoreElt(this,'externalAccounts');

            //this.$store.state.selectedExternalAccounts = ExternalResource.getUserExternalAccounts(this.externalAccounts,this.eventHub.user.username);

            StorageResource.setStoreElt(this,'brands');
            StorageResource.setStoreElt(this,'brandGroups');
            StorageResource.setStoreElt(this,'systems');
            this.$store.state.systems = this.systems;

            StorageResource.setStoreElt(this,'userPlant');

            if(this.eventHub.to.name!='invite')
            {
                this.loadPlant();
            }

            StorageResource.setStoreElt(this,'devices');
            StorageResource.setStoreElt(this,'allDevices');
            this.$store.dispatch('setAllDevices',this.allDevices);

            var ownShares = StorageService.getValue(localStorage, 'storage - ownShares');
            this.setOwnShares(StringService.toJson(ownShares));

            this.cachePlant = JSON.parse(JSON.stringify(this.plant));
            this.cacheDevices = JSON.parse(JSON.stringify(this.devices));
            StorageResource.setStoreElt(this,'services');
            this.$store.dispatch('setServices',this.services);
            StorageResource.setStoreElt(this,'subDevices');
            StorageResource.setStoreElt(this,'devicesByCapability');
            StorageResource.setStoreElt(this,'scenariosByCapability');
            StorageResource.setStoreElt(this,'dashboard');
            StorageResource.setStoreElt(this,'models');
            this.$store.dispatch('setModels',this.models);
            StorageResource.setStoreElt(this,'notificationsModules');
            StorageResource.setStoreElt(this,'scenarios');
            StorageResource.setStoreElt(this,'homepilots');
            StorageResource.setStoreElt(this,'appObj');

            this.setStoreRegions();

            this.$store.dispatch('setHomepilots',this.homepilots);

            this.$store.state.meshHomepilots = _filter(this.homepilots, function(hp) {
                return (hp.device.submodelObj.name.toLowerCase()  != 'homepilot' && hp.device.submodelObj.name.toLowerCase()  != 'hp-300'); 
            });

            this.addClassDevice();
            this.getCapabilities();
            LogService.consoleLogTag(this.CONSTANTS.TAG,'SET this.$store.state.selectedPlantId','this.plant.id',this.plant.id);
            this.$store.state.selectedPlantId = this.plant.id;



            StorageService.persist(localStorage, 'routeUid', this.eventHub.to.params.uid);
        },

        getAllTopology : async function(load, section, func, refresh, ignoreCache){ 
            return new Promise(
                (resolve, reject) => {
                    try
                    {
                        if(this.auth == null) return false;
                        if (typeof load === 'string') load = [load];
                        if(!refresh)
                        {
                            this.loadElementInStorage();
                        }
                        this.getPostAllTopology(load, section, func, refresh, ignoreCache, resolve, reject);
                    }
                    catch (e) {
                        console.log(e);
                        LogService.consoleErrorTag(this.CONSTANTS.TAG,'getAllTopology','error',e);
                        resolve();
                    }
                });
            
        },
        
        getPostAllTopology : function(load, section, func, refresh, ignoreCache, resolve, reject){

            var allPromise = [];
            /*if(this.$store.state.cancelTokenSource)
            {
                this.$store.state.cancelTokenSource.cancel('Operation canceled.');
            }*/
            var CancelToken = axios.CancelToken;
            this.$store.state.cancelTokenSource = CancelToken.source(); 
            
            allPromise.push(LocationResource.getPlantTopology(section, this.$store.state.cancelTokenSource,ignoreCache)); //0
            if(this.$store.state.bMainAccount)
            {
                allPromise.push(NotificationResource.getAll(true, this.$store.state.cancelTokenSource)); //1
            }
            else
            {
                allPromise.push(ShareResource.getSharesNotifications(this.$store.state.selectedPlantId,true, this.$store.state.cancelTokenSource)); //1
            }
            
            allPromise.push(SystemResource.getModels(undefined, this.$store.state.cancelTokenSource)); //2
            allPromise.push(DashboardService.getDashboard(this.$store.state.cancelTokenSource)); //3
            allPromise.push(SystemResource.getManufacturers(undefined, this.$store.state.cancelTokenSource)); //4
            allPromise.push(ExternalResource.getAllAccount(this.$store.state.cancelTokenSource)); //5
            allPromise.push(SystemResource.getBrandGroups(undefined, this.$store.state.cancelTokenSource)); //6
            allPromise.push(SystemResource.getAll(undefined, undefined, undefined, this.$store.state.cancelTokenSource)); //7
            allPromise.push(ShareResource.getSharesLocations(this.$store.state.cancelTokenSource)); //8
            allPromise.push(ShareResource.getOwnShares(undefined, undefined, this.$store.state.cancelTokenSource)); //9
            allPromise.push(SystemResource.getRegions(undefined, this.$store.state.cancelTokenSource)); //10
            allPromise.push(SystemResource.getApps(window.VARIANT)); //11

            const allDevicesStatusPromises = this.getAllDevicesStatusPromises();
            allPromise = allPromise.concat(allDevicesStatusPromises);

            Promise.all(allPromise).then(
                (response) => {
                    this.plant = {zones:[]};
                    this.userPlant = {zones:[]};
                    this.sharesLocations = [];
                    this.ownShares = [];
                    this.devices = [];
                    this.subDevices = [];
                    this.devicesByCapability = {};
                    this.scenariosByCapability = [];
                    this.services = [];
                    this.dashboard = [];
                    //this.notificationsModules = [];
                    this.models = [];
                    this.systems = [];
                    this.regions = [];
                    //response[0].data.plant.zones = [];
                    this.setManufacturersAndExternalAccounts([response[4],response[5]]);
                    this.getBrandGroups(response[6]);
                    this.getSystems(response[7]);
                    var topologyResponse = response[0];
                    var plant = topologyResponse.data.plant;
                    this.setUserPlant(plant);
                    //this.notificationsModules = response[1];
                    if(Array.isArray(response[1]))
                    {
                        let notificationsModules = response[1];
                        const plantId = (this.$store.state.bMainAccount?plant.id:this.$store.state.selectedPlantId);
                        let notificationsModulesOthersPlants = _filter(this.notificationsModules, (nm) => {
                            return nm.plantId != plantId; 
                        });

                        notificationsModules = notificationsModules.map((nm) =>{
                            nm.plantId = plantId;
                            return nm;
                        });
       
                        this.notificationsModules = ArrayService.merge(notificationsModulesOthersPlants,notificationsModules,'id');
                        LogService.consoleLogTag(this.CONSTANTS.TAG,'getAllTopology','this.notificationsModules',this.notificationsModules);
                    }            
                    this.getModels(response[2]);
                    this.getRegions(response[10]);
                    this.setAppObj(response[11]);
                    
                    this.initAllDevicesAndPlants(topologyResponse,response[8]); 
                    if(response[8])
                    {
                        
                        this.setSharesLocations(response[8]);
                        
                    }

                    LogService.consoleLogTag(this.CONSTANTS.TAG,'SELECTED PLANT ID','this.eventHub.to.name',this.eventHub.to.name);
                    if(this.eventHub.to.name=='invite')
                    {
                        plant = this.sharesLocations[0].plants[0];
                        this.loadPublicShare();
                    }
                    //compare the plant id of the main account with the one selected
                    /*if(this.store.state.selectedPlantId!=StorageService.getValue(localStorage, 'plantId'))
                    {*/ 

                    else if(this.eventHub.to.name=='sharesLocations' && this.eventHub.to.params.uid)
                    {
                        var sharesLocations = _find(this.sharesLocations, {'uid':this.eventHub.to.params.uid});   
                        if(sharesLocations)
                        {
                            //plant = JSON.parse(JSON.stringify(sharesLocations.plants?sharesLocations.plants[0]:[]));
                            if(sharesLocations.plants)
                            {
                                plant = sharesLocations.plants[0];
                            }
                            else
                            {
                                plant.splice(0);
                            }
                            this.$store.state.plantUser = (sharesLocations.user?sharesLocations.user:undefined);
                            this.$store.state.userMail = (sharesLocations.user?sharesLocations.user.email:undefined);
                            this.$store.state.selectedPlantId = plant.id;
                            
                            this.$store.state.selectedExternalAccounts = ExternalResource.getUserExternalAccounts(this.externalAccounts,sharesLocations.user.email);
                        }
                    }
                    else
                    {
                        
                        LogService.consoleLogTag(this.CONSTANTS.TAG,'SELECTED PLANT ID','_plant.id',plant.id);
                        LogService.consoleLogTag(this.CONSTANTS.TAG,'SELECTED PLANT ID','this.$store.state.selectedPlantId',this.$store.state.selectedPlantId);
                        let userMail = this.eventHub.user.username;
                        //If current plant ID == main user plant id
                        if(this.$store.state.selectedPlantId && this.$store.state.selectedPlantId!=plant.id)
                        {
                            let sharesLocation = ShareResource.getSharesLocationFromPlanId(this.sharesLocations, this.$store.state.selectedPlantId)
                            plant = sharesLocation.plants[0];
                            userMail = (sharesLocation.user?sharesLocation.user.email:undefined);
                            this.$store.state.plantUser = (sharesLocation.user?sharesLocation.user:undefined);
                        }
                        else
                        {
                            this.$store.state.plantUser = undefined;
                        }
                        this.$store.state.selectedPlantId = plant.id;
                        this.$store.state.userMail = userMail;
                        LogService.consoleLogTag(this.CONSTANTS.TAG,'SELECTED PLANT ID','this.$store.state.selectedPlantId',this.$store.state.selectedPlantId);
                        this.$store.state.selectedExternalAccounts = ExternalResource.getUserExternalAccounts(this.externalAccounts,this.$store.state.userMail);
                    }
                    //}
                    LogService.consoleLogTag(this.CONSTANTS.TAG,'getAllTopology','store.state.selectedExternalAccounts',this.$store.state.selectedExternalAccounts);
 
                    /*if(plant.zones)
                    {
                        this.movePlantModulesToRoom(plant,plant.zones[0]);
                    }*/
                    //this.parseMultisocket(topologyResponse.data.plant);

                    //TO DELETE AFTER TESTS !!!
                    //if(topologyResponse.data.plant.zones[0].modules==undefined)topologyResponse.data.plant.zones[0].modules = [];
                    //topologyResponse.data.plant.zones[0].modules = topologyResponse.data.plant.zones[0].modules.concat(topologyResponse.data.plant.modules);
                
                    

                    if (load.indexOf('all')!=-1)
                    {   
                        this.setDevices(plant);
                        this.setPlant(plant);
                        

                        //this.homepilots = LocationResource.getAllDevicesByModel(this.allDevices,'homepilot');
                        this.homepilots = LocationResource.getAllDevicesModelObjByProperty(this.allDevices,'gateway',true);
                        this.$store.dispatch('setHomepilots',this.homepilots);

                        this.$store.state.meshHomepilots = _filter(this.homepilots, (hp) =>{
                            return (hp.device.submodelObj.name.toLowerCase()  != 'homepilot' && hp.device.submodelObj.name.toLowerCase()  != 'hp-300'); 
                        });
                    }
                    else
                    {
                        for(var i=0; i<load.length; i++)
                        {
                            var str = load[i];
                            this['get'+str.charAt(0).toUpperCase() + str.slice(1)](plant,func);
                        }
                    }

                    this.getDashboard(response[3]);
                    this.initServicesHeating();

                    this.devicesByCapability = DeviceResource.groupDevicesByCapability(this.allDevices);
                    this.scenariosByCapability = DeviceResource.groupScenariosByCapability(this.services);

                    if(response[9])
                    {

                        if(response[9].data.shares && response[9].data.shares.length > 0)
                        {
                            StorageResource.storeElt('ownShares', response[9].data.shares);
                            this.setOwnShares(response[9].data.shares);
                        }    
                    }

                    if(allDevicesStatusPromises.length>0)
                    {
                        for(let i=12; i<12+allDevicesStatusPromises.length; i++)
                        {
                            this.initAllDevicesStatus(response[i]);
                        }
                    }

                    this.allTopologyFinished = false;
                    LogService.consoleLogTag(this.CONSTANTS.TAG,'getAllTopology','plant',this.plant);
                    StorageResource.storeElt('plant',this.plant);
                    StorageResource.storeElt('userPlant',this.userPlant);
                    StorageResource.storeElt('devices',this.allDevices);
                    StorageResource.storeElt('allDevices',this.allDevices);
                    this.$store.dispatch('setAllDevices',this.allDevices);
                    StorageResource.storeElt('subDevices',this.subDevices);
                    StorageResource.storeElt('devicesByCapability',this.devicesByCapability);
                    StorageResource.storeElt('scenariosByCapability',this.scenariosByCapability);
                    StorageResource.storeElt('dashboard',this.dashboard);
                    StorageResource.storeElt('models',this.models);
                    this.$store.dispatch('setModels',this.models);
                    StorageResource.storeElt('notificationsModules',this.notificationsModules);
                    StorageResource.storeElt('scenarios',this.scenarios);
                    StorageResource.storeElt('homepilots',this.homepilots);
                    StorageResource.storeElt('services',this.services);
                    this.$store.dispatch('setServices',this.services);
                    StorageResource.storeElt('regions',this.regions);

                    this.setStoreRegions();
                    StorageResource.storeElt('sharesLocations',this.sharesLocations);
                    this.initSharesNotifications();
                    

                    //if(UartService.iswaitStateCallbackArray())
                    //{
                    /*if(!this.eventHub.from.name || this.eventHub.to.name=='rooms' || this.eventHub.to.name=='sharesLocations' || refresh)
                    {
                    UartService.sendGetInfoForAllGroup(this.homepilots,this.allDevices,this.eventHub);   
                    }*/

                    //}
                    if(this.configMenu.id=='sharesLocations')
                    {
                        this.initSharesLocationsMenu();
                    }
                    this.eventHub.$emit('loadingPageShow',false);
                    this.addClassDevice();
                    this.eventHub.$emit('getAllTopologyFinished');
                    resolve();

                }).catch(
                (error) =>{
                    LogService.consoleErrorTag(this.CONSTANTS.TAG,'getAllTopology','error',error);
                    this.allTopologyFinished = false;
                    this.eventHub.$emit('loadingPageShow',false);
                    if(error.message=='Operation canceled.') return false;
                    this.eventHub.$emit('getAllTopologyFinished');
                    this.initSharesNotifications();
                    /*if(!this.eventHub.from.name || this.eventHub.to.name=='rooms' || this.eventHub.to.name=='sharesLocations' || refresh)
                    {
                        UartService.sendGetInfoForAllGroup(this.homepilots,this.allDevices,this.eventHub);    
                    }*/
                    resolve();
                });
        },

        getAllDevicesStatusPromises : function(){
            var allPromise = [];
            const modelSystem = [{model:'sthi',system:'meteo'}];
            modelSystem.forEach((ms) => {
                allPromise.push(DeviceResource.getAllStatus(ms.model,ms.system));
            });
            return allPromise;
        },


        initAllDevicesStatus : function(devices){
            if(!Array.isArray(this.$store.state.allDevices) || !Array.isArray(devices))return;
            devices = devices.filter( d => d.associateStatus == 'ASSOCIATED');
            devices.forEach((d) => {
                if(Array.isArray(d.status))
                {
                    let allDevicesDevice = this.$store.state.allDevices.find(dAllDevices => dAllDevices.id == d.id);
                    if(allDevicesDevice && allDevicesDevice.device)
                    {
                        if(!Array.isArray(allDevicesDevice.device.status))
                        {
                            allDevicesDevice.device.status = [];
                        }
                        allDevicesDevice.device.status = { ...allDevicesDevice.device.status, ...d.status};
                    }
                }
            });
        },

        initAllDevicesAndPlants : function(userTopology,sharesLocations){
            LogService.consoleLogTag(this.CONSTANTS.TAG,'initAllDevicesAndPlants','sharesLocations',sharesLocations);
            var _this = this;
            this.allDevices.splice(0);

            if((userTopology && userTopology.data.plant))
            {
                if(userTopology.data.plant.zones)
                {
                    this.movePlantModulesToRoom(userTopology.data.plant,userTopology.data.plant.zones[0]);
                }
                this.setDevices(userTopology.data.plant,this.allDevices);
            }
            if(sharesLocations && sharesLocations.data.sharedLocations && sharesLocations.data.sharedLocations.length > 0)
            {
                sharesLocations.data.sharedLocations.forEach(function(sl) {
                    if(sl.plants && sl.plants.length > 0)
                    {
                        sl.plants.forEach(function(p) {
                            if(p)
                            {
                                if(p.zones)
                                {
                                    _this.movePlantModulesToRoom(p,p.zones[0]);
                                }
                                _this.setDevices(p,_this.allDevices);
                            }
                        });  
                    }
                });
            }
        },


        loadPlant : function(){
            let loadingPageShow = true;
            let plant = undefined;
            if(this.eventHub.to.params.uid)
            {
                loadingPageShow = false;   
                let sharesLocations = _find(this.sharesLocations, {'uid':this.eventHub.to.params.uid});
                if(sharesLocations && sharesLocations.plants && sharesLocations.plants.length>0)
                {
                    plant = sharesLocations.plants[0];
                    this.setPlant(plant);
                }
                StorageResource.setStoreElt(this,'plant');
            }
            else if(this.eventHub.to.name == 'rooms' && StorageService.getValue(localStorage, 'storage - userPlant'))
            {
                loadingPageShow = false;
                try {
                    plant = JSON.parse(StorageService.getValue(localStorage, 'storage - userPlant'));
                    this.setPlant(plant);

                } catch(e) {
                    LogService.consoleErrorTag(this.CONSTANTS.TAG,'loadPlant','error with JSON');
                }

            }
            else if (StorageService.getValue(localStorage, 'storage - plant'))
            {
                loadingPageShow = false;
                try {
                    plant = JSON.parse(StorageService.getValue(localStorage, 'storage - plant'));
                    this.setPlant(plant);

                } catch(e) {
                    LogService.consoleErrorTag(this.CONSTANTS.TAG,'loadPlant','error with JSON');
                }
            }
            if(plant)
            {
                let sharesLocation = ShareResource.getSharesLocationFromPlanId(this.sharesLocations, plant.id);
                this.$store.state.plantUser = (sharesLocation && sharesLocation.user?sharesLocation.user:undefined);
                this.$store.state.userMail = (sharesLocation && sharesLocation.user?sharesLocation.user.email:this.eventHub.user.username);
                this.$store.state.selectedExternalAccounts = ExternalResource.getUserExternalAccounts(this.externalAccounts,this.$store.state.userMail);

            }
            this.eventHub.$emit('loadingPageShow',loadingPageShow);
            this.allTopologyFinished = loadingPageShow;
        },

        setPlant : function(plant){
            if(plant.zones==undefined)plant.zones = [];
            this.plant = plant;
            this.$store.state.selectedPlantId=plant.id;

            
            this.$store.state.bMainAccount=(StorageService.getValue(localStorage, 'plantId')==this.$store.state.selectedPlantId);
            this.$nextTick(() => {
                this.getScenarios(plant);
            });
            LogService.consoleLogTag(this.CONSTANTS.TAG,'setPlant','this.$store.state.selectedPlantId',this.$store.state.selectedPlantId);
            LogService.consoleLogTag(this.CONSTANTS.TAG,'setPlant','plantId',StorageService.getValue(localStorage, 'plantId'));
            StorageResource.storeElt('plant',plant);
        },

        setUserPlant : function(userPlant){
            if(userPlant.zones==undefined)userPlant.zones = [];
            this.userPlant = userPlant;
        },

        getModules : function(plantId,zone,devicesArr){
          //the array of module id to delete at the end (MOES Eliot for example)
          var moduleIdTodeleteArr = [];
          /*Selected item*/
          //Can select item (share, etc)
          zone.selected = false;

          for(var i=0; i< zone.modules.length; i++){

            /*Selected item*/
            //Can select item (share, etc)
            zone.modules[i].selected = false;   

            //If module is a scenario
            if(zone.modules[i].scenario)
            {
                var scenario = _find(this.scenarios, {'id':zone.modules[i].id});
                /*console.log(_merge(zone.modules[i], scenario));*/
                if(scenario && scenario.locationId)
                {
                    zone.modules[i].locationId = scenario.locationId;
                }
            }
            else if(zone.modules[i].service){
                let serviceIndex = _findIndex(this.services, {'id':zone.modules[i].id});
                if(serviceIndex!=-1)
                {
                    this.services.splice(serviceIndex,1);
                }
                zone.modules[i].plantId = plantId;
                zone.modules[i].zoneId = zone.id;
                this.services.push(zone.modules[i]);
                var nm = ArrayService.find(this.notificationsModules,'id',zone.modules[i].id);
                if(nm!=undefined)
                {
                    zone.modules[i].notification = nm;
                }
                else
                {
                    var emptyNotification = NotificationResource.getEmptyNotification(zone.modules[i]);
                    zone.modules[i].notification = emptyNotification;
                    this.notificationsModules.push(emptyNotification);
                }
            }
            else
            {
                var moduleUidToDelete = this.parseMultisocket(zone,i);
                if(moduleUidToDelete =='')
                {
                    var mod = zone.modules[i];
                    //Add room informations to module
                    mod.plantId = plantId;
                    mod.zoneId = zone.id;
                    mod.zoneName = zone.name;
                    this.initModuleStatusValue(mod);

                    var nm = ArrayService.find(this.notificationsModules,'id',mod.id);
                    mod.notification = {};
                    if(mod.device){
                      //Add manufacturer to module
                      var brand = _find(this.brands, {'name': mod.device.brand});
                      if(brand)
                      {
                        mod.device.brandObj = brand;
                      }
                      var model = _find(this.models, {'name': mod.device.model});
                      if(model)
                      {
                        //To set widget type at 'custom' 
                        if(Camera.CUSTOM_CAMERA_MODELS.indexOf(mod.device.model)!=-1)
                        {
                            mod.type = 'custom';
                        }
                        mod.device.modelObj = model;
                        mod.device.subdeviceModels = model.subdeviceModels;
                        mod.device.submodelObj = _find(model.submodels, {'name': mod.device.submodel});
                        mod.device.capabilities = DeviceResource.getCapabilities(mod.device);
                        /*if(mod.device.submodelObj.name=='t30')
                        {
                            mod.device.capabilities.push(
                            {
                                capability: 'wake_up'
                            })
                        }*/
                      }

                      if(nm!=undefined)
                      {
                        mod.notification = nm;
                      }
                      else
                      {
                        var emptyNotification = NotificationResource.getEmptyNotification(zone.modules[i]);
                        mod.notification = emptyNotification;
                        this.notificationsModules.push(emptyNotification);
                      }
                      this.sthiFix(mod.device);
                      this.models.forEach(function(m){
                        if(m.submodels){
                            var submodel = _find(m.submodels, function(e){ return e.name == mod.device.submodel;})
                            if(submodel != undefined)
                                mod.device.defaultWidgetModel = submodel.defaultWidgetModel;
                        }
                      });

                      /*OFFLINE*/
                      //offline state of device
                      //mod.offline = (mod.device.submodelObj.meshCompatible?true:false);
                      mod.offline = false;
                      if(devicesArr)
                      {
                        devicesArr.push(mod);
                      }
                      else
                      {
                        this.devices.push(mod);
                      }
                      
                      this.addSubDevices(mod);

                    }
                }
                else
                {
                    moduleIdTodeleteArr.push(moduleUidToDelete);
                }
            }
          }
          //Removing unnecessary devices from the display (MOES Eliot for example)
          if(moduleIdTodeleteArr.length>0)
          {
            zone.modules = _remove(zone.modules, function(module) {
                return (moduleIdTodeleteArr.indexOf(module.id) ==-1);
            });
          }
          
          /*for(var i=0; i< moduleIndexTodeleteArr.length; i++)
          {
            zone.modules.splice(moduleIndexTodeleteArr[i], 1);
          }*/
        },

        initServicesHeating : function(){
            for(var i=0; i<this.services.length; i++){
                if(this.services[i].service.model=='heating' && this.services[i].service.infos){
                    if(this.services[i].service.submodel=='central_heating')
                        var sthiId = this.services[i].service.infos.heaters[0].associatedSensors[0];
                    else{
                        if(this.services[i].service.infos.heaters)
                            var sthiId = this.services[i].service.infos.heaters[0].associatedSensors[0];
                        else
                            var sthiId = this.services[i].service.infos.sensors[0].moduleId;
                    }
                    this.services[i].sensor = _filter(this.allDevices, function(o) { return o.id == sthiId; });
                    if(this.services[i].service.submodel == 'electric_heating' && this.services[i].service.infos.heaters){
                        for(var j=0; j<this.services[i].service.infos.heaters.length; j++){
                            var sensor = {
                                'associatedHeaters' : [this.services[i].service.infos.heaters[j].moduleId],
                                'moduleId' : this.services[i].service.infos.heaters[j].associatedSensors[0]
                            }
                            if(this.services[i].service.infos.sensors)
                                this.services[i].service.infos.sensors.push(sensor);
                            else{
                                this.services[i].service.infos.sensors = [sensor];
                            }
                        }
                    }
                }
            }
        },

        parseMultisocket : function(zone,index){
            var module = zone.modules[index];
            if(
                module.device
                && module.device.model=='plug')
                {
                    if(module.device.subsockets)
                    {
                        if(module.device.subsockets.length > 1)
                        {
                            var uidMultisocket = module.id;
                            for(var k=0; k<module.device.subsockets.length; k++){
                                var socket = JSON.parse(JSON.stringify(module));
                                socket.device.subsockets = [socket.device.subsockets[k]];
                                socket.device.status = socket.device.subsockets[0].status;
                                socket.name = socket.device.subsockets[0].name;
                                socket.idMulti = socket.device.externalLocationId;
                                socket.idModule = socket.id;
                                socket.id = socket.device.subsockets[0].id;
                                socket.plot = k;
                                socket.icon = socket.device.subsockets[0].icon;
                                zone.modules.push(socket);
                            }
                            return uidMultisocket;
                        }
                        else if(module.plot==undefined)
                        {
                            module.idPlug = module.device.externalLocationId;
                            module.idModule = module.id;
                            module.plot = 0;
                            module.id = module.device.subsockets[0].id;
                            module.icon = module.device.subsockets[0].icon;
                            module.name = module.device.subsockets[0].name;
                            module.device.status = module.device.subsockets[0].status;
                        }
                    }
                    else
                    {
                        return module.id;
                    }
                }
                return '';
                //zone.modules.splice(index, 1);
        },

        /**
         * Function that adds the device class (like SmartCamera, etc)
         */

        addClassDevice: function()
		{
            var _this = this;
            this.smartCameras.splice(0);
            var devices = _filter(this.allDevices, function(d) {
                return d.device.model == 'smart_camera' || d.device.model == 'battery_vdp' || d.device.model == 'oskkio-1' || d.device.model == 't21';
            });

            devices.forEach(function(d) { 
                if(d.device.class==undefined)
                {
                    _this.smartCameras.push(new SmartCamera(_this.eventHub, d, _this.inApp));
                    //d.device.class  = new SmartCamera(_this.eventHub, d, _this.inApp); 
                }
                
            });
        },

        initModuleStatusValue: function(module)
		{
            module.statusvalue = false;
			if(module.device && module.device.status)
			{
				var statusState = ArrayService.find(module.device.status,'capability',['status','position_vertical','protection']);
				if(statusState)
				{
					module.statusvalue = statusState.value;
				}
				else
				{
                    module.statusvalue = (module.device.status.status!=undefined ? module.device.status.status : false);
				}
			}
        },

        initStatusForUartDevice: function(module)
		{ 
			if(module.device.submodelObj.meshCompatible)
			{
                var m = _find(this.cacheDevices, {id:module.id});
                if(m)
                {
                    if(m.device.status)
                    {
                        module.device.status = [];
                        m.device.status.forEach(function(status){
                            module.device.status.push(status);
                        });
                    }
                }
            }
        },

        addSubDevices : function(module)
		{
            var _this = this;
            switch(module.device.model)
            {
                case 'rb' :
                    module.device.relays = module.device.relays.map(function(s,index) {
                        
                        s.notificationName = module.name +' '+ _this.$gettextInterpolate(_this.$gettext('button %{s}'), {s: index+1 });
                        return s;
                    });
                    this.subDevices.push(
                    {
                        parent: module,
                        modules: module.device.relays
                    });
                    break;
                case 'trf' :    
                case 'cmr' :
                    module.device.switchs = module.device.switchs.map(function(s,index) {
                        s.notificationName = module.name +' '+ _this.$gettextInterpolate(_this.$gettext('button %{s}'), {s: index+1 });
                        return s;
                    });
                    this.subDevices.push(
                    {
                        parent: module,
                        modules: module.device.switchs
                    });
                    break;
                case 'tel' :
                    var modules = [];
                    if(module.device.channels)
                    {
                        for(var j=0; j<module.device.channels.length; j++)
                        {
                            var channel = module.device.channels[j];
                            //Add notification name for notification
                            channel.switchs = channel.switchs.map(function(s,index) {
                                s.notificationName = module.name +' '+ _this.$gettextInterpolate(_this.$gettext('button %{s}'), {s: index+1 });
                                return s;
                            });
                            modules = modules.concat(channel.switchs);
                        }
                    }
                    if(module.device.switchs)
                    {
                        module.device.switchs = module.device.switchs.map(function(s,index) {
                            s.notificationName = module.name +' '+ _this.$gettextInterpolate(_this.$gettext('button %{s}'), {s: index+1 });
                            return s;
                        });
                        modules = modules.concat(module.device.switchs);
                    }
                    this.subDevices.push(
                    {
                        parent: module,
                        modules: modules
                    });
                    break;
            }
		},
        setDevices : function(response, devicesArr){
          var _this = this;
          if(response.zones){
            for(var i = 0; i<response.zones.length; i++)
            {
                //Vue.set(response.zones[i],'groupTest','titi');
                //Vue.set(response.zones[i],'groups',[{name:'titi'}]);
                if(response.zones[i].modules){
                    //response.zones[i].modules = _orderBy(response.zones[i].modules, function(m) { return m.name.toLowerCase()}, ['asc']);
                    this.getModules(response.id,response.zones[i],devicesArr);
                    Vue.set(response.zones[i],'groups',GroupResource.getAllWidgetGroupObject(
                        response.zones[i],
                        _this.$gettext(response.zones[i].name),
                        ['energy','light'],
                        _this.CONSTANTS.GROUP_WIDGET_TITLE
                        )
                        );
                }
            }
          }
        },

        getScenarios: function(plant){
            this.scenarios = [];
            var zones = plant.zones;
            LogService.consoleLogTag(this.CONSTANTS.TAG,'getScenarios','zones',zones);
            for(var i=0; i<zones.length; i++){
                var obj = _filter(zones[i].modules, function(o) { return o.scenario; });
                this.scenarios = _concat(this.scenarios, obj);
            }
            LogService.consoleLogTag(this.CONSTANTS.TAG,'getScenarios','this.scenarios',this.scenarios);
            this.eventHub.$emit('getScenariosFinished');
        },
        
        getSystems: function(response){
            var _this = this;
            if(response.data)
            {


                var systems = response.data.systems.map(function(s) {
                    s.displayName = _this.$gettext(s.name);
                    return s;
                });
                StorageResource.storeElt('systems',systems);
                this.systems =  systems;
                this.$store.state.systems = systems;
            }
        },

        getIcons: function(section){
            var _this = this;
            LocationResource.getIcons().then(
                function(response) {
                    _this.icons = response.data.icons;
                }).catch(
                function() {});
        },

        getManufacturersAndExternalAccounts: function(){

           var _this = this;
           var allPromise = [];
           allPromise.push(SystemResource.getManufacturers());
           allPromise.push(ExternalResource.getAllAccount());
           StorageResource.setStoreElt(this,'manufacturers');
           StorageResource.setStoreElt(this,'externalAccounts');
           StorageResource.setStoreElt(this,'brands');
           if(StorageService.getValue(localStorage, 'hcUrl'))
           {
                this.hcUrl = StorageService.getValue(localStorage, 'hcUrl');
           }
           Promise.all(allPromise).then(
            function(response){
                _this.setManufacturersAndExternalAccounts(response);
            }).catch(
            function(e) {
              console.log(e);
            });
        },

        getBrandGroups: function(response){
            var _this = this;
            var brandGroups = response.data.brandGroups;
            if(!this.$store.state.variantManager.isComfortlife()){
                brandGroups = _filter(brandGroups, function(b) { 
                    return ([_this.$store.state.variantManager.getBrandGroupName(),'amazon_alexa','google_home'].indexOf(b.name)!=-1);
                });
            }
            this.brandGroups = _orderBy(brandGroups, 'displayName').map(function(bg) {
                if(bg.brands.length==1)
                {
                    var b = _find(_this.brands, {name:bg.name});
                    _merge(bg, b);
                }
                else{
                    bg.brands = bg.brands.map(function(bgb) {
                        var b = _find(_this.brands, {name:bgb.name}); 
                        _merge(bgb, b);
                        return bgb;
                    });
                }
                return bg;
            });
            
            StorageResource.storeElt('brandGroups',this.brandGroups);
        },

        setManufacturersAndExternalAccounts: function(response){
            var manufacturersResponse = response[0];
            var externalAccountResponse = response[1];
            this.manufacturers = [];
            this.externalAccounts = {
                externalAccounts: [],
                sharedExternalAccounts: []
            };
            this.setManufacturers(manufacturersResponse.data.manufacturers);
            if(externalAccountResponse.status==200)
                this.setAllExternalAccount(externalAccountResponse.data);
            StorageResource.storeElt('manufacturers',this.manufacturers);
            StorageResource.storeElt('brands',this.brands);
            StorageResource.storeElt('externalAccounts',this.externalAccounts);
         },    

        setManufacturers: function(manufacturers){
            this.manufacturers = manufacturers;
            this.brands = SystemResource.getAllManufacturersBrands(this.manufacturers);
        },

        setSharesLocations: function(response){
            var _this = this;
            var sharedLocations = (response.data.sharedLocations && response.data.sharedLocations.length > 0 ? response.data.sharedLocations: []);
            //Vue.set(_this.$store.state, 'sharesLocations', sharedLocations);
            if(sharedLocations.length>0)
            {
                sharedLocations.forEach(function(sl) {
                    if(sl.plants[0])
                    {
                        _this.sharesLocations.push(
                            {
                                uid: (sl.plants?sl.plants[0].id: _this.uuidV4()),
                                user: sl.user,
                                plants: sl.plants
                            }
                        );
                        /*if(Array.isArray(sl.plants))
                        {
                            sl.plants.forEach(function(p) {
                                _this.addSharesNotificationsModules(p,_this.$store.state.cancelTokenSource);
                            });
                        }*/
                    }
                });
            }
        }, 

        addOwnShares: function(shares){
            for(var i = 0; i< shares.length; i++)
            {
                this.initOwnShare(shares[i]);  
                this.ownShares.push(shares[i]);
            }
            var s = (sessionStorage.getItem('authorization')?sessionStorage:localStorage);
            StorageService.persist(s, 'storage - ownShares', stringify(this.ownShares));
        },

        setOwnShares: function(shares){
            var _this = this;
            // Add shares attribute to list all location ids items 
            if(shares && shares.length > 0)
            {
                shares.forEach(function(s) {
                    _this.initOwnShare(s);
                });

                // Order by receiver email
                //shares = _orderBy(shares, function(s) { return s.receiver[0].email.toLowerCase()}, ['asc']);
                this.ownShares = shares;
            }
        },

        initOwnShare: function(share){
            var _this = this;
            //To copy link tooltip
            share.showCopied = false;
            if(!share.receiver)
            {
                share.receiver = {email:''};
            }

            if(share.shares)
            {
                share.shares.splice(0);
                
            }
            else
            {
                share.shares = [];
            }
            
            if(share.locationIds)
            {
                share.locationIds.forEach(function(li) {
                    var z = _find(_this.plant.zones, { 'id': li});
                    var sharedZone = _find(share.shares, { 'id': li});
                    if(z && !sharedZone)
                    {
                        share.shares.push(
                            {
                                id: z.id,
                                name: z.name,
                                modules: z.modules
                            }
                        );
                    }
                    else
                    {
                        var zm = LocationResources.findModuleInPlant(_this.plant,li);
                        if(zm)
                        {
                            var sharedZone = _find(share.shares, { 'id': zm.zone.id});
                            if(!sharedZone)
                            {
                                var z = {
                                    id: zm.zone.id,
                                    name: zm.zone.name,
                                    modules: []
                                };
                                z.modules.push(zm.module);
                                share.shares.push(z); 

                            }
                            else
                            {
                                var sharedZoneModule = _find(sharedZone.modules, { 'id': zm.module.id});
                                if(!sharedZoneModule)
                                {
                                    sharedZone.modules.push(zm.module);
                                }
                            }
                        }
                    }
                });
            }
        },



        getDashboard: function(response){
          var _this = this;
          var dashboardResponse = response;
          dashboardResponse.data.views.forEach(function(view) {
            _this.initCells(view);
          });
          _this.dashboard = dashboardResponse.data;
          _this.dashboard.selectedView = this.dashboard.views[0];
          if(_this.dashboard.views.length>0)
            _this.dashboard.views[0];
        },
        initCells : function(view){
          var _this = this;
          var nbLine = 0;
          var template = view.template;
          var viewWidth = template.width;
          var viewHeight = template.height;
          var viewWidthPercent = (100/viewWidth);
          var viewheightPercent = (100/viewHeight);
          var cells = template.cells;
          var widgets = view.widgets;
          var arrLine = [];
          for(var i = 0; i< viewHeight; i++)
          {
            arrLine.push([]);
          } 
          cells.forEach(function(cell,index) {
            var left = arrLine[nbLine].length * viewWidthPercent+'%';
            var top = nbLine * viewheightPercent+'%';
            for(var i = 0; i< cell.height; i++)
            {
              for(var j = 0; j< cell.width; j++)
              {
                arrLine[nbLine+i].push(index);
              }
              if(arrLine[nbLine].length==viewWidth)
                nbLine++;
            }
            cell.position = {left : left, top: top };
            var widget = null;
            if(widgets)
              widget = ArrayService.find(widgets,'index',index);
            if(widget)
            {
              cell.widget = widget;
              if(widget.data)
              {
                if(widget.data.device){
                  var device = _find(_this.allDevices, {id:widget.data.device.id});
                  if(device)
                  {
                    _merge(widget.data, device);
                  }
                  _this.sthiFix(widget.data.device);

                  for(var i = 0; i < _this.plant.zones.length; i++){
                    if(_this.plant.zones[i].modules){
                      for(var j = 0; j< _this.plant.zones[i].modules.length; j++){
                        if(_this.plant.zones[i].modules[j].id == widget.data.id)
                          widget.data.zone = _this.plant.zones[i].name;
                      }
                    }
                  }
                }
                else if(widget.data.service){
                    for(var i = 0; i < _this.plant.zones.length; i++){
                        if(_this.plant.zones[i].modules){
                          for(var j = 0; j< _this.plant.zones[i].modules.length; j++){
                            if(_this.plant.zones[i].modules[j].id == widget.data.id)
                              widget.data.zone = _this.plant.zones[i].name;
                          }
                        }
                    }
                    if(widget.data.service.model=='heating' && widget.data.service.infos){
                        if(widget.data.service.submodel=='central_heating')
                            var sthiId = widget.data.service.infos.heaters[0].associatedSensors[0];
                        else{
                            if(widget.data.service.infos.heaters)
                                var sthiId = widget.data.service.infos.heaters[0].associatedSensors[0];
                            else
                                var sthiId = widget.data.service.infos.sensors[0].moduleId;
                        }
                        widget.data.sensor = [_find(_this.allDevices, function(o) { return o.id == sthiId; })];
                    }
                }
                else
                  widget.data.zone = ' ';
                var notificationsModule = ArrayService.find(_this.notificationsModules,'id',widget.data.id);
                widget.data.notification = (notificationsModule==undefined ? {notifications:[]} : notificationsModule);
              }
            }
          });
        },
        getModels: function(response){
            var _this = this;
            response.data.models = response.data.models.map(function(m) {
              m.text = m.label + ' (' + _this.$gettext(m.description) +')';
              return m;
            });
            this.models = response.data.models;
            this.getCapabilities();
        },

        getRegions: function(response){
            this.regions = response.data.regions;
            LogService.consoleLogTag(this.CONSTANTS.TAG,'getRegions','regions',this.regions);
        },

        setAppObj: function(response){
            const app = (response?.data?.apps?.length>0?response.data.apps[0]:undefined);
            this.$store.dispatch('setAppObj',app);
            StorageResource.storeElt('appObj',app);
            LogService.consoleLogTag(this.CONSTANTS.TAG,'setAppObj','appObj',app);
        },

        setStoreRegions: function(){
            LogService.consoleLogTag(this.CONSTANTS.TAG,'setStoreRegions','this.$store',this.$store);
            StorageResource.setStoreElt(this,'regions');
            this.$store.dispatch('setRegions',this.regions);
            this.$store.dispatch('setSelectedRegion',LanguageService.getSelectedRegion());
            LogService.consoleLogTag(this.CONSTANTS.TAG,'setStoreRegions',' this.$store.state.regions', this.$store.state.regions);
            LogService.consoleLogTag(this.CONSTANTS.TAG,'setStoreRegions',' this.$store.state.selectedRegion', this.$store.state.selectedRegion);
        },

        getCapabilities: function(){
            var _this = this;
            this.capabilities = [];
            this.models.forEach(function(elt) {
                var model = elt.name;
                if(elt.subdeviceModels){
                    elt = elt.subdeviceModels[0];
                }
                if(elt.capabilities){
                    for(var i=0; i<elt.capabilities.length; i++){
                        if(_this.capabilities.hasOwnProperty(elt.capabilities[i].capability)){
                            _this.capabilities[elt.capabilities[i].capability].models.push(model);
                        }
                        else{
                            _this.capabilities[elt.capabilities[i].capability] = {"models":[model],"brands":[]};
                        }
                        if(elt.submodels){
                            for(var j=0; j<elt.submodels.length; j++){
                                var brand = elt.submodels[j].brand;
                                if(_findIndex(_this.brands, function(b){return b.name == brand && (b.wizard.type == 'INTERNAL' || b.name == 'home_confort');})!=-1){
                                    if(_this.capabilities[elt.capabilities[i].capability].brands.indexOf(brand)==-1)
                                        _this.capabilities[elt.capabilities[i].capability].brands.push(brand);
                                }
                            }
                        }
                    }      
                }
            });
            if(this.capabilities.status)
            {
                this.capabilities.status.models.push('tel');
            }
        },

        setAllExternalAccount : function(externalAccounts){
            if(externalAccounts.externalAccounts || externalAccounts.sharedExternalAccounts)
                this.externalAccounts = externalAccounts;
            this.getDisplayExternal();
        },

        getDisplayExternal : function(){
            if(!this.externalAccounts.externalAccounts)return false;
            var _this = this;
            var hcAccount = _find(this.externalAccounts.externalAccounts, {'external': {'name':'home_confort'}});
            var s = (this.eventHub.user.rememberMe?localStorage:sessionStorage);
            if(hcAccount)
            {
                hcAccount.external.url = 'about:blank';
                hcAccount.external.manufacturer = _find(this.brands, {'name':'home_confort'}).manufacturer;
                ExternalResource.getExternalDisplay(hcAccount.external.name).then(
                    function(response) {
                        hcAccount.external.url = response.data.url;
                        _this.hcUrl = response.data.url;
                        StorageService.persist(s, 'hcUrl', response.data.url);
                    }).catch(
                    function() {
                    });
            }
        },
        getDefaultRooms : function()
        {
          // If the variants haven't default rooms, use WS
          if(this.$store.state.variantManager.class.rooms)
          {
            this.defaultRooms = this.$store.state.variantManager.class.rooms;
          }
          else
          {
            var _this = this;
            StorageResource.setStoreElt(this,'defaultRooms');
            LocationResource.getDefaultRooms().then(
              function(response){
                _this.defaultRooms = response.data.rooms;
                StorageResource.storeElt('defaultRooms',_this.defaultRooms);
              }
            ).catch(
            function(e) {
              console.log(e);
            });
          }
        },

        getServiceModels : function()
        {
          var _this = this;
          StorageResource.setStoreElt(this,'serviceModels');
          ServiceResource.getServiceModels().then(
            function(response){
              _this.serviceModels = response.data.models;
              StorageResource.storeElt('serviceModels',_this.serviceModels);
            }
          ).catch(
          function(e) {
            console.log(e);
          });
        },

        getTemplates : function()
        {
          var _this = this;
          StorageResource.setStoreElt(this,'templates');
          DashboardService.getTemplates().then(
            function(response){
              _this.templates = _orderBy(response.data.templates, 'displayName');
              StorageResource.storeElt('templates',_this.templates);
            }
          ).catch(
          function(e) {
            console.log(e);
          });
        },

        getWidgetModels : function()
        {
          var _this = this;
          StorageResource.setStoreElt(this,'widgetModels');
          DashboardService.getWidgetModels().then(
            function(response){
              _this.widgetModels = response.data.widgetModels;
              StorageResource.storeElt('widgetModels',response.data.widgetModels);
            }
          ).catch(
          function(e) {
            console.log(e);
          });
        },
        getResourcesAccounts : function()
        {
            var _this = this;   
            var s = (this.eventHub.user.rememberMe?localStorage:sessionStorage);
            UserResource.getResourcesAccounts().then(
            function(response) {
                var obj = {"sms":0,"email":0,"push":0};
                for(var i=0; i<response.data.resourceAccounts.length; i++){
                    obj[response.data.resourceAccounts[i].type.toLowerCase()] = response.data.resourceAccounts[i].availableCount;
                }
                _this.eventHub.user.setResourcesCount(obj);
                StorageService.persist(s, 'resourcesCount', JSON.stringify(obj));
            }).catch(
            function() {
                _this.eventHub.user.setResourcesCount({"sms":0,"email":0,"push":0});
                StorageService.persist(s, 'resourcesCount', JSON.stringify({"sms":0,"email":0,"push":0}));
            });
        },

        login: function (userLogin,selectedIndexRegion) { 
            if(selectedIndexRegion=="-1")
            {
                this.eventHub.$emit('displayAlert', this.CONSTANTS.SELECT_REGION_ERROR);
                return false;
            }
           
            if((userLogin.username != '' && userLogin.password != '')||(this.eventHub.to.name=='invite')){
                var _this = this;
                const regions = LanguageService.CONSTANTS.REGIONS;
                UserResource.login(userLogin,regions[selectedIndexRegion]).then(
                    function(response) {
                        _this.eventHub.$emit('loginFinished');
                        GlobalResource.setBackendUrl(regions[selectedIndexRegion]);
                        _this.auth = response.data.authorization;
                        _this.eventHub.user.authorization = response.data.authorization;
                        _this.setUser();
                        // MQTT + clientId
                        SystemService.initSystemInfos();
                        StorageService.persist((sessionStorage.getItem('authorization')?sessionStorage:localStorage), 'mqttInfos', JSON.stringify(SystemService.setMqttInfos()));
                        _this.eventHub.$emit('mqttConnect');
                        _this.eventHub.$emit('loadingPageShow',true);
                        _this.displayTutorial();
                        _this.editShare();
                        _this.launchGlobalWs();
                        _this.initConfigMenu();
                        if(_this.eventHub.to.name!='invite')
                        {
                            _this.$router.push('/home/rooms');
                        }
                        else
                        {
                            ShareResource.getSharesLocations().then(
                                function(response) {
                                    _this.getAllTopology('all',undefined, undefined, undefined, true);
                                    
                                }).catch(
                                function(error) {
                                    _this.eventHub.splashScr.displayNotValidShare();
                                });
                        }
                    }).catch(
                    function(error) {
                        LogService.consoleErrorTag(_this.CONSTANTS.TAG,'login','error',error);
                        if(_this.eventHub.to.name!='invite')
                        {
                            if(error.response && error.response.status==404){
                                _this.eventHub.$emit('displayAlert', _this.CONSTANTS.LOGIN_ERROR_MAIL_OR_PASSWORD);
                            }
                            else{
                                _this.eventHub.$emit('displayAlert', _this.CONSTANTS.LOGIN_ERROR);
                            }
                        }
                        else
                        {
                            _this.eventHub.splashScr.displayNotValidShare();
                        }
                        _this.eventHub.$emit('loginFinished');
                        StorageService.removeItem(localStorage, 'region-ws');
                    });
            }
            else{
                this.eventHub.$emit('displayAlert', this.CONSTANTS.EMPTY_FIELD);
            }
        },

        logout: function (user, autoconnect) { 
            try 
            {
                this.eventHub.$emit('resetOnClickButtons');
                
                if(this.$store.state.cancelTokenSource)
                {
                    this.$store.state.cancelTokenSource.cancel('Operation canceled.');
                }
                var authorization = StorageService.getValue(localStorage, 'authorization');
                CordovaService.pushUnregister(this, authorization, SystemService.getSystemInfos());       
                this.pushRegister.unsubscribePush(this);
                this.eventHub.startPushRegister = false;
                this.pushRegister.clear();

                this.startSubscribePushPlant = false;

                StorageService.clear(sessionStorage);

                var hideTutorials = [];
                var uciByun = {};
                if(StorageService.keyExists(localStorage,'uciByun'))
                {
                    uciByun = StorageService.getValue(localStorage, 'uciByun');
                }

                var localStoragekeys = StorageService.getKeys(localStorage);
                for(var i=0; i<localStoragekeys.length; i++)
                {
                    if(localStoragekeys[i].indexOf('__hideTutorial')!=-1 && localStoragekeys[i]!='uciByun')
                    {
                        hideTutorials.push(localStoragekeys[i]);
                    }

                }

                StorageService.clear(localStorage);

                localStorage.setItem('uciByun',uciByun);
                for(var i=0; i<hideTutorials.length; i++)
                {
                    localStorage.setItem(hideTutorials[i],true);
                }
                
                this.$store.commit('resetState');

                this.rightPanel.hide = true;

                CordovaService.stopUdp(this.eventHub);
                this.startUdp = false;

                this.initRightPanel();
                this.eventHub.user.clear();
                this.eventHub.$emit('mqttDisconnect');
                Vue.prototype.$mqtt = undefined;

                CordovaService.logoutEZ();

                this.resetData();
                this.deleteInfos();
                localStorage.setItem('app', window.VARIANT);
                return false;
            }
            catch (e) {
                this.eventHub.$emit('displayAlert', e.message);
                console.error(e);
            }
            finally
            {
                if(user)
                {
                    /*if(autoconnect)
                    {
                        var selectedIndexRegion = LanguageService.getIndexFromUrl();
                        this.login(user,selectedIndexRegion);
                    }
                    else
                    {*/
                        this.$router.push({ name: 'login', params: {user: user}});
                    //}
                    
                }
                else
                {
                    this.$router.push({ name: 'login'});
                }
            }
        },

        deleteInfos: function(){
          this.devices = [];
          this.subDevices = [];
          this.allDevices = [];
          this.plant = {zones:[]};
          this.users = undefined;
          this.scenarios = [];
          this.dashboard = [];
          this.externalAccounts = {                
            externalAccounts: [],
            sharedExternalAccounts: []
          };
          this.defaultRooms = [];
          this.templates = [];
          this.widgetModels = [];
          this.homepilots = [];
          this.notificationsModules = [];
          this.eventHub.commandIdList = [];
          this.sharesLocations= [];
          this.ownShares= [];
          this.smartCameras= [];
        },

        /**
         * Function to change a property of a device
         * TO REMOVE WHEN THERE IS A CONNECTION BETWEEN THE OBJECTS OF DASHBOARD AND ROOMS!
         */
        setDevice : function(widget,property,propertyValue){
            if(this.dashboard.views){
                for(var i=0; i<this.dashboard.views.length; i++){
                    for(var j=0; j<this.dashboard.views[i].template.cells.length; j++){
                        if(this.dashboard.views[i].template.cells[j].widget &&
                            this.dashboard.views[i].template.cells[j].widget.data &&
                            this.dashboard.views[i].template.cells[j].widget.data.device &&
                            this.dashboard.views[i].template.cells[j].widget.data.id == widget.id)
                        {
                            this.dashboard.views[i].template.cells[j].widget.data.device[property] = propertyValue;
                        }

                    }
                }
            }
            if(this.plant.zones){
                for(var i=0; i<this.plant.zones.length; i++){
                    if(this.plant.zones[i].modules){
                        for(var j=0; j<this.plant.zones[i].modules.length; j++){
                            if(this.plant.zones[i].modules[j].device && this.plant.zones[i].modules[j].id == widget.id )
                            {
                                this.plant.zones[i].modules[j].device[property] = propertyValue;
                            }
                        }
                    }
                }
            }
        },

        /**
         * Function to change the status of a device / service
         * TO REMOVE WHEN THERE IS A CONNECTION BETWEEN THE OBJECTS OF DASHBOARD AND ROOMS!
         */
        setDeviceStatus : function(widget, status, moduleType, displayNotification, capability, executeOffline){
          var statusIndex = 0;
          var found = false;
          if(this.dashboard.views){
            for(var i=0; i<this.dashboard.views.length; i++){
              for(var j=0; j<this.dashboard.views[i].template.cells.length; j++){
                if(this.dashboard.views[i].template.cells[j].widget &&
                    this.dashboard.views[i].template.cells[j].widget.data &&
                    this.dashboard.views[i].template.cells[j].widget.data[moduleType] &&
                    this.dashboard.views[i].template.cells[j].widget.data[moduleType].status &&
                    this.dashboard.views[i].template.cells[j].widget.data[moduleType].status.length > 0 &&
                    (this.dashboard.views[i].template.cells[j].widget.data.id == widget.id ||
                     this.dashboard.views[i].template.cells[j].widget.data[moduleType].remoteId == widget.id
                    )
                    )
                {
                  if(executeOffline)
                  {
                    this.dashboard.views[i].template.cells[j].widget.offline = false;
                  }
                  if(this.dashboard.views[i].template.cells[j].widget.data[moduleType].model=="sthi")  
                  {
                      break;
                  }
                  else{
                    var statusIndex = 0;
                    if(capability)
                    {
                        statusIndex = _findIndex(this.dashboard.views[i].template.cells[j].widget.data[moduleType].status, {'capability':capability});
                        /*if(capability=='color_temperature')
                        {
                            var s = _find(this.dashboard.views[i].template.cells[j].widget.data[moduleType].status, {'capability':'status'});
                            if(s)
                            {
                                s.value = 'on';
                            }
                        }*/
                    }
                    if(statusIndex!=-1)
                    {
                        this.dashboard.views[i].template.cells[j].widget.data[moduleType].status[statusIndex].value  = status;
                    }
                  }
                }
              }
            }
          }

          /*if(this.$route.params.plant)
          {
            for(var i=0; i<this.$route.params.plant.zones.length; i++){
                if(this.setModuleDeviceStatus(widget, this.$route.params.plant.zones, moduleType, status, capability, executeOffline))
                {
                    break;
                }
            }
          }*/

          /*this.sharesLocations.forEach(function(sl,index){
            if(sl.plants && sl.plants.length > 0)
            {
                for(var i=0; i<sl.plants[0].zones.length; i++){
                    if(_this.setModuleDeviceStatus(widget, sl.plants[0].zones, moduleType, status, capability, executeOffline))
                    {
                        break;
                    }
                }
            }
          });*/
        
          if(this.plant.zones){
            for(var i=0; i<this.plant.zones.length; i++){
                if(this.setModuleDeviceStatus(widget, this.plant.zones, moduleType, status, capability, executeOffline))
                {
                    break;
                }
            }
          }
          this.setCacheDevices();
          //this.$store.commit('setDevices');
          //if(displayNotification && notification != '')
            //this.eventHub.$emit('displayAlert', notification, 'success');
        },

        
        setModuleDeviceStatus : function(widget, zones, moduleType, status, capability, executeOffline){
            for(var i=0; i<zones.length; i++){
                if(zones[i].groups){
                  var g = _find(zones[i].groups, {'id':widget.id});
                  if(g)
                  {
                      var s = _find(g.device.status, {'capability':capability});
                      if(s)
                      {
                          //g.statusSwitch = status;
                          s.value = status;
                          //this.eventHub.$emit('refreshModule_'+g.id,g);
                      }
                      return true;
                  }
                }  
                if(zones[i].modules)
                {
                    for(var j=0; j<zones[i].modules.length; j++){
                        if(zones[i].modules[j][moduleType])
                        {
                            if(zones[i].modules[j].id == widget.id || zones[i].modules[j][moduleType].remoteId == widget.id )
                            {
                                if(executeOffline)
                                {
                                    zones[i].modules[j].offline = false;
                                }
                                if(zones[i].modules[j][moduleType].status && zones[i].modules[j][moduleType].status.length)
                                {
                                    if(zones[i].modules[j][moduleType].model=="sthi")  
                                    {
                                        return false;
                                    }  
                                    var statusIndex = 0;
                                    if(capability)
                                    {
                                        statusIndex = _findIndex(zones[i].modules[j][moduleType].status, {'capability':capability});
                                    }
                                    if(zones[i].modules[j][moduleType].model=="plug" && zones[i].modules[j][moduleType].subsockets){
                                        zones[i].modules[j][moduleType].subsockets[0].status[statusIndex].value = status;
                                        zones[i].modules[j][moduleType].status[statusIndex].value = status;
                                        return false;
                                    }
                                    else{  
                                        if(statusIndex!=-1)
                                        {
                                            zones[i].modules[j][moduleType].status[statusIndex].value = status;
                                        }
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }   
            }
            return false;
        },


        //Refresh module information after mqtt or udp infos event
        refreshInfoFromEvent : function(module){
            var _this = this;
            this.dashboard.views.forEach(function(view) {
                //var d = _find(view.widgets, {'data':  {'id':module.id}});
                var ds = _filter(view.widgets, {'data':  {'id':module.id}});
                //var d2 = _find(view.template.cells, {widget : { data : {'id':module.id}}});
                var d2s = _filter(view.template.cells, {widget : { data : {'id':module.id}}});
                ds.forEach(function(d) {
                    module.device.status.forEach(function(moduleStatus){
                        _this.changeStatusModule(d.data.device.status, moduleStatus);
                    });
                });
                d2s.forEach(function(d2) {
                    module.device.status.forEach(function(moduleStatus){
                        _this.changeStatusModule(d2.widget.data.device.status, moduleStatus);
                    });
                });
            });
            this.plant.zones.forEach(function(zone) {
                var d = _find(zone.modules, {'id':module.id});
                if(d)
                {
                    module.device.status.forEach(function(moduleStatus) {
                        _this.changeStatusModule(d.device.status, moduleStatus);
                    });
                }
            });
            let d = _find(this.allDevices, function(o) { return o.id == module.id; })
            if(d)
            {
                module.device.status.forEach(function(moduleStatus) {
                    _this.changeStatusModule(d.device.status, moduleStatus);
                });
            }
        },

        changeStatusModule : function(deviceStatus, moduleStatus){
            var s = _find(deviceStatus, {'capability': moduleStatus.capability});
            if(s)
            {
                s.value = moduleStatus.value;
            }
            else
            {
                deviceStatus.push(moduleStatus);
            }
        },

        /**
         * 
         * @param {*} module 
         * @param {*} autoplay 
         */

        showCamera : function(module, autoplay, noWakeUp, noDuration){
            if(module.device.model=='camera')
            {
                //CordovaService.stopEZFullscreen();
                var ea = ExternalResource.getExternalAccountsByModule(module.id, this.sharesLocations, this.externalAccounts);
                CordovaService.launchFullscreen(this.eventHub, ea, module,(this.$route.name == 'view'?'landscape':'portrait'),this.uuidV4(),(noDuration?undefined:this.settings.fullscreenCameraDisplayDuration));
            }
            else if(module.device.submodel=='vdp_100')
            {
                LinkwilPluginService.startLinkwillPlugin(module,this.eventHub);
            }
            else
            {
                this.eventHub.$emit('show_'+module.id, autoplay, noWakeUp);
            }
        },
        
        displayMainControlDevice: function(module, status){
            this.displayModalMainControlDevice = true;
            this.mainControlDeviceModule.module = module;
            this.mainControlDeviceModule.status = status;
            clearTimeout(this.modalMainControlDeviceCloseTimeout);
            var _this = this;
            this.modalMainControlDeviceCloseTimeout = setTimeout(function() {
                _this.closeModalMainControlDevice(true);
            }, this.CONSTANTS.MODALMAINCONTROLDEVICE_CLOSE_TIMEOUT);
        },

        closeModalMainControlDevice: function (decline) {
            clearTimeout(this.modalMainControlDeviceCloseTimeout);
            this.displayModalMainControlDevice = false;
            if(this.mainControlDeviceModule.module.device)
            {
               if(this.mainControlDeviceModule.module.device.model == 'vdp' && decline)
              {
                CommonResource.setStatus(this.mainControlDeviceModule.module, {"capability": "call","value": "hang_up"}, undefined, this.eventHub,true);
                //LinkwilPluginService.stopLinkwil();
              }
            }
            this.mainControlDeviceModule = {
                module : 
                {
                    device:{}
                },
                status : {}
            };
          },

        closeModalDisplayModalTutorial: function () {
            this.displayModalTutorial = false;
            localStorage.setItem(this.eventHub.user.username+'__hideTutorial',true);
        },

        //----Fix pending the BE integration of the temperature probe
        sthiFix : function(device){
            
            /*if(device.model == 'sthi')   
            {
                if(_find(device.status, {'capability':'desiredTemperature'})==undefined)
                {
                    device.status.push({'capability': 'desiredTemperature', 'value': ''});
                }
                if(_find(device.status, {'capability':'position'})==undefined)
                {
                    device.status.push({'capability': 'position', 'value': ''});
                }
            }*/
        },

        //------------------------

         stopEZFullscreen : function(){
            CordovaService.stopEZFullscreen(this);
        },

        /*--------SHARE--------*/

        closeShareAddModal : function(){
            this.shareAddModal = false;
        },

        editShare: function(){
            var _this = this;
            if(this.eventHub.shareInvite.type=='private')
            {
                ShareResource.editShare(this.eventHub.shareInvite.shareId,{"status": "ACCEPTED"}).then(
                    function(response){
                        _this.eventHub.$emit('displayAlert', _this.CONSTANTS.SHARE_SUCCESS, 'success');
                    }).catch(
                    function(e) {
                        _this.eventHub.$emit('displayAlert', _this.CONSTANTS.SHARE_ERROR);
                    });
                this.eventHub.shareInvite =
                {
                    shareId: '',
                    email: '',
                    authorization:''
                };
            }
        },

        /*addSharesNotificationsModules: function(plant){
            var _this = this;
            ShareResource.getSharesNotifications(plant.id).then(
                function(response){
                    if(response.data && Array.isArray(response.data.modules))
                    {
                        _this.notificationsModules = ArrayService.merge(_this.notificationsModules,response.data.modules,'id');
                    }
                    LogService.consoleLogTag(_this.CONSTANTS.TAG,'addSharesNotificationsModules','response',response);
                    LogService.consoleLogTag(_this.CONSTANTS.TAG,'addSharesNotificationsModules','_this.notificationsModules',_this.notificationsModules);
                }).catch(
                function(e) {
                    LogService.consoleErrorTag(_this.CONSTANTS.TAG,'addSharesNotificationsModules','error',e);
            });
        },*/

        launchPublicShare : function()
        {
            LogService.consoleLogTag(this.CONSTANTS.TAG,'launchPublicShare');
            var user = new User();
            user.authorization = this.eventHub.to.query.authorization;
            LogService.consoleLogTag(this.CONSTANTS.TAG,'launchPublicShare','user',user);
            var selectedIndexRegion = LanguageService.getIndexFromUrl();
            this.login(user,selectedIndexRegion);
            //this.eventHub.$emit('logout',user, true);

        },
            
        loadPublicShare : function()
        {
            this.$store.state.selectedPlantId = this.sharesLocations[0].plants[0].id;
            this.$store.state.plantUser = this.sharesLocations[0].user;

            if(this.allDevices.length==1)
            {
                this.showDisplayMainControlDeviceModal = true;
                this.$nextTick(function() {
                    this.eventHub.$emit('showMainControlDevice',this.allDevices[0],this.devices,this.models);
                });
            }
            else
            {
                this.show = true;
            }
        },

        marketStyle: function(content){
            if(content == 'main-panel' && !this.$store.state.variantManager.displayMarket()){
                return 'display: none;'
            } 
            else if(content == 'app-router' && !this.$store.state.variantManager.displayMarket()){
                return 'width: 100%;'
            }
            else if(window.innerWidth < window.innerHeight)
                if(content == 'app-router')
                    return 'width: 100%;'
                else
                    return 'display: none;'
            else
                return ''
        },

        // CACHE
        setCacheDevices() {
        var s = (sessionStorage.getItem('authorization')?sessionStorage:localStorage);
        //StorageService.persist(s, 'storage - devices', stringify(this.devices));
        //StorageService.persist(s, 'storage - plant', stringify(this.plant));
        },


        // MQTT/UDP CALLBACK MANAGEMENT

        callbackManagement(payload,callbackEvent) {
        PayloadManagerService.rebuildEliotPayloadElt(payload,this.allDevices,this.eventHub);
        var _this = this;
        if(payload==undefined || payload.event=='scenario')return false;
        //Check if command uid already exists
        if(payload.uid==undefined || this.eventHub.checkCommandIdListExists(payload.uid)!=-1) return false;
        this.eventHub.commandIdList.push(payload.uid);
        try{
            if(PayloadManagerService.runServiceLinkedToPayload(this.eventHub,payload,this.allDevices,this.services,callbackEvent,inAppCheck()))return false;
            if(payload.event=='discover')
            {  
                this.eventHub.$emit('discoverEvent',payload.modules);  
            }
            else if(payload.action=='sync')
            {  
                this.eventHub.$emit('refreshDatas');  
            }
            else if(payload.modules && Array.isArray(payload.modules))
            {
                payload.modules.forEach(function(module) {
                    //INSTALL EVENT
                    if(payload.event=='install')
                    {  
                        _this.eventHub.$emit('device_event_install_'+module.device.identifier.toLowerCase(), module);
                    }
                    else{

                        var moduleType = (module.device?'device':(module.service?'service':''));
                        var statusArray = [];
                        
                        //If associationToken, propagate a device hello event
                        if(module[moduleType].identifier)
                        {
                            _this.eventHub.$emit('device_event_infos_'+module[moduleType].identifier.toLowerCase(),module);
                        }

                        //module[moduleType].uartFrame = 'FF0C02000101010C0020646400000008'
                        
                        if(module[moduleType].status)
                        {
                            statusArray = module[moduleType].status;
                        }
                        /*else if(module[moduleType].uartFrame)
                        {
                            var oper = module[moduleType].uartFrame.substr(14,2);
                            // 0C = get info
                            if(oper=='0C')
                            {
                                UartService.getInfosNew(module[moduleType],this.allDevices,this.cacheDevices);
                                _this.setCacheDevices();
                            }
                            else
                            {
                                var m = UartService.getModuleFromUartFrame(module[moduleType],this.devices);
                                if(m)
                                {
                                    statusArray = m[moduleType].status;
                                    module.id = m.id;
                                    
                                }
                            }
                        }*/
                        statusArray.forEach(function(elt) {
                            if(elt.value && elt.value != '' && elt.value != 'show' && elt.value != 'pending' && elt.capability != 'call')
                            {
                                _this.eventHub.$emit('setDeviceStatus', {model:'',id: module.id}, elt.value, moduleType, true, elt.capability,true);
                            }
                        });
                    }
                });
                this.eventHub.$emit('setEventPayload',payload, callbackEvent);
            }
        }
        catch(e){
            console.log(e);
        };
        },

        initRightPanel: function () {
        this.rightPanel.init = true;
        this.rightPanel.extend = false;
        this.rightPanel.minimize = false;
        //this.rightPanel.url = "https://market.comfortlife.me/test";
        this.rightPanel.url = GlobalResource.WEBSITE_STORE;
        },

        marketOnload: function () {
            this.rightPanel.hideContent = false;
        },

        setExtendRightPanel: function (b) {
            this.rightPanel.extend = b;
            this.rightPanel.minimize = !(b);
        },

        setMinimizeRightPanel: function (b) {
            this.rightPanel.minimize = b;
            this.rightPanel.extend = !(b);
        },

        setUrlRightPanel: function (url) {
            this.rightPanel.url = url;
        },

        setHideContentRightPanel: function (hideContent) {
            this.rightPanel.hideContent = hideContent;
        },
        
        setInitRightPanel: function (init) {
            this.rightPanel.init = init;
        },

        setHideRightPanel: function (hide) {
            this.rightPanel.hide = hide;
        },

        executeHideContentRightPanel: function () {
            this.setHideContentRightPanel(true);
        },

        //DEVICES
        addDevice : function(module,brandName,availableModels){
            if(module)
                this.homepilot=module;
            else
                this.homepilot=null;
            this.brandName = brandName;
            this.availableAddDeviceModels=availableModels;
            this.installModal=true;
            this.displayCloseIconInstallModal=true;
        },

        closeInstallModal : function(bclose){
            this.addDevicecloseConfirmDialog=false;

            this.eventHub.$emit('setLoading',  false);
            if(bclose)
            {
                if(typeof bclose == 'boolean')
                {
                    this.installModal=false;
                }
                else if(typeof bclose == 'string')
                {
                    this[bclose]=false;
                }
            }
            this.externalName = '';
            this.externalRoute = '';
            //this.eventHub.$emit('loadElements',  ['DevicesGroups', 'Devices']);
            //this.eventHub.$emit('getAllTopology', 'all');
            this.eventHub.$emit('getAllTopology', 'all', undefined, undefined, undefined,true);
        },
        
        closeServiceInstallModal : function(){
            this.eventHub.$emit('setLoading',  false);
            this.addServicecloseConfirmDialog=false;
            this.serviceAddModal=false;
            this.eventHub.$emit('getAllTopology', 'all');
        },

        changeDisplayCloseIconInstallModal : function(visible){
            this.displayCloseIconInstallModal = visible;
        },

        addDeviceFromRedirect: function(externalName,externalRoute)
        {
            if(this.$route.params.externalName)
            {
                this.externalName = externalName;
                this.externalRoute = externalRoute;
                this.addDevice();
            }
        },

        closeDialog : function(){
            this.installModal=false;
            this.displayCloseIconInstallModal = true;
        },

        loadElements : function(elements)
        {
            for(var i = 0 ; i<elements.length; i++)
            {
                this.eventHub.$emit('get'+elements[i], 'ROOMS');
            };
            //this.displayTutorial();
            
            this.eventHub.$emit('getAllTopology', 'all', 'ROOMS', undefined, undefined,true);
        },

        //SERVICES

        addService : function(){
            this.editedService = undefined;
            this.serviceAddModal = true;
        },

        openEditService : function(service){
            var s = JSON.parse(JSON.stringify(service));
            this.editedService = s;
            this.editedService.zoneId = this.getRoomModule(s.id);
            this.serviceAddModal = true;
        },

        addServiceFinished : function(){
            this.closeServiceInstallModal();
        },

        getRoomModule : function(moduleId){
                for(var i=0; i<this.plant.zones.length; i++){
                    if(_find(this.plant.zones[i].modules, function(o) { return o.id == moduleId; }))
                        return this.plant.zones[i].id;
                }
        },
        
        displayTutorial : function(force){
            if(this.$store.state.variantManager.class.tutorial.hide)return false;
            if(this.auth != null && this.eventHub.to.name!='invite'){
                if(!StorageService.keyExists(localStorage,this.eventHub.user.username+'__hideTutorial') || force)
                {
                    this.displayModalTutorial = true;
                }
            } 

        },
  }
};

</script>
