import DeviceResource from 'common/services/resources/device.js'

export default {
	data:
		function () {
			return {
					CONSTANTS:
					{
						ASSOCIATION_TXT : this.$gettext('Wait 30 seconds.<br/>Then, hold the reset button down for at least 5 seconds and release: the LED flashes.<br/>Click Next to associate it.'),
						ASSOCIATE_ERROR : this.$gettext('Unable to associate the device'),
						ASSOCIATE_SUCCESS : this.$gettext('The device has been successfully associated'),
						ADD_ERROR : this.$gettext('Unable to add the device'),
						TRY_NUMBER : 8,
						TRY_TIMEOUT : 5000,
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
					},
					diagnosticInterval: {},
					diagnosticStatus: {},
					numberTry: 0,
					wait:
					{
						percent: 0,
						time: 0,
					},
					waiting: false,
					loading:false
			}
	},
    beforeDestroy () {
      clearInterval(this.diagnosticInterval);
    },
	created: function () {
		this.eventHub.$emit('setDisplayPrevious', true);
    },
	methods: {
		associate: function(){
			this.eventHub.$emit('setDisplayPrevious', false);
			this.loading = true;
			var _this = this;
			var commandId = this.uuidV4();
			var bearerId = this.$parent.homepilot.id;
			var model = this.$parent.model;
			var submodel = this.$parent.submodel.name;
			var locationId = this.$parent.$parent.plant.zones[0].id;
			var brand =this.$parent.$parent.brand;
			DeviceResource.associateTo(model.name,model.systems[0],submodel,bearerId,commandId,locationId,brand,this.eventHub).then(
				function(response) {
					_this.loading = false;
					_this.eventHub.$emit('setDeviceId',response.data.id);
					_this.eventHub.$emit('setDeviceObj',response.data);
					_this.eventHub.$emit('nextInstallStep');
					_this.eventHub.$emit('addAssociateDevice', response.data);
			}).catch(
				function(e) {
					if(e == 'Error: Network Error')
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
					else if(e.response && e.response.status == 401)
						_this.eventHub.$emit('logout');
					_this.loading = false;
					_this.eventHub.$emit('setValidation', false);
					_this.eventHub.$emit('setInternalMainStep', 6);
				});
		}
 	}
}
