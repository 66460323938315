import { hostname } from "os";
import store from 'vuex_path/store'
import StorageService from 'common/services/storage.js'

export default class {
    constructor(createdAt, description) {
        this.createdAt = createdAt || '';
        this.description = description || '';
    }
    getCreatedAt()
    {
        return this.createdAt;
    }
    getDescription()
    {
        return this.description;
    }  

    addNotification(id, notificationsmodules, modules, json, subdeviceName)
    {
        //if(store.state.selectedPlantId!=StorageService.getValue(localStorage, 'plantId'))return false;
        var module = _find(modules, function(o) { return o.id == id; });
        var notificationsmodule = _find(notificationsmodules, function(o) { return o.id == module.id; });
        if(json.modules[0].device){
            var type = "device";
        }
        else{
            var type = "service";
        }
        this.createdAt = json.timestamp;
        if(subdeviceName != undefined)
            module.name = subdeviceName;
        if(json.modules[0][type].status[0].value)
            this.description = module.name+' : '+json.modules[0][type].status[0].value;
        else
            this.description = module.name+' : '+json.modules[0][type].status[0].capability;
        var notification = {
            'commandId': json.uid,
            'createdAt':this.createdAt,
            'description':this.description
        }
        if(notificationsmodule != undefined){
            notificationsmodule.notifications.push(notification);
        }  
        else{
            var createModule = {
                'id' : id,
                'name' : module.name,
                'notifications' : [notification],
                'type' : null
            }
            module.notification.id = id;
            module.notification.name = name;
            module.notification.notifications.push(notification);
            module.notification.type = null;
        }
    } 
}
