import ReceiversSelect from 'common/components/receivers-select/receivers-select.vue'

export default {
	props:['receivers','plant', 'user', 'appConstants'],
	components: {ReceiversSelect},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						SELECT_RECEIVER_ERROR: this.$gettext('Please add an item'),
					},
					notifications: []

			}
		},
	created: function () {
	},
	destroyed: function () {
		
	},
	mounted: function () {
	},
	computed: {
	},
	watch: {
  	},
	methods: {
		next: function(){
			this.addReceivers();
			if(this.receivers.length==0)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.SELECT_RECEIVER_ERROR);
				return false;
			}
			this.$emit("nextStep");
			
		},

		addReceivers: function(){
			var _this = this;
			this.receivers.splice(0);
			this.notifications.forEach(function(n) {
				if(n.infos && n.infos.to)
				{
					_this.receivers.push(n.infos.to);
				}
			});
		}
  	}
}
