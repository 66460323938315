import Global from './global.js'
import StorageService from '../storage.js'

import axios from 'axios'

export default {

    setVerifyCode: function (id,verifyCode) { 
        var _this = this;
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+'camera/surveillance/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+id+'/verifyCode',
                    {
                        'verifyCode' : verifyCode
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        }
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },

    recordsTimeline: function (id) {
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'smart_camera/surveillance/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+id+'/timelines',
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        }
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    }
}
