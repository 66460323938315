import HomepilotResource from 'common/services/resources/homepilot.js'
import DeviceResource from 'common/services/resources/device.js'


export default {
    props:['mac'],
    data: function () {
        return {
          CONSTANTS:{
            TRY_NUMBER : 8,
            TRY_TIMEOUT : 5000
          },
          diagnosticStatus: {},
          numberTry: 0
        }
    },
    created: function () {
      this.eventHub.$emit('setDisplayPrevious', false);
      this.associate();
    },
    beforeDestroy () {
      clearInterval(this.diagnosticStatus);
    },
	methods: {
    associate: function(){
        var _this = this;
        var json = {
  					'mac' : this.mac,
  					'timezone': this.moment.tz.guess()
        };
        HomepilotResource.associate(json,this.eventHub).then(
          function(response) {
            clearInterval(_this.diagnosticStatus);
            _this.eventHub.$emit('setDeviceId',response.data.id);
            _this.setIntervalFunction(function(){_this.associateStatus(response.data.id)});
        }).catch(
          function(error) {
            if(error.response)
            {
              if(error.response.status==409){
                _this.setIntervalFunction(function(){_this.associate()});
              }
              else{
                _this.$parent.result = false;
                _this.eventHub.$emit('nextInstallStep');
              }
            }
            else
            {
              _this.$parent.result = false;
              _this.eventHub.$emit('nextInstallStep');
            }
          });
    },



    setIntervalFunction : function(functionToGo){
      clearInterval(this.diagnosticStatus);
      this.diagnosticStatus = setInterval(() => {
          if (this.numberTry == this.CONSTANTS.TRY_NUMBER) {
            clearInterval(this.diagnosticStatus);
            this.$parent.result = false;
            this.eventHub.$emit('nextInstallStep');
          }
          else{
            functionToGo();
            this.numberTry +=1
          }
      }, this.CONSTANTS.TRY_TIMEOUT);
    },

    associateStatus: function(id){
      var _this = this;
      var model = this.$parent.model;
      DeviceResource.associateStatus(model.name,model.systems[0],id).then(
        function(response) {
          if(response.data[model.name+'s'][0].associateStatus  == 'ASSOCIATED'){
            clearInterval(_this.diagnosticStatus);
            _this.$parent.result = true;
            _this.eventHub.$emit('nextInstallStep');
            _this.eventHub.$emit('addAssociateDevice', response.data);
          }
      }).catch(
        function() {
        });
      },
	}
}
