export default class{
    
    constructor(user, defaultView = 'rooms', viewAutoplayDelay = 6, cameraFrameRate = 5, fullscreenCameraDisplayDuration = 10) {
        this.user = user;
        this.defaultView = defaultView;
        this.viewAutoplayDelay = viewAutoplayDelay;
        this.cameraFrameRate = cameraFrameRate;
        this.fullscreenCameraDisplayDuration = fullscreenCameraDisplayDuration;
    }

}