import DeviceResource from 'common/services/resources/device.js'
import ScenarioResource from 'common/services/resources/scenario.js'
import StringService from 'common/services/string.js'

export default {
    CONSTANTS:
    {
        BUTTON_TAGS : 'bouton numéro|button number',
        CAPABILITIES_VOICE_COMMANDS :
        {
            'fr':
            {
                'allumé,allumée,allumer,éteindre' :
                {
                    capability: 'status',
                    values:
                    {
                        'allumé,allumée,allumer':
                        {
                            value: 'on'
                        },
                        'éteindre':
                        {
                            value: 'off'
                        }
                    }
                },
                'bouton\\s?numéro\\s?(un|deux|de|[1-2]),déclencher,activée,activé,activer' :
                {
                    capability: 'trigger'
                },
                'activée,activé,activer,désactivée,désactivé,désactiver' :
                {
                    capability: 'active',
                    values:
                    {
                        'activée,activé,activer':
                        {
                            value: 'enable'
                        },
                        'désactivée,désactivé,désactiver':
                        {
                            value: 'disable'
                        },
                    }
                },
                'activée partiellement,activé partiellement,activer partiellement,armer partiellement,armé partiellement,armée partiellement,activée,activé,activer,désactivée,désactivé,désactiver,armé en mode partiel,armée en mode partiel,armer en mode partiel,armé,armée,armer,désarmé,désarmée,des armées,désarmer' :
                {
                    capability: 'protection',
                    values:
                    {
                        'activée,activé,activer,armé,armée,armer':
                        {
                            value: 'arm'
                        },
                        'désactivée,désactivé,désactiver,désarmé,désarmée,des armées,désarmer':
                        {
                            value: 'disarm'
                        },
                        'activée partiellement,activé partiellement,activer partiellement,armer partiellement,armé partiellement,armée partiellement,armé en mode partiel,armée en mode partiel,armer en mode partiel':
                        {
                            value: 'partial'
                        },
                    }
                },
                'ouvrir,fermée,fermé,fermer,ouvrir instantanément,fermer instantanément,fermé instantanément,fermée instantanément,défiler bas,défilé bas,défilée bas,défiler haut,défilé haut,défilée haut' :
                {
                    capability: 'position_vertical',
                    values:
                    {
                        'ouvrir':
                        {
                            value: 'up'
                        },
                        'fermée,fermé,fermer':
                        {
                            value: 'down'
                        },
                        'ouvrir instantanément':
                        {
                            value: 'instant_up'
                        },
                        'fermer instantanément,fermé instantanément,fermée instantanément':
                        {
                            value: 'instant_down'
                        },
                        'défiler bas,défilé bas,défilée bas':
                        {
                            value: 'scroll_up'
                        },
                        'défiler haut,défilé haut,défilée haut':
                        {
                            value: 'scroll_down'
                        }
                    }
                },
                'montre moi,montre-moi' :
                {
                    capability: 'show'
                },
            },

            'en':
            {
                'turn on,switch on,turn off,switch off,power on,power off' :
                {
                    capability: 'status',
                    values:
                    {
                        'turn on,switch on,power on':
                        {
                            value: 'on'
                        },
                        'turn off,switch off,power off':
                        {
                            value: 'off'
                        }
                    }
                },
                'button\\s?number\\s?[1-4],trigger,start' :
                {
                    capability: 'trigger'
                },
                'enable,disable' :
                {
                    capability: 'active',
                    values:
                    {
                        'enable':
                        {
                            value: 'enable'
                        },
                        'disable':
                        {
                            value: 'disable'
                        },
                    }
                },
                'total,outdoor,partially enable,enable,disable,switch on,switch off,alarm partial,arm,disarm,partially arm' :
                {
                    capability: 'protection',
                    values:
                    {
                        'total,enable,switch on,arm':
                        {
                            value: 'arm'
                        },
                        'disable,switch off,disarm':
                        {
                            value: 'disarm'
                        },
                        'outdoor,partially enable,alarm partial,partially arm':
                        {
                            value: 'partial'
                        },
                    }
                },
                'open,close,instant open,instant close,scroll up,scroll down' :
                {
                    capability: 'position_vertical',
                    values:
                    {
                        'open':
                        {
                            value: 'up'
                        },
                        'close':
                        {
                            value: 'down'
                        },
                        'instant open':
                        {
                            value: 'instant_up'
                        },
                        'instant close':
                        {
                            value: 'instant_down'
                        },
                        'scroll up':
                        {
                            value: 'scroll_up'
                        },
                        'scroll down':
                        {
                            value: 'scroll_down'
                        }
                    }
                },
                'show me' :
                {
                    capability: 'show'
                },
            },

            'zh':
            {
                '关,开' :
                {
                    capability: 'status',
                    values:
                    {
                        '开':
                        {
                            value: 'on'
                        },
                        '关':
                        {
                            value: 'off'
                        }
                    }

                },
                '按钮\\s?[1-4],启动' :
                {
                    capability: 'trigger'
                },
                '开启,关闭' :
                {
                    capability: 'active',
                    values:
                    {
                        '开启':
                        {
                            value: 'enable'
                        },
                        '关闭':
                        {
                            value: 'disable'
                        },
                    }
                },
                '启动,关闭,部分启动' :
                {
                    capability: 'protection',
                    values:
                    {
                        '启动':
                        {
                            value: 'arm'
                        },
                        '关闭':
                        {
                            value: 'disarm'
                        },
                        '部分启动':
                        {
                            value: 'partial'
                        },
                    }
                },
                '开,关' :
                {
                    capability: 'position_vertical',
                    values:
                    {
                        '开':
                        {
                            value: 'up'
                        },
                        '关':
                        {
                            value: 'down'
                        }
                    }
                }
            }
        }
    },

    /**
        Trigger command
        @param matches speech recognition matches.
        @param devicesByCapability all user devices sort by capability.
        @param scenarios all user scenarios.
        @return triggerCommand (object) : {
                                                voiceCommand (string) -> voice command (ex : 'start'),
                                                capabilityObject (object) -> {
                                                                    capability -> capability,
                                                                    value -> capability value
                                                                    },
                                                buttonIndex (integer) : number of the selected button (relay, switch)
                                                modules (array) -> all modules related to the trigger
                                            }
    */

    getTriggerCommand : function(matches,devicesByCapability, scenarios, scenariosByCapability, plant, language)
    {
        console.log('getTriggerCommand getTriggerCommand');
        var triggerCommand =
        {
            voiceCommand: '',
            buttonIndex: undefined,
            capabilityObject : undefined,
            modules: []
        };
        var modules = [];
        var matchesCapabilities = this.findMatchesCapabilities(matches, language);
        console.log('MATCHESCAPABILITIES');
        console.log(plant);
        console.log(matchesCapabilities);
        if(matchesCapabilities.voiceCommand!='')
        {
            triggerCommand.voiceCommand = matchesCapabilities.voiceCommand;
            triggerCommand.capabilityObject = matchesCapabilities.capabilityObject;
            triggerCommand.buttonIndex = matchesCapabilities.buttonIndex;

            for(var i = 0; i<matchesCapabilities.capabilityObject.length;i++)
            {
                var capabilityObject = matchesCapabilities.capabilityObject[i];

                // If capability = 'trigger' -> search in user scenarios array
                if(capabilityObject.capability == 'trigger')
                {
                    modules = modules.concat({
                        elements: ScenarioResource.findScenarioByName(scenarios, matchesCapabilities.matchesDevices),
                        capabilityObject: capabilityObject
                    });
                }
                
                //if(modules.length==0)
                //{
                    console.log('capabilityObject');
                    console.log(capabilityObject);
                    console.log(devicesByCapability);
                    // Search in user devices array
                    var elements = new Array();
                    elements = elements.concat(DeviceResource.findElementsByCapabilityAndName(devicesByCapability, capabilityObject.capability, matchesCapabilities.matchesDevices));
                    elements = elements.concat(DeviceResource.findElementsByCapabilityAndName(scenariosByCapability, capabilityObject.capability, matchesCapabilities.matchesDevices));
                    console.log('elements');
                    console.log(elements);
                    modules = modules.concat({
                        elements: elements,
                        capabilityObject: capabilityObject
                    });
                //}
            }
        }
        /*else
        {*/
            modules = modules.concat({
                elements: ScenarioResource.findScenarioByName(scenarios, matchesCapabilities.matches),
            });

        //}
        triggerCommand.modules = modules;
        console.log(triggerCommand);
        console.log('MATCHESCAPABILITIES - END');
        return triggerCommand;
    },

    /**
        Find the action trigger in matches
        @param matches speech recognition matches.
        @return matchesCapabilities (object) : {
                                                    voiceCommand (string) -> voice command (ex : 'start'),
                                                    capabilityObject (object) -> {
                                                                        capability -> capability,
                                                                        value -> capability value
                                                                        },
                                                    buttonIndex (integer) : number of the selected button (relay, switch)
                                                    matchesDevices (array) -> the remaining words after the action trigger




    */
    findMatchesCapabilities: function(matches, language)
    {
        console.log('findMatchesCapabilities');
        var matchesCapabilities =
        {
            voiceCommand: '',
            capabilityObject: undefined,
            buttonIndex: undefined,
            matchesDevices: [],
            matches: matches

        }
        var firstMatchesCapability = undefined;
        var _this = this;
        console.log('findMatchesCapabilities - matches');
        console.log(matches);
        for(var i = 0; i <matches.length; i++)
        {
            var m = matches[i].toLowerCase();

            console.log(m);
            if(firstMatchesCapability)
            {
                var index = m.indexOf(firstMatchesCapability.voiceCommand);
                if(index!=-1)
                {
                    var subMatch = m.substr(index+(firstMatchesCapability.voiceCommand.length));
                    matchesCapabilities.matchesDevices.push(subMatch);
                }

            }
            else
            {
                firstMatchesCapability = _this.findFirstMatchesCapability(m,language);
                console.log('FIRST MATCHES');
                console.log(firstMatchesCapability);
                if(firstMatchesCapability)
                {
                    matchesCapabilities.voiceCommand = firstMatchesCapability.voiceCommand;
                    matchesCapabilities.capabilityObject = firstMatchesCapability.capabilityObject;
                    matchesCapabilities.buttonIndex = firstMatchesCapability.buttonIndex;
                    matchesCapabilities.matchesDevices.push(firstMatchesCapability.subMatch);
                }
            }
        }
        console.log('END - findMatchesCapabilities');
        return matchesCapabilities;
    },

    /**
        Find the first action to use in the analysis of the remaining matches
        @param match speech recognition match.
        @return firstMatchesCapability (object) : {
                                                    voiceCommand (string) -> voice command (ex : 'start'),
                                                    buttonIndex (integer) : number of the selected button (relay, switch)
                                                    capabilityObject (object) -> {
                                                                        capability -> capability (ex : 'status'),
                                                                        value -> capability value (ex : 'on'),
                                                                        },
                                                    subMatch (string) -> the remaining words after the first action trigger
                                                  }
    */

    findFirstMatchesCapability: function(match,language)
    {
        console.log('findFirstMatchesCapability');
        var _this = this;
        var firstMatchesCapability = undefined;
        console.log(language);
        var cvcs = this.CONSTANTS.CAPABILITIES_VOICE_COMMANDS[language];
        console.log(cvcs);
        var capabilities = [];
        var regCheckObject = {};
        for(var x in cvcs)
        {
            var voiceCommandsArray = x.split(',');
            for(var i = 0; i <voiceCommandsArray.length; i++)
            {
                var vc = voiceCommandsArray[i];
                console.log(vc);
                var reg = new RegExp("^" + vc + "(\\s?|$)");
                console.log(reg);
                console.log(match);
                var regCheck = reg.exec(match);
                if(regCheck !== null)
                {
                    console.log('FOUND');
                    vc =  regCheck[0].trim();
                    //var index = StringService.regexIndexOf(match, reg);
                    var index = match.indexOf(regCheck[0]);
                    var subMatch = match.substr(index+(vc.length));
                    //get capability object
                    var capabilityObject = _this.findCapabilityObject(cvcs[x],vc);
                    capabilities.push(capabilityObject);
                    console.log("\\b(" + _this.CONSTANTS.BUTTON_TAGS + ")\\b");
                    console.log(_replace(vc, new RegExp("\\b(" + _this.CONSTANTS.BUTTON_TAGS + ")\\b"), ''));
                    regCheckObject =
                    {
                        vc: vc,
                        buttonIndex: parseInt(this.getNumberByString(_replace(vc, new RegExp("\\b(" + _this.CONSTANTS.BUTTON_TAGS + ")\\b"), '').trim())),
                        subMatch: subMatch
                    };
                    console.log(regCheckObject);
                    break;
                }
            }
        }
        if(capabilities.length>0)
        {
            var firstMatchesCapability ={
                voiceCommand: regCheckObject.vc,
                buttonIndex: regCheckObject.buttonIndex,
                capabilityObject: capabilities,
                subMatch: regCheckObject.subMatch
            };
        }
        console.log('findFirstMatchesCapability - END');
        return firstMatchesCapability;
    },

    /**
        Find the capability object associate to the vocal command
        @param capabilitiesVoiceCommand (string) the list of command vocals where the action trigger was found
        @param vocalCommand (string) the action trigger found

        @return capabilityObject (object) -> {
                                                capability -> capability (ex : 'status'),
                                                value -> capability value (ex : 'on'),
                                            }
    */

    findCapabilityObject: function(capabilitiesVoiceCommand, vocalCommand)
    {
        var capabilityObject =
        {
            capability: capabilitiesVoiceCommand.capability,
            value: undefined
        };
        if(capabilitiesVoiceCommand.values)
        {
            for(var x in capabilitiesVoiceCommand.values)
            {

                vocalCommand = vocalCommand.toLowerCase().trim();
                var commandsArray = x.split(',');
                if(commandsArray.indexOf(vocalCommand)!=-1)
                {
                    capabilityObject.value = capabilitiesVoiceCommand.values[x].value;
                    break;
                }
            }
        }
        return capabilityObject;
    },

    getNumberByString: function(string)
    {
        switch(string)
        {
            case 'un': 
                        return 1;
            case 'deux': 
            case 'de':
                        return 2;          

        }   
        return string;
    }
}
