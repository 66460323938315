import { consoleError } from "vuetify/es5/util/console";

export default {
		find: function(arr, attr, str) {
      str=(typeof str !== 'object'?[str]:str);
      if (!Array.prototype.find) {
        Object.defineProperty(Array.prototype, "find", {
          value: function(predicate) {
            if (this === null) {
              throw new TypeError('Array.prototype.find called on null or undefined');
            }
            if (typeof predicate !== 'function') {
              throw new TypeError('predicate must be a function');
            }
            var list = Object(this);
            var length = list.length >>> 0;
            var thisArg = arguments[1];
            var value;

            for (var i = 0; i < length; i++) {
              value = list[i];
              if (predicate.call(thisArg, value, i, list)) {
                return value;
              }
            }
            return undefined;
          }
        });
      }
      return arr.find(function(obj){return str.indexOf(obj[attr])!=-1});
    },
		indexOf: function(arr, attr, str) {
			for(var key = 0 ; key < arr.length ; key++)
			{
				if(arr[key][attr] == str)
					return key;
			}
			return -1;
    },
		groupByNumber: function(arr,number) {
      var groupedArr = [];
      for(var i = 0 ; i < arr.length ; i++)
			{
        var mod = i%number;
        if(mod==0)
        {
          var subMod = [];

        }
        subMod.push(arr[i]);
        if(subMod.length==number)
        {
          groupedArr.push(subMod);
        }
			}
			return groupedArr;
    },

    merge : (arr1, arr2, prop) => {
      const resultMap = new Map(arr1.map((item) => [item[prop], item]));
      arr2.forEach((item) => {
          const mapItem = resultMap.get(item[prop]);
          if (mapItem) Object.assign(mapItem, item);
          else resultMap.set(item[prop], item);
      });
      return [...resultMap.values()];
  }
    
}
