import HPInstall from './hp/hp.vue'
import AlarmInstall from './alarm/alarm.vue'
import SthiInstall from './sthi/sthi.vue'
import RbInstall from './rb/rb.vue'
import DeviceInstall from './device-install/device-install.vue'
import DeviceOnOffInstall from './device-on-off-install/device-on-off-install.vue'
import WifiInstall from './wifi-install/wifi-install.vue'
import Device2_4Install from './device-2_4-install/device-2_4-install.vue'
import UartService from 'common/services/uart.js'
import { consoleError } from 'vuetify/es5/util/console'

export default { 
    props:['chooseType','brand','plant','homepilot', 'model', 'submodel', 'step', 'deviceObj','qrCodeElts', 'prefilledMac', 'installType', 'installObject'],
    components:{DeviceInstall, DeviceOnOffInstall, HPInstall, AlarmInstall, SthiInstall, RbInstall, WifiInstall, Device2_4Install},
    data: function () {
        return {
        }
    },
    created: function () {
      this.eventHub.$emit('setDisplayPrevious', true);
      this.eventHub.$emit('setDisplayTitle', false);
    },
	  methods: {
      isNormalDevice : function(){
        return (this.$parent.normal_device.indexOf(this.submodel.name) != -1);
  		},
  		isOnOffDevice : function(){
        return (this.$parent.on_off_device.indexOf(this.submodel.name) != -1);
      },
  		wifiInstall : function(){
            return (this.model.type == 'WIFI');
      },
      device2_4Install : function(){
        return this.submodel.meshCompatible;
      }
	  }
}
