import AddDeviceWizardClient from './client/add-device-client.vue'
import AddDeviceWizardInternal from './internal/add-device.vue'
import AddDeviceWizardUndefined from './undefined/add-device-undefined.vue'
import AddDeviceWizardExternal from './external/add-device-external.vue'

export default {
	props:['wizardStep','wizardTitle','grdpWarning','brand','plant','models','homepilots', 'brands', 'defaultRooms', 'systems', 'devices', 'icons',
	'noPreviousForWizard',
	'availableModels',
	'chooseWizard',
	'user',
	'externalAccounts',
	'qrCodeElts',
	'model',
	'submodel',
	'prefilledMac', 
	'selectedHomepilot',
	'chooseType'
	],
	components: {AddDeviceWizardClient, AddDeviceWizardInternal, AddDeviceWizardUndefined,AddDeviceWizardExternal},
	data:
		function () {
			return {
				    CONSTANTS:
					{
					
					},
			}
		},
	created: function () {
		if(this.noPreviousForWizard===true)
		{
			this.eventHub.$emit('setDisplayPrevious', false);
		}
	},
	methods: {

  }
}
