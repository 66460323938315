export default {
   props:['model'],
    data: function () {
        return {
          CONSTANTS:{
                      WHAT_KIND_INSTALLATION : this.$gettext('What <strong>kind of installation</strong> do you want?'),
                      WARNING_INSTALLATION : this.$gettext('For the installation of your first Home Pilot, we strongly advise you to install it in cable because it must never lose its internet connection.<br/>A second Home Pilot can be installed in Wifi.')
  					        }
        }
    },
    created: function () {
      this.eventHub.$on('nextChooseStep', this.nextChooseStep);
      this.eventHub.$emit('setDisplayTitle', true, 'Add a device');
    },
	methods: {
    nextChooseStep : function(value, type){
      if(type != 'back'){
        this.$parent.mode = (this.$parent.mode == 'automatic'?'manual':'automatic');
        this.$parent.button = (this.$parent.button == 'automatic'?'manual':'automatic');
        this.$parent.installationTitle = this.$gettext('Installation : '+this.$parent.mode+' - '+(value=='cable'?'RJ45 cable':'wifi'));
        this.$parent.installationButton = this.$gettext(this.$parent.button+' mode');
      }
      this.eventHub.$emit('setDisplayTitle', false);
      this.$parent.step = value;
      this.eventHub.$emit('setInstallStep', 1);    
    },
    mobil : function(){
      if(window.innerWidth<=1024)
        return true;
    }
	}
}
