
export default {
  hls: undefined,
  video: undefined,
  play: function(videoTagString,url) {
      this.video = document.querySelector(videoTagString);
      if(Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(url);
        this.hls.attachMedia(this.video);
        this.hls.on(Hls.Events.MANIFEST_PARSED,function() {
          this.video.play();
      });
      }
      // hls.js is not supported on platforms that do not have Media Source Extensions (MSE) enabled.
      // When the browser has built-in HLS support (check using `canPlayType`), we can provide an HLS manifest (i.e. .m3u8 URL) directly to the video element throught the `src` property.
      // This is using the built-in support of the plain video element, without using hls.js.
      // Note: it would be more normal to wait on the 'canplay' event below however on Safari (where you are most likely to find built-in HLS support) the video.src URL must be on the user-driven
      // white-list before a 'canplay' event will be emitted; the last video event that can be reliably listened-for when the URL is not on the white-list is 'loadedmetadata'.
      else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
        this.video.src = url;
        this.video.addEventListener('loadedmetadata',function() {
          this.video.play();
        });
      }
  },
  destroy: function() {
    if(this.video)
    {
      this.video.pause()
      
    }
    /*if(this.hls)
    {
      this.hls.destroy()
      
    }*/
  }
}