import WifiInstallConfigDevice from './wifi-install-config-device.vue';
import WifiInstallChoose from './wifi-install-choose.vue';
import WifiInstallSync from './wifi-install-sync.vue';
import WifiInstallConfig from './wifi-install-config.vue';
import wifiInstallSyncSmartconfig from './wifi-install-sync-smartconfig.vue';
import WifiInstallAssoc from './wifi-install-assoc.vue';
import WifiInstallConnect from './wifi-install-connect.vue';
import StringService from 'common/services/string.js';
import UserResource from 'common/services/resources/user.js'

const V2_INSTALL = ['s26','s55', 'n_wr07cl'];

const V2_INSTALL_BRAND = ['comfort_life'];

const V2_INSTALL_MODEL_NOT_SUPPORTED = ['a6','a6_pro'];

const AP_SYNC_SECONDS_V1 = 12;

const AP_SYNC_SECONDS_V2 = 5;

const SC_SYNC_SECONDS_V2 = 5;

export default {
	props: ['homepilot', 'model', 'submodel', 'step', 'qrCodeElts', 'installType', 'installObject'],
	components: {
		WifiInstallConfigDevice,
		WifiInstallChoose,
		WifiInstallSync,
		wifiInstallSyncSmartconfig,
		WifiInstallConfig,
		WifiInstallAssoc,
		WifiInstallConnect
  	},
	data: function () {
		return {
		  	CONSTANTS:
			{
			  PLUG_HOST : '192.168.100.1',
			  PLUG_PORT : 8000,
			  /*PLUG_HOST : '192.168.51.222',
			  PLUG_PORT : 4444,*/
			  QRCODE_MODELS:['battery_vdp','oskkio-1'],
			  AUDIO_MODELS:['vdp']
			},
			installSteps:{
			  'default' : 
			  [
				{
				  name: 'wifi-install-choose',
				  props:
				  {
					'txt1': this.$gettext('Connect to the network'),
					'txt2': this.$gettext('Select your network and type your password to connect.'),
				  }
				},
				{
				  name: 'wifi-install-config-device',
				  props:
				  {
					steps: [
					  {
						'txt': this.$gettext('Turn on the power and reset the device according to your manual'),
						'img': '@/assets/images/device/cw/cl-install-'+this.submodel.name+'-connection.png'
					  }
					]
				  }
				}
			  ],
			  'synchronization' : 
			  {
				name: 'wifi-install-sync',
				props:
				{

					'sentences' : {
						'title_ap': this.$gettext('Manual installation'),
						'title': this.$gettext('Installation'),
						'default': this.$gettextInterpolate(this.$gettext('Even if the LED is blinking, push button on the Smart Socket for at least <b>%{seconds} seconds</b> and release it. The LED starts to blink very slowly.'), {seconds: (this.checkIfV2Install(this.submodel)?AP_SYNC_SECONDS_V2:AP_SYNC_SECONDS_V1)}),
						'smart_config':  this.$gettextInterpolate(this.$gettext('Even if the LED is blinking, push button on the Smart Socket for at least <b>%{seconds} seconds</b> and release it. The LED starts to flash very quickly.'), {seconds: SC_SYNC_SECONDS_V2}),
					},
					'nextStepTimeout': 0,
					'img': {
						'default': require('@/assets/images/device/cw/cl-install-association.png'),
						'CW-100': require('@/assets/images/device/cw/cl-install-CW-100-association.png'),
						'CW-300': require('@/assets/images/device/cw/cl-install-CW-300-association.png'),
						'CW-310': require('@/assets/images/device/cw/cl-install-CW-310-association.png'),
						'CW-5000': require('@/assets/images/device/cw/cl-install-CW-5000-association.png'),
						'CW-8000': require('@/assets/images/device/cw/cl-install-CW-8000-association.png'),
					}
				}
			  },
			  'enterUid' : 
			  {
				'uid':
				{
				  name: 'wifi-install-connect',
				  props:
				  {
					field: 
					{
					  expression: /^(\w{4})-(\d{6})-(\w{7})$/,
					  errorMsg: this.$gettext('The format of the uid is not correct.<br/>The correct format is: XXXX-NNNNNN-XXXXXXX.'),
					  maxlength: 19
					},
					txt: this.$gettext('Please enter the uid of the device registered under the camera')
				  }
				},
				'mac':
				{
				  name: 'wifi-install-connect',
				  props:
				  {
					/*field: 
					{
					  expression: /^\w{12}$/,
					  errorMsg: this.$gettext('The format of the uid is not correct.<br/>The correct format is: XXXXXXXXXXXX.'),
					  maxlength: 12
					},*/
					txt: this.$gettext('Please enter the uid of the device registered under the device')
				  }
				}
			  }
			},
			installStepsSelected:[],
			headerStep: 1,
			initStep:1
		}
	},
	created: function () {
		this.init();
		this.eventHub.$on('retryWifiInternalInstall', this.retryWifiInternalInstall);
	  
	},
	destroyed: function () {
		this.eventHub.$off('retryWifiInternalInstall', this.retryWifiInternalInstall);
	},
	methods: {

		checkIfV2Install : (submodel) => ((submodel.createdFrom=='DEVELOPER_PORTAL')||(V2_INSTALL.indexOf(submodel.name)!=-1)||(V2_INSTALL_BRAND.indexOf(submodel.brand)!=-1 && V2_INSTALL_MODEL_NOT_SUPPORTED.indexOf(submodel.name)==-1)),
		
		init : function(noNextInstallStep,headerStep){
			// Init HZ model and LM model like CW
			//this.installSteps['hz'] = this.installSteps['lm'] = this.installSteps['cw'];
			var model = this.model.name;
			this.headerStep = headerStep;
			this.installStepsSelected.splice(0);

			this.installObject.plug.host = this.CONSTANTS.PLUG_HOST;
			this.installObject.plug.port = this.CONSTANTS.PLUG_PORT;

			//AUDIO INSTALL
			if((this.CONSTANTS.AUDIO_MODELS.indexOf(model)!=-1 && this.installType == 'standard')|| this.installType == 'audio')
			{
				if(this.installType == 'audio')
				{
					this.initStep = 4;
				}
				this.initAudioInstall();
				this.initAudioQRcode();
			}
			//QRCODE INSTALL
			else if((this.CONSTANTS.QRCODE_MODELS.indexOf(model)!=-1 && this.installType == 'standard')|| this.installType == 'qrcode')
			{
				if(this.installType == 'qrcode')
				{
					this.initStep = 3;
				}
				this.initQRCodeInstall();
				this.initAudioQRcode();
			}
			//SMART CONFIG INSTALL
			else if((this.checkIfV2Install(this.submodel) && this.installType == 'standard') || this.installType == 'smart_config')
			{
				if(this.installType == 'smart_config')
				{
					this.initStep = 4;
				}
				this.initSmartConfigInstall();
			}
			
			//AP INSTALL
			else
			{
				if(this.installType != 'standard')
				{
					if(this.checkIfV2Install(this.submodel))
					{
						this.initStep = 4;
					}
					else
					{
						this.initStep = 3;
					}
				}
				this.initAPInstall();
			}
			this.eventHub.$emit('setInstallType', this.installType);
			//If QRCode 
			if(this.qrCodeElts[2] && model!='homepilot')
			{
				this.installObject.device.uid = JSON.parse(JSON.stringify(this.qrCodeElts[2]));
				this.eventHub.$emit('setQRCodeElts',[]);
				this.$nextTick(function() {
					this.eventHub.$emit('nextInstallStep');
				});
				/*if(!noNextInstallStep)
				{
					this.$nextTick(function() {
						this.eventHub.$emit('nextInstallStep');
					});
				}*/
			}
			else
			{
				/*this.$nextTick(function() {
					this.eventHub.$emit('setInstallStep', this.initStep);
					this.eventHub.$emit('setHeaderStep', this.initStep);
				});*/

			}
		},
	  
		initAudioInstall : function(){
			this.installType = 'audio';
			this.installStepsSelected.push(this.installSteps.enterUid.uid);
			this.installStepsSelected = this.installStepsSelected.concat(this.installSteps['default']);
			this.installStepsSelected[2].props.steps = [
				{
					'txt': 
							'<b>'+this.$gettext('Start setup mode.')+'</b><br/>'+
							this.$gettext('Your product must be operational before installation.')+'<br/>'+
							this.$gettext('The LED should be red and flashing.')+'<br/>'+'<br/>'+
							this.$gettext('Help : if the light is not flashing red, press the reset button for 10 seconds to hear a beep.'),
					'img': require('@/assets/images/device/vdp/cl-install-vdp_100-install-setup.png'),
				}
			];
			this.installObject.device.password=StringService.randomString(12);
			let header = [
				{'title' : 'Device configuration'},
				{'title' : 'Connect to the network'},
				{'title' : 'Device configuration'},
				{'title' : 'Association'},
				{'title' : 'Notifications'},
				{'title' : 'Name'},
				{'title' : 'Room'}
			];

			this.eventHub.$emit('setStepper', header ,this.headerStep);
		},

		initQRCodeInstall : function(){
			console.log('initQRCodeInstall');
			this.installType = 'qrcode';
			this.installStepsSelected = this.installStepsSelected.concat(this.installSteps['default']);
			//this.installStepsSelected.push(this.installSteps.synchronization);
			this.installStepsSelected[1].props.steps = [
				{
				'txt': this.$gettext('Push button on the camera for at least 12 seconds and release it.'),
				'img': require('@/assets/images/device/cw/cl-install-association.png')
				}
			];
			this.installObject.device.password=StringService.randomString(12);
			console.log(this.headerStep);
			this.eventHub.$emit('setStepper', [
				{'title' : 'Connect to the network'},
				{'title' : 'Device configuration'},
				//{'title' : 'Configuration'},
				{'title' : 'Association'},
				{'title' : 'Notifications'},
				{'title' : 'Name'},
				{'title' : 'Room'}
			],this.headerStep);
		},
  
		initAPInstall : function(){
			if(this.installType!='ap')
			{
				this.installType = (window.device && window.device.platform=="Android" && parseInt(window.device.version,10) < 10 ?'smart_ap':'ap');
				//this.installType = 'ap';
			}
			
			if(this.CONSTANTS.AUDIO_MODELS.indexOf(this.model.name)!=-1 )
			{
				this.installStepsSelected.push(this.installSteps.enterUid.uid);
			}
			
			if(this.checkIfV2Install(this.submodel))
			{
				this.installStepsSelected.push(this.installSteps.enterUid.mac);
			}
			
			this.installStepsSelected = this.installStepsSelected.concat(this.installSteps['default']);

			if(this.CONSTANTS.AUDIO_MODELS.indexOf(this.model.name)==-1 )
			{
				this.installStepsSelected.push(this.installSteps.synchronization);
			}
			
			var steppers = [];
			if(this.CONSTANTS.AUDIO_MODELS.indexOf(this.model.name)!=-1 )
			{
				steppers.push({'title' : 'Device configuration'});
			}
			if(this.checkIfV2Install(this.submodel))
			{
				steppers.push({'title' : 'Device configuration'});
			}
			//Smart AP
			if(this.installType=='smart_ap')
			{
				this.installType = 'smart_ap';
				steppers = steppers.concat([{'title' : 'Connect to the network'},
				{'title' : 'Device configuration'},
				{'title' : 'Synchronize'},
				{'title' : 'Association'},
				{'title' : 'Notifications'},
				{'title' : 'Name'},
				{'title' : 'Room'}]);

				//this.installStepsSelected.splice(3,1);
				/*this.installStepsSelected[this.installStepsSelected.length-1].props.steps.unshift(
					{
					loading: false,
					isValid: true,
					txt: this.$gettextInterpolate(this.$gettext('Connection to the device: "%{s}-xxxxxxxxx"'), {s: this.submodel.name})
					}
				);*/
			}
			else
			{
				steppers = steppers.concat([{'title' : 'Connect to the network'},
				{'title' : 'Device configuration'},
				{'title' : 'Synchronize'},
				{'title' : 'Configuration'},
				{'title' : 'Association'},
				{'title' : 'Notifications'},
				{'title' : 'Name'},
				{'title' : 'Room'}]);
				let text = '@/assets/images/device/cw/cl-install-'+this.submodel.name+'wifi.png';
				this.installStepsSelected.push({
						name: 'wifi-install-config',
						props:
						{
							
							'txt1' : 'Open the Wifi Settings menu, then connect your phone to the device Wifi:<br/>\"%{s}-%{uid}\"<br/>You will be disconnected from the Internet during the process.If your phone asks you, stay connected to your device Wifi and do not use mobile data.',
							'img': text,
							'txt2':this.$gettext('Then get back to the application to finish the installation.')
						}
					}
				);
			}
			if(this.CONSTANTS.AUDIO_MODELS.indexOf(this.model.name)!=-1 )
			{
				var index = _findIndex(steppers, {title:'Synchronize'});
				if(index!=-1)
				{
					steppers.splice(index,1);
				}
			}
			
			this.installStepsSelected.push(
				{
					name: 'wifi-install-assoc',
					props:
					{
						txt: this.$gettext('Please wait, your device is being associated with your account.'),
						steps:
						[
							{
								loading: false,
								isValid: true,
								txt: this.$gettext('Connection')
							},
							{
								loading: false,
								isValid: true,
								txt: this.$gettext('Configuration')
							},
							{
								loading: false,
								isValid: true,
								txt: this.$gettext('Finalization')
							}
						]
				}
			});
			this.eventHub.$emit('setStepper', steppers, this.headerStep);
		},

		initSmartConfigInstall : function(){
			if(this.installType == 'standard')
			{
				this.installType = 'smart_config';
			}
			this.eventHub.$emit('setStepper', [
				{'title' : 'Device configuration'},
				{'title' : 'Connect to the network'},
				{'title' : 'Device configuration'},
				{'title' : 'Synchronize'},
				//{'title' : 'Synchronize'},
				{'title' : 'Association'},
				{'title' : 'Notifications'},
				{'title' : 'Name'},
				{'title' : 'Room'}
			],this.headerStep);
			this.installStepsSelected.push(this.installSteps.enterUid.mac);
			this.installStepsSelected = this.installStepsSelected.concat(this.installSteps['default']);
			this.installStepsSelected.push(this.installSteps.synchronization);
			this.installStepsSelected.push(/*{
					name: 'wifi-install-sync-smartconfig',
					props:
					{
						
						'txt' : this.$gettext('Is the LED flashing quickly or did it flash quickly?'),
					}
				},*/
				{
				name: 'wifi-install-assoc',
				props:
				{
					txt: this.$gettext('Please wait, your device is being associated with your account.'),
					steps:
					[
						{
							loading: false,
							isValid: true,
							txt: this.$gettext('Connection')
						},
						{
							loading: false,
							isValid: true,
							txt: this.$gettext('Configuration')
						},
						{
							loading: false,
							isValid: true,
							txt: this.$gettext('Finalization')
						}
					]
				}
			});	
		},

		initAudioQRcode : function(){
			this.installStepsSelected.push(
			{
				name: 'wifi-install-assoc',
				props:
				{
					txt: this.$gettext('Please wait, your device is being associated with your account.'),
					steps:
					[
						{
							loading: false,
							isValid: true,
							txt: this.$gettext('Finalization')
						}
					]
				}
			});
		},

		retryWifiInternalInstall : function(installType){
			if(installType)
			{
			  this.eventHub.$emit('setInstallType', installType);
			}
			var associationToken = StringService.randomString(16,'hexa');
			this.installObject.associationToken=associationToken;
			this.installObject.device.password=associationToken;
			UserResource.setAssocCode(associationToken).then(
				function(response) {
			}).catch(
				function() {
				
			});
			//this.eventHub.$emit('setInstallStep', 1);
			this.eventHub.$emit('setInternalMainStep', 4);
			this.eventHub.$emit('setMainStep', 3);
			this.$nextTick(function() {
				var index = _findIndex(this.installStepsSelected, {name:'wifi-install-sync'});
				index = (index==-1?1:index+1);
				this.init(true,index);
				this.eventHub.$emit('setInstallStep', index);
			});
		},
	}
}
