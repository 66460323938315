import StringService from 'common/services/string.js'
export default {
  data:
    function () {
      return {
        CONSTANTS:{
          UNVAILABLE: this.$gettext('Available soon'),
          DOWNLOAD_APP_TITLE : StringService.toUppercaseFirstCharacter(this.$gettext('download app'))
        }
      }
  },
  created: function () {
  },
  methods:
  {
    unavailable: function(){
      this.eventHub.$emit('displayAlert',  this.CONSTANTS.UNVAILABLE);
    }
  }
}
