import deviceOnOffInstall1 from './device-on-off-install1.vue';
import deviceOnOffInstall2 from './device-on-off-install2.vue';

export default {
	props: ['homepilot', 'model', 'submodel', 'step'],
	components: {deviceOnOffInstall1, deviceOnOffInstall2},
    data: function () {
        return {
            installSentences:{
              'ale' : {
                'sentences' : {
                  1 : 'Connect the siren to a power outlet or use 1.5V LR14 batteries.',
                  2 : 'Then press the pairing button named "Code".'
                },
                'test' : 'the siren must flash and ring',
                'img' : {
                  1 : require('@/assets/images/device/ale/cl-install-ALE-connection_12200.png')
                }
              },
              'bulb' : {
                'sentences' : {
                  1 : 'Turn off the power of your light fixture or electrical panel',
                  2 : 'Unscrew the lamp (Cold) and place the remote controlled socket by screwing .. Then tighten the lamp on the remote controlled Mini Sleeve',
                  3 : 'Reconnect power to your lighting or electrical panel and then press the button to turn on the lamp socket .. Press the button again to turn off',
                  4 : 'On the plug, press on the coding button for 3s,release... then click Associate.'
                },
                'test' : 'the LED must light up',
								'img' : {
                  1 : require('@/assets/images/device/bulb/bulb.png')
                }
              },
              'ecr' : {
                'sentences' : {
                  1 : 'Turn off the power of your light fixture or electrical panel',
                  2 : 'Connect the 2 wires: Phase (terminal L) and Neutral (terminal N ) of the power supply 230V ~ arrival. The power supply must be direct (without switch). The Ground wire should be connected to the Ground.',
                  3 : 'Connect the 2 wires of the device to be controlled (eg lighting), this device must be in the ON position permanently if a switch is present. Phase on the X terminal and neutral to the N terminal',
                  4 : 'Reset the 230V~',
                  5 : 'On the accessory, press and hold 3 seconds the button coding, the LED will flash for 30 seconds ... then click Associate.'
                },
                'test' : 'the LED must light up',
								'img' : {
                  1 : require('@/assets/images/device/ecr/ECR-100.png'),
                  2 : require('@/assets/images/device/ecr/ECR-100_1.png')
                }
              },
              'frx' : {
                'sentences' : {
                  1 : 'Plug your device to an electrical outlet',
                  2 : 'Connect the device you want to pilot (Light, radio, etc), this device has to be in ON position permanently',
                  3 : 'On the plug, press and maintain the button for 3s : the led should flash slowly'
                },
                'test' : 'the LED must light up',
								'img' : {
                  1 : require('@/assets/images/device/frx/frx.png')
                }
              },
              'mmv' : {
                'sentences' : {
                  1 : 'Turn off the power of your engine or electrical panel',
                  2 : 'Connect the 2 wires: Phase (terminal L) and Neutral (terminal N ) of the power supply 230V ~ arrival. The power supply must be direct (without switch). The Ground wire should be connected to the Ground.',
                  3 : 'Connect the wires of your engine according to your system configuration (see diagrams)',
                  4 : 'Reset the 230V~',
                  5 : 'On the accessory, press and hold 3 seconds the button coding, the LED will flash for 30 seconds ... then click Associate.'
                },
                'test' : '',
								'img' : {
                  1 : require('@/assets/images/device/mmv/MMV-100.png'),
                  2 : require('@/assets/images/device/mmv/MMV-100_1.png')
                }
              },
              'prf' : {
                'sentences' : {
                  1 : 'Plug your device to an electrical outlet',
                  2 : 'Connect the device you want to pilot (Light, radio, etc), this device has to be in ON position permanently',
                  3 : 'On the plug, press and maintain the button for 3s : the led should flash slowly'
                },
                'test' : 'the LED must light up',
								'img' : {
                  1 : require('@/assets/images/device/prf/prf.png')
                }
              },
              'prfx' : {
                'sentences' : {
                  1 : 'Plug your device to an electrical outlet',
                  2 : 'Connect the device you want to pilot (Light, radio, etc), this device has to be in ON position permanently',
                  3 : 'On the plug, press and maintain the button for 3s : the led should flash slowly'
                },
                'test' : 'the LED must light up',
								'img' : {
                  1 : require('@/assets/images/device/prfx/prfx.png')
                }
              },
              'ycb' : {
                'sentences' : {
                  1 : 'On the back, unscrew the hatch. Then, you go to the red coding pad.',
                  2 : 'Check 1 to 5 same as remote control.',
                  3 : 'Then code each outlet by its corresponding letter.',
                  4 : 'Replace the hatch and connect your remote control plug to a powered wall outlet',
                  5 : 'Then click on Associate'
                },
                'test' : 'the LED must light up',
								'img' : {
                  1 : ''
                }
              },
            }
        }
    },
    created: function () {
			this.eventHub.$emit('setStepper', [
				{'number' : 1, 'title' : 'Connection'},
        {'number' : 2, 'title' : 'Test'},
        {'number' : 3, 'title' : 'Notifications'},
				{'number' : 4, 'title' : 'Name'},
				{'number' : 5, 'title' : 'Room'}
			], 1);
    },
	  methods: {
	}
}
