/** 
 * 
 * 
 * */ 

export default class Device{
    static WAIT_STATE_CALLBACK_TIMEOUT = 3000;
    constructor() {
    }

}