import SystemResource from 'common/services/resources/system.js'
import Global from 'common/services/resources/global.js'
import ExternalResource from 'common/services/resources/external.js'
import StringService from 'common/services/string.js'
import store from 'vuex_path/store'

export default {
    props:['brand', 'wizardTitle', 'commonTxt', 'introductory', 'grdpWarning', 'validateButton'],
    data: function () {
        return {
          CONSTANTS:
          {
          },
          commonTxt:'',
          introductory:'',
          grdpWarning:'',
          validateButton:''
        }
    },
    created: function () {
        this.eventHub.$emit('setStepName', 'gdpr');
        this.eventHub.$emit('setDisplayTitle', true, StringService.toUppercaseFirstCharacter(this.$gettext((this.brand.wizard.type=='EXTERNAL'?'add devices':'add a kit'))));
      },
    methods: {
      getBrandSrc: function (brand) {
        return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
      },
      validate: function(){
        if(this.brand.wizard.type=='EXTERNAL' || this.brand.name == 'philips')
        {
          ExternalResource.getExternalAuthorize(this.brand.name);
        }
        else
        {
          this.eventHub.$emit('setMainStep', 3);
        }
      } 
    }
}
