export default {
    data: function () {
        return {
            CONSTANTS:{
                MIDDLE_CONTENT_TXT: this.$gettext('Wait 30 seconds.<br/>Then, hold the reset button down for at least 5 seconds and release: the LED flashes.<br/>Then, click Next.')
            }
        }
    },
    created: function () {
    },
	  methods: {
		  next: function(){
        this.eventHub.$emit('nextInstallStep');
		  },
	}
}
