export default {
	props: 
	{
		steps: {
			type: Array
		},
		submodel:{
			type: Object
		},
	}, 
	data:
		function () {
			return {
				CONSTANTS:
				{
				},
				currentStep:0
			}
	},
	beforeDestroy () {
	},	
	created: function () {
    },
	methods: {
		img: function(img)
		{
			console.log('img',img);
			try
			{
				return require(img);
			}
			catch(e)
			{
				return require('@/assets/images/device/install/generic-connection.png');
			}
		},
		nextStep: function()
		{
			this.currentStep++;
			if(this.currentStep==this.steps.length)
			{
				this.eventHub.$emit('nextInstallStep');
			}
		},
 	}
}