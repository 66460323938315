import { render, staticRenderFns } from "./add-room.vue?vue&type=template&id=18df8e2d&scoped=true&"
import script from "./add-room.js?vue&type=script&lang=js&"
export * from "./add-room.js?vue&type=script&lang=js&"
import style0 from "./add-room.css?vue&type=style&index=0&id=18df8e2d&scoped=true&lang=css&"
import style1 from "./add-room-vuetify.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18df8e2d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard,VCardActions,VCardTitle})
