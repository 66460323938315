import Global from 'common/services/resources/global.js'
export default {
	props:['wizardTitle','brand','externalDevices'],
	components: {},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						LIST_TXT : this.$gettext('Here is the list of your %{s} devices. You can associate each of these devices with a %{appName} room, or click directly on « Done ». You will find all the devices in the settings menu.')
					},
					loading:false,
					listTxt: ''
			}
		},
	created: function () {
		this.init();
	},
	destroyed: function () {
	},
	methods: {
		getBrandSrc: function (brand) {
			return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
		},
		init: function(){
			this.eventHub.$emit('setDisplayPrevious', false);
			this.listTxt=this.$gettextInterpolate(this.CONSTANTS.LIST_TXT, {s: this.brand.manufacturer.name,appName: this.$store.state.variantManager.class.title});
		},
		edit: function(module){
			this.$emit('setSelectedModule',module);
			this.eventHub.$emit('nextWizardStep');
		},
		validate: function(){
			this.eventHub.$emit('closeInstallModal','installModal');
			//this.$router.push('/home/rooms');
		}	
	}
}

