import AlarmResource from 'common/services/resources/alarm.js'
export default {
    data: function () {
        return {
            CONSTANTS:
            {
                ASSOCIATE_ERROR : this.$gettext('Unable to associate the alarm'),
                ASSOCIATE_SUCCESS : this.$gettext('The alarm has been successfully associated'),
                TRY_NUMBER : 8,
                TRY_TIMEOUT : 5000
            },
            diagnosticInterval: {},
            diagnosticStatus: {},
            numberTry: 0,
            wait:
            {
                percent: 0,
                time: 0,
            },
            waiting: false

        }
    },
    beforeDestroy () {
      clearInterval(this.diagnosticInterval);
      clearInterval(this.diagnosticStatus);
    },
    created: function () {
        this.eventHub.$emit('setDisplayPrevious', false);
        this.associate();
        this.diagnosticInterval = setInterval(() => {
            if (this.wait.percent >= 100) {
                this.waiting = true;
                clearInterval(this.diagnosticInterval);
                this.$parent.alarm.uiElts.step3 = this.$gettext('Association in progress');
            }
            this.wait.percent += 6.7;
            this.wait.time += 1;
        }, 1000);
    },
	methods: {
        associate: function(){
            var _this = this;
            var commandId = this.uuidV4();
            var bearerId = this.$parent.homepilot.id;
            var submodel = this.$parent.submodel.name;
            var locationId = this.$parent.$parent.$parent.plant.zones[0].id;
            AlarmResource.associateAlarm(submodel,bearerId,commandId,locationId).then(
                function(response) {
                    _this.$parent.alarm.id = response.data.id;
                    _this.eventHub.$emit('setDeviceId',response.data.id);
                    _this.diagnostic(response.data.id);
                    }).catch(
                    function() {
                    _this.eventHub.$emit('setValidation', false);
                    _this.eventHub.$emit('setInternalMainStep', 6);
                });
            },
        diagnostic : function(id){
            this.diagnosticStatus = setInterval(() => {
                if (this.numberTry == this.CONSTANTS.TRY_NUMBER) {
                    this.waiting = false;
                    clearInterval(this.diagnosticStatus);
                    this.eventHub.$emit('setValidation', false);
                    this.eventHub.$emit('setInternalMainStep', 6);
                }
                else{
                    this.associateStatus(id);
                    this.numberTry +=1;
                }
            }, this.CONSTANTS.TRY_TIMEOUT);
        },
        associateStatus: function(id){
            var _this = this;
            var model = this.$parent.model;
            AlarmResource.diagnostic('modules', 'id' ,id).then(
                function(response) {
                    if(response.data[model.name+'s'][0].associateStatus  == 'ASSOCIATED'){
                    _this.waiting = false;
                    clearInterval(_this.diagnosticStatus);
                    _this.eventHub.$emit('nextInstallStep');
                    }
                }).catch(
            function() {
            });
        },
	}
}
