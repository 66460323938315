import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'
import LogService from 'common/services/log.js'
import UserResource from 'common/services/resources/user.js'
export default {
    
     CONSTANTS:
     {
         TAG: 'EXTERNAL_RESOURCE'
     },
     /* ACCOUNT CLASS */
     Account: function()
     {
        return { 
            account: 
            { 
                name: '',
                authorization: '' 
            }, 
            external: 
            { 
                name: '', 
                title: '', 
                endpoint: '', 
                compatibility: ''
            }, 
            active: false 
        }
     },
     /* SERVICE METHODS */
     getAllAccount: function (source) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'external/account/all',
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        cancelToken: source.token
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        if(error.response && error.response.status == 404)
                        {
                            return resolve([]);
                        }
                        return reject(error);
                    });
            }
        );
    },
    getExternalDisplay: function (externalName) {
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'external/account/'+externalName+'/service/display',
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },

    getExternalAuthorize: function (externalName) {
        //Explanation : CL-755
        var r = JSON.parse(StorageService.getValue(localStorage, 'region-ws'));
        var url = (externalName == 'legrand_home_plus_control' && r.region != "dev" ?Global.WS_HOST:Global.getBackendUrl());
        window.open(url+'external/account/'+externalName+'/service/authorize?authorization='+StorageService.getValue(localStorage, 'authorization')+'&app='+StorageService.getValue(localStorage, 'app'),'_self');
    },
    
    add: function (login, pwd, externalName) {
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+'external/account/'+externalName+'/service/login',
                    {
                            name: login,
                            login: login,
                            password: pwd
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    delete: function (externalName) {
        return new Promise(
            function(resolve, reject){
                axios.delete(Global.getBackendUrl()+'external/account/'+externalName,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    synchronize: function (externalName) {
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+'external/account/'+externalName+'/service/synchronize',
                    {},
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    accountRefresh: function (externalName, user) {
        var data = {};
        if(user && user.email && user.email!=UserResource.getUserName())
        {
            data.sharingUserEmail = user.email;
        }
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+'external/account/'+externalName+'/service/token/refresh',
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },

    getUserExternalAccounts: function (externalAccounts, username) {
        var ea = undefined;
        LogService.consoleLogTag(this.CONSTANTS.TAG,'getUserExternalAccounts','externalAccounts',externalAccounts);
        LogService.consoleLogTag(this.CONSTANTS.TAG,'getUserExternalAccounts','username',username);
        if(externalAccounts.sharedExternalAccounts)
        {
            ea = _find(externalAccounts.sharedExternalAccounts, {'user':{'email':username}});
            if(ea)
            {
                ea = ea.externalAccounts;
            }
        }
        LogService.consoleLogTag(this.CONSTANTS.TAG,'getUserExternalAccounts','ea',ea);
        if(!ea && externalAccounts.externalAccounts)
        {
            ea = externalAccounts.externalAccounts;
        }
        return (ea?ea:[]);
    },

    getExternalAccountsByModule: function(moduleId, sharesLocations, externalAccounts) {
        for(var i = 0; i<sharesLocations.length; i++)
        {
            if(sharesLocations[i].plants)
            {
                for(var j = 0; j<sharesLocations[i].plants.length; j++)
                {
                    if(sharesLocations[i].plants[j].modules)
                    {
                        var module = _find(sharesLocations[i].plants[j].modules, {'id':moduleId});
                        if(module)
                        {
                            return this.getUserExternalAccounts(externalAccounts,sharesLocations[i].user.email);
                        }
                    }
                    if(sharesLocations[i].plants[j].zones){
                        for(var k = 0; k<sharesLocations[i].plants[j].zones.length; k++)
                        {
                            var module = _find(sharesLocations[i].plants[j].zones[k].modules, {'id':moduleId});
                            if(module)
                            {
                                return this.getUserExternalAccounts(externalAccounts,sharesLocations[i].user.email);
                            }
                        }
                    }
                }
            }
        }
        return externalAccounts.externalAccounts;
    },
}
