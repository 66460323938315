export default {
    props:
        {
            type: {
                type: String,
                default: 'success'
            }
        },

    data:
        function () {
                    return {
                                CONSTANTS:
                                {
                                },
                    }
                },

}
