/**
 *  Manager who manages the processing of payloads (mqtt, udp, etc.)
 * 
 */
import EventService from 'common/services/event.js'

const CAPABILITIES_RUN_SERVICE_DOORBELL = ['ring','call'];


export default {

    rebuildEliotPayloadElt: function(payload,allDevices,eventHub) {
        let payloadModules = [];
        let rebuild = false;
        if(payload.modules && Array.isArray(payload.modules))
        {
            payload.modules.forEach(function(m, index) {
                if(m.device)
                {
                    if(
                        m.device.status 
                        && m.device.status.length > 0)
                    {
                        for(var i = 0;i<m.device.status.length;i++)
                        {
                            let payloadDeviceStatus = m.device.status[i];
                            if(['on','off'].indexOf(payloadDeviceStatus.value)!=-1)
                            {
                                let module = _find(allDevices, function(d) 
                                {
                                    return (d.device && [d.device.externalLocationId,d.id].indexOf(m.device.bearerId)!=-1 && parseInt(payloadDeviceStatus.index)==d.plot);
                                });
                                if(module && module.device && module.device.status)
                                {
                                    let status = _find(module.device.status, {'capability':'status'});
                                    let commandDevice = _find(eventHub.commandDeviceList, {'uid':payload.uid,'deviceUid':module.id});
                                    if((status && status.value != payloadDeviceStatus.value)|| (commandDevice && commandDevice.index==module.plot))
                                    {
                                        let payloadModule = 
                                        {
                                            'id': module.id,
                                            'device': 
                                            {
                                                'status':[payloadDeviceStatus]
                                            }
                                        }

                                        payloadModules.push(payloadModule);
                                    }
                                    rebuild = true;
                                }

                            }
                        }
                    }
                }
                if(!rebuild)
                {
                    payloadModules.push(payload.modules[index]);
                }
            });
            payload.modules = payloadModules;
        }
    },


    runServiceLinkedToPayload: function(eventHub,payload,allDevices,services,callbackEvent,inAppCheck) {
        if(!inAppCheck)return false;
        try {
            payload = (typeof payload == 'string' ? JSON.parse(payload) : payload);
          } catch (e) {
            payload = {};
        }
        if(payload.modules)
        {
            for(var i = 0;i<payload.modules.length;i++)
            {
                let m = payload.modules[i];
                let device = (m.devicerf?m.devicerf:m.device);
                if(device)
                {
                    if(
                        device.status 
                        && device.status.length > 0
                        && _find(device.status, function(s) { return CAPABILITIES_RUN_SERVICE_DOORBELL.indexOf(s.capability)!=-1 })
                        )
                    {
                        let deviceService = _find(services, function(s) { 
                            return (s.service
                                    && s.service.model == 'access_control'
                                    && s.service.infos
                                    && s.service.infos.triggers
                                    && s.service.infos.triggers.length>0
                                    && s.service.infos.triggers[0].infos
                                    && s.service.infos.triggers[0].infos.id == m.id)
                                });
                        if(deviceService
                            && deviceService.service.infos.actions
                            && deviceService.service.infos.actions.length>0
                            && deviceService.service.infos.actions[0].infos
                            )
                        {
                            let module = _find(allDevices, {'id':deviceService.service.infos.actions[0].infos.id});
                            if(module)
                            {
                                EventService.executeOnCall(module,eventHub,payload,callbackEvent);
                                return true;
                            }
                        }
                        //
                    }
                }
            };
        }
        return false;
    },



}