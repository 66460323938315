import { render, staticRenderFns } from "./smart_camera.vue?vue&type=template&id=c3ba5f1c&scoped=true&"
import script from "./smart_camera.js?vue&type=script&lang=js&"
export * from "./smart_camera.js?vue&type=script&lang=js&"
import style0 from "./smart_camera.css?vue&type=style&index=0&id=c3ba5f1c&scoped=true&lang=css&"
import style1 from "./smart_camera_swiper.css?vue&type=style&index=1&lang=css&"
import style2 from "./smart_camera-vuetify.css?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c3ba5f1c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VBadge,VBtn,VDialog,VProgressCircular,VRadio,VRadioGroup,VSwitch})
