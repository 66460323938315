import GlobalResource from 'common/services/resources/global.js'
import StorageService from 'common/services/storage.js'
import LanguageService from 'common/services/language.js'
import User from 'common/classes/User.js';
import axios from 'axios'
import store from 'vuex_path/store'

window._find = require('lodash/find');

function getUserLanguage()
{
    var userLang = (navigator.language || (navigator.languages && navigator.languages[0]));
    userLang = userLang.replace(/_/g,'-');
    var tab = userLang.split("-");
    userLang = tab[0];
    return userLang;
}

export default {
    waiting: 
    {
        activate: false,
        subscribe: false,
        login: false,
        generateNewPassword: false

    },    
     activate: function (hash,region) {
        var _this = this;
        
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.activate == true)return false;
                axios.post(LanguageService.getBackendUrlByRegion(region,GlobalResource.getBackendUrl())+'user/account/activation',
                    {
                        'hash': hash,
                        'language': getUserLanguage(),
                        'app': StorageService.getValue(localStorage, 'app')
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then(function (response) {
                        _this.waiting.activate = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.activate = false;
                        return reject(error);
                    });
            }
        );
    },
    subscribe: function (user, captcha,region) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.subscribe == true)return false;
                axios.post(LanguageService.getBackendUrlByRegion(region.region,GlobalResource.getBackendUrl())+'user/account',
                    {
                        'login': user.username,
                        'password': user.password,
                        'firstname': user.firstname,
                        'lastname': user.lastname,
                        'captcha': captcha,
                        'app': StorageService.getValue(localStorage, 'app'), 
                        'language': getUserLanguage()
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then(function (response) {
                        _this.waiting.subscribe = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.subscribe = false;
                        return reject(error);
                    });
            }
        );
    },
    login: function (user,region) {
        let _this = this;
        let body = {};
        let headers = {
            'Content-Type': 'multipart/form-data'
        };
        if(user.authorization)
        {
            headers.authorization = user.authorization;
        }
        else if(user.username && user.password)
        {
            body = {
                'login': user.username,
                'password': user.password,
                'clientId': '1234567890ABCDEF',
                'platform': 'web',
                'appName': 'comfortlife',
                'environment': 'DEV'
            };
        }
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.login == true)return false;
                axios.post(LanguageService.getBackendUrlByRegion(region.region,GlobalResource.getBackendUrl())+'user/account/login',
                    body,
                    {
                        headers: headers,
                    }).then(function (response) {
                        _this.waiting.login = false;
                        var datas = response.data;
                        var s = (user.rememberMe?localStorage:sessionStorage);

                        store.state.authorization = datas.authorization;
                        StorageService.persist(s, 'authorization', datas.authorization);

                        store.state.plantId = datas.plantId;
                        store.state.selectedPlantId = datas.plantId;
                        store.state.bMainAccount = true;

                        StorageService.persist(s, 'plantId', datas.plantId);

                        store.state.username = user.username;
                        StorageService.persist(s, 'username', user.username);

                        store.state.mode = datas.mode;
                        StorageService.persist(s, 'mode', datas.mode);

                        store.state.role = datas.roles[0];
                        StorageService.persist(s, 'role', datas.roles[0]);

                        store.state.dashboardId = datas.dashboardId;
                        StorageService.persist(s, 'dashboardId', datas.dashboardId);

                        store.state.familyId = datas.familyId;
                        StorageService.persist(s, 'familyId', datas.familyId);

                        store.state.mqtt = JSON.stringify(datas.mqtt);
                        StorageService.persist(s, 'mqtt', JSON.stringify(datas.mqtt));

                        store.state.availableSmsCount = (datas.smsAccounts && datas.smsAccounts.length>0?datas.smsAccounts[0].availableSmsCount:0);
                        StorageService.persist(s, 'availableSmsCount', (datas.smsAccounts && datas.smsAccounts.length>0?datas.smsAccounts[0].availableSmsCount:0));
                        
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.login = false;
                        return reject(error);
                    });
            }
        );
    },
    getAssocCode: function () {
        var authorization = StorageService.getValue(localStorage, 'authorization');
        return new Promise(
            function(resolve, reject){
                axios.get(GlobalResource.getBackendUrl()+'user/account/associationToken/?app='+StorageService.getValue(localStorage, 'app'),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': authorization
                        },
                        timeout: GlobalResource.WS_TIMEOUT
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },   
    setAssocCode: function (associationToken) {
        var authorization = StorageService.getValue(localStorage, 'authorization');
        return new Promise(
            function(resolve, reject){
                axios.post(GlobalResource.getBackendUrl()+'user/account/associationToken/',
                    {
                        'associationToken': associationToken,
                        'app': StorageService.getValue(localStorage, 'app')
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': authorization
                        },
                        timeout: GlobalResource.WS_TIMEOUT
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },  
    getResourcesAccounts: function () {
        var authorization = StorageService.getValue(localStorage, 'authorization');
        return new Promise(
            function(resolve, reject){
                axios.get(GlobalResource.getBackendUrl()+'user/account/resources',
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': authorization
                        }
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
     generateNewPassword: function (hash,region) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.generateNewPassword == true)return false;
                axios.put(LanguageService.getBackendUrlByRegion(region,GlobalResource.getBackendUrl())+'user/account/password',
                    {
                        'hash': hash
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then(function (response) {
                        _this.waiting.generateNewPassword = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.generateNewPassword = false;
                        return reject(error);
                    });
            }
        );
    },
     sendMailForNewPassword: function (email, captcha, region) {
        return new Promise(
            function(resolve, reject){
                axios.get(LanguageService.getBackendUrlByRegion(region.region,GlobalResource.getBackendUrl())+'user/account/password/?email='+email+'&captchaId='+captcha.id+'&captchaCode='+captcha.code+'&language='+getUserLanguage()+'&app='+StorageService.getValue(localStorage, 'app'),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },

    setUser: function(user) {
      user.username = StorageService.getValue(localStorage, 'username');
      user.resourcesCount = this.getResourcesCount();
      user.isRegular = this.isRegular();
      user.authorization = StorageService.getValue(localStorage, 'authorization');
    },

	getUserName: function() {
        return StorageService.getValue(localStorage, 'username');
    },

	getMode: function() {
        return StorageService.getValue(localStorage, 'mode');
    },

	getMqtt: function() {
        return JSON.parse(StorageService.getValue(localStorage, 'mqtt'));
    },
    
	getResourcesCount: function() {
        var resourcesCount = StorageService.getValue(localStorage, 'resourcesCount');
        return resourcesCount;
    },

	isRegular: function() {
        return ((StorageService.getValue(localStorage, 'mode')==null)
                ||(StorageService.getValue(localStorage, 'mode')=='undefined')
                ||(StorageService.getValue(localStorage, 'mode')=='REGULAR')
                );
    }

}
