/** 
 * 
 * Camera :  Abstract Class
 * 
 * */ 

const CAMERA_MODELS = ['camera', 'smart_camera', 'vdp', 'battery_vdp', 't21','oskkio-1'];

const CUSTOM_CAMERA_MODELS = ['smart_camera', 'vdp', 'battery_vdp', 't21','oskkio-1'];

export default class Camera{

    constructor(eventHub,module) {
        if (this.constructor === Camera) {
          throw new TypeError('Abstract class "Camera" cannot be instantiated directly');
        }
        this.eventHub = eventHub;
        this.module = module;
        var _this = this;
        
        this.eventHub.$off('show_'+this.module.id);
        this.eventHub.$on('show_'+this.module.id, 
        function(autoplay, noWakeup)
        {
          autoplay = (autoplay?autoplay:false);
          noWakeup = (noWakeup?noWakeup:false);
          _this.show('sd', autoplay, noWakeup);
        });
      }

    static get CAMERA_MODELS() {
      return CAMERA_MODELS;
    }

    static get CUSTOM_CAMERA_MODELS() {
      return CUSTOM_CAMERA_MODELS;
    }

    show() {
      throw new Error('You must implement this function');
    }

}