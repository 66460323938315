import Global from './global.js'
import StorageService from '../storage.js'
import ArrayService from '../array.js'
import StringService from '../string.js'
import MacService from '../mac.js'

import store from 'vuex_path/store'
import axios from 'axios'

export default {
    waiting: 
    {
        associateTo : false,
        validate : false,
        associate : false
    },
    associateTo: function (model, system, submodel, bearerId, commandId, locationId, brand, deviceObj, eventHub, source) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        var body = {
            'bearerId' : bearerId,
            'submodel' : submodel
        };
        if(commandId)
            body.commandId = commandId;
        if(locationId)
            body.locationId = locationId; 
        if(brand)
            body.brand = brand.name;
        if(deviceObj.identifier) 
        {
            body.identifier = deviceObj.identifier;
        }
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.associateTo == true)return false;
                axios.post(Global.getBackendUrl()+model+'/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/association',
                body,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT,
                        cancelToken: source.token
                    }).then(function (response) {
                        _this.waiting.associateTo = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.associateTo = false;
                        if(error.response){
                            if(error.response.status==402)
                            {
                                eventHub.$emit('displayAlertKey', 'unauthorized');
                                return reject('associationFailed');
                            }
                            else if(error.response.status==403)
                            {
                                eventHub.$emit('displayAlertKey', 'cannot_installed');
                                return reject('associationFailed');
                            }
                        }
                        return reject(error);
                    });
            }
        );
    },
    associateStatus: function(model, system, id) {
        return new Promise(
          function(resolve, reject){
              axios.get(Global.getBackendUrl()+model+'/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+id+'/diagnostic',
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                      timeout: Global.WS_TIMEOUT
                  }).then(function (response) {
                      return resolve(response);
                  })
                  .catch(function (error) {
                      return reject(error);
                  });
          }
        );
    },
    getUpgrade: function(model, system, id, currentVersion) {
        return new Promise(
          function(resolve, reject){
              axios.get(Global.getBackendUrl()+model+'/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+id+'/upgrade?currentVersion='+currentVersion,
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                      timeout: Global.WS_TIMEOUT
                  }).then(function (response) {
                      return resolve(response);
                  })
                  .catch(function (error) {
                      return reject(error);
                  });
          }
        );
    },
    associate: function (model, system, json, source, eventHub) {
        var _this = this;    
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.associate == true)return false;
                axios.post(Global.getBackendUrl()+model+'/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/association',
                    json,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT,
                        cancelToken: source.token
                    }).then(function (response) {
                        _this.waiting.associate = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.associate = false;
                        if(error.response){
                            if(error.response.status==402)
                            {
                                eventHub.$emit('displayAlertKey', 'unauthorized');
                                return reject('associationFailed');
                            }
                            else if(error.response.status==403)
                            {
                                eventHub.$emit('displayAlertKey', 'cannot_installed');
                                return reject('associationFailed');
                            }
                        }
                        return reject(error);
                    });
            }
        );
    },

    getAllStatus: function (model, system) {
        if(typeof model !== 'string' ||  typeof system !== 'string' )return false;
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+model+'/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId'),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        }
                    }).then(function (response) {
                        if(response.data && response.data[model+'s'])
                        {
                            return resolve(response.data[model+'s']);
                        }
                        return resolve([]);
                    })
                    .catch(function () {
                        return resolve([]);
                    });
            }
        );
    },

    getStatus: function (device, key, value) {
        return new Promise(
            function(resolve, reject){
                var model = device.model;
                var system = device.systems[0];
                axios.get(Global.getBackendUrl()+model+'/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/'+key+'/value/'+value,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        }
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    setStatus: function (json, device, value) {
        var model = device.model;
        var system = device.systems[0];
        var path = model+'/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+value;
        var _this = this;
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+path,
                    json,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    validate: function (model,system,commandId,value) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.validate == true)return false;
                axios.post(Global.getBackendUrl()+model+'/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+value+'/validation',
                    {
                        'commandId' : commandId
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.validate = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.validate = false;
                        return reject(error);
                    });
            }
        );
    },
    registration: function (model,system,associationToken, submodel) {

        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+model+'/'+system+'/registration',
                    {
                        'mac' : MacService.generate(),
                        'ip': '127.0.0.1',
                        'submodel': submodel,
                        'associationToken':associationToken,
                        'data' : {}
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return resolve(false);
                    });
            }
        );
    },
    getModule : function(moduleId, devices, subDevices)
    {
        var m = 
        {
            module : undefined,
            moduleParent : undefined
        }
        for(var i = 0; i< devices.length;  i++)
        {
            var d = devices[i];
            if(d.id == moduleId || (d.device && d.device.remoteId == moduleId) || (d.idMulti == moduleId))
            {
                m.module = d;
                break;
            }        
        }
        if(m.module==undefined)
        {
            for(var i = 0; i < subDevices.length ; i++ )
            {
                for(var j = 0; j < subDevices[i].modules.length ; j++ )
                {
                    var subDevice = subDevices[i].modules[j];
                    if(subDevice.id==moduleId)
                    {
                        m.module = subDevice;
                        m.moduleParent = subDevices[i].parent;
                        return m;
                    }
                }
            }
        }
        return m;
    },

    moveModuleIntoZone : function(module, plant, zoneTo)
    {
        var zonesFrom = _find(plant.zones, { 'id': module.zoneId });
        var modules = _reject(zonesFrom.modules, { 'id': module.id });
        zonesFrom.modules = modules;
        if(zoneTo.modules==undefined)zoneTo.modules=[];
        zoneTo.modules.push(module);
    },

    getCapabilities : function(device)
    {
        let capabilities = [];
   
        if(device.subsockets && device.subsockets.length>0 && device.subdeviceModels && device.subdeviceModels.length>0)
        {
            device.subsockets.forEach(function(ds) {
                let dsm = _find(device.subdeviceModels, {'name': ds.name});
                if(dsm)
                {
                    capabilities = capabilities.concat(dsm.capabilities);
                }
            });
            if(capabilities.length==0)
            {
                capabilities = capabilities.concat(device.subdeviceModels[0].capabilities);
            }
        }
        else
        {
            let  model = (device.modelObj?device.modelObj:_find(store.state.models, {'name': device.model}));
            capabilities = capabilities.concat(model ? model.capabilities : []);
        }
        return capabilities;
    },

    groupDevicesByCapability : function(devices)
    {
        var devicesByCapability = {};
        devices.forEach(function(d) {
            if(d.device)
            {
                if(!_isEmpty(d.device.capabilities))
                {
                    d.device.capabilities.forEach(function(c) {
                        if(devicesByCapability[c.capability]==undefined)
                            devicesByCapability[c.capability] = [];
                        devicesByCapability[c.capability].push(d);
                    });
                }
                // RELAYS
                else if(d.device.relays)
                {
                    if(devicesByCapability['trigger']==undefined)
                        devicesByCapability['trigger'] = [];
                    devicesByCapability['trigger'].push(d);
                }
                else if(d.device.subdeviceModels)
                {
                    d.device.subdeviceModels[0].capabilities.forEach(function(c) {
                        if(devicesByCapability[c.capability]==undefined)
                            devicesByCapability[c.capability] = [];
                        devicesByCapability[c.capability].push(d);
                    });
                }
            }
        });
        return devicesByCapability;
    },

    getMergeDevicesByCapabilityWithCapabilities : function(devices,capabilities)
    {
        let devicesByCapabilityWithCapabilities = [];
        for(let x in devices)
        {
            if(capabilities.indexOf(x)!=-1)
            {
                devicesByCapabilityWithCapabilities = ArrayService.merge(devicesByCapabilityWithCapabilities,devices[x],'id');
            }
        }
        return devicesByCapabilityWithCapabilities;
    },

    getDevicesBySystem : function(devices,system)
    {
        return _filter(devices, function(d) {
            return (d.device && d.device.systems && d.device.systems.length > 0 && d.device.systems[0] == system)
        });
    },

    groupScenariosByCapability : function(services)
    {
        var scenariosByCapability = {};
        services.forEach(function(s) {
            if(s.service)
            {
                if(!_isEmpty(s.service.status))
                {
                    s.service.status.forEach(function(c) {
                        if(scenariosByCapability[c.capability]==undefined)
                            scenariosByCapability[c.capability] = [];
                         scenariosByCapability[c.capability].push(s);
                    });
                }
            }
        });
        return scenariosByCapability;
    },
    /**
     * 
     * @param {*} devicesByCapability 
     * @param {*} capability 
     * @param {*} elementNameArray : devices or rooms name array
     * 
     */
    findElementsByCapabilityAndName : function(devicesByCapability, capability, elementNameArray)
    {
        var devicesCapability = devicesByCapability[capability];
        var devices = [];
        var fraction = 0.91;
        if(devicesCapability)
        {
            if(elementNameArray.length>0)
            {
                for(var i = 0; i <elementNameArray.length; i++)
                {
                    var elementName = elementNameArray[i];
                    for(var j = 0; j < devicesCapability.length; j++)
                    {
                        var deviceCapabilityName = devicesCapability[j].name;
                        var deviceCapabilityZoneName = devicesCapability[j].zoneName;
                        //Checks whether an item matches a device name or room name 
                        if(ArrayService.find(devices,'id',devicesCapability[j].id)==undefined)
                        {
                            var accuratelyCompareName = StringService.accuratelyCompare(elementName,deviceCapabilityName);
                            var accuratelyCompareZoneName = StringService.accuratelyCompare(elementName,deviceCapabilityZoneName);
                            if(accuratelyCompareName.test)
                            {
                                if(accuratelyCompareName.fraction>fraction)
                                {
                                    devices = [];
                                    fraction = accuratelyCompareName.fraction;
                                }
                                if(accuratelyCompareName.fraction==fraction)
                                {
                                    devicesCapability[j].findType = 'device';
                                    devices.push(devicesCapability[j]);
                                }
                            }
                            else if(accuratelyCompareZoneName.test)
                            {
                                if(accuratelyCompareZoneName.fraction>fraction)
                                {
                                    devices = [];
                                    fraction = accuratelyCompareZoneName.fraction;
                                }
                                if(accuratelyCompareZoneName.fraction==fraction)
                                {
                                    devicesCapability[j].findType = 'group';
                                    devices.push(devicesCapability[j]);
                                }
                            }
                        }
                    }
                }
            }
            else
            {
                devices = devices.concat(devicesCapability);
            }
        }
        return devices;
    },

    filterDevicesInRooms : function(rooms,moduleType,verifyCapability,unavailableModelsArray,verifyModels)
    {
        unavailableModelsArray = unavailableModelsArray || [];
        var filteredRooms = new Array();
        var _this = this;
        rooms.forEach(function(room,index) {
            if(room.modules && room.modules.length > 0)
            {
                var modules = new Array();
                console.log('room.modules',room.modules);
                for(var j=0; j < room.modules.length; j++)
                {    
                    var m = room.modules[j];
                    if(moduleType == 'device'){
                        if(m.device && unavailableModelsArray.indexOf(m.device.model)==-1 ){
                            var foundCapability = false;
                            //If the model device is a allowed model 
                            if(verifyModels && verifyModels.indexOf(m.device.model)!=-1)
                            {
                                modules.push(room.modules[j]);
                            }
                            else
                            {
                                if(m.device.capabilities){
                                    if(_findIndex(m.device.capabilities, verifyCapability) != -1)
                                    {
                                        foundCapability = true;
                                        modules.push(room.modules[j]);
                                    }
                                }
                                if(!foundCapability && m.device.subdeviceModels && _this.findCapabilityInSubdeviceModels(m.device.subdeviceModels,verifyCapability,m.device.submodel))
                                {
                                    modules.push(room.modules[j]);
                                }
                            }
                        }
                    }
                    else{
                        if(m.service){
                            modules.push(m);
                        }
                    }
                }
                if(modules.length>0)
                {
                    var room = {
                        name: room.name,
                        modules: modules
                    }
                    filteredRooms.push(room);
                }
            }
        });
        console.log('filterDevicesInRooms',filteredRooms);
        return filteredRooms;
    },

    findCapabilityInSubdeviceModels: function (subdeviceModels,verifyCapability,submodel) 
    {
        for (var i = 0; i < subdeviceModels.length; i++) {
            var sd = subdeviceModels[i];
            if(sd.capabilities){
                for(var j=0; j < sd.capabilities.length; j++){
                    if(sd.capabilities[i][verifyCapability] == true)
                    {
                        return true;
                    }
                }
            }
            if(sd.subdeviceModels)
            {
                var found = this.findCapabilityInSubdeviceModels(sd.subdeviceModels,verifyCapability);
                if (found) return found;
            }
        }
        return false;
    },
}
