export default {
    data: function () {
        return {
            CONSTANTS:
            {
                'TXT_1' : this.$gettext('Before proceeding, go near your alarm to do manipulations.<br /><br />Then, when you\'re ready, click Next')
            },
            loading:false
        }
    },
    created: function () {
    },
	methods: {
        next: function(){
            this.loading = true;
            this.eventHub.$emit('nextInstallStep');
        },
	}
}
