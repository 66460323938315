export default {
	props: 
	{
		steps: {
			type: Array
		},
		submodel:{
			type: Object
		},
	},
	data:
		function () {
			return {
				CONSTANTS:
				{
				},
				currentStep:0
			}
	},
	beforeDestroy () {
	},	
	created: function () {
    },
	methods: {
		nextStep: function()
		{
			this.currentStep++;
			if(this.currentStep==this.steps.length)
			{
				this.eventHub.$emit('nextInstallStep');
			}
		},
 	}
}