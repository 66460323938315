import DeviceResource from 'common/services/resources/device.js'
import RoomsSelect from 'common/components/rooms-select/rooms-select.vue'
var stringify = require('json-stringify-safe');
let searchDevice = function (serviceArraySearch,deviceArraySearch)
{
	let device = undefined;
	if(serviceArraySearch && serviceArraySearch.length>0)
	{
		device =_find(deviceArraySearch, { 'id': (serviceArraySearch[0].infos?serviceArraySearch[0].infos.id:'')});
	}
	return device;
}

export default {

	props: {
		service:{
			type: Object,
			default:{}
		},
		triggers:{
			type: Array,
			default:[]
		},
		actions:{
			type: Array,
			default:[]
		},
		devices:{
			type: Array,
		},
		step:{
			type: Number,
		},
		devicesByCapability:{
			type: Array,
			default:[]
		},
		plant:{
			type: Object,
			default:{}
		},
		user:{
			type: Object,
			default:{}
		},
		defaultRooms:{
			type: Array,
			default:[]
		},
	},
	components: {RoomsSelect},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						TITLE: this.$gettext('Configure your doorbell kit'),
						ADD_DOORBELL_ALERT : this.$gettext('Please add a doorbell'),
						ADD_CAMERA_ALERT : this.$gettext('Please add a camera'),
						ROOMS_TITLE : _upperFirst(this.$gettext('rooms')),
						INPUT_ERROR : this.$gettext('Please enter a name for your kit'),
						ROOM_ERROR : this.$gettext('Please select a room'),
					},
					selectedTriggers: [],
					selectedActions: [],
					selectedTrigger: undefined,
					selectedAction: undefined,
					doorbellsExpansionList: 
					{
						title: this.$gettext('Doorbells'),
						active: false,
						onClick:(d)=> {
							d.selected = !d.selected;
						},
						list: 
						[
							{
								title: this.$gettext('Add a new device'),
								icon:'cl-icon cl-icon-plus',
								onClick: ()=> {
								}	
							}
						]
					},
					camerasExpansionList: 
					{
						title: this.$gettext('Cameras'),
						active: false,
						onClick:(d)=> {
							d.selected = !d.selected;
						},
						list: 
						[
							{
								title: this.$gettext('Add a new device'),
								icon:'cl-icon cl-icon-plus',
								onClick: ()=> {
								}	
							}
						]
					},
					name: '',
			}
		},
	created: function () {
		this.init();
	},
	computed: {
	},
	watch: {
		devicesByCapability: function (val, oldVal) {
			this.selectedTriggers = JSON.parse(stringify(_orderBy(DeviceResource.getMergeDevicesByCapabilityWithCapabilities(this.devicesByCapability,['call','ring']), function(d) { return d.name.toLowerCase()}, ['asc'])));
				
		},
		devices: function (val, oldVal) {
			this.selectedActions = JSON.parse(stringify(_orderBy(DeviceResource.getDevicesBySystem(this.devices,'surveillance'), function(d) { return d.name.toLowerCase()}, ['asc'])));
		},
	},
	destroyed: function () {
	}, 	
	methods: {
		init: function(){
			if(this.service.name)
			{
				this.name = this.service.name;
			}
			this.initElements();
			if(this.service.infos)
			{
				this.selectedTrigger = searchDevice(this.service.infos.triggers,this.selectedTriggers);
				this.selectedAction =searchDevice(this.service.infos.actions,this.selectedActions);
			}
		},

		initElements : function(){
			this.selectedTriggers = JSON.parse(stringify(_orderBy(DeviceResource.getMergeDevicesByCapabilityWithCapabilities(this.devicesByCapability,['call','ring']), function(d) { return d.name.toLowerCase()}, ['asc'])));
			this.selectedActions = JSON.parse(stringify(_orderBy(DeviceResource.getDevicesBySystem(this.devices,'surveillance'), function(d) { return d.name.toLowerCase()}, ['asc'])));
		},

        onClickRoom : function(zone){
			this.service.locationId = zone.id;
		},

		validate: function(){
			if(this.name=='')
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.INPUT_ERROR);
				return false;
			}
			this.service.name = this.name;

			if(this.selectedTrigger==undefined)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.ADD_DOORBELL_ALERT);
				return false;
			}
			if(this.selectedAction==undefined)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.ADD_CAMERA_ALERT);
				return false;
			}
			if(this.service.locationId=='')
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.ROOM_ERROR);
				return false;
			}
			this.service.setTriggers(this.selectedTrigger);
			this.service.setActions(this.selectedAction);
			this.eventHub.$emit('addEditServiceWizard');
		}
  	}
}