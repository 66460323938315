export default class {
    constructor(name, locationId, notificationLevel, infos) {
        this.name = name || '';
        this.locationId = locationId || '';
        this.notificationLevel = notificationLevel || 'INFO';
        this.infos = infos || {};
    }
    getName()
    {
        return this.name;
    }
    getLocationId()
    {
        return this.locationId;
    }  
    getInfos()
    {
        return this.infos;
    } 
    getNotificationLevel()
    {
        return this.notificationLevel;
    }  
    getTriggers()
    {
        return this.infos.triggers;
    }
    getActions()
    {
        return this.infos.actions;
    }
    setAttributs(name, locationId, notificationLevel, alert)
    {
      this.name = name;
      this.locationId = locationId;
      this.notificationLevel = notificationLevel;
      this.alert = alert;
    }
    
    checkElementExistInNextArray(arrayElts,index,serviceElt,device)
    {
        var nextIndex = index+1;
        if(nextIndex<arrayElts.length)
        {
            if(device.device.subdeviceModels)
              var indexInOtherElements = _findIndex(arrayElts[nextIndex].devices, function(d){return d.switch_id == device.switch_id && d.status == device.status;});
            else
              var indexInOtherElements = _findIndex(arrayElts[nextIndex].devices, {'id': device.id});
            if(indexInOtherElements!=-1)
            {
                serviceElt.forStates.push(arrayElts[index+1].capability);
                arrayElts[index+1].devices.splice(indexInOtherElements,1);
            }
        }
    } 
    checkNotificationExistInNextArray(arrayElts,index,serviceElt,elt,type)
    {
        var nextIndex = index+1;
        if(nextIndex<arrayElts.length)
        {
            var indexInOtherElements = _findIndex(arrayElts[nextIndex][type], function(o) { return  JSON.stringify(o) ==  JSON.stringify(elt)})
            if(indexInOtherElements!=-1)
            {
              serviceElt.forStates.push(arrayElts[index+1].capability);
              arrayElts[index+1][type].splice(indexInOtherElements,1);
            }
        }
    }   
}

/*{
    "name": "My Service",
    "type": "string",
    "locationId": "1234567890ABCDEF",
    "infos": {
      "actions": [
        {
          "type": "command",
          "forStates": [
            {
              "capability": "protection",
              "value": "arm"
            }
          ],
          "infos": {
            "capability": "string",
            "value": "string",
            "moduleId": "string"
          }
        }
      ],
      "triggers": [
        {
          "type": "status",
          "forStates": [
            {
              "capability": "protection",
              "value": "arm"
            }
          ],
          "infos": {
            "capability": "string",
            "value": "string",
            "operation": "EQUAL",
            "moduleId": "string"
          }
        }
      ]
    }
  }*/
