import { render, staticRenderFns } from "./al-t_4.vue?vue&type=template&id=04ab3334&scoped=true&"
import script from "./al-t_4.js?vue&type=script&lang=js&"
export * from "./al-t_4.js?vue&type=script&lang=js&"
import style0 from "./al-t_4.css?vue&type=style&index=0&id=04ab3334&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "04ab3334",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle})
