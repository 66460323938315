import CommonResource from 'common/services/resources/common.js'

import UartLight from 'common/services/uart/light.js'
import UartEnergy from 'common/services/uart/energy.js'


import NumberService from 'common/services/number.js'
import StorageService from 'common/services/storage.js'
import CordovaService from 'common/services/cordova.js'
import moment from 'moment'
import Device from 'common/classes/Device.js';
import store from 'vuex_path/store'

import { v4 as uuidV4 } from 'uuid';

const TAG = 'uart.js'

export default {
    CONSTANTS:
    {
        DEFAULT_HDR : 'FF',
        DEFAULT_COMMON_DATA_LENGTH : 4,
        DEFAULT_GROUP : 'FE',
        DEFAULT_CMD :
        {
            'join' : '00',
            'setStatus' : '02',
            'remove' : '03'

        },
        DEFAULT_TYPE :
        {

            'light' : 
            {
                'value' : '0x0001',
                'manager' : UartLight
            },
            'energy' : 
            {
                'value' : '0x0002',
                'manager' : UartEnergy
            },
            'security' : 
            {
                'value' : '0x0003'
            },
            'command' : 
            {
                'value' : '0x0004'
            },
            'sensor' : 
            {
                'value' : '0x0005',
                'dem' : '0x0005',
                'dmf' : '0x0006'
            },
            'movement' : 
            {
                'value' : '0x0007'
            },      
        },
    },  
    startupTimeout: 500,
    sendGetInfoProgress:false,
    setTimeoutGetInfoArray: [],
    waitStateCallbackArray: [],
    
    //UART : get info
    initStartupTimeout () {
        this.startupTimeout = 500;
    },
    
    incrementStartupTimeout () {
        this.startupTimeout += 500;
    },
    
    getFrame: function (module, statusObj, cmd) {
        console.log('getFrame');
        console.log(module);
        if(statusObj.value=='on' || statusObj.value=='off')
        {
            statusObj.capability = 'status';
        }
        
        console.log(statusObj);
        var hdrHex = '00';
        var dtlHex = '00';
        var typeHex = 'FFFF';
        /*var brandHex = '0000';
        var modelHex = '0000';*/
        var groupHex = this.CONSTANTS.DEFAULT_GROUP;
        var dataHex = undefined;
        //dataHex = (status=='on'?'41':'42');
        var cmdHex = (cmd ? this.CONSTANTS.DEFAULT_CMD[cmd]: '02');
        var memberHex = '00';
        var fcsHex = '00';
        //FF05020001FF0100
        if(module.device)
        {
            var device = module.device;
            console.log('DEVICE');
            console.log(device);
            /*brandHex =  NumberService.convertHex(device.brandObj.index);
            modelHex =  NumberService.convertHex(device.submodelObj.index);*/
            var c = _find(device.capabilities, {'capability': statusObj.capability});
            console.log(c)
            if(c)
            {
                if(c.stateEntities)
                {
                    console.log('DATAHEX_stateEntities');
                    console.log(statusObj);
                    console.log(c.stateEntities);
                    var stateEntitie = _find(c.stateEntities, {'name':statusObj.value});
                    console.log(stateEntitie);
                    if(stateEntitie && stateEntitie.index)
                    {
                        dataHex =  NumberService.convertHex(stateEntitie.index);
                        console.log('DATAHEX2_stateEntities');
                        console.log(dataHex);
                    }
                }
                else
                {
                    dataHex =  NumberService.convertHex(c.index);

                    console.log('DATAHEX');
                    console.log(dataHex);
                    if(c.capability=='color_temperature'|| c.capability=='brightness'|| c.capability=='rgb')
                    {
                        // check if the uart data has been passed in the status object
                        if(statusObj.uartData)
                        {
                            dataHex = statusObj.uartData;
                        }

                        else
                        {
                            console.log('TEMPERATURE');
                            console.log(device);
                            var colorTemperature = _find(device.status, {'capability': 'color_temperature'});
                            var brightness = _find(device.status, {'capability': 'brightness'});
                            console.log(colorTemperature);
                            console.log(brightness);
                            var ct = '64';
                            if(colorTemperature)
                            {
                                var colorTemperatureCapability = _find(device.capabilities, {'capability': 'color_temperature'});
                                ct = this.convertKelvinToHex(colorTemperature.value,colorTemperatureCapability.min,colorTemperatureCapability.max);
                            }
                            var b = (brightness?parseInt(brightness.value).toString(16).padStart(2, '0'):'64');
    
                            console.log(ct);
                            console.log(b);
                            
                            dataHex = '4F'+b+ct+'000000';
                        }
                        console.log('DATAHEX2');
                        console.log(dataHex);
                    }
                }


            }
            if(dataHex==undefined)return false;
            hdrHex = this.CONSTANTS.DEFAULT_HDR;
            dtlHex = (this.CONSTANTS.DEFAULT_COMMON_DATA_LENGTH+(dataHex.length/2)).toString(16).padStart(2, '0').toUpperCase();
            console.log('dtlHex');
            console.log(dtlHex);
            var systemType = this.CONSTANTS.DEFAULT_TYPE[module.device.systems[0]];
            //TYPE
            if(systemType)
            {
                typeHex = (systemType[module.device.model]?systemType[module.device.model]:systemType.value).replace('0x','');
            }

            if(module.device.index)
            {
                memberHex = NumberService.convertHex(module.device.index);
            }

            var frame = hdrHex+ // HDR
                        dtlHex+ // DTL
                        cmdHex+ //CMD
                        typeHex+ //TYPE
                        groupHex+  //GROUP
                        memberHex+ //MEMBER 
                        dataHex; //DATA (D0..D5)
                        
            /*frame = 'FF'+ // HDR
                        '05'+ // DTL
                        '02'+ //CMD
                        '0001'+ //TYPE
                        '00'+  //GROUP
                        '06'+ //MEMBER  
                        '0C'; //DATA (D0..D5)
                        console.log('FRAME');*/
                        
            console.log(frame);
            fcsHex = this.getFcs(frame);  //FCS
            console.log('fcsHex');
            console.log(frame+fcsHex);
            return frame+fcsHex;
        }
    },  
    /**
     * GET FCS (add each byte and take only the last byte of this addition)
     * @param {*} frame 
     */
    getFcs: function(frame){
        var totalDec = 0;
        for(var i = 0; i <= frame.length - 2; i=i+2){
            var hexa = frame.substr(i, 2);
            var deci = parseInt(hexa,16);
            totalDec = totalDec + deci;
        }
        var totalHex = totalDec.toString(16);
        return totalHex.substr(totalHex.length - 2, 2).toUpperCase();
    },
    

    changeStatusFromGetInfos: function(memberHex,infos,device,devices){
        var memberDec = parseInt(memberHex,16);
        var m = _find(devices, {'device':{'index':memberDec}});
        //if(m)return false;
        console.log('changeStatusFromGetInfos');
        console.log(memberDec);
        console.log(infos);
        console.log(device.bearerId);
        console.log(devices);
        console.log(m);
        if(m.device && m.device.status)
        {
            var status = _find(m.device.status, {'capability':'status'});
            if(status && infos.state)
            {
                status.value = (infos.state=='01'?'on':'off');
            }
            var colorTemperature = _find(m.device.status, {'capability':'color_temperature'});
            if(colorTemperature && infos.ledTone)
            {
                var colorTemperatureCapability = _find(m.device.capabilities, {'capability':'color_temperature'});
                if(colorTemperatureCapability)
                {
                    var kelvinTemperature =  this.convertHexToKelvin(parseInt(infos.ledTone,16),colorTemperatureCapability.min,colorTemperatureCapability.max);
                    colorTemperature.value = kelvinTemperature;
                }
            }
            var access = _find(m.device.status, {'capability':'access'});
            if(access && infos.state)
            {
                access.value = (infos.state=='00'?'open':'close');
            }
            console.log(m);
        }
    },
    getModuleFromUartFrame: function(device, devices){
        console.log('getDeviceInfosFromUartFrame');
        var dtl = parseInt(device.uartFrame.substr(2,2),16);
        var memberHex = device.uartFrame.substr(12,2);
        var dataLength = dtl - this.CONSTANTS.DEFAULT_COMMON_DATA_LENGTH;
        var module = 
        {
            id: undefined,
            device:
            {
                status: []
            }
        }

        if(dataLength>0)
        {
            console.log(dataLength);
            var dataHex = '0x'+device.uartFrame.substr(14,dataLength*2);

            var typeHex = device.uartFrame.substr(6,4);
            
            var memberDec = parseInt(memberHex,16);
            console.log(memberDec);
            var m = _find(devices, {'device':{'index':memberDec}});
            console.log('MODULE');
            console.log({'device':{'index':memberDec}});
            console.log(m);
            console.log('DATAHEX');
            console.log(dataHex);
            console.log('TYPEHEX');
            console.log(typeHex);
            if(m && m.device)
            {
                module.id = m.id;

                var typeObj = this.CONSTANTS.DEFAULT_TYPE[m.device.systems[0]];

                if(typeObj && typeObj.manager)
                {
                    module.device.status = typeObj.manager['convertCallbackFrame'](m,dataHex.substr(2));
                }
                else
                {
                    if(dataHex.substr(2,2) == '4F')
                    {
                        module.device.status.push({
                            capability: 'color_temperature'
                        });
                        var status = dataHex.substr(4,2);
                        status = (status=='00'?'off':'on');
                        var s = _find(m.device.status, {'capability': 'status'});
                        if(s && s.value != status)
                        {
                            module.device.status.push({
                                capability: 'status',
                                value: (status=='00'?'off':'on')
                            });
                        }
                        console.log(module.device.status);
                    }
                    if(typeHex == '0005')
                    {
                        module.device.status.push({
                            capability: 'detect'
                        });
                    }
                    else
                    {
                        for(var i = 0 ; i<m.device.capabilities.length ; i++) {
                            var stateEntitie = _find(m.device.capabilities[i].stateEntities, function(se){
                                console.log('SE');
                                console.log(se.index);
                                console.log(dataHex);
                                if(dataHex.indexOf(se.index)!=-1)
                                {
                                    return true;
                                }
                            });
                            console.log('STATE_ENTITIE');
                            console.log(stateEntitie);
                            if(stateEntitie)
                            {
                                if(dataLength>1 && m.device.capabilities[i].capability == 'status')
                                {
                                    var status = dataHex.substr(4,2);
                                    stateEntitie.name = (status=='00'?'off':'on');
                                }
                                module.device.status.push({
                                    capability: m.device.capabilities[i].capability,
                                    value: stateEntitie.name
                                });
                                console.log(module);
                                return module;
                            }
                        }
                    }
                }
                return module;
            }
            else
            {
                return false;
            }
        }
        else
        {
            return false;
        }
    },

    convertKelvinToHex: function(value, min, max){
        var delta = (parseInt(max) - parseInt(min));
        var deltaValue = (parseInt(value) - parseInt(min));
        var percent = Math.round((deltaValue*100/delta));
        return NumberService.convertHex(percent);
    },

    convertHexToKelvin: function(value, min, max){
        var delta = (parseInt(max) - parseInt(min));
        var kelvin = (value * delta / 100) + parseInt(min);
        return kelvin;
    },

    sendGetInfoForAllGroup: function(homepilots,devices,eventHub){
        console.log('sendGetInfoForAllGroup');
        console.log(homepilots);
        console.log(devices);
        var _this = this;
        var hps = _filter(homepilots, function(hp) {
            return (hp.device.submodelObj.name.toLowerCase()  != 'homepilot' && hp.device.submodelObj.name.toLowerCase()  != 'hp-300'); 
        });
        this.initStartupTimeout();
        this.sendGetInfoProgress = true;
        hps.forEach(function(hp){
            console.log(hp);
            _this.sendGroupGetInfo(hp,devices,eventHub);
        });

    },

    sendGroupGetInfo: function(hp, devices, eventHub){
        console.log('sendGroupGetInfo');
        var fcsHex = '00';
        var typesArr = ['0001','0002','0003','0004','0005','0006','0007'];
        var _this = this;
    
        //typesArr.forEach(function(t){
            var getInfoId = hp.id /*+ '-' + t*/;
            if(!_this.setTimeoutGetInfoArray[getInfoId])
            {
                _this.setTimeoutGetInfoArray[getInfoId] = setTimeout(function() {

                    var uartFrame = 'FF'+ // HDR
                    '05'+ // DTL
                    '02'+ //CMD
                    'FFFF'+ //TYPE
                    'FE'+  //GROUP
                    'FF' + //MEMBER  
                    '0C'; //DATA (D0..D5)
                    console.log('FRAME');
                    fcsHex = _this.getFcs(uartFrame);  //FCS
                    console.log('fcsHex');
                    console.log(uartFrame+fcsHex);
                    uartFrame = uartFrame+fcsHex;
                    var json = {
                        'action': 'command',
                        'timestamp':moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        'uid':uuidV4(),
                        'modules': [
                            {
                                'id': hp.id,
                                'device':{
                                    'bearerId':hp.id,
                                    'uartFrame': uartFrame
                                }
                            }
                        ]
                    };
                    console.log('JSON GROUP GET INFO');
                    console.log(json);
                    var path = hp.device.model+'/'+hp.device.systems[0]+'/addressLocation/plants/'+store.state.selectedPlantId+'/modules/parameter/id/value/'+hp.id;
                    console.log(path);
                    eventHub.$emit('mqttPublish',  path, json);
                    CordovaService.setStatus(json).then(
                        function(response) {
                        }).catch(
                        function(e) {
                        });
                    setTimeout(function() {
                    }, 500);

                    if(hp.device.associatedDevices)
                    {
                        var hpDevices = _filter(devices, function(d) {
                            return (hp.device.associatedDevices.indexOf(d.id)!=-1);
                        });
                        hpDevices.forEach(function(m){
                            clearTimeout(_this.waitStateCallbackArray[m.id]);
                            _this.waitStateCallbackArray[m.id] = setTimeout(function(){
                                _this.waitStateCallbackArray[m.id] = undefined;
                                console.log('waitStateCallback');
                                console.log(m);
                                m.offline =true;
                            }, Device.WAIT_STATE_CALLBACK_TIMEOUT);
                        });   
                    }
                    _this.setTimeoutGetInfoArray[getInfoId] = undefined;
                    clearTimeout(_this.waitStateCallbackArray[getInfoId]);
                    _this.waitStateCallbackArray[getInfoId] = setTimeout(function(){
                        _this.waitStateCallbackArray[getInfoId] = undefined;
                    }, Device.WAIT_STATE_CALLBACK_TIMEOUT);
                }, _this.startupTimeout);
                //_this.incrementStartupTimeout();
            }
        //});
    },

    iswaitStateCallbackArray: function(){
        for (var x in this.waitStateCallbackArray)
        {
            if(this.waitStateCallbackArray[x]!=undefined)
            {
                return false;
            }
        }
        return true;
    },

    getFrameNew: function (module,dataHex, cmd) {
        var hdrHex = '00';
        var dtlHex = '00';
        var typeHex = 'FFFF';
        var groupHex = (module.device.groupHex ? module.device.groupHex : this.CONSTANTS.DEFAULT_GROUP);
        var cmdHex = (cmd ? this.CONSTANTS.DEFAULT_CMD[cmd]: '02');
        var memberHex = '00';
        var fcsHex = '00';

        hdrHex = this.CONSTANTS.DEFAULT_HDR;
        dtlHex = (this.CONSTANTS.DEFAULT_COMMON_DATA_LENGTH+(dataHex.length/2)).toString(16).padStart(2, '0').toUpperCase();
        console.log('dtlHex');
        console.log(dtlHex);
        if(module.device.systems && module.device.systems.length==0)
        {
            return false;
        }

        var systemName = module.device.systems[0];

        var system = _find(store.state.systems, {'name': systemName});

        if(!system)
        {
            return false;
        }

        if(!system.index)
        {
            return false;
        }
        
        typeHex = system.index.replace('0x','');

        if(module.device.index)
        {
            memberHex = NumberService.convertHex(module.device.index);
        }

        var frame = hdrHex+ // HDR
                    dtlHex+ // DTL
                    cmdHex+ //CMD
                    typeHex+ //TYPE
                    groupHex+  //GROUP
                    memberHex+ //MEMBER 
                    dataHex; //DATA (D0..D5)

        console.log(frame);
        fcsHex = this.getFcs(frame);  //FCS
        console.log('fcsHex');
        console.log(frame+fcsHex);
        return frame+fcsHex;
    }, 

    getInfosNew: function(device,devices,cacheDevices){
        var frame = device.uartFrame;
        console.log('getInfosNew');
        console.log(devices);
        console.log(cacheDevices);
        var dtl = parseInt(device.uartFrame.substr(2,2),16);
        var typeHex = device.uartFrame.substr(6,4);
        var memberHex = device.uartFrame.substr(12,2);
        var dataLength = dtl - this.CONSTANTS.DEFAULT_COMMON_DATA_LENGTH;
        var infos = {};

        var system = _find(store.state.systems, {'index': '0x'+typeHex});

        if(system)
        {
            var typeObj = this.CONSTANTS.DEFAULT_TYPE[system.name];

            if(typeObj && typeObj.manager)
            {
                
                var dataHex = device.uartFrame.substr(14,dataLength*2);
                //Cache
                this.changeStatusFromGetInfosNew(typeObj,memberHex,dataHex,device,cacheDevices);
        
                //Current
                this.changeStatusFromGetInfosNew(typeObj,memberHex,dataHex,device,devices);
                    
            }

        }
        else
        {
            //OLD SYSTEM

            //Infos for light
            if(typeHex=='0001')
            {
                infos = 
                {
                    state: frame.substr(16,2),
                    ledModel: frame.substr(18,2),
                    ledBright: frame.substr(20,2),
                    ledTone:  frame.substr(22,2),
                    rgbModel: frame.substr(24,2),
                    rgbModelOld: frame.substr(26,2)
                }
            }
            else if(typeHex=='0006')
            {
                //If get info returns 0C01 for close -> convert to 0C0001
                infos = 
                {
                    state: (frame.substr(16,4)=='0001'?'01':frame.substr(16,2))
                }
            }

            if(dataLength>0)
            {
                //Cache
                this.changeStatusFromGetInfos(memberHex,infos,device,cacheDevices);

                //Current
                this.changeStatusFromGetInfos(memberHex,infos,device,devices);
                
            }
        }

    },

    sendCommand: function(module,method,eventHub){
        console.log('sendCommand');
        console.log(arguments);
        if(arguments.length<3)
        {
            console.log(TAG + ' sendCommand: Insufficient number of parameters');
            return false;
        }

        if(module.device.systems && module.device.systems.length==0)
        {
            console.log(TAG + ' sendCommand: No device system');
            return false;
        }

        var typeObj = this.CONSTANTS.DEFAULT_TYPE[module.device.systems[0]];

        if(!typeObj)
        {
            console.log(TAG + ' sendCommand: No type object found');
            return false;
        }

        var manager = typeObj.manager;

        if(!manager)
        {
            console.log(TAG + ' sendCommand: No manager');
            return false;
        }

        if(!manager[method])
        {
            console.log(TAG + ' sendCommand: Manager method was not found');
            return false;
        }

        var managerCallbackObj = manager[method](module);

        if(!managerCallbackObj)
        {
            console.log(TAG + ' sendCommand: Manager method callback returns false');
            return false;
        }

        var uartFrame = this.getFrameNew(module,managerCallbackObj.dataHex, managerCallbackObj.cmd);

        if(!uartFrame)
        {
            console.log(TAG + ' sendCommand: no uart frame');
            return false;
        }
        console.log('SEND MESH');
        CommonResource.sendMeshStatus(eventHub, module, managerCallbackObj.status, uartFrame);
    },

    changeStatusFromGetInfosNew: function(typeObj,memberHex,dataHex,device,devices){
        console.log('changeStatusFromGetInfosNew');
        var memberDec = parseInt(memberHex,16);
        console.log(devices);
        console.log(memberDec);
        var m = _find(devices, {'device':{'index':memberDec}});
        if(m)
        {
          //Set 'getinfo' boolean to true to block the notification
          device.bGetInfo = true;
          m.offline = false;
          if(this.waitStateCallbackArray[m.id])
          {
            clearTimeout(this.waitStateCallbackArray[m.id]);
            this.waitStateCallbackArray[m.id] = undefined;
          }
          console.log(m);
          var status = typeObj.manager['convertCallbackFrame'](m,dataHex,true);
          console.log(status);
          if(m.device && m.device.status && status)
          {
            status.forEach(function(s){
                var st = _find(m.device.status, {'capability':s.capability});
                if(st)
                {
                    st.value = s.value;
                }
            });
          }
          console.log(m);
          console.log(devices);
          console.log('DEVICE');
        }
    },
    
}
