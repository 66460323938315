/** 
 * 
 * 
 * */ 

export default class Group{
    constructor(name, icon, id, roomId, system, defaultWidgetModel, devices) {
        //To overcome the problem of minifying the name of the class
        this.className = 'Group';
        this.id = id,
        this.name =  name;
        this.roomId =  roomId;
        this.icon =  icon;
        this.statusSwitch =  'off';
        var status  = JSON.parse(JSON.stringify(system.availableCapabilities)).map(function(c) {
            c.value = '';
            return c
          });
        this.devices = devices;
        this.device = 
        {
            'model': 'room',
            'submodelObj': 
            {
                meshCompatible: true
            },
            'systems':[system.name],
            'status':[],
            'capabilities': 
            [

                {
                    capability: "status",
                    displayName: "Switch",
                    index: null,
                    readable: true,
                    executable: true,
                    can_trigger: true,
                    priority: 0,
                    states: [
                        "on",
                        "off"
                    ],
                    stateEntities: [
                        {
                        "name": "on",
                        "index": "0x4101"
                        },
                        {
                        "name": "off",
                        "index": "0x4201"
                        }
                    ],
                    default_value: "on"
                }
            ],
            'defaultWidgetModel': defaultWidgetModel
        }
        if(system.name=='light')
        {

            this.device.capabilities.push({
                capability: "brightness",
                index: "0x43",
                readable: true,
                executable: true,
                can_trigger: true,
                priority: false,
                default_value: "100",
                min: "5",
                max: "100",
                unit: "%",
            },
            {
                capability: "color_temperature",
                index: "0x46",
                readable: true,
                executable: true,
                can_trigger: true,
                priority: false,
                default_value: "4250",
                min: "3000",
                max: "5500",
                unit: "K"
            },
            {
                capability: "rgb",
                index: "0x4a",
                readable: true,
                executable: true,
                can_trigger: true,
                priority: false,
                default_value: "0xFFFFFF",
                min: "0x000000",
                max: "0xFFFFFF"
            });
        }
        this.device.status = status;
    }
}