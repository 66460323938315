import { render, staticRenderFns } from "./add-device-discover-installation.vue?vue&type=template&id=f8f826f2&scoped=true&"
import script from "./add-device-discover-installation.js?vue&type=script&lang=js&"
export * from "./add-device-discover-installation.js?vue&type=script&lang=js&"
import style0 from "./add-device-discover-installation.css?vue&type=style&index=0&id=f8f826f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8f826f2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VDivider})
