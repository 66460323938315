import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'

export default {
    waiting: 
    {
        associate : false
    },  
    associate: function (json,eventHub) {
        var _this = this;      
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.associate == true)return false;
                axios.post(Global.getBackendUrl()+'homepilot/home/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/association',
                    json,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.associate = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.associate = false;
                        if(error.response){
                            if(error.response.status==402)
                            {
                                eventHub.$emit('displayAlertKey', 'unauthorized');
                                return reject('associationFailed');
                            }
                            else if(error.response.status==403)
                            {
                                eventHub.$emit('displayAlertKey', 'cannot_installed');
                                return reject('associationFailed');
                            }
                        }
                        return reject(error);
                    });
            }
        );
    },
    getAll: function () {
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'homepilot/home/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId'),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    }
}
