export default {
    props: [],
    data:
        function () {
                    return {
                                CONSTANTS:
                                {
                                },
                    }
                },
     created:
        function () {
        }

}
