import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'
import store from 'vuex_path/store'

var moment = require('moment-timezone');

export default {
    getAll: function (newOnly,source) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/notifications?newOnly='+newOnly+'&timezone='+moment.tz.guess(),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        cancelToken: source.token
                    }).then(function (response) {
                        return resolve(response.data.modules);
                    })
                    .catch(function (error) {
                        if(error.response && error.response.status == 404)
                        {
                            return resolve([]);
                        }
                        return reject(error);
                    });
            }
        );
    },
    getModulesNotifications: function (modules)
    {
        var notifications = [];
        modules.forEach(function(m) {
            var moduleName = m.name;
            var moduleId = m.id;
            var notWithModName = m.notifications.map(function(n) {
                n.moduleName = moduleName;
                n.moduleId = moduleId;
                return n;
            });
            notifications = notifications.concat(notWithModName);
        });
        return notifications;
    },
    seeAllNotifications: function ()
    {
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/notifications/see',
                    {},
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    seeNotification: function (moduleId, notificationId)
    {
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/module/'+moduleId+'/notifications/'+notificationId+'/see',
                    {},
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    getEmptyNotification(module)
    {
        return{
            'id' : module.id,
            'name' : module.name,
            'notifications' : [],
            'plantId': module.plantId,
            'type' : null 
        }
    }

}
