
const formatTriggersActions = function (arr, allDevices) 
{
  let formatArr = [];
  if(arr && arr.length>0)
  {
    arr.forEach((e) =>{
      e.name = '';
      e.icon = '';
      let d = _find(allDevices, function(d) { return d.id == (e.infos?e.infos.id:''); });
      if(d)
      {
        e.name = d.name;
        e.icon = (d.icon != null?d.icon+'-default':(d.device?d.device.model:''));
      }
      formatArr.push(e);
    });
  }
  return formatArr;
}

export default {
    props: ['module'],
    data:
          function () {
              return {
                CONSTANTS:
                {
                  'MUTE_TXT' : this.$gettext('mute')
                },
                mute: false
              }
      },
    created: function () {
    },

    computed: {
      triggersActions: function () {
        let triggersActions = {
          triggers: [],
          actions: []
        };
        //triggersActions.triggers = formatTriggersActions(this.module.service.infos.triggers,this.$store.state.allDevices);
        //triggersActions.actions = formatTriggersActions(this.module.service.infos.actions,this.$store.state.allDevices);
        return triggersActions;
      },
    },

  }