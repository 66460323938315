import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'
import Group from 'common/classes/Group.js';
import store from 'vuex_path/store'
import { v4 as uuidV4 } from 'uuid';
export default {
    waiting: 
    {
        addGroup : false,
        sendCommand : false
    },      
    getAllGroups: function (section) {
      return new Promise(
        function(resolve, reject){
            axios.get(Global.getBackendUrl()+'group/all/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'?section='+section,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': StorageService.getValue(localStorage, 'authorization')
                    },
                }).then(function (response) {
                    return resolve(response);
                })
                .catch(function (error) {
                    return reject(error);
                });
        }
      );
    },
    addGroup: function (groups, system) {
        var _this = this;      
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.addGroup == true)return false;
                axios.post(Global.getBackendUrl()+'group/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId'),
                      groups,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.addGroup = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.addGroup = false;
                        return reject(error);
                    });
            }
        );
    },
    deleteGroup: function (system, groupId) {
        return new Promise(
            function(resolve, reject){
                axios.delete(Global.getBackendUrl()+'group/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+groupId,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    sendCommand: function (system, groupId, command) {
        var _this = this;       
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.sendCommand == true)return false;
                axios.post(Global.getBackendUrl()+'group/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+groupId,
                    command,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.sendCommand = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.sendCommand = false;
                        return reject(error);
                    });
            }
        );
    },
    /**
     * 
     * @param {*} modules 
     */
    getAllGroupObject: function (modules,availableSystems) {
        modules = _filter(modules, function(m) { 
            if(
                m.device
                && m.device.modelObj
                && m.device.modelObj.systems.length > 0
                && (availableSystems && Array.isArray(availableSystems)?availableSystems.indexOf(m.device.modelObj.systems[0])!=-1:true)
                && m.device.submodelObj
                && m.device.submodelObj.meshCompatible
                )
            {
                return true;
            }
            else
            {
                return false;
            }   
    
        });
        return _groupBy(modules, function(m) {
            return m.device.modelObj.systems[0];
        });
    },

    getAllWidgetGroupObject: function (zone,zoneNameTranslate,availableSystems,groupWidgetTitle) {
        let allGroupObject = this.getAllGroupObject(zone.modules,availableSystems);
        let allWidgetGroupObject = [];
        for(var key in allGroupObject)
        {
            if(allGroupObject[key].length>1)
            {
                const s = _find(store.state.systems, {name:key});
                if(s)
                {
                    allWidgetGroupObject.push(new Group(
                        (groupWidgetTitle[key]?groupWidgetTitle[key]:zoneNameTranslate),
                        key+'-group', 
                        uuidV4(),
                        zone.id,
                        s,
                        'default_status',
                        allGroupObject[key]
                        ));
                }
            }
        }
        return allWidgetGroupObject;
    }
}
