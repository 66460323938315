import Service from './Service.js'
import Language from 'common/services/language.js'
var stringify = require('json-stringify-safe');

const ACCESS_CONTROL_CAPABILITIES = ['ring','call'];

export default class extends Service {

    constructor(name, locationId, notificationLevel, infos) {
        super(name, locationId, notificationLevel, infos);
    }
    
    setTriggers(elements) {
      if(!Array.isArray(elements))
      {
        elements = [elements];
      }
      let _this = this;
      this.infos.triggers = [];
      let elts = JSON.parse(stringify(elements));
      elts.forEach(function(element,index) {
        if(element.device)
        {
          let capability =  _find(element.device.capabilities, function(c) { return ACCESS_CONTROL_CAPABILITIES.indexOf(c.capability)!=-1 });
          let trigger =  {
            'type': 'status',
            'infos': {
              'capability': capability.capability,
              'operation': 'EQUAL',
              'moduleId': element.id
            }
          };
          if(capability.capability=='call')
          {
            trigger.infos.value='pending';
          }
          _this.infos.triggers.push(trigger);
        }
      });
    }
    setActions(elements) {
      if(!Array.isArray(elements))
      {
        elements = [elements];
      }
      let _this = this;
      this.infos.actions = [];
      let elts = JSON.parse(stringify(elements));
      elts.forEach(function(element,index) {
        let action =  {
          'type': 'command',
          'infos': {
            'capability': 'show',
            'moduleId': element.id
          }
        };
        _this.infos.actions.push(action);
      });
    }
}

/*
{
  "name": "Access control service",
  "infos": {
    "actions": [
      {
        "type": "command",
        "infos": {
          "capability": "show",
          "moduleId": "a09a9d64cfd09eccb981a5b38355b854"
        }
      }
    ],
    "triggers": [
      {
        "type": "status",
        "infos": {
          "capability": "ring",
          "operation": "EQUAL",
          "moduleId": "e2595943b5772ad2791136e15f8c7a76"
        }
      }
    ]
  }
}
}*/