import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'

export default {
    waiting: 
    {
        addView: false,
        addWidget : false,
        editView : false,
        editWidget : false
    },    
    getTemplates: function (templateName) {
        if(!templateName)templateName='';
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'templates/'+templateName, 
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },   
    getWidgetModels: function (WidgetCategory) {
        if(!WidgetCategory)WidgetCategory='';
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'widgetModels/'+WidgetCategory.toUpperCase(), 
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },   
    getDashboard: function (source) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'dashboard/'+StorageService.getValue(localStorage, 'dashboardId'), 
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        cancelToken: source.token
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        if(error.response && error.response.status == 404)
                        {
                            return resolve({});
                        }
                        return reject(error);
                    });
            }
        );
    },
    addView: function (templateName,name) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.addView == true)return false;
                axios.post(Global.getBackendUrl()+'dashboard/'+StorageService.getValue(localStorage, 'dashboardId')+'/view',
                      {
                        "templateName": templateName,
                        "name": name,
                      },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.addView = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.addView = false;
                        return reject(error);
                    });
            }
        );
    },
    addWidget: function (viewId, widget) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.addWidget == true)return false;
                axios.post(Global.getBackendUrl()+'dashboard/'+StorageService.getValue(localStorage, 'dashboardId')+'/view/'+viewId+'/widget',
                    widget,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.addWidget = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.addWidget = false;
                        return reject(error);
                    });
            }
        );
    },
    editView: function (viewId,data) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.editView == true)return false;
                axios.put(Global.getBackendUrl()+'dashboard/'+StorageService.getValue(localStorage, 'dashboardId')+'/view/'+viewId,
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.editView = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.editView = false;
                        return reject(error);
                    });
            }
        );
    },   
    editWidget: function (viewId,widgetId,data) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.editWidget == true)return false;
                axios.put(Global.getBackendUrl()+'dashboard/'+StorageService.getValue(localStorage, 'dashboardId')+'/view/'+viewId+'/widget/'+widgetId,
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.editWidget = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.editWidget = false;
                        return reject(error);
                    });
            }
        );
    },   
    deleteView: function (viewId) {
        return new Promise(
            function(resolve, reject){
                axios.delete(Global.getBackendUrl()+'dashboard/'+StorageService.getValue(localStorage, 'dashboardId')+'/view/'+viewId,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    deleteWidget: function (viewId, widgetId) {
        return new Promise(
            function(resolve, reject){
                axios.delete(Global.getBackendUrl()+'dashboard/'+StorageService.getValue(localStorage, 'dashboardId')+'/view/'+viewId+'/widget/'+widgetId,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    getAllEntities: function (widgetModels)
    {
        var entities = [];
        var result = widgetModels.map(function(wm) {return wm.entities;});
        for(var i = 0; i < result.length; i++)
        {
            entities = entities.concat(result[i]);
        }
        entities.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        return entities;
    }
}
