export default {
  props: ['module','use'],
  data:
		function () {
			return {
        speedHover: false,
        directionHover: false,
        centerHover: false,
        randomHover: false,
        reverseHover: false,
        learnHover: false
      }
  },
  methods: {
    setStatus: function(value) {
      if(this.use == "scenario-action")
      {
        this.eventHub.$emit('createScenarioItem', this.module, undefined, this.module.device.buttons[value], this.use);
      }
      else
      {
        this.$parent.$emit('set-status', value);
      }
    }
  }
}
