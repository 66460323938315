import Vue from 'vue';

var touchstart = function touchstart(event, wrapper) {
    event.stopPropagation();
      var touch = (event.changedTouches?event.changedTouches[0]:event);
      wrapper.touchstartX = touch.clientX;
      wrapper.touchstartY = touch.clientY;
      event.currentTarget.style.transitionDuration = '0s'; 
      wrapper.start && wrapper.start(Object.assign(event, wrapper));
    };
    
var touchend = function touchend(event, wrapper) {
    event.stopPropagation();
    var touch = (event.changedTouches?event.changedTouches[0]:event);
    wrapper.touchendX = touch.clientX;
    wrapper.touchendY = touch.clientY;

    wrapper.end && wrapper.end(Object.assign(event, wrapper));

    handleGesture(event, wrapper);
};

var touchmove = function touchmove(event, wrapper) {
    event.stopPropagation();
    var touch = (event.changedTouches?event.changedTouches[0]:event);
    wrapper.touchmoveX = touch.clientX;
    wrapper.touchmoveY = touch.clientY;
    if(wrapper.touchmoveX!=0)
    {
        var moveX = -(wrapper.touchstartX - wrapper.touchmoveX);
        event.currentTarget.style.transform = 'translate3d('+moveX+'px, 0, 0)';
    }
    wrapper.move && wrapper.move(Object.assign(event, wrapper));
};

var transitionend = function transitionend(event, wrapper) {
    if(event.propertyName=='transform')
    {   
        //event.currentTarget.style.display = 'none';
        wrapper.left && wrapper.swipeLeft && wrapper.left(wrapper);
        wrapper.right && wrapper.swipeRight && wrapper.right(wrapper);
    }
};
    
const handleGesture = (event, wrapper) => {
    var el = event.currentTarget;
    const { touchstartX, touchendX, touchstartY, touchendY } = wrapper;
    const dirRatio = 0.5;
    const minDistance = el.offsetWidth/2;
    wrapper.offsetX = touchendX - touchstartX;
    wrapper.offsetY = touchendY - touchstartY;
    if (Math.abs(wrapper.offsetY) < dirRatio * Math.abs(wrapper.offsetX)) {
        wrapper.swipeLeft = (touchendX < touchstartX - minDistance);
        wrapper.swipeRight = (touchendX > touchstartX + minDistance);
        if(wrapper.swipeLeft || wrapper.swipeRight)
        {
            el.addEventListener('transitionend', e => transitionend(e, wrapper), false);
            el.style.transitionDuration = '0.2s';
            el.style.transform = 'translate3d('+(wrapper.swipeLeft?'-':'')+el.offsetWidth+'px, 0, 0)'; 
        }
    }

    if (Math.abs(wrapper.offsetX) < dirRatio * Math.abs(wrapper.offsetY)) {
        wrapper.up && (touchendY < touchstartY - minDistance) && wrapper.up(wrapper);
        wrapper.down && (touchendY > touchstartY + minDistance) && wrapper.down(wrapper);
    }

    if(!wrapper.swipeLeft && !wrapper.swipeRight)
    {
        wrapper.init();
    }
}
      
function inserted (el, { value }, vnode) {
    const wrapper = {
        swipeLeft: false,
        swipeRight: false,
        touchstartX: 0,
        touchstartY: 0,
        touchendX: 0,
        touchendY: 0,
        touchmoveX: 0,
        touchmoveY: 0,
        offsetX: 0,
        offsetY: 0,
        left: value.left,
        right: value.right,
        up: value.up,
        down: value.down,
        start: value.start,
        move: value.move,
        end: value.end,
        init: function () {
            el.style.transitionDuration = '0.2s'; 
            el.style.transform = 'translate3d(0, 0, 0)'; 
        }
    }

    const target = value.parent ? el.parentNode : el
    const options = value.options || { passive: true }

    // Needed to pass unit tests
    if (!target || !target.draggable) return
    target.addEventListener('touchstart', e => touchstart(e, wrapper), false);
    target.addEventListener('touchend', e => touchend(e, wrapper), false);
    target.addEventListener('touchmove', e => touchmove(e, wrapper), false);
    target.addEventListener('dragstart', e => touchstart(e, wrapper), false);
    target.addEventListener('dragend', e => touchend(e, wrapper), false);
    target.addEventListener('drag', e => touchmove(e, wrapper), false);
}

function unbind (el, { value }) {
    const target = value.parent ? el.parentNode : el
    
    if (!target) return

    target.removeEventListener('touchstart', touchstart);
    target.removeEventListener('touchend', touchend);
    target.removeEventListener('touchmove', touchmove);
    target.removeEventListener('dragstart', touchstart);
    target.removeEventListener('dragend', touchend);
    target.removeEventListener('drag', touchmove);    
    target.removeEventListener('transitionend', transitionend);  
}

Vue.directive('dragelt', {
    inserted: inserted,
    unbind: unbind
});