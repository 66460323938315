import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { createSimpleTransition } from 'vuetify/lib/components/transitions/createTransition'

const fadeTransition = createSimpleTransition('fade')
Vue.component('fade', fadeTransition)

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#4364AC'
          },
        },
    },
    icons: 
    {
        values: {

            'cl-icon-home-share': 'cl-icon cl-icon-home-share',
            'cl-icon-home': 'cl-icon cl-icon-home',
            'cl-icon-user': 'cl-icon cl-icon-user',
            'cl-icon-key': 'cl-icon cl-icon-key',
            'cl-icon-chevron-thin-down': 'cl-icon cl-icon-chevron-thin-down',
            'cl-icon-video': 'cl-icon cl-icon-video',
            'cl-icon-magnifying-glass': 'cl-icon cl-icon-magnifying-glass',
            'cl-icon-heating-default': 'cl-icon cl-icon-heating-default',
            'cl-icon-action-alert': 'cl-icon cl-icon-action-alert',
            'cl-icon-trigger-detect': 'cl-icon cl-icon-trigger-detect'
        }

    },
    //theme: { disable: true }
});
