import browser from 'browser-detect';
import Global from 'common/services/resources/global.js'
import UserResource from 'common/services/resources/user.js'
import StorageService from 'common/services/storage.js'
import LanguageService from 'common/services/language.js'
import StringService from 'common/services/string.js'
import LogService from 'common/services/log.js'
import store from 'vuex_path/store'

var md5 = require('md5');
import { v4 as uuidV4 } from 'uuid';

const TAG = 'SystemService';

export default {

    getClientId: function() {
        try{
            var mqttInfos = StorageService.getValue(localStorage, 'mqttInfos');
            LogService.consoleLogTag(TAG,'getClientId','systemInfos',mqttInfos);
            mqttInfos = JSON.parse(mqttInfos);
            const token = (mqttInfos.uid?mqttInfos.uid:mqttInfos.token);
            return 'fe-'+token;
        }
        catch(e)
        {
            return 'fe-unknown-'+uuidV4();
        }
    },

    getPlatform: function() {
        var native = (navigator.userAgent.indexOf('ComfortLife')!=-1);
        return{
            native : native,
            os: (native ? navigator.userAgent.split('-')[1].toLowerCase():navigator.userAgent)
        }
    },
    
    setSystemInfos: function(deviceInfos) {
        var obj = browser();
        var platform = this.getPlatform();
        var b = (obj.name?obj.name:'')+" "+(obj.version?obj.version:'');
        var hardware = (deviceInfos?deviceInfos.hardware:'');
        var os = (deviceInfos?deviceInfos.software:(obj.os?obj.os:''));
        var uid = (deviceInfos?deviceInfos.uid:uuidV4());
        const userClientId = md5(
            StorageService.getValue(localStorage, 'authorization')+
            LanguageService.getUserLanguage()+
            (platform.native?platform.os.toUpperCase():'WEB')+
            uid+
            hardware+
            os+
            b
            );
        return{
            uid : userClientId,
            token: userClientId,
            version: Global.VERSION,
            build: Global.BUILD,
            hardware: hardware,
            software: os,
            browser: b
        };
    },

    setMqttInfos: function() {
        var obj = browser();
        var platform = this.getPlatform();
        var b = (obj.name?obj.name:'')+" "+(obj.version?obj.version:'');
        var os = (obj.os?obj.os:'');
        var uid = uuidV4();
        const token = md5(
            StorageService.getValue(localStorage, 'authorization')+
            LanguageService.getUserLanguage()+
            (platform.native?platform.os.toUpperCase():'WEB')+
            uid+
            os+
            b
            );
        return{
            uid : token,
            token: token,
        };
    },

    getSystemInfosKey : function() {
        const userName = (UserResource.getUserName()?UserResource.getUserName():store.state.plantId);
        const region = StringService.toJson((StorageService.getValue(localStorage, 'region-ws')?StorageService.getValue(localStorage, 'region-ws'):store.state['region-ws']));
        if(region && userName)
        {
            return userName+'|'+region.region;
        }
        return false;
    },

    getSystemInfos : function() {

        const systemInfosKey = this.getSystemInfosKey();

        if(!systemInfosKey)
        {
            LogService.consoleErrorTag(TAG,'getSystemInfos','systemInfosKey error');
            return false;
        }

        return (store.state.userClientIdByUsername[systemInfosKey]?store.state.userClientIdByUsername[systemInfosKey]:false);
    },

    initSystemInfos: function() {
        const systemInfosKey = this.getSystemInfosKey();

        LogService.consoleLogTag(TAG,'initSystemInfos','systemInfosKey',systemInfosKey);

        if(!systemInfosKey)
        {
            LogService.consoleErrorTag(TAG,'initSystemInfos','systemInfosKey error');
            return;
        }

        if(StorageService.keyExists(localStorage,'uciByun'))
        {
            LogService.consoleLogTag(TAG,'initSystemInfos','StorageService.getValue(localStorage, uciByun)',StorageService.getValue(localStorage, 'uciByun'));
            const uciByun = StringService.toJson(StorageService.getValue(localStorage, 'uciByun'));
            if(uciByun)
            {
                store.dispatch('setUserClientIdByUsername',uciByun);
            }
            
        }
        LogService.consoleLogTag(TAG,'initSystemInfos','store.state.userClientIdByUsername[systemInfosKey]',store.state.userClientIdByUsername[systemInfosKey]);
        if(store.state.userClientIdByUsername[systemInfosKey])
        {
            return;
        }
        if(StorageService.keyExists(localStorage,'systemInfos') && systemInfosKey && store.state.userClientIdByUsername[systemInfosKey]==undefined)
        {
            const systemInfos = StringService.toJson(StorageService.getValue(localStorage, 'systemInfos'));
            LogService.consoleLogTag(TAG,'initSystemInfos','systemInfos',systemInfos);
            if(systemInfos)
            {
                if(systemInfos.uid==undefined && systemInfos.token)
                {
                    systemInfos.uid = systemInfos.token;
                }
                store.state.userClientIdByUsername[systemInfosKey] = systemInfos;
                StorageService.removeItem(localStorage,'systemInfos');
            }   
        }
        else
        {
            LogService.consoleLogTag(TAG,'initSystemInfos','this.getSystemInfos()',this.setSystemInfos());
            store.state.userClientIdByUsername[systemInfosKey] = this.setSystemInfos();
        }
        LogService.consoleLogTag(TAG,'initSystemInfos','store.state.userClientIdByUsername[systemInfosKey]',store.state.userClientIdByUsername[systemInfosKey]);
        StorageService.persist(localStorage, 'uciByun', JSON.stringify(store.state.userClientIdByUsername));
    },

    checkifAndroidLower10: function() {
        return (window.device && window.device.platform=="Android" && parseInt(window.device.version,10) < 10);
    }
}