import Global from './global.js'
import StorageService from 'common/services/storage.js'
import LanguageService from 'common/services/language.js'
import SystemService from 'common/services/system.js'
import LogService from 'common/services/log.js'
import axios from 'axios'
import store from 'vuex_path/store'

const TAG = 'clientService';

export default {   
     register: function (token) {
        const systemInfosKey = SystemService.getSystemInfosKey();
        var systemInfos  = store.state.userClientIdByUsername[systemInfosKey];
        
        if(systemInfos)
        {
            //systemInfos = JSON.parse(systemInfos);
            if(systemInfos.uid)
            {
                var platform = SystemService.getPlatform();
                systemInfos.platform = (platform.native?platform.os.toUpperCase():'WEB');
                systemInfos.language = LanguageService.getUserLanguage();
                if(token)
                {
                    systemInfos.token = token;
                }
                LogService.consoleLogTag(TAG,'register','systemInfos',systemInfos);
                return new Promise(
                    function(resolve, reject){
                        axios.post(Global.getBackendUrl()+'user/client/register',
                            systemInfos,
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Authorization': StorageService.getValue(localStorage, 'authorization')
                                },
                            }).then(function (response) {
                                return resolve(response);
                            })
                            .catch(function (error) {
                                return reject(error);
                            });
                    }
                );
            }
        }
    },

    unregister: function (authorization, systemInfos) {
        var bUserCLientId = false;
        const systemInfosKey = SystemService.getSystemInfosKey();
        var systemInfos  = store.state.userClientIdByUsername[systemInfosKey];
        LogService.consoleLogTag(TAG,'unregister','systemInfos',systemInfos);
        if(systemInfos)
        {
            //systemInfos = JSON.parse(systemInfos);
            if(systemInfos.uid)
            {
                bUserCLientId = true;
                return new Promise(
                    function(resolve, reject){
                        axios.post(Global.getBackendUrl()+'user/client/unregister',
                            {
                                uid: systemInfos.uid,
                                token:  systemInfos.token
                            },
                            {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                    'Authorization': authorization
                                },
                            }).then(function (response) {
                                return resolve(response);
                            })
                            .catch(function (error) {
                                return reject(error);
                            });
                    }
                );
            }
        }
        if(!bUserCLientId)
        {
            return new Promise(
                function(resolve, reject){
                    var error = new Error("unregister - no system infos");
                    return reject(error);
                }
            );
        }
    },
}
