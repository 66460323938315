import { render, staticRenderFns } from "./wifi-install-config.vue?vue&type=template&id=ff4d2cae&scoped=true&"
import script from "./wifi-install-config.js?vue&type=script&lang=js&"
export * from "./wifi-install-config.js?vue&type=script&lang=js&"
import style0 from "./wifi-install-config.css?vue&type=style&index=0&id=ff4d2cae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ff4d2cae",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})
