import HomepilotResource from 'common/services/resources/homepilot.js'

export default {
    data: function () {
        return {
          password: '',
          showPassword: true,
          load: false,
          diagnosticStatus: {},
          numberTry: 0,
          CONSTANTS:{
                      INPUT_ERROR : this.$gettext('Please enter the password'),
                    }
        }
    },
    created: function () {
    },
	  methods: {
		  next: function(){
        if(this.password == ''){
          this.eventHub.$emit('displayAlert',  this.CONSTANTS.INPUT_ERROR);
        }
        else{
          this.load = true;
          this.startSmnt();
        }
		  },
      startSmnt: function(){
        var _this = this;
        cordova.exec(
          function(winParam) {
            console.log('wincallback @smntservice start_smnt');
            _this.diagnostic();
          },
          function(error) {
            console.log('error @smntservice');
          },
          'SmartConnect',
          'start_smnt',
          [_this.$parent.wifi.ssid, _this.password, _this.$parent.associationToken]);
      },
      stopSmnt: function(){
        cordova.exec(
          function(winParam) {
            console.log('wincallback @smntservice stop_smnt');
          },
          function(error) {
            console.log('error @smntservice');
          },
          'SmartConnect',
          'stop_smnt',
          []
        );
      },
      associate: function(){
          var _this = this;
          var json = {
              'associationToken' : this.$parent.associationToken,
              'timezone': 'Europe/Paris'
          };
          HomepilotResource.associate(json,this.eventHub).then(
            function(response) {
              clearInterval(this.diagnosticStatus);
              _this.stopSmnt();
              _this.$parent.result = true;
              _this.eventHub.$emit('nextInstallStep');
          }).catch(
            function() {
            });
      },
      diagnostic : function(){
        this.diagnosticStatus = setInterval(() => {
            if (this.numberTry == 12) {
              clearInterval(this.diagnosticStatus);
              this.stopSmnt();
              this.$parent.result = false;
              this.eventHub.$emit('nextInstallStep');
            }
            else{
              this.associate()
              this.numberTry +=1
            }
        }, 10000);
      },
	}
}
