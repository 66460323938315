export default {
    props: 
    {
      module: 
      {
        type: Object,
        default:{}
      },
    },
    components: {},
    data () {
      return {
        component: null,
      }
    },
    computed: {
      loader() {
        if (!this.module.device) {
            return null
        }
        //return () => import(`http://www-dev.comfortlife.local/comfortlife/common/components/widget/home_confort/YC-4000B/widget.vue`)
        return () => import(`./widget/${this.module.device.brand}/${this.module.device.submodel}/widget.vue`)
      },
    },   
    created: function(){
      this.eventHub.$on('custom_controlCallback_'+this.module.id,this.controlCallback);
    },
    beforeDestroy: function ()
		{
			this.eventHub.$off('custom_controlCallback_'+this.module.id,this.controlCallback);
		},
    mounted: function(){
      this.loader()
        .then(() => {
           this.component = () => this.loader()
        })
        .catch(() => {
        });
    },
    methods: {
      control: function(obj){
        this.$parent.$emit('set-status', obj);
      },
      controlCallback: function(module){
        if(typeof this.$refs['custom'].onControlCallBack == 'function')
        {
          this.$refs['custom'].onControlCallBack(module);
        }
      },
  	}
}
