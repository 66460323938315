import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'

export default {
    waiting: 
    {
        add : false
    },    
    getServiceModels: function (modelName) {
        if(modelName==undefined)modelName='';
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'service/comfort/models/'+modelName,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return resolve(error);
                    });
            }
        );
    },
    getAll: function () {
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'service/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId'),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return resolve(error);
                    });
            }
        );
    },
    add: function (serviceName,service) {
        var _this = this; 
        /*service = {
            "name": "Access control service2",
            "infos": {
              "actions": [
                {
                  "type": "command",
                  "infos": {
                    "capability": "show",
                    "moduleId": "a09a9d64cfd09eccb981a5b38355b854"
                  }
                }
              ],
              "triggers": [
                {
                  "type": "status",
                  "infos": {
                    "capability": "ring",
                    "operation": "EQUAL",
                    "moduleId": "e2595943b5772ad2791136e15f8c7a76"
                  }
                }
              ]
            }
          };*/

          
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.add == true)return false;
                axios.post(Global.getBackendUrl()+'service/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+serviceName,
                service,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.add = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.add = false;
                        return reject(error);
                    });
            }
        );
    },
    edit: function (serviceId,service) {
        var _this = this; 
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.add == true)return false;
                axios.put(Global.getBackendUrl()+'service/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+serviceId,
                service,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.add = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.add = false;
                        return reject(error);
                    });
            }
        );
    },
	setStatus: function (parameterKey,parameterValue,json) {
        var _this = this; 
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.add == true)return false;
                axios.post(Global.getBackendUrl()+'service/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/'+parameterKey+'/value/'+parameterValue,
                json,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.add = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.add = false;
                        return reject(error);
                    });
            }
        );
    },   
}
