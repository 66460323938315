import { render, staticRenderFns } from "./add-device-client.vue?vue&type=template&id=fc671492&scoped=true&"
import script from "./add-device-client.js?vue&type=script&lang=js&"
export * from "./add-device-client.js?vue&type=script&lang=js&"
import style0 from "./add-device-client.css?vue&type=style&index=0&id=fc671492&scoped=true&lang=css&"
import style1 from "./add-device-client-vuetify.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc671492",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
