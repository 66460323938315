export default class{

    constructor() {
        this.displayName = ''
        this.dashboardCount = 0
        this.roomCount = 0
        this.userCount = 0
        this.deviceCount = 0
        this.groupCount = 0
        this.scenarioCount = 0
        this.serviceCount = 0
    }

    setVersionsInfos(obj) {
        if(typeof obj == 'object'){
            _merge(this, obj);
        }
    }

}
