export default {
    data: function () {
        return {
        }
    },
    created: function () {
        this.$parent.$parent.initMode('Wifi');
        this.eventHub.$emit('setDisplayPrevious', true);
    },
	  methods: {
		  next: function(){
			  this.eventHub.$emit('nextStepWifi');
		  }
	}
}
