export default class{

    socket = undefined;

    constructor() {
        if(window.Socket)
        {
            this.socket = new Socket();
        }
    }

    socketExists = function() {
        return (this.socket!=undefined);
    }

    onData = function(callback) {
        this.socket.onData = function(data)
        {
            if(callback)
            {
                callback(data);
            }
        }
    }

    onError = function(callback) {
        this.socket.onError = function(errorMessage)
        {
            if(callback)
            {
                callback(errorMessage);
            }
        }
    } 

    onClose = function(callback) {
        this.socket.onClose = function(hasError)
        {
            if(hasError)
            {
                callback(hasError);
            }
        }
    }

    open = function(host, port, onSuccess, onError) {
        this.socket.open(host, port, onSuccess, onError);
    }

    shutdownWrite = function(onSuccess, onError) {
        this.socket.shutdownWrite(onSuccess, onError);
    }

    close = function(onSuccess, onError) {
        this.socket.close(onSuccess, onError);
    }

    shutdownWriteAndClose = function() {
        if(this.socket!=undefined)
        {
            this.socket.shutdownWrite();
            this.socket.close();
        }
    }

    write(dataString, onSuccess, onError) {
        var data = new Uint8Array(dataString.length);
        for (var i = 0; i < data.length; i++) {
          data[i] = dataString.charCodeAt(i);
        }
        if(this.socket)
        {
            this.socket.write(data, onSuccess, onError);
            return true;
        }
        else
        {
            return false;
        }
    }
}
