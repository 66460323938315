import Vue from 'vue'

import Notifications from 'vue-notification'
import Velocity from 'velocity-animate'

import StringService from 'common/services/string.js'
import DeviceResource from 'common/services/resources/device.js'
import CommonResource from 'common/services/resources/common.js'
import Notification from 'common/classes/Notification.js'
import EventService from 'common/services/event.js'
import CordovaService from 'common/services/cordova.js'
import WindowService from 'common/services/window.js'
import Language from 'common/services/language.js'
import UartService from 'common/services/uart.js'
import ColorService from 'common/services/color.js'
import ShareResource from 'common/services/resources/share.js'

import Global from 'common/services/resources/global.js'
import LogService from 'common/services/log.js'

const TAG = 'notification';

Vue.use(Notifications, { Velocity });
export default {
    props:['subMenuSharesLocations','allDevices','devices','subDevices', 'services', 'scenarios', 'notificationsModules', 'externalAccounts', 'user', 'sharesLocations'],
    data:
        function () {
                    return {
                                CONSTANTS:
                                {
                                  DISPLAY_ALERT_TIMEOUT: 3000,
                                  NOTIFICATION_TIMEOUT: 10000,
                                  NOTIFICATION_TXT : _upperFirst(this.$gettext('notification')),
                                  NOTIFICATION_LEVEL_SUCCESS: "success",
                                  NOTIFICATION_LEVEL_INFO: "info",
                                  NOTIFICATION_LEVEL_WARNING: "warning",
                                  NOTIFICATION_LEVEL_ALERT: "alert",
                                  KEY : 
                                  {
                                    'unauthorized':
                                    {
                                      'txt': this.$gettext('You need to upgrade you account version to do this action'),
                                      'icon': 'shopping-basket'
                                    },
                                    'cannot_installed':
                                    {
                                      'txt': this.$gettext('The device cannot be installed. Please contact the administrator.'),
                                      'icon': 'error'
                                    }
                                  },
                                  MODEL_TXT : 
                                  {
                                    'wa':
                                    {
                                      'txt': this.$gettext('Sound detected at %{name}')
                                    },
                                    'sthi':
                                    {
                                      'txt': this.$gettext('%{name} display %{value} degrees')
                                    }
                                  },
                                  CAPABITILTIES_TXT : 
                                  {
                                    'status': 
                                    {
                                      'on': this.$gettext('%{name} has been turned on'),
                                      'off': this.$gettext('%{name} has been turned off'),
                                      'request': this.$gettext('The request has been sent'),
                                    },
                                    'trigger':
                                    {
                                      'txt': this.$gettext('%{name} has been triggered'),
                                    },
                                    'active': 
                                    {
                                      'enable': this.$gettext('%{name} has been enabled'),
                                      'disable': this.$gettext('%{name} has been disabled'),
                                    },
                                    'protection': 
                                    {
                                      'arm': this.$gettext('%{name} has been armed'),
                                      'disarm': this.$gettext('%{name} has been disarmed'),
                                      'partial': this.$gettext('%{name} has been partialy armed'),
                                    },
                                    'dim':
                                    {
                                      'txt': this.$gettext('%{name} has been dimmed to %{value}'),
                                    },
                                    'position_vertical': 
                                    {
                                      'up': this.$gettext('%{name} is opening'),
                                      'down': this.$gettext('%{name} is closing'),
                                      'instant_up': this.$gettext('%{name} is instantly opening'),
                                      'instant_down': this.$gettext('%{name} is instantly closing'),
                                      'scroll_up': this.$gettext('%{name} is scrolling up'),
                                      'scroll_down': this.$gettext('%{name} is scrolling down'),
                                    },
                                    'ring':
                                    {
                                      'txt': this.$gettext('%{name} is ringing!'),
                                    },
                                    'alert':
                                    {
                                      'txt': this.$gettext('Alert detected on %{name}!'), 
                                    },
                                    'detect':
                                    {
                                      'txt': this.$gettext('Motion detected at %{name}'),
                                    },
                                    'access': 
                                    {
                                      'open': this.$gettext('%{name} is opening'),
                                      'close': this.$gettext('%{name} is closing'),
                                    },
                                    'speed': 
                                    {
                                      'txt': this.$gettext('The %{name} speed is changing'),
                                    },
                                    'temperature': 
                                    {
                                      'txt': this.$gettext('%{name} : %{value} °C'),
                                    },
                                    'temperature': 
                                    {
                                      'txt': this.$gettext('%{name} : %{value} °C'),
                                    },
                                    'temperatureDiff': 
                                    {
                                      //show : Boolean => unable notification for specific capability
                                      'show': false,
                                    },
                                    'desiredTemperature':
                                    {
                                      'show': false,
                                    },
                                    'call': 
                                    {
                                      'txt': this.$gettext('Somebody’s ringing at %{name}. Open'),
                                    },
                                    'record':
                                    {
                                      'start': this.$gettext('A record started on %{name}'),
                                      'stop': this.$gettext('A record stopped on %{name}'),
                                    },
                                    'rgb': 
                                    {
                                      'txt': this.$gettext('%{name} switched to a new <span style="color:%{color};">color</span>'),
                                    },
                                    'frequency': 
                                    {
                                      'txt': this.$gettext('%{name} changed her frequency of video stream'),
                                    },
                                    'detection': 
                                    {
                                      'enable': this.$gettext('%{name} detection enabled'),
                                      'disable': this.$gettext('%{name} detection disabled'),
                                    },
                                    'record_on_detect': 
                                    {
                                      'enable': this.$gettext('Recording on %{name} detection enabled'),
                                      'disable': this.$gettext('Recording on %{name} detection disabled'),
                                    },
                                    'light_on_detect': 
                                    {
                                      'enable': this.$gettext('Light on %{name} detection enabled'),
                                      'disable': this.$gettext('Light on %{name} detection disabled'),
                                    },
                                    'color_temperature': 
                                    {
                                      'txt': this.$gettext('%{name} color temperature has changed'),
                                    },
                                    'brightness': 
                                    {
                                      'txt': this.$gettext('%{name} brightness changed'),
                                    },
                                    'thermostat': 
                                    {
                                      'heat': this.$gettext('%{name} has been turned on'),
                                      'cool': this.$gettext('%{name} has been turned off'),
                                      'auto': this.$gettext('%{name} has been set to automatic')
                                    },
                                  }
                                },
                                id: 0,
                                commandIdList:[],
                                background: false,
                                mobile: false,
                    }
                },
    beforeDestroy: function ()
    {
      document.removeEventListener('pause', this.setBackground.bind(this,true), false);
      document.removeEventListener('resume', this.setBackground.bind(this,false), false);
      document.removeEventListener('resize', this.launchResize);
      this.eventHub.$off('shownotification', this.show);
      this.eventHub.$off('displayAlert', this.displayAlert);
      this.eventHub.$off('displayAlertKey', this.displayAlertKey);
      this.eventHub.$off('setEventPayload', this.setEventPayload);
      this.eventHub.$off('setBackground', this.setBackground);
    },
    created: function () {
        document.addEventListener('pause', this.setBackground.bind(this,true), false);
        document.addEventListener('resume', this.setBackground.bind(this,false), false);
        document.addEventListener('resize', this.launchResize);
        this.eventHub.$on('shownotification', this.show);
        this.eventHub.$on('displayAlert', this.displayAlert);
        this.eventHub.$on('displayAlertKey', this.displayAlertKey);
        this.eventHub.$on('setEventPayload', this.setEventPayload);
        this.eventHub.$on('setBackground', this.setBackground);
        this.init();
        this.launchResize();
    },
    methods:
        {
            launchResize: function()
            {
              if(window.innerWidth<=1024)
              {
                this.mobile = true;
              }
              else
              {
                this.mobile = false;
              }
            },
            init: function()
            {
            },
            getIcon: function(icon)
            {
                var cls = {
                    'icon' : true,
                    'cl-icon' : true,
                }
                cls['cl-icon-'+icon] = true;
                return cls;
            },
            setBackground: function(background)
            {
              this.background = background;
            },

            displayAlertKey: function (key,type)
            {          
              var message = this.CONSTANTS.KEY[key].txt;
              var icon = (this.CONSTANTS.KEY[key].icon?this.CONSTANTS.KEY[key].icon:undefined);
              var messageType = (type?type:undefined);
              this.displayAlert(message, messageType, icon);
            },
            displayAlert: function (message,type,icon,duration)
            {
              var json =
              {
                notification:
                {
                  header: this.CONSTANTS.NOTIFICATION_TXT,
                  level: (type==undefined?'alert':type),
                  title: message,
                  type: 'displayAlert',
                  icon:
                  {
                    type: 'default',
                  }
                }
              }
              if (icon != undefined){
                json.notification.icon = {
                  type: 'local',
                  uri: icon
                }
              }
              this.show(json,duration);
            },
            /**
             * 
             * @param {*} json 
             * @param {string} callbackEvent
             */
            setEventPayload: function (json,callbackEvent)
            {
              LogService.consoleLogTag(TAG,'setEventPayload','json',json);
              let homeTitle = '';
              let noDisplayNotification = false;
              json = StringService.toJson(json);
              if(!json || (this.background && json.event == 'alert' && (callbackEvent=='mqtt' ||callbackEvent=='udp')))return false;
              /*json = {
                event: "infos",
                ip: "",
                modules: 
                [
                  {
                    device:
                    {
                      associateStatus:"",
                      bearerId:"",
                      code:"",
                      model:0,
                      rf:"",
                      status:
                      [
                        {capability: "status", value: "[0]"}
                      ],
                      switchs:[],
                      unit:0,
                      value:""
                    },
                    //id: "8e926c525cb6bf75bbb4229d751ea23e"
                    id : "single_lg"
                  }
                ],
                timestamp:"2018-03-29 11:53:33",
                uid:"681bb0bf64beb155b0bab973afe70e05",
                uptime:0
              };*/

              //private function to clean the payload
              var cleanPayload = function ()
              {
                if(json.uid)
                {
                  json.uid = _trim(json.uid,'{}');
                }
                json.modules = StringService.toJson(json.modules);
                if(json.modules)
                {
                  json.modules.forEach(function(module,index) {
                    module.id = _trim(module.id,'{}');
                  });
                }
              }
              cleanPayload();
              LogService.consoleLogTag(TAG,'setEventPayload','json',json);
              if(json.modules && Array.isArray(json.modules) && json.modules.length > 0 && json.modules[0].device)
              {
                //If getinfo
                if(json.modules[0].device.bGetInfo)
                {
                  return false;
                }

                //UART MANAGEMENT
                /*if(json.modules[0].device.uartFrame)
                {
                  var module = UartService.getModuleFromUartFrame(json.modules[0].device,this.allDevices);
                  if(module)
                  {
                    json.modules = [module];
                  }
                }*/
                var d = DeviceResource.getModule(json.modules[0].id, this.allDevices, this.subDevices);
                if(json.event=="infos")
                {
                  this.eventHub.$emit('device_event_infos_'+json.modules[0].id);
                }
                
                LogService.consoleLogTag(TAG,'setEventPayload','d',d);
                /*OFFLINE*/
                if(d && d.module)
                {
                  var moduleDevice = _find(this.allDevices,{id:d.module.id});
                  if(moduleDevice)
                  {
                    let home = ShareResource.getSubMenuSharesLocationsFromZoneId(this.subMenuSharesLocations,moduleDevice.zoneId);
                    homeTitle = (home?'['+home.title+']':'');
                    moduleDevice.offline = false;
                    if(UartService.waitStateCallbackArray[d.module.id])
                    {
                      clearTimeout(UartService.waitStateCallbackArray[d.module.id]);
                      UartService.waitStateCallbackArray[d.module.id] = undefined;
                    }
                  }
                }
                if(json.action){
                  if(json.modules[0].device && json.modules[0].device.status && json.modules[0].device.status[0]){
                    if(_find(json.modules[0].device.status,{capability:'show'})!=undefined)
                    {
                      this.eventHub.$emit('showCamera',d.module);
                      //this.eventHub.$emit('launchEZFullscreenFinished');
                      return;
                    }
                    else if(_find(json.modules[0].device.status,{capability:'stop'})!=undefined)
                    {
                      this.eventHub.$emit('stopEZFullscreenFinished');
                      //this.eventHub.$emit('stopCamera',d.module);
                      return;
                    }
                    else if(d.module && d.module.device.model == 'vdp' && json.modules[0].device.status[0].value == 'answer'){
                      this.eventHub.$emit('closeModalMainControlDevice');
                      return;
                    }
                  }
                }
              }
              //check if the payload has an event and modules
              // WARNING : Silent is just to not display the notification but we need to make the treatment on the device
              if(json.event == undefined || json.event == 'silent' || _isEmpty(json.modules)) return false;
              //If "modules" key is a string -> convert to JSON
              try {
                json.modules = (typeof json.modules == 'string' ? JSON.parse(json.modules) : json.modules);
              } catch (e) {
                json.modules = [];
              }
              LogService.consoleLogTag(TAG,'setEventPayload','callbackEvent',callbackEvent);
              if(callbackEvent!='onNotificationTap')
              {
                //Check if command uid already exists
                if(this.commandIdList.indexOf(json.uid)!=-1) return false;
                this.commandIdList.push(json.uid);
              }
              /*
              Execute commands according to the received payload.
              TODO : This part will have to be removed from this function and integrated into a function that manages the commands.
              */
              /*if(this.inApp)
              {*/
                LogService.consoleLogTag(TAG,'setEventPayload','json.modules[0].device',json.modules[0].device);
                if(json.modules[0].device)
                {
                  var m = DeviceResource.getModule(json.modules[0].id, this.allDevices, this.subDevices);
                  if(m.module && json.modules[0].device.status && json.modules[0].device.status[0])
                  {
                    if(json.modules[0].device.status[0].capability == 'show'){
                      var ea = ExternalResource.getExternalAccountsByModule(m.module.id, this.sharesLocations, this.externalAccounts);
                      CordovaService.launchFullscreen(this.eventHub, ea,m.module,(this.$route.name == 'view'?'landscape':'portrait'),this.uuidV4());
                      return;
                    }
                    else if(json.modules[0].device.status[0].capability == 'call' && json.modules[0].device.status[0].value == 'pending'){
                      EventService.executeOnCall(m.module,this.eventHub,json,callbackEvent);
                      return;
                    }
                  }
                }
              //}

              //DEVICE
              if(json.modules[0].device){
                var event = json.event;
                var capabilityObj = undefined;
                var moduleName = '';
                var moduleParent = undefined;
                var _this = this;
                var m = undefined;

                json.modules.forEach(function(module,index) {
                  m = DeviceResource.getModule(module.id, _this.allDevices, _this.subDevices);
                  //Check if the capabilty exists
                  if(module.device && module.device.status)
                  {
                    capabilityObj = module.device.status[0];
                  }
                  if(capabilityObj==undefined)return false;
                  if(capabilityObj.capability == 'access' || capabilityObj.capability == 'alert' || capabilityObj.capability == 'detect'){
                    _this.eventHub.$emit('widgetAlert', json.modules[0].id);
                  }
                  if(m.module==undefined) return false;
                  //Check if the device is a Eliot device
                  if(m.module.device)
                  {
                    if(m.module.device.model == 'plug')
                    {
                      var capabilityObjValueJson = StringService.toJson(capabilityObj.value);
                      if(Array.isArray(capabilityObjValueJson))
                      {
                        if(capabilityObjValueJson.length==1)
                        {
                          capabilityObj.value = (capabilityObj.value=='[1]'?'on':'off');
                        }
                        else
                        {
                          capabilityObj.value = 'request';
                        }
                      }
                    }
                    if(m.module.device.model == 'sthi' && json.event == 'infos')
                    {
                      _this.eventHub.$emit('refreshInfoFromEvent',module);
                      if(json.modules[0].device.status[0].capability == 'humidity'){
                        noDisplayNotification = true;
                      }
                    }
                    else if(m.module.device.status &&  (m.module.device.status[0].capability == 'access' || _find(m.module.device.status,{capability:'status'}) || m.module.device.status[0].capability == 'position_vertical'))
                    {
                      m.module.device.status.forEach(function(status,index) {
                        var statusJson = _find(json.modules[0].device.status,{capability:m.module.device.status[index].capability});
                        if(statusJson && statusJson.value)
                        {
                          m.module.device.status[index].value = statusJson.value;
                        }
                      });
                    }
                    _this.eventHub.$emit('custom_controlCallback_'+module.id, module);
                  }
                  if(noDisplayNotification)return false;
                  module.device.status.forEach(function(elt) {
                    //if(elt.value && elt.value != ''){
                      if(m.module.notification_level == 'INFO' || m.module.notification_level == 'ALERT'){
                        var notification = _this.getJsonNotification(event, elt, m.module, m.moduleParent, m.module.notification_level,homeTitle);
                        //Increment the badge number of the device
                        if(m.module.notification_level == 'ALERT' && json.modules[0].device){
                          _this.user.decrementResourcesCount(m.module, 'alert');
                          var notifications = new Notification();
                          notifications.addNotification(json.modules[0].id, _this.notificationsModules, _this.allDevices, json);
                        }
                      }
                      else if(m.moduleParent){
                        if(m.moduleParent.notification_level == 'INFO' || m.moduleParent.notification_level == 'ALERT')
                        {
                          var notification = _this.getJsonNotification(event, elt, m.module, m.moduleParent, m.moduleParent.notification_level,homeTitle);
                        }
                        if(m.moduleParent.notification_level == 'ALERT' && json.modules[0].device){
                          _this.user.decrementResourcesCount(m.moduleParent, 'alert');
                          var notifications = new Notification();
                          notifications.addNotification(m.moduleParent.id, _this.notificationsModules, _this.allDevices, json, m.module.notificationName);
                        }
                      }
                      //Check if the module exists in main devices array to get the module name
                      if(notification)
                      {
                        _this.show(notification);
                      }
                    //}
                  });
                });
              }
              //SERVICE
              else if(json.modules[0].service){
                var service = _find(this.services, function(o) { return o.id == json.modules[0].id; });
                let home = ShareResource.getSubMenuSharesLocationsFromZoneId(this.subMenuSharesLocations,service.zoneId);
                homeTitle = (home?'['+home.title+']':'');
                if(service.notification_level == 'INFO' || service.notification_level == 'ALERT'){
                  var _this = this;
                  var notification = this.getJsonServiceNotification(service, json,  homeTitle);
                  this.show(notification);
                  if(service.notification_level == 'ALERT'/* && json.event == 'alert'*/){
                    _this.user.decrementResourcesCountService(service, json);
                    var notifications = new Notification();
                    notifications.addNotification(json.modules[0].id, _this.notificationsModules, _this.services, json);
                  }
                }
              }
              //SCENARIO
              else if(json.modules[0].scenario){
                var scenario = _find(this.scenarios, function(o) { return o.id == json.modules[0].id; });
                if(scenario.notification_level == 'INFO'){
                  this.user.decrementResourcesCount(scenario.scenario, 'actions');
                  var message = this.CONSTANTS.CAPABITILTIES_TXT.trigger.txt;
                  message = this.$gettextInterpolate(message, {name: scenario.name})
                  this.eventHub.$emit('displayAlert', message, 'success');
                }
              }
            },

            getJsonServiceNotification: function(service, json,homeTitle){
              var message = '';
              var level = 'info';
              if(service.notification_level == 'ALERT')
                level = 'alert'
              var icon = {
                type: 'default'
              };
              if(service.icon != null)
                icon = {
                  type: 'local',
                    uri: service.icon
                };
              if(json.event == 'infos')
                message = this.CONSTANTS.CAPABITILTIES_TXT[json.modules[0].service.status[0].capability][json.modules[0].service.status[0].value];
              else
                message = this.CONSTANTS.CAPABITILTIES_TXT[json.event].txt;
              message = this.$gettextInterpolate(message, {name: service.name});
              return {
                notification: {
                  header: homeTitle +' '+this.CONSTANTS.NOTIFICATION_TXT,
                  level: level,
                  icon: icon,
                  title: service.name,
                  message: message,
                  localized_message: message,
                  device_uid: service.id,
                  device_name: service.name
                }
              }
            },

            getJsonNotification: function(event, capabilityObj, module, moduleParent, notificationLevel, homeTitle)
            {
              var capabilityTxtObj = '';
              if(module.device && module.device.model == 'wa'){
                capabilityTxtObj = this.CONSTANTS.MODEL_TXT[module.device.model];
              }
              else{
                capabilityTxtObj = this.CONSTANTS.CAPABITILTIES_TXT[capabilityObj.capability];
              }
              var icon = {
                type: 'default'
              };
              var title = '';
              var show = true;
              var deviceName = '';
              var deviceUid = '';
              var level = 'info';
              
              if(capabilityTxtObj)
              {
                show = !(capabilityTxtObj.show===false);
              }

              if(capabilityTxtObj==undefined)
              {
                capabilityTxtObj = {
                                          txt: module.name +(capabilityObj.value==undefined?'':' - '+capabilityObj.value),
                                       }
              }
              var moduleDevice =  (moduleParent!=undefined ? moduleParent: module);
              if(moduleDevice!=undefined)
              {
                if(moduleDevice.icon != null){
                  icon = {
                    type: 'local',
                    uri: moduleDevice.icon+'-default'
                  }
                }
                else{
                  icon = {
                    type: 'local',
                    uri: moduleDevice.device.model
                  }
                }
              }

              if(notificationLevel == "ALERT"){
                level = 'alert'
              }

              deviceUid = module.id;
              title = (module.notificationName?module.notificationName:module.name);
              deviceName = title;
              var message = (capabilityTxtObj.txt?capabilityTxtObj.txt:(capabilityTxtObj[capabilityObj.value]?capabilityTxtObj[capabilityObj.value]:""));
              message = this.$gettextInterpolate(message, {name: title, value: capabilityObj.value, 
                color: (capabilityObj.value && ColorService.checkHex(capabilityObj.value)?'#'+capabilityObj.value.substr(2):'')
              });
              var localizedMessage = message;

              return {
                notification: {
                  header: homeTitle +' '+this.CONSTANTS.NOTIFICATION_TXT,
                  show: show,
                  level: level,
                  icon: icon,
                  title: title,
                  message: message,
                  localized_message: localizedMessage,
                  device_uid: deviceUid,
                  device_name: deviceName
                }
              }
            },

            show: function(json, duration)
            {
                if(window.innerWidth<=1024)
                {
                  /*this.$notify({
                    group: 'notification',
                    clean: true
                  })*/
                }
                var group = 'notification';
                var notification = json.notification;
                if(notification.message=="")return false;
                var title = (notification.localized_message?notification.localized_message:notification.title);

                var text = notification.message;
                var icon = notification.icon;
                var duration = (duration?duration:(notification.type=='displayAlert'?this.CONSTANTS.DISPLAY_ALERT_TIMEOUT:this.CONSTANTS.NOTIFICATION_TIMEOUT));
                var type =
                {
                    level: notification.level,
                    type: notification.type,
                    icon:{},
                    device_uid: notification.device_uid,
                    header:notification.header
                }
                if(icon.type=="default")
                    type.icon = this.getIcon(notification.level);
                else if(icon.type=="local")
                    type.icon = this.getIcon(icon.uri);
                else
                    type.icon.uri=icon.uri;
                //this.id++;
                this.$notify({
                  group : group,
                  title : title, 
                  text : text, 
                  type : type, 
                  duration : duration
                });
            },
            click: function(props)
            {
                if(props.item.title == this.CONSTANTS.KEY['unauthorized'].txt && this.$store.state.variantManager.isComfortlife()){
                  this.openVersionMarket();
                }
                var m = DeviceResource.getModule(props.item.type.device_uid, this.allDevices, this.subDevices);
                if(m.module && m.module.device && m.module.device.model == "vdp"){
                  var status = {
                    "capability": "trigger"
                  }
                  CommonResource.setStatus(m.module, status, m.module.id, this.eventHub);
                }
                props.close();
                //if(props.type=='displayAlert')return false;
                //this.$router.push('/home/events');
                //this.eventHub.$emit('navbarDrawerSelectMenu','events');
            },
            openVersionMarket: function()
            {
                var lang = (Language.getUserLanguage()=='fr'?'fr':'en');
			          WindowService.open(Global.WEBSITE_STORE+'/services-'+lang+'/versions-'+lang,'_blank',this.inApp);
            },
        }
}
