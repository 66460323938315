import DeviceResource from 'common/services/resources/device.js'
import CloudSpinner from 'common/components/cloud-spinner/cloud-spinner.vue'

import axios from 'axios'

const TRY_TIMEOUT = 5000;
const TRY_NUMBER = 8;
const ASSOCIATION_TIMEOUT = 5000;

export default {
	props: ['association', 'img','deviceObj','chooseType'],
	components:{CloudSpinner},
	data:
		function () {
			return {
					CONSTANTS:
					{
						ADD_ERROR : this.$gettext('Unable to add the device'),
						ADD_SUCCESS : this.$gettext('The device has been successfully added'),
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
					},
					associationInterval: undefined,
					bAssociateFailed:false,
					stepTimeout: undefined,
					diagnosticInterval: undefined,
					diagnosticStatus: undefined,
					wait:
					{
						percent: 0,
						time: 0,
					},
					numberTry : 0,
					picture: '',
					waiting: false,
					device: {},
					cancelSource:undefined,
					stepNumber: 1,
					steps:
					[
						{
							loading: false,
							isValid: true,
							txt: this.$gettext('Association in progress')
						}
					]
			}
	},
	beforeDestroy () {
		clearTimeout(this.stepTimeout);
		clearInterval(this.associationInterval);
		clearInterval(this.diagnosticInterval);
		clearInterval(this.diagnosticStatus);
	},
	created: function () {
		this.eventHub.$emit('setDisplayPrevious', false);
		if(this.chooseType=='discover')
		{
			this.waiting = true;
			this.executeTimeout('associate',ASSOCIATION_TIMEOUT, TRY_NUMBER);
		}
		else{
			this.associationTimeout = setTimeout(() => {
				this.executeTimeout('associate',ASSOCIATION_TIMEOUT, TRY_NUMBER);
			}, ASSOCIATION_TIMEOUT);
			this.diagnosticInterval = setInterval(() => {
				if (this.wait.percent >= 100) {
					this.associateFailed();
				}
				this.wait.percent += 3.34;
				this.wait.time += 1;
			}, 1000);
		}

	},
	methods: {
		associate: function(){
			var _this = this;
			var commandId = this.uuidV4();
			var bearerId = this.$parent.homepilot.id;
			var model = this.$parent.model;
			var submodel = this.$parent.submodel.name;
			var locationId = this.$parent.$parent.plant.zones[0].id;
			var brand =this.$parent.$parent.brand;
			var CancelToken = axios.CancelToken;
			this.cancelSource = CancelToken.source(); 
			DeviceResource.associateTo(model.name,model.systems[0],submodel,bearerId,commandId,locationId,brand,this.deviceObj,this.eventHub, this.cancelSource).then(
				function(response) {
					_this.eventHub.$emit('setDeviceId',response.data.id);
					let deviceObj = {
						"id": response.data.id,
						"identifier": response.data.device.identifier,
						"name": '',
						"icon": null,
						"notificationLevel": 'INFO',
						"alert" : []
					}
					_this.eventHub.$emit('setDeviceObj',deviceObj);
					_this.launchAssociateStatus(deviceObj);
					_this.device = response.data;
			}).catch(
				function(e) {
					if(e == 'Error: Network Error')
					{
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
						_this.associateFailed();
					}
					else if(e.response && e.response.status == 401)
					{
						_this.eventHub.$emit('logout');
					}	
					else
					{
						if(!_this.bAssociateFailed)
						{
							_this.executeTimeout('associate',ASSOCIATION_TIMEOUT, TRY_NUMBER);
						}
					}
			});
		},

		diagnostic : function(id){
			this.diagnosticStatus = setInterval(() => {
				if (this.numberTry == TRY_NUMBER) {
					this.waiting = false;
					clearInterval(this.diagnosticStatus);
					this.eventHub.$emit('setValidation', false);
					this.eventHub.$emit('setInternalMainStep', 6);
				}
				else{
					this.associateStatus(id)
					this.numberTry +=1
				}
			}, TRY_TIMEOUT);
		},

		launchAssociateStatus: function(module){
			this.numberTry = 1;
			this.waiting = true;
			this.steps[this.stepNumber-1].loading = true;
			clearInterval(this.diagnosticInterval);
			clearTimeout(this.stepTimeout);
			this.diagnostic(module.id);
		},

		associateStatus: function(id){
			var _this = this;
			var model = this.$parent.model;
			DeviceResource.associateStatus(model.name,model.systems[0],id).then(
				function(response) {
					if(response.data[model.name+'s'][0].associateStatus  == 'ASSOCIATED'){
						_this.waiting = false;
						clearInterval(_this.diagnosticStatus);
						_this.eventHub.$emit('nextInternalMainStep');
						_this.eventHub.$emit('nextHeaderStep');
						if(_this.device.id)
						{
							_this.eventHub.$emit('addAssociateDevice', _this.device);
						}
					}
			}).catch(
				function() {
				});
		},

		executeTimeout: function(execFunc, duration, tries)
		{
			duration = (duration==undefined?TRY_TIMEOUT:duration);
			tries = (tries==undefined?TRY_NUMBER:tries);
			if(this.numberTry==tries)
      		{
				this.associateFailed();
			}
			else
			{
				this.numberTry++;
				clearTimeout(this.stepTimeout);
				this.stepTimeout = setTimeout(() => {
					this[execFunc]();
				}, duration);
			}		
		},

		associateFailed: function()
		{
			this.bAssociateFailed = true;
			if(this.cancelSource)
			{
				this.cancelSource.cancel('Operation canceled.');
			}
			clearTimeout(this.stepTimeout);
			clearInterval(this.associationInterval);
			clearInterval(this.diagnosticInterval);
			clearInterval(this.diagnosticStatus);
			this.eventHub.$emit('setValidation', false);
			this.eventHub.$emit('setInternalMainStep', 6);
		}
	}

}
