export default {
  props: ['list'],
  data:
		function () {
			return {
        CONSTANTS:{
          TITLE : this.$gettext('Select an <b>icon</b> for your device')
        },
			}
	},
  created: function () {
  },
	methods: {
    setIcon : function(icon){
      this.eventHub.$emit('setDeviceIcon', icon)
    }
  }
}
