export default class{
    
    constructor(username, password, resourcesCount, isRegular, rememberMe, hidePassword, authorization, versionInfos) {
        this.username = username || '';
        this.password = password || '';
        this.resourcesCount = resourcesCount || {"sms":0,"email":0,"push":0};
        this.isRegular = isRegular || true;
        this.rememberMe = rememberMe || true;
        this.hidePassword = hidePassword || true;
        this.authorization = authorization || '';
        this.versionInfos = versionInfos || {};
    }
    setResourcesCount(resourcesCount) {
       this.resourcesCount = resourcesCount;
    }
    setVersionInfos(versionInfos) {
        this.versionInfos = versionInfos;
    }
    clear() {
        this.username = '';
        this.password = '';
        this.resourcesCount = {"sms":0,"email":0,"push":0};
        this.isRegular =  true;
        this.rememberMe =  true;
        this.authorization =  '';
    }
    decrementResourcesCount(module, type)
    {
        var notifs = Object.keys(this.resourcesCount);
        if(module[type]){
            for(var i=0; i<notifs.length; i++){
                if(this.resourcesCount[notifs[i]] == 'INF'){
                    return
                }
                else if(this.resourcesCount[notifs[i]] > 0){
                    var number = _filter(module[type], function(o) { return o.type == notifs[i]; }).length;
                    this.resourcesCount[notifs[i]] -= number;
                }
            }
        }
    }
    decrementResourcesCountService(service, json){
        var state = '';
        var module = {};
        var actions = [];
        if(json.modules[0].service.triggeredBy == 'manual')
            state = 'arm'
        else
            state = service.service.status[0].value
        for(var i=0; i<service.service.infos.actions.length; i++){
            if(_findIndex(service.service.infos.actions[i].forStates, function(o) { return o.value == state }) != -1){
                actions.push(service.service.infos.actions[i]);
            }
        }
        module.alert = actions;
        this.decrementResourcesCount(module, 'alert');
    }
}
