import CordovaService from 'common/services/cordova.js'
import CameraResource from 'common/services/resources/camera.js'
import ExternalResource from 'common/services/resources/external.js'
export default {
    props: ['module', 'frameRate', 'externalAccounts','sharesLocations'],
    data: function () {
            return {
              CONSTANTS: {
                //DISPLAYIMAGETIMEOUT: 75
                DISPLAYIMAGETIMEOUT: 5,
                CAMERA_ERROR_PLAY_CAMERA_TIMEOUT:2000
              },
              src:'',
              loaded:false,
              error:null,
              cameraErrorModal: false,
              verificationCode:''
           }
    },
    created: function () {
        document.addEventListener('deviceready', this.playCamera.bind(this), false);
        this.eventHub.$on('cameraError', this.cameraError);
        this.eventHub.$on('launchEZFullscreenFinished', this.stopProcess);
        this.eventHub.$on('stopEZFullscreenFinished', this.startProcess);
    },

    mounted: function () {
        var _this = this;
        window.setTimeout(function() {
            _this.playCamera();
        }, 5000);
    },
    
    destroyed: function(){
        this.eventHub.$off('cameraError', this.cameraError);
        this.eventHub.$off('launchEZFullscreenFinished', this.stopProcess);
        this.eventHub.$off('stopEZFullscreenFinished', this.startProcess);
        this.stopProcess();
    },
	methods: {
        cameraStyle: function () {
            var style = {};
            if(this.module.device.brandObj)
                style.backgroundImage = 'url("'+this.module.device.brandObj.imagePath+'")';
            style.backgroundSize= 'contain';
            style.backgroundRepeat= 'no-repeat';
            style.backgroundPosition= 'center';
            return style;
        },
        cameraError: function (error,module,uuidV4) {
            if(module.id!=this.module.id) return false;
            var _this = this;
            var brandName = module.device.brandObj.name;
            var ea = ExternalResource.getExternalAccountsByModule(this.module.id,this.sharesLocations,this.externalAccounts);
            var displayImageTimeout = ([1,2,3,4,5,6,7,8,9,10].indexOf(parseInt(this.frameRate))==-1?this.CONSTANTS.DISPLAYIMAGETIMEOUT:(parseInt(this.frameRate)));
            if(error.type=='accessTokenError')
            {
              ExternalResource.accountRefresh(brandName,this.$store.state.plantUser).then(
                function(response) {
                    var externalAccount = _find(ea, {external:{name:_this.module.device.brand.toLowerCase()}});
                    externalAccount.account.accessToken = response.data.accessToken;
                    CordovaService.playCamera(_this,ea,_this.module, displayImageTimeout,uuidV4);
                }).catch(
                  function(e) {
                    console.log(e);
                    error.initializeToGeneral();  
                    _this.eventHub.$emit('displayAlert', _this.$gettext(error.txt)+(Number.isInteger(error.errorCode)?' ('+_this.$gettextInterpolate(_this.$gettext('error: %{errorCode}'), {errorCode: error.errorCode })+')':''));
                });
            }
            else
            {
                this.error = error;
                setTimeout(function() {
                    CordovaService.playCamera(_this,ea,_this.module, displayImageTimeout,uuidV4);
                }, this.CONSTANTS.CAMERA_ERROR_PLAY_CAMERA_TIMEOUT);

            }
            if(error.type!='accessTokenError' && error.type!='verificationCodeError')
                this.eventHub.$emit('displayAlert', this.$gettext(error.txt)+(Number.isInteger(error.errorCode)?' ('+this.$gettextInterpolate(this.$gettext('error: %{errorCode}'), {errorCode: error.errorCode })+')':''));
        },
        settings: function () {
            this.verificationCode = (this.module.device.verifyCode?this.module.device.verifyCode:'');
            this.cameraErrorModal = true;
        },
        checkVericationCode: function () {
            var max = 12;
            if (this.verificationCode.length > max) {
                this.verificationCode = this.verificationCode.substr(0, max);
            }
        },      
        sendVerificationCode: function () {
            this.module.device.verifyCode = this.verificationCode;
            this.cameraErrorModal = false;
            var displayImageTimeout = ([1,2,3,4,5,6,7,8,9,10].indexOf(parseInt(this.frameRate))==-1?this.CONSTANTS.DISPLAYIMAGETIMEOUT:(parseInt(this.frameRate)));
            var ea = ExternalResource.getExternalAccountsByModule(this.module.id,this.sharesLocations,this.externalAccounts);
            CordovaService.playCamera(this,ea,this.module,displayImageTimeout,this.uuidV4());
            CameraResource.setVerifyCode(this.module.id,this.module.device.verifyCode).then(
                function(response) {
                  _this.eventHub.$emit('setDevice', _this.module, 'verifyCode',_this.verificationCode);
                }).catch(
                function() {});      
        },

        launchFullscreen: function () {
            var ea = ExternalResource.getExternalAccountsByModule(this.module.id,this.sharesLocations,this.externalAccounts);
            CordovaService.launchFullscreen(this.eventHub, ea,this.module,(this.$route.name == 'view'?'landscape':'portrait'),this.uuidV4());
        },

        playCamera: function () {      
            if(this.loaded)return false;
            this.loaded = true;
            var displayImageTimeout = ([1,2,3,4,5,6,7,8,9,10].indexOf(parseInt(this.frameRate))==-1?this.CONSTANTS.DISPLAYIMAGETIMEOUT:(parseInt(this.frameRate)));
            var ea = ExternalResource.getExternalAccountsByModule(this.module.id,this.sharesLocations,this.externalAccounts);
            CordovaService.playCamera(this,ea,this.module,displayImageTimeout,this.uuidV4());
        },

        startProcess: function () {
            this.playCamera();
        },

        stopProcess: function () {
            this.loaded = false;
            CordovaService.stopCamera(this);
        },
	}
}
