
import AlT_1 from './al-t_1.vue';
import AlT_2 from './al-t_2.vue';
import AlT_3 from './al-t_3.vue';
import AlT_4 from './al-t_4.vue';
import AlT_5 from './al-t_5.vue';
import AlT_6 from './al-t_6.vue';

export default {
	props: ['alarm', 'alarmRemote', 'homepilot', 'step', 'title', 'model', 'submodel', 'step'],
	components: {AlT_1,AlT_2,AlT_3,AlT_4,AlT_5,AlT_6},
    data: function () {
        return {
            CONSTANTS:
            {
                'IMG_PATH' : 'assets/images/device/alarm/'
            }
        }
    },
    created: function () {
			this.eventHub.$emit('setStepper', [
				{'number' : 1, 'title' : 'Operational'},
				{'number' : 2, 'title' : 'Position'},
				{'number' : 3, 'title' : 'Association'},
				{'number' : 4, 'title' : 'Disarm'},
				{'number' : 5, 'title' : 'Association'},
				{'number' : 6, 'title' : 'Test'},
				{'number' : 7, 'title' : 'Name'},
				{'number' : 8, 'title' : 'Room'}
			], 1);
    },
	methods: {
	}
}
