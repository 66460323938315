import HPCable1 from './hp-cable1.vue';
import HPCable2 from './hp-cable2.vue';
import HPCable3 from './hp-cable3.vue';
import HPCable4 from './hp-cable4.vue';
import HPCable5 from './hp-cable5.vue';

export default {
	props: ['device', 'model', 'submodel', 'step', 'prefilledMac'],
	components: {HPCable1,HPCable2,HPCable3,HPCable4,HPCable5},
    data: function () {
        return {
            result: false,
						mac: ''
        }
    },
    created: function () {
		  this.eventHub.$on('nextStepCable', this.nextStepCable);
		  this.eventHub.$on('backStepCable', this.backStepCable);
			this.eventHub.$on('setMac', this.setMac);
			this.eventHub.$emit('setStepper', [
				{'number' : 1, 'title' : 'Connection'},
				{'number' : 2, 'title' : 'Reset'},
				{'number' : 3, 'title' : 'MAC'},
				{'number' : 4, 'title' : 'Association'},
				{'number' : 5, 'title' : 'Result'},
				{'number' : 6, 'title' : 'Notifications'},
				{'number' : 7, 'title' : 'Name'},
				{'number' : 8, 'title' : 'Room'}
			], 1);
			if(this.prefilledMac)
				this.setMac(this.prefilledMac);
    },
		destroyed: function () {
		  this.eventHub.$off('nextStepCable', this.nextStepCable);
		  this.eventHub.$off('backStepCable', this.backStepCable);
			this.eventHub.$off('setMac', this.setMac);
    },
	methods: {
		nextStepCable: function(){
			this.eventHub.$emit('nextInstallStep');
		},
		backStepCable: function(){
			this.eventHub.$emit('backInstallStep');
		},
		setMac: function(value){
			this.mac = value;
		}
	}
}
