import Global from 'common/services/resources/global.js'
import LocationResource from 'common/services/resources/location.js'
import GlobalSpinner from 'common/components/global-spinner/global-spinner.vue'
import { consoleError } from 'vuetify/es5/util/console';

export default {
	props:['title','plant','selectedRoom', 'device', 'defaultRooms', 'noCloseDialog', 'selectDefaultRooms'],
	components: {
		GlobalSpinner
	},
	data:
		function () {
			return {
					CONSTANTS: {
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
					},
					room:{
						name:'',
						background:
						{ "backgroundImage": "url("+require('@/assets/images/rooms/empty.jpg')+")"},
						backgroundFile: undefined,
						key: undefined
					},
					imgStyle: {},
					uploadProgress: 0,
					roomKeySelected:undefined
			}
		},
	created: function () {
		this.init();
		this.eventHub.addEvent('associateImageProgress', this.associateImageProgress);
	},
	methods: {
		init : function()
		{
			if(this.title==undefined)
			{
				this.title = 'add a new room';
			}
			if(this.selectedRoom!=undefined)
			{
				this.room.name = this.$gettext(this.selectedRoom.name);
				var thumb = '';
				if(this.selectedRoom.image && this.selectedRoom.image.thumb && this.selectedRoom.image.thumb.path!='')
				{
					if(this.selectedRoom.image.thumb.key !=undefined )
					{
						this.room.key = this.selectedRoom.image.thumb.key;
						thumb = require('@/assets/'+this.$store.state.variantManager.class.defaultRoomsPath+'/'+this.selectedRoom.image.thumb.key+'_thumb.jpg');
					}
					else
					{
						this.room.backgroundFile = '';
						thumb = Global.getBackendUrl()+this.selectedRoom.image.thumb.path;
					}
				}
				this.room.background =
				(this.selectedRoom.image!=undefined?{backgroundImage : 'url("'+thumb+'")'}:{});
			}
		},
		associateImageProgress : function(progress)
		{
			this.uploadProgress = Math.round(progress.loaded * 100 / progress.total);
		},
		img: function (zone) {
			var style = {};
			if(zone.image && zone.image.thumb && zone.image.thumb.path)
			{
				style.backgroundImage = "url("+require('@/assets/'+this.$store.state.variantManager.class.defaultRoomsPath+'/'+zone.key+'_thumb.jpg')+")";
			}
			else
			{
				style.backgroundImage = "url("+require('@/assets/images/icon-default.png')+")";
			}
			
			style.backgroundSize = 'cover';
			  return style;
		},
		validate : function()
		{
			if(this.selectedRoom==undefined)
				this.addRoom();
			else
				this.updateRoom();
		},
		addRoom : function(){
			var _this=this;
			var backgroundFile = ((this.room.backgroundFile) || this.room.key!=undefined ? (this.room.key!=undefined ? this.room.key : this.room.backgroundFile) : undefined);
			if(_this.room.name == ''){
				_this.eventHub.$emit('setLoadingValidate',  false);
				_this.eventHub.$emit('displayAlert',  _this.$parent.$parent.CONSTANTS.INPUT_ERROR);
			}
			/*else if(_this.room.backgroundFile == undefined){
				_this.eventHub.$emit('setLoadingValidate',  false);
				_this.eventHub.$emit('displayAlert',  _this.$parent.$parent.CONSTANTS.BACKGROUND_ERROR);
			}*/
			else{
				var id = _this.plant.id;
				var level = 'ZONE';
				_this.roomKeySelected = _this.room.key;
				LocationResource.addLocationTopology(_this.room.name, level, id).then(
					function(response) {
						_this.eventHub.$emit('setLoadingValidate',  false);
						var roomId = response.data.id;
						if(backgroundFile!=undefined)
						{
							LocationResource.associateImage('zone', roomId , backgroundFile, _this.eventHub).then(
								function(response) {
                  					var image = response.data.image;
									_this.addNewRoom(roomId,image);
								}).catch(
									function(response) {
								});
						}
						else
							_this.addNewRoom(roomId);
					}).catch(
						function(e) {
							_this.eventHub.$emit('setLoadingValidate',  false);
							if(e == 'Error: Network Error')
								_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
							else if(e.response && e.response.status == 401)
								_this.eventHub.$emit('logout');
							else if(e.response && [402].indexOf(e.response.status)!=-1)
								_this.eventHub.$emit('displayAlertKey', 'unauthorized');
							else if(e=="Error: Request failed with status code 422"){
									_this.eventHub.$emit('displayAlert',  _this.$parent.$parent.CONSTANTS.ROOM_ERROR_LEVEL);
							}
							else{
								_this.eventHub.$emit('displayAlert',  _this.$parent.$parent.CONSTANTS.ROOM_ERROR);
							}
						});
			 }
		},
		updateRoom : function(){
			var _this=this;
			var backgroundFile = ((this.room.backgroundFile) || this.room.key!=undefined ? (this.room.key!=undefined ? this.room.key : this.room.backgroundFile) : undefined);
			if(_this.room.name == ''){
					_this.eventHub.$emit('displayAlert',  _this.$parent.$parent.CONSTANTS.INPUT_ERROR);
			}
			else{
				var allPromise = [];
				var bBackgroundChange = false;
				if(_this.room.name != this.selectedRoom.name)
				{
					allPromise.push(LocationResource.editLocationTopology('zone', _this.selectedRoom.id, _this.room.name));
				}
				if(backgroundFile != undefined)
				{
					bBackgroundChange = true;
					allPromise.push(LocationResource.associateImage('zone', _this.selectedRoom.id , backgroundFile, _this.eventHub));
				}
				if(allPromise.length==0)
					_this.finish();
				else
				{
					_this.roomKeySelected = _this.room.key;
					Promise.all(allPromise).then(
						function(response){
							_this.selectedRoom.name = _this.room.name;
							if(bBackgroundChange)
							{
								_this.selectedRoom.image = response[allPromise.length-1].data.image;
								//If key
								if(typeof backgroundFile == 'string')
								{
									if(_this.selectedRoom.image.full)
									{
										_this.selectedRoom.image.full.key = backgroundFile;
									}
									if(_this.selectedRoom.image.thumb)
									{
										_this.selectedRoom.image.thumb.key = backgroundFile;
									}
								}
							}
							_this.finish();
						}).catch(
						function(e) {
							if(e == 'Error: Network Error')
								_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
							else if(e.response && e.response.status == 401)
								_this.eventHub.$emit('logout');
							else
								_this.eventHub.$emit('displayAlert',  _this.$parent.$parent.CONSTANTS.ROOM_EDIT_ERROR);
							_this.eventHub.$emit('setLoadingValidate',  false);
						});
				}
			 }
		},

		chooseRoomBackground : function(){
			document.querySelector('#fileInputRoomBackground').click();
		},

		changeRoomBackground : function(event){
			this.room.key=undefined;
			this.room.backgroundFile = event.target.files[0];
			this.room.background = {
				backgroundImage : 'url("'+URL.createObjectURL(this.room.backgroundFile)+'")'
			}
		},
		selectBackgroundRoom : function(br){
			this.room.key = (br.key ? br.key :undefined);
		},
		addNewRoom : function(roomId,image){
			if(this.device != undefined)
			{
        		var _this = this;
				LocationResource.edit('module',this.device.id, {containerId:roomId,name:this.device.name}).then(
					function(obj) {
						_this.eventHub.$emit('setValidation', true);
						_this.eventHub.$emit('nextInternalMainStep');
						_this.eventHub.$emit('closeDialog','displayRoomAddModal');
						}).catch(
					function() {
				});
			}
			else
			{
				var z = {
					id:roomId,
					modules:[],
					name: this.room.name,
					type: null,
					image: {
						full : {
								path : ''
							},
						thumb : {
							path : ''
						},
					}
        		};
				if(image)
				{
					var key = (typeof backgroundFile == 'string' ? backgroundFile : undefined);
					z.image  =  {
						full : {
								path : image.full.path,
								key: key
							},
						thumb : {
								path : image.thumb.path,
								key: key
							}
					}
				}
				this.plant.zones.push(z);
				this.eventHub.$emit('initRoomBlocks');
				this.finish(z);
			}
		},
		finish : function(zone)
		{
			this.room.name = '';
			this.uploadProgress = 0;
			this.eventHub.$emit('displayAlert',  (this.selectedRoom==undefined?this.$parent.$parent.CONSTANTS.ROOM_SUCCESS:this.$parent.$parent.CONSTANTS.ROOM_EDIT_SUCCESS), 'success');
			if(!this.noCloseDialog)
				this.eventHub.$emit('closeDialog');
			this.eventHub.$emit('addRoomFinished',zone);
		}
  }
}
