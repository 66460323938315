
export default {
    
  consoleLogTag: function(tag = 'TAG', funct = 'FUNCTION', message = '', element = ''){
    if(process.env.NODE_ENV!='development')return;
    console.log(tag+' - '+funct+' : '+message);
    if(element != '')
      console.log(element);
  },
  consoleErrorTag: function(tag = 'TAG', funct = 'FUNCTION', message = '', element = ''){
    if(process.env.NODE_ENV!='development')return;
    console.error(tag+' - '+funct+' : '+message);
    if(element != '')
      console.error(element);
  }
}