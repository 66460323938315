import VueSlider from 'vue-slider-component'
import 'vendor/vue-slider-component/theme/default.css'
import UartService from 'common/services/uart.js'

import iro from '@jaames/iro';

export default {
  /**
   * selectedCapability : used by Scenario
   */
  props: ['module','selectedCapability'],
  data:
		function () {
			return {
        CONSTANTS:
        {
          RGB_HUE_MODEL : ['philips_hue_light']
        },
        colorPicker:{},
        status:
        {
          value: undefined
        },
        rgb:undefined,
        colorTemperature:undefined,
        colorTemperatureOptions: {
          height: 14,
          dotSize: 20,
          dotOptions:
          {
            style: {
              backgroundColor: 'white'
            }
          },
          interval: 1,
          clickable: true,
          tooltip: true,
          railStyle:{
          }
        },
        brightness:undefined,
        brightnessOptions: {
          height: 14,
          dotSize: 20,
          dotOptions:
          {
            style: {
              backgroundColor: 'white'
            }
          },
          interval: 1,
          clickable: true,
          tooltip: true,
          railStyle:
          {
            backgroundImage: 'linear-gradient(to right, black, white)'
          }
        },
        displayBrightness: false,
      }
  },
  components: {
    VueSlider
  },

  created: function () {
    this.status = _find(this.module.device.status, {'capability':'status'});
    this.initSlider('colorTemperature', {'capability':'color_temperature'});
    this.initSlider('brightness', {'capability':'brightness'});
    window.addEventListener('resize', this.resizeContent);
  },

  mounted: function () {
    var _this = this;
    setTimeout(function() {
      _this.initColorPicker('rgb', {'capability':'rgb'});
      _this.resizeContent();
    }, 1);
  },

  beforeDestroy: function ()
	{
		window.removeEventListener('resize', this.resizeContent);
  },
  
  watch: {
		"rgb.value": function (val, oldVal) {
      if(val)
      {
        var value = val.substr(2);
        this.$parent.$refs['control-device'].style.backgroundColor = '#'+value;
        this.colorPicker.color.hexString = '#'+value;
      }

		},
  },
  methods: {
    resizeContent : function(){
      this.colorPicker.resize(this.$refs['widget-container-bottom-control-device'].offsetWidth-20);
    },

    initSlider : function(name, capability){
      this[name] = _find(this.module.device.status, capability);
      if(this[name])
      {
        var c = JSON.parse(JSON.stringify(_find(this.module.device.capabilities, capability)));
        if(c)
        {
          this[name].value = (this[name].value ?this[name].value:parseInt(JSON.parse(JSON.stringify(c.default_value))));
          this[name+'Options'].min = c.min;
          this[name+'Options'].max = c.max;
          this[name+'Options'].unit = '{value}'+c.unit;
          if(capability.capability=='color_temperature')
          {
            this[name+'Options'].railStyle.backgroundImage = 'linear-gradient(to right, '+chroma.temperature(c.min).hex()+', '+chroma.temperature(c.max).hex()+')';
          }
        }
      }
    },

    initColorPicker: function(name, capability){
      this[name] = _find(this.module.device.status, capability);
      if(this[name])
      {
        var c = JSON.parse(JSON.stringify(_find(this.module.device.capabilities, capability)));
        if(c)
        {
          var _this = this;
          var rgbValue = '';
          if(this.selectedCapability){
            rgbValue = 'faffff';
            this[name].value = '0x'+rgbValue;
            this.brightness.value = 100;
          } else {
            this[name].value = (this[name].value!=undefined?
                                  (this[name].value.substring(0,2)=='0x'?
                                  // If the color is black, the color picker is all black and cannot be changed => #FFFFFF
                                    (this[name].value=='0x000000'
                                    && this.CONSTANTS.RGB_HUE_MODEL.indexOf(this.module.device.model)==-1?'0xFFFFFF':this[name].value):
                                    '0xFFFFFF'):
                                    (c.default_value?JSON.parse(JSON.stringify(c.default_value)):'0xFFFFFF')
                                );
            rgbValue = this[name].value.substr(2);
          }
          this.$parent.$refs['control-device'].style.backgroundColor = '#'+rgbValue;
          var size = (window.innerHeight<450?this.$parent.$refs['control-device-content-content'].clientHeight:this.$parent.$refs['control-device-content-content'].clientWidth);
          this.colorPicker = new iro.ColorPicker(this.$refs['color-picker-container'],
          {
            color: '#'+rgbValue,
            layout: [
              {
                component: iro.ui.Wheel,
                options: {}
              }
            ]
          });
          this.colorPicker.on("input:end", function(color, changes) {
            _this.rgb.value = '0x'+parseInt(color.rgb.r).toString(16).padStart(2, '0')+parseInt(color.rgb.g).toString(16).padStart(2, '0')+parseInt(color.rgb.b).toString(16).padStart(2, '0');
            if(_this.CONSTANTS.RGB_HUE_MODEL.indexOf(_this.module.device.model)!=-1)
            {
              _this.changeRGBHue(color);
            }
            else
            {
              _this.changeRGB();
            }
          });
        }
      }
    },

    lightenColor: function(){
      if(this.CONSTANTS.RGB_HUE_MODEL.indexOf(this.module.device.model)!=-1)
      {
        this.colorPicker.color.hsv = {'h': this.colorPicker.color.hsv.h, 's': this.colorPicker.color.hsv.s, 'v': this.brightness.value};
        this.$parent.$refs['control-device'].style.backgroundColor = 'rgb('+this.colorPicker.color.rgb.r+','+this.colorPicker.color.rgb.g+','+this.colorPicker.color.rgb.b+')';
      }
    },

    setBrightness: function(){
      if(this.status.value!='on')return false;
      if(this.CONSTANTS.RGB_HUE_MODEL.indexOf(this.module.device.model)!=-1)
      {
        this.rgb.value = '0x'+parseInt(this.colorPicker.color.rgb.r).toString(16).padStart(2, '0')+parseInt(this.colorPicker.color.rgb.g).toString(16).padStart(2, '0')+parseInt(this.colorPicker.color.rgb.b).toString(16).padStart(2, '0');
        if(this.selectedCapability){
          return
        } else {
          this.$parent.$emit('set-status', {'capability': 'rgb', 'value':  this.rgb.value});
        }
      }
      else{
        if(this.module.className && this.module.className =='Group')
        {
          this.$parent.$emit('set-status', {'capability': 'brightness', 'value': this.brightness.value});
        
        }
        else
        {
          UartService.sendCommand(this.module, 'setBrightness',this.eventHub);
        }
      }
    },

    setColorTemperature: function(){
      if(this.status.value!='on')return false;
      if(this.module.className && this.module.className =='Group')
      {
        this.$parent.$emit('set-status', {'capability': 'color_temperature', 'value': this.colorTemperature.value});
      
      }
      else
      {
        UartService.sendCommand(this.module, 'setColorTemperature',this.eventHub);
      }
      
    },

    setStatus: function(){
        /*if(this.module.device.submodelObj && this.module.device.submodelObj.meshCompatible)
        {
          UartService.sendCommand(this.module, (this.status.value=='on'?'turnOff':'turnOn'),this.eventHub);
        }
        else
        {*/
          this.$parent.$emit('set-status', {capability:'status',value:this.status.value});
        //}
    },

    changeRGBHue: function(color){
      this.$parent.$refs['control-device'].style.backgroundColor = 'rgb('+color.rgb.r+','+color.rgb.g+','+color.rgb.b+')';
      if(this.selectedCapability){
        return
      } else {
        this.$parent.$emit('set-status', {'capability': 'rgb', 'value': this.rgb.value});
      }
    },

    changeRGB: function(){
      if(this.module.className && this.module.className =='Group')
      {
        this.$parent.$emit('set-status', {'capability': 'rgb', 'value': this.rgb.value});
      
      }
      else
      {
        UartService.sendCommand(this.module, 'setRGB',this.eventHub);
      }
    },

    validate: function(){
      if(this.selectedCapability){
        this.selectedCapability.value = this.rgb.value;
        this.$parent.$emit('clickOnItem', this.selectedCapability);
      }
    }
  }
}
