import Vue from 'vue'
import CameraResources from 'common/services/resources/camera.js'
import DeviceResources from 'common/services/resources/device.js'
import LocationResources from 'common/services/resources/location.js'
import LinkwilPluginService from 'common/services/cordova/linkwilPlugin.js'
import StorageService from 'common/services/storage.js'
import HlsService from 'common/services/hls.js'
import LanguageService from 'common/services/language.js'
import moment from 'moment'
import SmartCamera from 'common/classes/SmartCamera.js'
import NumberService from 'common/services/number.js'

import DeleteModal from 'common/components/delete-modal/delete-modal.vue'

/*import Swiper from 'swiper/dist/js/swiper.esm.bundle';*/
import Swiper from 'swiper/bundle';

import { isUndefined } from 'util'

import { v4 as uuidV4 } from 'uuid';

let upgradeProgressInfosTimeout = undefined;

const UPGRADE_PROGRESS_INFOS_TIMEOUT = 600000;


export default {
	props: ['module','devices'],
	components: {DeleteModal},
	data: function () {
		return {
			CONSTANTS: {
                        GET_DEVICE_INFOS_TIMEOUT: 10000
			},
			styleSwiper: {
				backgroundImage:''
			},
			swiperTimelineOptions: {},
			swiperRecordsTimelineOptions: {
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},
			},
			swipers:  [],
			swiperRecordsTimeActiveIndex:0,
            // virtual data
			swiperTimelineVirtualData:  {
				slides: []
			  },
			onLive: true,
			soundOn: true,
			microOn: false,
			light: {'value': "off"},
			detection: {'value': "disable"},
			recordOnDetection: {'value': "disable"},
			lightOnDetection: {'value': "disable"},
			liveRecord: false,
			notDisturb: false,
			recordOnRing: true,
			loading: false,
			recordDisplaying: false,
			settings: false,
			selectedFrequency: {'value': "50"},
			frequencies: ["50", "60"],
			timeline: {},
			recordsDates: [],
			serverIp: '',
			serverPort: '',
			recordName: '',
			url: '',
			timezone: '',
			recordsLoading: false,
			settingsLoading : false,
			indexSelectedRecord: -1,
			indexDateSelected: '',
			thumbnailLoadTimeout: [],
			firmware:
			{
				version: (this.module.device.firmware && this.module.device.firmware.version ? this.module.device.firmware.version : 'unknown'),
				latestFirmwareVersion: '',
				latestVersionUrl: undefined
			},

			toUpgrade:false,

			getDeviceInfosTimeout: undefined,
			confirmUpgradeModal: false,
			indexDays:[],
		}
	},
	computed: {

		getRecordsByDate: function() {
			return date => this.timeline.filter(record => record.date == date);
		},
		firmwareVersionTranslate: function() {
			return this.$gettext(this.firmware.version);
		},
	},

	mounted() {
		let plugin = document.getElementById('hls-plugin');
		if(plugin == null){
			plugin = document.createElement('script');
			plugin.id = 'hls-plugin';
			plugin.setAttribute(
				"src",
				"vendor/hls.js/hls.min.js"
			);
			plugin.async = true;
			document.body.appendChild(plugin);
		}
	},

	created: function () {
		var _this = this;
		this.eventHub.$on('camera_onStop_'+this.module.id, this.onStop);
		this.eventHub.$on('get_linkwil_records'+this.module.id, this.setTimelineLinkwil);
		this.eventHub.$on('get_linkwil_records_error'+this.module.id, this.setTimelineErrorLinkwil);
		this.eventHub.$on('LinkwilPlugin_getDetection_'+this.module.id, this.getDetectionLinkwilPlugin);
		this.eventHub.$on('LinkwilPlugin_getDeviceInfos_'+this.module.id, this.getDeviceInfosLinkwilPlugin);
		this.eventHub.addEventIfNotExist('LinkwilPlugin_upgrade_'+this.module.id, this.upgradeLinkwilPlugin);
		this.styleSwiper.backgroundImage  = 'url('+require('@/assets/images/manufacturers/'+this.module.device.brandObj.manufacturer.name+'/brands/'+this.module.device.brand+'/bandevideo.jpg')+')'; 
		if(_find(this.module.device.status,{capability:'status'}))
			this.light = _find(this.module.device.status,{capability:'status'});
		if(_find(this.module.device.status,{capability:'detection'}))
			this.detection = _find(this.module.device.status,{capability:'detection'});
		if(_find(this.module.device.status,{capability:'record_on_detect'}))
			this.recordOnDetection = _find(this.module.device.status,{capability:'record_on_detect'});
		if(_find(this.module.device.status,{capability:'light_on_detect'}))
			this.lightOnDetection = _find(this.module.device.status,{capability:'light_on_detect'});
		/*if(_find(this.module.device.status,{capability:'record_on_call'}))
			this.recordOnRing = (_find(this.module.device.status,{capability:'record_on_call'}).value == 'disable'?false:true);*/
		if(_find(this.module.device.status,{capability:'frequency'})){
			this.selectedFrequency = _find(this.module.device.status,{capability:'frequency'}); 
			if(!this.selectedFrequency.value)
			{
				this.selectedFrequency.value = '50';    
			}
		}
		this.module.device.firmware  = this.firmware;
		this.notDisturb = (this.module.notification_level == 'NONE'?true:false);
		if(this.module.device.model == 'vdp'){
			if(this.module.updateInProgess!=true)
			{
				DeviceResources.associateStatus(this.module.device.model, this.module.device.systems[0], this.module.id).then(
					function(response) {
						var vdps = response.data.vdps;
						if(vdps.length>0)
						{
							if(vdps[0].latestFirmwareVersion)
							{
								_this.firmware.latestFirmwareVersion = vdps[0].latestFirmwareVersion;
							}
						}
					}).catch(
						function(e) {
								console.log(e);
						}
				);
				if(this.inApp)
				{      
					LinkwilPluginService.getDetection(this);
				}
			}
		} else {
			DeviceResources.associateStatus('smart_camera', 'surveillance', this.module.id).then(
				function(response) {
					_this.timezone = response.data.smart_cameras[0].explicitTimezone;
			}).catch(
				function(e) {
					console.log(e);
				}
			);
			/*CameraResources.recordstimeline(this.module.id).then(
				function(response) {
					_this.recordsLoading = false;
					if(response.data[0]){
						_this.timeline = response.data[0].timeline;
						_this.recordName = response.data[0].stream.split('/')[1];
						_this.serverIp = response.data.serverIp;
						_this.serverPort = response.data.serverPort;
					}
					_this.module.device.recordEndpoint = response.data.recordEndpoint;
					_this.module.device.recordPort = response.data.recordPort;
					_this.settimeline();
			}).catch(
				function(e) {
					_this.recordsLoading = false;
					console.log(e);
				}
			);*/
			this.getCameraStatus();
		}
	},

	beforeDestroy: function ()
	{
		this.eventHub.$off('get_linkwil_records'+this.module.id, this.setTimelineLinkwil);
		this.eventHub.$off('get_linkwil_records_error'+this.module.id, this.setTimelineErrorLinkwil);
		this.eventHub.$off('LinkwilPlugin_getDetection_'+this.module.id, this.getDetectionLinkwilPlugin);
		this.eventHub.$off('LinkwilPlugin_getDeviceInfos_'+this.module.id, this.getDeviceInfosLinkwilPlugin);
		//this.eventHub.$off('LinkwilPlugin_upgrade_'+this.module.id, this.upgradeLinkwilPlugin);
		HlsService.destroy();
	},
	methods: {
		sound: function () {
			this.soundOn = !this.soundOn;
		},
		speak: function () {
			this.microOn = !this.microOn;
		},
		grid: function () {
		},
		download: function () {
		},
		deleteRecord: function () {
		},
		shareRecord: function () {
		},
		setDetectionStatus: function () {
			var _this = this;
			if(this.module.device.submodel == 'vdp_100')
			{
				LinkwilPluginService.setDetection(this.module,this.detection.value);
			}
			else
			{
				this.switchCapabilityValue('detection');
			}
			var detection = _find(this.module.device.status,{capability:'detection'});
			if(detection)
			{
				if(detection.value == "disable"){
					var recordOnDetect = _find(this.module.device.status,{capability:'record_on_detect'});
					if(recordOnDetect)
					{
						if(recordOnDetect.value == 'enable'){
							this.recordOnDetection.value = "disable";
							setTimeout(function() {
								_this.setRecordOnDetectionStatus();
							}, 1000);
						}
					}
					var lightOnDetect = _find(this.module.device.status,{capability:'light_on_detect'});
					if(lightOnDetect)
					{
						if( lightOnDetect.value == 'enable'){
							this.lightOnDetection.value = "disable";
							setTimeout(function() {
								_this.setLightOnDetectionStatus();
							}, 2000);
						}
					}
				}              
			}
		},
		setLightStatus: function () {
			this.switchCapabilityValue('status');
		},
		setRecordOnDetectionStatus: function () {
			this.switchCapabilityValue('record_on_detect');
		},
		setLightOnDetectionStatus: function () {
			this.switchCapabilityValue('light_on_detect');
		},
		setFrequency: function () {
			if(_find(this.module.device.status,{capability:'frequency'})){
				var status = {
					'capability': 'frequency',
					'value': this.selectedFrequency.value
				}
				this.$parent.$emit('set-status', status);
			}
		},
		doNotDisturb: function () {
			var obj = {'notificationLevel' : (this.notDisturb == false?'ALERT':'NONE')};
			if(this.notDisturb == false)
			{
				obj.alert = [{
					type: "push", 
					infos: {
						language: LanguageService.getUserLanguage(),
						title: this.$store.state.variantManager.class.title
					}
					}];
			}
			this.module.notification_level = (this.notDisturb == false?'ALERT':'NONE');
			LocationResources.edit('module', this.module.id, obj);
		},
		gate: function(){
			/*var status = {
				"capability": "trigger"
			}
			this.$parent.$emit('set-status', status);*/
			LinkwilPluginService.openGate(this.module);
		},
		switchCapabilityValue: function (capability) {
			var status = _find(this.module.device.status,{capability:capability});
			this.$parent.$emit('set-status', status);
		},

		setTimeline: function(timeline) {
			var _this = this;

			//this.timeline = timeline;
			this.swiperTimelineOptions = {};

			//Empty the array if no record			  
			if(timeline.length==0)
			{
				this.recordsDates = [];
				this.indexDateSelected = '';
			}	  
			else
			{

				timeline.forEach(function(elt,index) {
					var beginDate = new Date(elt.start*1000).toLocaleString('en-GB', { timeZone: _this.timezone });
					var endDate = new Date(elt.end*1000).toLocaleString('en-GB', { timeZone: _this.timezone });
					elt.index = index;
					elt.displayDuration = _this.setSecondsInHours(elt.duration);
					elt.thumbnail = 'http://'+_this.serverIp+':'+_this.serverPort+'/record/'+_this.recordName+'/dvr_thumbnail_'+elt.start+'.mp4#t=1000,1005';
					elt.date = _this.changeFormatDate(beginDate.split(', ')[0]);
					elt.beginHour =  beginDate.split(', ')[1];
					elt.endHour = endDate.split(', ')[1];
				});
				
				//Sort by time
				timeline.sort((a, b) => (a.beginHour < b.beginHour) ? 1 : -1);
				//Sort by date
				timeline.sort((a, b) => (a.date < b.date) ? 1 : -1);
	
				var firstDay = timeline[0].date;
				var yesterday = moment(firstDay).subtract(1,'d').format('YYYY-MM-DD');
				var beforeYesterday = moment(firstDay).subtract(2,'d').format('YYYY-MM-DD');

				this.recordsDates = [firstDay, yesterday,beforeYesterday];

				//Filter 3 last days
				timeline = _filter(timeline, function(t) {return (_this.recordsDates.indexOf(t.date)!=-1);});

				//Group by date
				
				timeline = _mapValues(_groupBy(timeline, 'date'),
							  clist => clist.map(t => _omit(t, 'date')));

				for(var x in timeline)
				{
					
					this.timeline[x]={
						records: []
					};
					this.timeline[x].records = timeline[x];
					this.timeline[x].day = x;
	
					Vue.set(_this.swiperTimelineVirtualData, x, {
						slides: []
					});

				}
				var days = Object.keys(timeline).sort();
				var fDay = days[days.length-1];
				this.indexDateSelected = fDay;

				this.$nextTick(function() {

					for(var x in timeline)
					{
						var cls = '.swiper-container_'+x;
						this.swipers[cls] = new Swiper(cls, {
							slidesPerView: 3,
							spaceBetween: 3,
							virtual: {
							slides: (function () {
									return _this.timeline[x].records;
							}()),
							renderExternal(data) {
								// assign virtual slides data
								var date = this.el.id.replace('swiperTimeline_','');
								var lastIndex = _this.timeline[date].records.length-1;
								_this.swiperTimelineVirtualData[date] = data;
								//Vue.set(_this.swiperTimelineVirtualData, date, data);
							},
							},
						});
						setTimeout(function(date) {
							var cls = '.swiper-container_'+date;
							_this.swipers[cls].update();
							_this.swipers[cls].slideTo(_this.timeline[date].records.length-1);
							_this.swipers[cls].off('resize');
						}, 1, x);
					}

				});

			}
		},

		setSecondsInHours: function(seconds){
			var time = new Date();
			time.setTime(seconds*1000);
			return (time.getHours()-1)+":"+time.getMinutes()+":"+time.getSeconds();
		},
		changeFormatDate: function(date){
			var tab = date.split('/');
			return tab[2]+'-'+tab[1]+'-'+tab[0]
		},
		getCameraStatus: function(){
			var json = {
				'action': 'get_info',
				'timestamp':moment.utc().format('YYYY-MM-DD HH:mm:ss'),
				'uid': uuidV4(),
				'modules': [
					{
					'id': this.module.id,
					'device':{
					}
					}
				]
			};
			var path = this.module.device.model+'/'+this.module.device.systems[0]+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+this.module.id;
			this.eventHub.$emit('mqttPublish',  path, json);
		},

		setTimelineLinkwil: function(records,date,index) {
			var timeline = 
			{
				date: date,
				records:[]
			};
			records.forEach(function(elt) {
				var obj = {};
				obj.date = date;
				obj.name = elt;
				var time = elt.split('_')[1].substr(0, 6);
				obj.beginHour = time.substr(0, 2)+':'+time.substr(2, 2)+':'+time.substr(4, 2);
				timeline.records.push(obj);
			});
			timeline.records.sort((a, b) => (a.beginHour < b.beginHour) ? 1 : -1);
			//this.recordsDates[index] = timeline;
			this.recordsDates.push(timeline);
			var nextIndex = index+1;

			if(this.indexDays[nextIndex])
			{
				LinkwilPluginService.getLinkwilRecords(this,this.indexDays[nextIndex],nextIndex);
			}
			else
			{
				this.recordsLoading = false;
			}
			
		},

		setTimelineErrorLinkwil: function() {
			this.recordsLoading = false;
		},
				
		getDetectionLinkwilPlugin: function(json) {
			LinkwilPluginService.getDeviceInfos(this);
			if(json.event=='onGetDetectionSuccess')
			{
				this.detection.value = json.detection;   
			}
		},

		getDeviceInfosLinkwilPlugin: function(json) {
			var _this = this;
			if(json.event=='onGetDeviceInfosSuccess' && json.infos)
			{
				Vue.set(this.module, 'updateInProgess', false);
				if(json.infos.versionName)
				{
					this.firmware.version = json.infos.versionName;
					this.eventHub.$emit('setCacheDevices');
					DeviceResources.getUpgrade(this.module.device.model, this.module.device.systems[0], this.module.id, json.infos.versionName).then(
					function(response) {
						if(response.status!=204)
						{
							_this.toUpgrade = true;
							if(response.data.file)
							{
								_this.firmware.latestVersionUrl = response.data.file;
							}
						}
						_this.settingsLoading = false;
			
					}).catch(
						function(e) {
						_this.settingsLoading = false;
						_this.toUpgrade = false;
						console.log(e);
						}
					);
				}
			}
		},

		upgradeLinkwilPlugin: function(success, json) {
			Vue.set(this.module, 'updateInProgess', false);
			if(success)
			{

				this.toUpgrade = false;
				this.executeLinkwilGetDeviceInfos();
				if(json.version)
				{
					this.firmware.version = json.version;
				}
			}
		},

		changeSection: function(section) {
			if(!this.onLive && this.recordsLoading)return false;
			if(section == 'live'){
				this.onLive = true;
				this.indexSelectedRecord=-1;
				HlsService.destroy();
			} else {
				this.recordsLoading = true;
				this.onLive = false;
			}
			var _this = this;


			if(!this.onLive && (this.module.device.model == 'smart_camera' || SmartCamera.isRTPDisplay(this.module.device.model))){
				CameraResources.recordsTimeline(this.module.id).then(
					function(response) {
						_this.recordsLoading = false;
						if(response.data[0]){
							var timeline = response.data[0].timeline;
							_this.recordName = response.data[0].stream.split('/')[1];
							_this.serverIp = response.data.serverIp;
							_this.serverPort = response.data.serverPort;
							_this.setTimeline(timeline);
						}
						_this.module.device.recordEndpoint = response.data.recordEndpoint;
						_this.module.device.recordPort = response.data.recordPort;
						
				}).catch(
					function(e) {
						_this.recordsLoading = false;
						console.log(e);
					}
				);
			} else if(!this.onLive && this.module.device.submodel == 'vdp_100'){
				if(this.inApp)
				{
					this.getLinkwilRecords(); 
				}	
				else    
					this.recordsLoading = false;
			} else {
				this.recordDisplaying = false;
			}
		},
		liveFullScreen: function(){
			if(this.module.device.submodel == 'vdp_100'){
				//if(this.inApp)
				LinkwilPluginService.startLinkwillPlugin(this.module,this.eventHub) 
			}
			else{
				this.loading = true;
				this.recordDisplaying = false;
				
				if(this.module.device.class==undefined || this.module.device.class.show==undefined){
					var d = _find(this.devices, {id:this.module.id});
					d.device.class  = new SmartCamera(this.eventHub, d, this.inApp);
					d.device.class.show('sd');
				} else{
					this.module.device.class.show('sd');
				}
			}
		},
		displayRecord: function(record) {
			if(this.module.device.submodel == 'vdp_100'){
				if(this.inApp)
					LinkwilPluginService.playLinkwilRecords(this.module, record.name);
			}
			else{
				this.indexSelectedRecord = record.index;
				this.loading = false;
				this.recordDisplaying = true;
				
				var substractTimestamps = NumberService.substract(record.end, record.start);
				var duration = (substractTimestamps!==false && substractTimestamps>0 ? substractTimestamps: record.duration);

				this.url = 'http://'+this.serverIp+':'+this.serverPort+'/record/'+this.recordName+'/playlist_dvr_range-'+record.start+'-'+duration+'.m3u8';
				HlsService.play('.smart_camera__visu__video',this.url);
			}
		},
		onStop: function(){
			this.loading = false;
		},
		displaySettings: function(value){
			var _this = this; 
			this.settings = value;
			if(this.settings)
			{
				this.settingsLoading = true;
			}
			if(this.module.device.submodel == 'vdp_100')
			{
				this.executeLinkwilGetDeviceInfos(); 
			}
		},

		stopGetDeviceInfosTimeout: function(){
			if(this.settingsLoading)
			{
				this.settingsLoading = false;
			}
			
		},

		upgrade: function(){
			if(this.inApp)
			{
				var url = this.firmware.latestVersionUrl;
				Vue.set(this.module, 'updateInProgess', true);
				clearInterval(upgradeProgressInfosTimeout);
				upgradeProgressInfosTimeout = setTimeout(() => {
					Vue.set(this.module, 'updateInProgess', false);
				}, UPGRADE_PROGRESS_INFOS_TIMEOUT);
				LinkwilPluginService.upgradeLinkWil(this,url);
			}  
			this.confirmUpgradeModal = false;
		},

		getLinkwilRecords: function(){
			var today = moment().format('YYYY-MM-DD');
			var yesterday = moment().subtract(1,'d').format('YYYY-MM-DD');
			var beforeYesterday = moment().subtract(2,'d').format('YYYY-MM-DD');
			this.indexDays[0] = today;
			this.indexDays[1] = yesterday;
			this.indexDays[2] = beforeYesterday;
			this.recordsDates = [];
			//LinkwilPluginService.getLinkwilRecords(this,today,2);
			//LinkwilPluginService.getLinkwilRecords(this,yesterday,1);
			LinkwilPluginService.getLinkwilRecords(this,today,0);
		},

		executeLinkwilGetDeviceInfos: function(){
			var _this = this;
			clearInterval(this.getDeviceInfosTimeout);
			this.getDeviceInfosTimeout = setTimeout(function() {
				_this.stopGetDeviceInfosTimeout();
				}, this.CONSTANTS.GET_DEVICE_INFOS_TIMEOUT);
			if(this.inApp)
			{
				LinkwilPluginService.getDeviceInfos(this);
			}  
		}	
	}
}
