
import MacService from 'common/services/mac.js'

export default {
    props:['mac'],
    data: function () {
        return {
          CONSTANTS:{
                      INPUT_ERROR : this.$gettext('Please enter a valid MAC address'),
                    },
          filteredMac:''
        }
    },
    created: function () {
      if(this.mac)
      {
        var mac = MacService.checkMac(this.mac);
        if(mac)
        {
          this.eventHub.$emit('setMac', this.mac);
          this.eventHub.$emit('nextStepCable');
        }
        else
        {
          this.filteredMac = '';
          this.eventHub.$emit('setMac', '');
        }
      }

    },
		watch: {
			filteredMac: function (val, oldVal) {
        if(val.length>oldVal.length)
        {
          this.filteredMac = MacService.formatStringToMac(this.filteredMac);
        }
			}
  	},    
    methods: {
      formatMac: function(){
        var _this = this;
        this.$nextTick(function() {
          _this.filteredMac = MacService.formatStringToMac(_this.filteredMac);
        });
      },
      next: function(){
        var mac = MacService.checkMac(this.filteredMac);
        if(mac){
          this.eventHub.$emit('setMac', mac);
          this.eventHub.$emit('nextStepCable');
        }
        else{
          this.eventHub.$emit('displayAlert',  this.CONSTANTS.INPUT_ERROR);
        }
      }
    }
}
