import {required} from 'vuelidate/lib/validators'
import Language from 'common/services/language.js'
import PhoneNumberService from 'common/services/phone-number.js'
import StorageService from 'common/services/storage.js'

export default {
    props:{
        actions: {
            type: Array,
			default:[]
        }
    },
    data:
        function () {
            return {
                CONSTANTS:{
                    INVALID_NUMBER : this.$gettext('Invalid phone number'),
					NUMBER_ALREADY_ADDED : this.$gettext('This phone number is already added in alerts'),
                },
                switchDefault: true,
                sms: '',
                message: ''
            }
        },
    created: function () {
    },
    validations: {
		sms: {
			required
		}
	},
    methods: {
        close: function ()
		{
			this.$emit('close');
        },
        validatePhoneNumber: function(){
			var mobilNumber = JSON.parse(JSON.stringify(this.sms));
			var mobilNumbers = _filter(this.actions, function(o) { return o.type == 'sms'; });
			if(this.$v.sms.$invalid || !PhoneNumberService.checkPhoneNumber(this.sms))
			{
			  this.eventHub.$emit('displayAlert', this.CONSTANTS.INVALID_NUMBER);
			  return false;
			}
			if(this.sms.substr(0, 1) == '0'){
				mobilNumber = this.sms.replace('0', '+33');
            }
			if(_findIndex(mobilNumbers, function(o) { return o.infos.to == mobilNumber; })!=-1){
				this.eventHub.$emit('displayAlert', this.CONSTANTS.NUMBER_ALREADY_ADDED);
				return false;
			}
			var obj = {
				type: 'sms',
				infos: {
					to: mobilNumber,
					language: Language.getUserLanguage(),
					app: StorageService.getValue(localStorage, 'app')
				}
			}
			if(this.message != '' && !this.switchDefault)
				obj.infos.message = this.message;
			this.actions.push(obj);
			this.close();
		},
    }
}
  