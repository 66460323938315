/** 
 * 
 * Camera :  Smart Camera Class
 * This class manages the different cameras:
 * -> Local stream
 * -> RTSP: 'smart_camera'
 * -> RTP: 't21', 'battery_vdp'
 * */ 

import Camera from './Camera.js'
import CommonResource from 'common/services/resources/common.js'
import CordovaService from 'common/services/cordova.js'
import LogService from 'common/services/log.js'
import store from 'vuex_path/store'
import { consoleError } from 'vuetify/es5/util/console'

const DISPLAY_TIMEOUT= 80000;

const MODEL_RTP_STREAM = ['t21','battery_vdp', 'oskkio-1'];

const TAG = "SmartCamera";

const WAKE_UP_SET_INTERVAL_TIMEOUT = 1000;

const WAKE_UP_SET_INTERVAL_TRIES = 10;

const ZRTALIVE_STRING = 'ZRTALIVE';

const ERROR_CODE = {
  '100' : 'NO AWS REGION' 
};



export default class SmartCamera extends Camera {

    constructor(eventHub,module,inApp) {
      super(eventHub,module);
      //To overcome the problem of minifying the name of the class
      this.className = 'SmartCamera';
      this.inApp=inApp;
      this.resolution = 'hd';
      this.autoplay = false;
      this.bWakeUp=false;
      this.loading=false;
      this.timeout = undefined;
      this.wakeUpSetInterval = undefined;
      this.wakeUpSetIntervalTries = 0;
      this.destroy();

      var _this = this;
      
      this.eventHub.$on('mqttPublish_'+this.module.id, 
      function(callback)
      {
        _this.mqttPublish(callback);
      });
      this.eventHub.$on('device_event_infos_'+this.module.id, 
      function(callback)
      {
        _this.deviceEventInfos();
      });

    }

    static get MODEL_RTP_STREAM() {
      return MODEL_RTP_STREAM;
    }

    static isRTPDisplay(model){
      return (SmartCamera.MODEL_RTP_STREAM.indexOf(model)!=-1);
    }

    deviceEventInfos(){
      LogService.consoleLogTag(TAG,'deviceEventInfos', 'bWakeUp', this.bWakeUp);
      if(this.bWakeUp)
      {
        LogService.consoleLogTag(TAG,'deviceEventInfos', 'executeShow');
        this.bWakeUp = false;
        //this.executeShow();
      }
    }

  /**
   * 
   * @param {*} resolution : hd-> video0.sdp, sd-> video1.sdp
   */

    show(resolution, autoplay, noWakeup) {
      var _this = this;
      const titleLog = this.className+' show';
      LogService.consoleLogTag(TAG,titleLog,'resolution',resolution);
      LogService.consoleLogTag(TAG,titleLog,'autoplay',autoplay);
      LogService.consoleLogTag(TAG,titleLog,'noWakeup',noWakeup);
      this.eventHub.$emit('camera_onShow_'+this.module.id);
      var _this = this;
      this.resolution = resolution;
      this.autoplay = autoplay;
      this.eventHub.$emit('hideDebugLog');
      clearTimeout(this.timeout);
      clearInterval(this.setIntervalWakeUp);
      this.timeout = setTimeout(function() {
        LogService.consoleLogTag(TAG,titleLog,'stopProcess');
      }, DISPLAY_TIMEOUT);

      this.loading = true;
      this.bWakeUp = false;

      this.launchShow(noWakeup);

    }

    launchShow(noWakeup) {

      var wuC = _find(this.module.device.capabilities, {capability:'wake_up'});
      if(wuC && !noWakeup)
      {
        this.bWakeUp = true;
        this.wakeUpBeforeShow();
        this.executeShow();
        /*CordovaService.setStatus(ZRTALIVE_STRING).then(
            function(response) {
            }).catch(
            function(e) {
            });
        setTimeout(function() {
        }, 500);*/
        //this.wakeUpBeforeShow();
      }
      else
      {
        this.executeShow();
      }
    }

    wakeUpBeforeShow() {
      CommonResource.setStatus(this.module, {capability:'wake_up'}, undefined, this.eventHub,true);
      /*  this.wakeUpSetInterval = setInterval(()=>{
        if(this.wakeUpSetIntervalTries==WAKE_UP_SET_INTERVAL_TRIES)
        {
          clearInterval(this.wakeUpSetInterval);
          this.stopProcess()
        }
        else
        {
          CommonResource.setStatus(this.module, {capability:'wake_up'}, undefined, this.eventHub,true);
          this.wakeUpSetIntervalTries++;
        }

      }, WAKE_UP_SET_INTERVAL_TIMEOUT);*/

    }
    executeShow() {
      //CommonResource.setStatus(this.module, {capability:'start_stream'}, undefined, this.eventHub,true);
      clearInterval(this.wakeUpSetInterval);
      this.loading = false;
      this.eventHub.$emit('camera_onStop_'+this.module.id);
      if(store.state.selectedRegion && store.state.selectedRegion.amazonRegion && store.state.selectedRegion.amazonRegion.name)
      {
        CordovaService.startKVSStream(this.eventHub,this.module.id,store.state.selectedRegion.amazonRegion.name);
      }
      else
      {
        this.displayError(100);
        this.stopStream();
      }
      
    }

    destroy()
    {
      this.eventHub.$off('mqttPublish_'+this.module.id);
      this.eventHub.$off('device_event_infos_'+this.module.id);
      clearTimeout(this.timeout);
    }

    stopProcess()
    {
      this.stopStream();
    }

    stopStream(){
      LogService.consoleLogTag(TAG,this.className+' stopStream');
      this.loading = false;
      this.bWakeUp = false;
      this.wakeUpSetIntervalTries = 0;
      this.eventHub.$emit('camera_onStop_'+this.module.id);
      clearTimeout(this.timeout);
      //CommonResource.setStatus(this.module, {capability:'stop_stream'}, undefined, this.eventHub,true);

    }

    displayError(errorCode)
    {
      this.eventHub.$emit('displayAlert', this.eventHub.$gettext('Can not display camera')+(Number.isInteger(errorCode)?' ('+this.eventHub.$gettextInterpolate(this.eventHub.$gettext('error: %{errorCode}'), {errorCode: errorCode})+')':''));
    }

}