export default {
	props: 
	{
		txt: {
			type: String
		},
		field: {
			type: Object
		},
		installObject: {
			type: Object
		},
		submodel: {
			type: Object
		},
	},
	data: 
		function () {
			return {
				CONSTANTS:
				{
				},
				uid:
				{
					rules: {
						required: value => value!="" || this.$gettext('Field is required'),
						regex : value => this.field.expression.test(value) || this.$gettext(this.field.errorMsg),
						/*,
						equal: v => v.length >= 19 || this.$gettextInterpolate(this.$gettext('Field must be %{s} characters'),{s: 19})*/
					},
				},
				form:
				{
					validation:false
				},
			}
	},
	mounted: function () {
		this.$refs['form'].validate();
		//this.installObject.device.uid = 'esp-dby';
		//this.installObject.device.uid = '600194e58285';
		//this.installObject.device.uid = 'a4cf12b7991e';
		//this.installObject.device.uid = 'cc50e363ac96';

		
	},
	watch: {
		"installObject.device.uid": function (val, oldVal){
			if(this.field)
			{
				this.installObject.device.uid = val.toUpperCase();
			}
		}
	},
	beforeDestroy () {
	},	

	created: function () {
  	},
	methods: {
 	}
}