import StorageService from '../storage.js'
import store from 'vuex_path/store'
var stringify = require('json-stringify-safe');
/*Storage Elts

users
plant
devices
subDevices
devicesByCapability
dashboard
notificationsModules
scenarios

models
notificationsModules
scenarios
homepilots
systems
manufacturers
externalAccounts
brands
defaultRooms
templates
widgetModels

*/


export default {
    storeElt: function (name,datas) {
        if(store.state.noCache)return false;
        var s = (sessionStorage.getItem('authorization')?sessionStorage:localStorage);
        try{
            StorageService.persist(s, 'storage - '+name, stringify(datas));
        }
        catch(e)
        {
            
        }
    },
    getAllStoreElt: function (name,datas) {
        if(store.state.noCache)return [];
        var storeEltArray = []
        for(var x in localStorage)
        {
            var index = x.indexOf('storage - ');
            if(index!=-1)
            {
                storeEltArray[x.replace('storage - ','')] = localStorage[x];
            }
        }
        return storeEltArray;
        
    },
    setStoreElt: function (_this, name, storageName) {
        if(store.state.noCache)return false;
        var s = (sessionStorage.getItem('authorization')?sessionStorage:localStorage);
        var storeElt = StorageService.getValue(s, 'storage - '+(storageName?storageName:name));
        if(storeElt && storeElt!='undefined')
        {
            if(_this[name])
            {
                _this[name] = JSON.parse(storeElt);
            }
            else
            {
                _this.$store.state[name] = JSON.parse(storeElt);
            }
        }
    }
}
