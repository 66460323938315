import Global from 'common/services/resources/global.js'
import LanguageService from 'common/services/language.js'
import WindowService from 'common/services/window.js'

export default {
  data:
    function () {
      return {
        CONSTANTS: {
          VERSION: Global.VERSION
        },
        userLang: LanguageService.getUserLanguage(),
      }
  },
  created: function () {
  },
  methods:
  {
    callLink : function(link){
      var lang = LanguageService.websiteUserLanguage();
      WindowService.open(Global.REDIRECT_URI_PROD+'/'+lang+'/'+link+'-'+lang,'_blank',this.inApp);
    }
  }
}
