import SetHtmlElt from 'common/services/set_html_elt.js'
import Help from '../help/help.vue'
import Download from '../download/download.vue'
import DeleteModal from '../delete-modal/delete-modal.vue'
import WindowService from 'common/services/window.js'
import StorageService from 'common/services/storage.js'
import Language from 'common/services/language.js'
import Global from 'common/services/resources/global.js'
import store from 'vuex_path/store'

export default {
	components:{Help, Download, DeleteModal},
	props: ['appConstants','user','users','manual-extend', 'externalAccounts', 'notificationsModules', 'hcUrl', 'versionInfos', 'sharesLocations'],
    data:
        function () {
            return {
				CONSTANTS:
				{
					NOT_YET : this.$gettext('Not available'),
					STORE_TITLE:  _upperFirst(this.$gettext('Store')),
					DASHBOARD_TITLE : _upperFirst(this.$gettext('dashboard')),
					ROOMS_TITLE : _upperFirst(this.$gettext('My home')),
					ALERTS_TITLE : _upperFirst(this.$gettext('Notifications')),
					SCENARIOS_GROUPS_TITLE : _upperFirst(this.$gettext('Services - Scenarios')),
					MARKETPLACE_TITLE : _upperFirst(this.$gettext('marketplace')),
					SETTINGS_TITLE : _upperFirst(this.$gettext('settings')),
					HELP_TITLE : _upperFirst(this.$gettext('help')),
					ABOUT_TITLE : _upperFirst(this.$gettext('About')),
					DOWNLOAD_APP_TITLE : _upperFirst(this.$gettext('download app')),
					SHARED_HOMES_TITLE : _upperFirst(this.$gettext('Shared homes')),
					LOGOUT_TITLE : this.$gettext('Log out'),
					TUTORIAL_TITLE : this.$gettext('Tutorial'),
					REMAINING_SMS:  this.$gettext('<b>%{s}</b> remaining SMS'),
					UNLIMITED_EMAILS: this.$gettext('Emails unlimited'),
					REMAINING_EMAILS: this.$gettext('<b>%{s}</b> remaining email(s)'),
					UNLIMITED_PUSHES: this.$gettext('Pushes unlimited'),
					REMAINING_PUSHES: this.$gettext('<b>%{s}</b> remaining push(es)'),
					VERSION: this.$gettext('Version: <b>%{s}</b>'),
					REMAINING_NOTIFICATIONS: this.$gettext('<b>%{s}</b> SMS - <b>%{m}</b> Mail - <b>%{n}</b> Notification'),
					APP_VERSION: this.$gettextInterpolate(this.$gettext('Version %{s}'), {s: Global.VERSION}),
					NOT_YET_PATH : [],
					EXTEND_WIDTH: 80,
					MINIMIZE_WIDTH: 0
				},
				currentLink: '',
				width: 288,
				drawer: false,
				displayShowMenu: false,
				mobile:false,
				mini: false,
				miniVariantWidth:0,
				displayLogoutModal: false,
				displayHelpModal: false,
				displayDownloadModal: false,
				mailTxt:"",
				region:Language.getRegionJson(),
				myProfileExpansionList: 
				{
					title: this.$gettext('My profile'),
					active: false,
					list: []

				},
				accountsExpansionList: 
				{
					title: _upperFirst(this.$gettext('users')),
					active: false,
					onClickAccounts:(member)=> {
						if(!this.eventHub.displayFeatureNotAvailableSharedAccount())
						{
						  return false;
						}
						if(this.$route.name=='settings')
							this.eventHub.$emit('openDialog', 'editUser-dialog', member);
						else
							this.$router.push({ name: 'settings', params: {displayUserAddModal: true, userToEdit: member}});
						this.drawer = false;
					},
					list: 
					[
						{
							title: _upperFirst(this.$gettext('add a user')),
							icon:'cl-icon cl-icon-plus',
							onClick: ()=> {
								if(this.eventHub.displayFeatureNotAvailableSharedAccount())
								{
									if(this.$route.name=='settings')
										this.eventHub.$emit('openDialog', 'addUser-dialog');
									else
										this.$router.push({ name: 'settings', params: {displayUserAddModal: true}});
									this.drawer = false;
								}
							}	
						}
					]
				}
			}
	},
	watch: {
		/*drawer: function (val, oldVal) {
			if(!val)this.mini=true;
		},*/
		'$route.name': function (val, oldVal) {
			this.region = Language.getRegionJson();
			this.selectMenu(val);
		}
	},

    computed: {
        total : function(){
			var count = 0;
			let notifications = _filter(this.notificationsModules, (n) => { return n.plantId == this.$store.state.selectedPlantId; });
			if(notifications){
				for(var i=0; i < notifications.length; i++){
					count = count + notifications[i].notifications.length;
				}
			}
			return count;
		},
		computedUsers: function(){

			return (this.$store.state.bMainAccount?this.users:{members:[{login:this.$store.state.userMail}]});
		},
		isComfortlife : function(){

			return this.$store.state.variantManager.isComfortlife();
		}
    },
    created: function () {
		if(this.manualExtend)
		{
			this.eventHub.$on('extendNavDrawer',this.onExtend);
			this.eventHub.$on('minimizeNavDrawer',this.onMinimize);
		}
		this.eventHub.$on('goTo',this.go);
		this.eventHub.$on('goToRooms',this.goToRooms);
		this.eventHub.$on('showMenu',this.showMenu);
		this.eventHub.$on('closeMenu',this.closeMenu);
		this.eventHub.$on('goToExternalPage',this.goToExternalPage);
		this.eventHub.$on('displayAbout',this.displayAbout);
		window.addEventListener('resize', this.resize);
		//this.eventHub.$on('navbarDrawerSelectMenu',this.selectMenu);
		this.init();
		this.resize();
	},
	beforeDestroy: function ()
	{
		this.eventHub.$off('goToExternalPage',this.goToExternalPage);
		this.eventHub.$off('goTo',this.go);
		this.eventHub.$off('goToRooms',this.goToRooms);
		this.eventHub.$off('showMenu',this.showMenu);
		this.eventHub.$off('closeMenu',this.closeMenu);
		this.eventHub.$off('displayAbout',this.displayAbout);
		window.removeEventListener('resize', this.resize);
	},	
	mounted: function () {
		try
		{
			if(this.$refs[this.$route.name])
				SetHtmlElt.addClass(this.$refs[this.$route.name][0].$el, 'select');
			else
				this.unSelectAll();
		}
		catch(e){}
	},
	methods: {
		init: function () {
			this.miniVariantWidth = (this.manualExtend?this.CONSTANTS.MINIMIZE_WIDTH:this.CONSTANTS.EXTEND_WIDTH);
			if (navigator.userAgent.match(/(android|iphone|blackberry|symbian|symbianos|symbos|netfront|model-orange|javaplatform|iemobile|windows phone|samsung|htc|opera mobile|opera mobi|opera mini|presto|huawei|blazer|bolt|doris|fennec|gobrowser|iris|maemo browser|mib|cldc|minimo|semc-browser|skyfire|teashark|teleca|uzard|uzardweb|meego|nokia|bb10|playbook)/gi) || this.inApp)
			{
				this.mini = false;
				this.width = window.innerWidth;
			}
			if(this.isComfortlife)
			{
				this.myProfileExpansionList.list.push({
					title: this.$gettext('Change version'),
					icon:'cl-icon cl-icon-price-ribbon',
					onClick: ()=> {
						this.openVersionMarket();
						this.drawer = false;
					}	
				},
				{
					title: this.$gettext('Buy notifications'),
					icon:'cl-icon cl-icon-shopping-basket',
					onClick: ()=> {
						this.openSmsMarket();
						this.drawer = false;
					}
				});
			}			
		},
		resize: function () {
			if (navigator.userAgent.match(/(android|iphone|blackberry|symbian|symbianos|symbos|netfront|model-orange|javaplatform|iemobile|windows phone|samsung|htc|opera mobile|opera mobi|opera mini|presto|huawei|blazer|bolt|doris|fennec|gobrowser|iris|maemo browser|mib|cldc|minimo|semc-browser|skyfire|teashark|teleca|uzard|uzardweb|meego|nokia|bb10|playbook)/gi) || this.inApp)
			{
				this.width = window.innerWidth;
			}
			else
			{
				this.width = 288;
			}
			if(window.innerWidth<=1024)
			{
				this.mobile = true;
			}
			else
			{
				this.mobile = false;
			}
		},	

		convertInfSymbol: function (val) {
			return (val=='INF'?'∞':val);
		},

		onExtend: function () {
			this.extend();
		},
		onMinimize: function () {
			this.minimize();
		},
		getIconClass: function (item) {
			var clsObj = {
							'cl' : true
						};
			clsObj[item.icon] = true;
			return clsObj;
		},
		toggle: function () {
			this.miniVariantWidth = (this.miniVariantWidth == this.CONSTANTS.MINIMIZE_WIDTH ? this.CONSTANTS.EXTEND_WIDTH : this.CONSTANTS.MINIMIZE_WIDTH );
		},
		minimize: function () {
			if(window.innerWidth>1024){
				this.mini = true;
				this.miniVariantWidth = this.CONSTANTS.MINIMIZE_WIDTH;
			}
		},
		extend: function () {
			this.miniVariantWidth = this.CONSTANTS.EXTEND_WIDTH;
		},
		go: function(path){
			this.unSelectAll();
			path = path.toLowerCase();
		    if(this.CONSTANTS.NOT_YET_PATH.indexOf(path)!=-1)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.NOT_YET);
				return false;
			}
			if(path=='home')path='';
			this.currentLink = path;
			if(path!='rooms')
			{
				this.eventHub.$emit('navbarDrawerGo');
			}
			/*if(this.$route.name=='sharesLocations')
			{
				this.eventHub.$emit('setPlant',JSON.parse(StorageService.getValue(localStorage, 'storage - userPlant')));
				this.eventHub.$emit('loadingPageShow',true);
				this.eventHub.$emit('getAllTopology','all');
			}*/
			this.init();
			this.$router.push((path=='view'?'/'+path:'/home/'+path));
			this.eventHub.$emit('initRightPanel');
			this.drawer = false;
			this.miniVariantWidth = 0;
		},
		
		goToRooms: function(){
			this.go('rooms');
		},

		goToExternalPage: function(url){
			if(this.inApp)
			{
				var s = (this.eventHub.user.rememberMe?localStorage:sessionStorage);
				var webviewParams =  {url: url, routeFrom: this.$route.name};
    			StorageService.persist(s, 'webviewParams',JSON.stringify(webviewParams));
				this.$router.push({ name: 'webview', params: webviewParams});
				this.eventHub.$emit('changeOrientation', 'user');
			}
			else
			{
				window.open(url,'external');
			}
		},

		logout: function(){
			this.displayLogoutModal = false;
			this.eventHub.$emit('setLoading',false);
			this.eventHub.$emit('logout');
		},
		selectMenu: function(name)
		{
			if(this.$refs[name])
			{
				var elt = this.$refs[name][0] || this.$refs[name];
				if(elt)
				{
					//this.unSelectAll();
					/*this.$nextTick(function() {
						SetHtmlElt.addClass(elt.$el, 'select');
					});*/
				}
			}
		},
		unSelectAll: function()
		{
			var elts = document.querySelectorAll('.v-navigation-drawer .v-list > div');
			for (var i = 0; i < elts.length; i++){
				SetHtmlElt.removeClass(elts[i], 'select');
			}
		},

		changeMenu: function(){
			this.setClickOnMenu();
			this.drawer = false;
		},
		showMenu: function(){
			this.displayShowMenu = true;
			if(this.drawer == false){
				this.drawer = true;
				this.miniVariantWidth = 80;
			}
			else{
				this.drawer = false;
				this.miniVariantWidth = 0;
		  	}
		},
		openDownloadPage: function(){
			WindowService.openDownloadApp('_blank',this.inApp);
		},
		goToMarket: function(){
			this.eventHub.$emit('displayMarket');
		},
		openSmsMarket: function(){
			var lang = (Language.getUserLanguage()=='fr'?'fr':'en');
			WindowService.open(Global.WEBSITE_STORE+'/notifications-'+lang,'_blank',this.inApp);
		},
		openVersionMarket: function(){
			var lang = (Language.getUserLanguage()=='fr'?'fr':'en');
			WindowService.open(Global.REDIRECT_URI_PROD+'/'+lang+'/versions-'+lang+'/#versions','_blank',this.inApp);
		},
		callLink : function(link){
			var lang = Language.websiteUserLanguage();
			WindowService.open(Global.REDIRECT_URI_PROD+'/'+lang+'/'+link+'-'+lang,'_blank',this.inApp);
		},
		displayAbout : function(){
			this.displayHelpModal = true;
		},

		setClickOnMenu : function(){
			if(this.$route.name!='sharesLocations'&&this.$route.name!='rooms'&&this.$route.name!='actions')
			{
				this.eventHub.$emit('setClickOnMenu', true);
			}
			else
			{
				this.eventHub.$emit('setClickOnMenu', false);
			}
		},


		onClickOutsideNavbarDrawer : function(){
			if(!this.mobile)return false;
			if(! this.displayShowMenu && this.drawer)
			{
				this.drawer = false;

			}
			this.displayShowMenu=false;
		},
	}
}
