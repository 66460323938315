import Settings from 'common/classes/Settings.js';
import StorageService from 'common/services/storage.js'

export default {
    CONSTANTS:
    {
    },
    settings: new Settings(),

    initSettings: function (user) {
        this.settings.user = user;
        var s = (this.settings.user.rememberMe?localStorage:sessionStorage);
        if(StorageService.keyExists(s,'defaultView'))
        {
            this.settings.defaultView = StorageService.getValue(localStorage, 'defaultView');
        }
        if(StorageService.keyExists(s,'viewAutoplayDelay'))
        {
            this.settings.viewAutoplayDelay = parseInt(StorageService.getValue(localStorage, 'viewAutoplayDelay'));
        }
        if(StorageService.keyExists(s,'cameraFrameRate'))
        {
            this.settings.cameraFrameRate = parseInt(StorageService.getValue(localStorage, 'cameraFrameRate'));
        }
        if(StorageService.keyExists(s,'fullscreenCameraDisplayDuration'))
        {
            this.settings.fullscreenCameraDisplayDuration = parseInt(StorageService.getValue(localStorage, 'fullscreenCameraDisplayDuration'));
        }
    },

    getSettings: function () {
        return this.settings;
    },

    setDefaultView: function (defaultView) {
        if(defaultView)
        {
            this.settings.defaultView = defaultView;
        }
        var s = (this.settings.user.rememberMe?localStorage:sessionStorage);
        StorageService.persist(s, 'defaultView', this.settings.defaultView);
    },

    setViewAutoplayDelay: function (viewAutoplayDelay) {
        if(viewAutoplayDelay)
        {
            this.settings.viewAutoplayDelay = viewAutoplayDelay;
        }
        var s = (this.settings.user.rememberMe?localStorage:sessionStorage);
        StorageService.persist(s, 'viewAutoplayDelay', this.settings.viewAutoplayDelay);
    },

    setCameraFrameRate: function (cameraFrameRate) {
        if(cameraFrameRate)
        {
            this.settings.cameraFrameRate = cameraFrameRate;
        }
        var s = (this.settings.user.rememberMe?localStorage:sessionStorage);
        StorageService.persist(s, 'cameraFrameRate', this.settings.cameraFrameRate);
    },

    setFullscreenCameraDisplayDuration: function (fullscreenCameraDisplayDuration) {
        if(fullscreenCameraDisplayDuration)
        {
            this.settings.fullscreenCameraDisplayDuration = fullscreenCameraDisplayDuration;
        }
        var s = (this.settings.user.rememberMe?localStorage:sessionStorage);
        StorageService.persist(s, 'fullscreenCameraDisplayDuration', this.settings.fullscreenCameraDisplayDuration);
    },

}
