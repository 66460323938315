import Service from './Service.js'
import Language from 'common/services/language.js'
import { consoleError } from 'vuetify/es5/util/console';

var stringify = require('json-stringify-safe');

export default class extends Service {

    constructor(name, locationId, notificationLevel, infos) {
        super(name, locationId, notificationLevel, infos);
    }
    
    setTriggers(element) {
        var _this = this;
        //var elts = JSON.parse(JSON.stringify(elements));
        if(element.devices && element.devices.length>0)
        {
          let devices = _filter(element.devices, function(m) { return m.selected == true; });
          devices.forEach(function(m) {
            var trigger =  {
                "type": "status",
                "forStates": [element.capability],
                "infos": {
                  "capability": "detect", 
                  "operation": "EQUAL",
                  "moduleId": m.id
                }
            };
            if(m.device.model=='dmf')
            {
                trigger.infos.capability = "access";
                trigger.infos.value = "open";
            }
            //Check if element exist in next elements array
            //_this.checkElementExistInNextArray(elts,index,trigger,m);
            _this.infos.triggers.push(trigger);
          });
        }
    }

    setActions(element) {
        var _this = this;
        if(element.devices && element.devices.length>0)
        {
          let devices = _filter(element.devices, function(m) { return m.selected == true; });
          devices.forEach(function(m) {
            if(m.id)
            {
                var action =  {
                    "type": "command",
                    "forStates": [element.capability],
                    "infos": {
                      "capability": "status", 
                      "value": (m.status?m.status:'on'),
                      "moduleId": (m.switch_id?m.switch_id:m.id)
                    }
                };
                //_this.checkElementExistInNextArray(elts,index,action,d);
                _this.infos.actions.push(action);
            }
          });
        }
    }  

    /**
     * 
     * @param {*} uiElement : Object element in add-service-wizard.js
     * @param {*} service : Object service
     */

    setUIElementsTriggers(service,uiElements) {
      //let devices = JSON.parse(stringify(devices));
      service.service.infos.triggers.forEach(function(trigger,index) {
        if(trigger)
        { 
          var d = _find(uiElements.devices, { 'id': trigger.infos.id || trigger.infos.moduleId });
          if(trigger.forStates)
          {
            trigger.forStates.forEach(function(forState,index) {
              if(forState.value==uiElements.capability.value)
              {
                d.selected = true;
              }
            });
          }
        }
      });
    }

    setUIElementsActions(service,uiElements) {
      //let devices = JSON.parse(stringify(devices));

      service.service.infos.actions.forEach(function(action,index) {
        if(action)
        {
          var elt = {};
          var arrType = '';
          if(action.type=='command')
          {
            elt = _find(uiElements.devices, { 'id': action.infos.id  || action.infos.moduleId });
            if(elt == undefined){
              elt = _find(uiElements.devices, function(d){
                if(d.device.switchs){
                  for(var i=0; i<d.device.switchs.length; i++){
                    if(d.device.switchs[i].id == action.infos.id)
                      return true;
                  }
                }
                if(d.device.channels){
                  for(var j=0; j<d.device.channels.length; j++){
                    for(var i=0; i<d.device.channels[j].switchs.length; i++){
                      if(d.device.channels[j].switchs[i].id == action.infos.id)
                        return true;
                    }
                  }
                }
              });
              elt.switch_id = action.infos.id;
              elt.status = action.infos.value;
              elt.switch_name = action.infos.name;
            }
            arrType = 'devices';
          }
          if(arrType!='' && action.forStates)
          {
            action.forStates.forEach(function(forState,index) {
              if(forState.value==uiElements.capability.value)
              {
                elt.selected = true;
              }
            });
          }
        }
      });
    }   
}

/*{
    "name": "My Service",
    "type": "string",
    "locationId": "1234567890ABCDEF",
    "infos": {
      "actions": [
        {
          "type": "command",
          "forStates": [
            {
              "capability": "protection",
              "value": "arm"
            }
          ],
          "infos": {
            "capability": "string",
            "value": "string",
            "moduleId": "string"
          }
        }
      ],
      "triggers": [
        {
          "type": "status",
          "forStates": [
            {
              "capability": "protection",
              "value": "arm"
            }
          ],
          "infos": {
            "capability": "string",
            "value": "string",
            "operation": "EQUAL",
            "moduleId": "string"
          }
        }
      ]
    }
  }
  {
    "type": "email",
    "infos": {
        "from": "foo2@bar.qux", //optional
        "to": "foo@bar.qux",
        "title": "My title", //optional
        "message": "My message" //optional
    }
}*/