export default class {

    constructor(errorCode) {
        this.errorCode = errorCode || -1;
        
        this.HIKVISION_ERROR_CODES = 
        {
            '101011': 'Incorrect verification code',
            '101010': 'Incorrect verification code',
            '120002': 'The device does not exist.',
            '120007': 'The device is offline.',
            '120010': 'Incorrect device verification code',
            '102000': 'The camera does not exist.',
            '102001': 'The camera does not exist.',
            '102003': 'The device is offline.',
            '102005': 'Camera name is required.',
            '102007': 'Incorrect device serial No.',
            '102009': 'Request response timed out.',
            '102030': 'Not supported by device.',
            '105002': 'Incorrect verification code',
            '380011': 'Incorrect verification code',
            //'395451': 'The stream type is not supported by the device.',
            '400035': 'Incorrect verification code',
            '400036': 'Incorrect verification code',
            '410023': 'The verification code is empty.'
        },
        this.HIKVISION_VERIFICATION_CODE_ERROR_CODES = [101011,101010,120010,105002,380011,400035,400036,410023];
        this.HIKVISION_ACCESS_TOKEN_ERROR_CODES = [110002,110003,400902,400032];
        this.type = this.getType();
        this.txt = this.getTxt();

    }

    getType = function() {
        if(this.isAccessTokenError())
        {
            return 'accessTokenError';
        }
        else if(this.isVerificationCodeError())
        {
            return 'verificationCodeError';
        }
        else
        {
            return 'general';
        }
    }

    getTxt = function() {
        return (this.HIKVISION_ERROR_CODES[this.errorCode]?this.HIKVISION_ERROR_CODES[this.errorCode]:'Cannot display camera, contact technical support');
    }

    initializeToGeneral = function()
    {
        //this.errorCode = -1;
        this.type = 'general';
        this.txt = 'Can not display camera';
    }
    
    isAccessTokenError = function() {
        return (this.HIKVISION_ACCESS_TOKEN_ERROR_CODES.indexOf(this.errorCode)!=-1);
    } 
    isVerificationCodeError = function() {
        return (this.HIKVISION_VERIFICATION_CODE_ERROR_CODES.indexOf(this.errorCode)!=-1);
    }
}