import AlertIcon from 'common/components/alert-icon/alert-icon.vue'
export default {
	props: {
		service:{
			type: Object,
			default:{}
		},	
		validation:{
			type: Object,
			default:{result:false}
		},
		services:{
			type: Array,
			default:undefined
		},
		versionInfos:{
			type: Object,
			default:{}
		}
	},
	components: {AlertIcon},
	data:
		function () {
			return {
				CONSTANTS:
				{
					'TEXT_GOOD' : this.$gettext('<strong>Installation completed</strong></br>Congratulations, the installation of your kit is complete.'),
					'TEXT_BAD' : this.$gettext('<strong>Unable to add the kit</strong></br>Please try again.'),
					'CLOSE' : this.$gettext('Close'),
					'RETRY' : this.$gettext('Retry')
				}
			}
		},
	created: function () {
		this.eventHub.$emit('setServiceDisplayPrevious', false);
	},
	destroyed: function () {
	}, 	
	methods: {
		finish: function(status){
			switch(status){
				case 'add':
					if(this.services.length >= this.versionInfos.serviceCount){
						this.eventHub.$emit('displayAlertKey', 'unauthorized');
					}
					else{
						this.eventHub.$emit('backServiceMainStep');
					}
				break;
				case 'close':
					this.eventHub.$emit('addServiceFinished',this.service);
				break;
				case 'retry':
					this.eventHub.$emit('serviceInit');
				break;
			}
		}
	}
}