export default {
  rgbToHex: function(r, g, b) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  },        

  checkHex: function(value) {
    var v = value.toString()
    v = v.replace('0x','#');
    return /^#([0-9A-F]{3}){1,2}$/i.test((v.substr(0,1)!='#'?'#'+v.toString():v));
  } 
}