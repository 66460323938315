import Language from 'common/services/language.js'
import StorageService from 'common/services/storage.js'

export default {
    props:{
        actions: {
            type: Array,
			default:[]
        }
    },
    data:
        function () {
            return {
                CONSTANTS:{
                    NOTIFICATION : _upperFirst(this.$gettext('notification')),
                },
                switchDefault: true,
                title: '',
                message: ''
            }
        },
    created: function () {
    },
    methods: {
        close: function ()
		{
			this.$emit('close');
        },
        addPush: function ()
		{
            var obj = {
				type: 'push',
				infos: {
                    language: Language.getUserLanguage(),
                    title: this.$store.state.variantManager.class.title,
                    app: StorageService.getValue(localStorage, 'app')
				}
            }

			if(this.title != '' && !this.switchDefault)
				obj.infos.title = this.title;
			if(this.message != '' && !this.switchDefault)
                obj.infos.message = this.message; 
            this.actions.push(obj);
            this.close();
        }
    }
}
  