import UserResource from 'common/services/resources/user.js'

export default {
	data:
		function () {
			return {
					CONSTANTS:
					{
						WIFI_ERROR : this.$gettext('Wifi error'),
						CHECK_WIFI_TIMEOUT:500
					},
					wifiOk: false,
					ssid:'',
					checkWifiTimeout: null
			}
	},
	created: function () {
    var _this = this;
		this.checkWifiTimeout=setTimeout(function(){
       _this.checkWifi();
    }, this.CONSTANTS.CHECK_WIFI_TIMEOUT);
    this.getAssocToken();
  },
	methods: {
		next: function(){
			this.eventHub.$emit('nextStepWifi');
		},
		checkWifi: function(){
			var _this = this;
			cordova.exec(
				function(winParam) {
					//console.log(winParam);
					_this.setWifiInfos(winParam);
					if (winParam.connected){
						_this.ssid = winParam.ssid;
						//console.log("wincallback " + winParam.ssid);
					}
          else {
						_this.eventHub.$emit('displayAlert', WIFI_ERROR);
						//console.log("Wifi not connected!");
					}
				},
				function(error) {
					_this.eventHub.$emit('setSSID','');
					_this.eventHub.$emit('displayAlert', WIFI_ERROR);
					//console.log("error");
				},
				"WifiService",
				"get_current_wifi",
				[]);
		},
		setWifiInfos: function(infos){
      		//console.log(JSON.stringify(infos));
      		var ssid = "";
     			var auths = "";
      		var freq = "";
					var smntIncapableReason = "";
					if (infos.connected){
						ssid = infos.ssid;
						auths  = infos.auths.join();
						freq = infos.is_2ghz ? "2.4GhZ" : "5Ghz";
					}
      		var smntCapable = infos.smnt_capable;
      		if (!smntCapable){
				    /*
				      this is an array which will contain, the reasons
				      why the current wifi setup does not support SmartConnect
				      reason could be one or multiple:
				      -> SMNT_INCAPABLE_WIFI_NOT_CONNECTED
				      -> SMNT_INCAPABLE_UNSUPPORTED_AUTH_TYPE
				      -> SMNT_INCAPABLE_IS_ON_5GHZ
				    */
          	smntIncapableReason = infos.smnt_reasons;
						eventHub.$emit('displayAlert', this.CONSTANTS.WIFI_ERROR.toUpperCase());
						this.wifiOk=false;
      		}
					else{
						this.wifiOk=true;
						clearTimeout(this.checkWifiTimeout);
					}
					this.eventHub.$emit('setWifi',{
											ssid: ssid,
											auths: auths,
											freq: freq,
											smntCapable: smntCapable,
											smntIncapableReason: smntIncapableReason
											});
      		//console.log("This ssid is : " + ssid);
    },
    getAssocToken: function(){
      var _this = this;
      UserResource.getAssocCode().then(
        function(response) {
          _this.$parent.associationToken = response.data.associationToken;
      }).catch(
        function() {
          _this.$parent.result = false;
          _this.eventHub.$emit('nextInstallStep');
      });
    },
	}
}
