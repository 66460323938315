export default {
    props: ['module','use'],
    data: function () {
        return {
        }
    },
    methods: {
      setStatus: function(value) {
        if(this.use == "scenario-action")
        {
          this.eventHub.$emit('createScenarioItem', this.module, undefined, this.module.device.commands[value], this.use);
        }
        else
        {
          this.$parent.$emit('set-status', value);
        }
      }
    }
}
  