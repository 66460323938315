export default {
	props: 
	{
		txt: {
			type: String
		},
		installObject: {
			type: Object
		}
	},
	data:
		function () {
			return {
				CONSTANTS:
				{
				},
			}
	},
	beforeDestroy () {
	},	 
	created: function () {
    },
	methods: {
 	}
}