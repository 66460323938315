import StorageService from 'common/services/storage.js'

import StringService from 'common/services/string.js'

//import { CONSTANTS as languageComfortlife} from './language/languageComfortlife'

//import { CONSTANTS as languagePhilipsWelcome } from './language/languagePhilipsWelcome'

import UserResource from 'common/services/resources/user.js'

import { consoleError } from 'vuetify/es5/util/console';

/*const LANGUAGES = {
  languageComfortlife,
  languagePhilipsWelcome
};*/

//const CONSTANTS = LANGUAGES['language'+StringService.toPascalCase(window.VARIANT)]

const CONSTANTS = {}

const DEFAULT_REGION = 'eu';
const LANGUAGES_MANAGED_BY_WEBSITE = ['en','fr','zh'];

function dotEnvToArray()
{
  let regions = [];
  const reg = new RegExp('^VUE_APP_');
  for(let key in process.env)
  {
      if(reg.test(key))
      {
        const regRegion = new RegExp('^VUE_APP_REGION_');
        const keySplit = key.split('_');
        if(regRegion.test(key))
        {
          let obj = {};
          if(keySplit[3])
          {
              let region = keySplit[3].toLowerCase();
              if(regions[region]==undefined)
              {
                  regions[region] = {};
              }
              const keyS = keySplit[4].toLowerCase();
              if(keySplit[5]==undefined)
              {
                  regions[region][keyS] = process.env[key];
              }
              else
              {
                  if(regions[region][keyS]==undefined)
                  {
                      regions[region][keyS] = {};
                  }
                  setValueFromDotEnv(regions[region][keyS],key,4);
              }
          }
        }
        else
        {
          const keyS = key.replace('VUE_APP_','');
          CONSTANTS[keyS] = process.env[key];
        }

      }
  }

  //REGION
  let regionsArray = [];
  for(let key in regions)
  {
    regionsArray.push(regions[key]);
  }

  CONSTANTS.REGIONS = regionsArray;
}

function setValueFromDotEnv(obj,key,index)
{
    const keySplit = key.split('_');
    const keyS = keySplit[index].toLowerCase();
    if(keySplit[index+1]==undefined)
    {
        obj[keyS] = process.env[key];
    }
    else
    {
        obj[keyS] = {};
        setValueFromDotEnv(obj[keyS],key,index+1);
    }
}


export default {
  CONSTANTS,

  initRegions: function(){
    /*if(this.isSandBox())
    {
      this.CONSTANTS.REGIONS = this.CONSTANTS.SANDBOX_REGIONS;
    }
    else if(this.isDev())
    {
      this.CONSTANTS.REGIONS = this.CONSTANTS.DEV_REGIONS.concat(this.CONSTANTS.REGIONS).concat(this.CONSTANTS.SANDBOX_REGIONS);
    }*/
    dotEnvToArray();
  },

  isSandBox: function(){
    return (document.location.href.split('/')[2].indexOf('sandbox')!=-1)
  },

  isDev: function(){
    return (document.location.href.split('/')[2].indexOf('dev')!=-1)
  },

  isVM: function(){
    return (document.location.href.split('/')[2].split('.')[0] =='www-dev');
  },

  websiteUserLanguage: function(){
    var lang = this.getUserLanguage();
    return (LANGUAGES_MANAGED_BY_WEBSITE.indexOf(lang)!=-1?lang:'en');
  },

  transformLanguageName: function(languageName){
    var tab = languageName.split("-");
    if(tab[0] == "zh"){
      return languageName;
    }
    else{
      var userLangMaj = tab[0].toUpperCase();
      var userLang = tab[0]+"-"+userLangMaj;
      return userLang
    }
  },
  getUserLanguage: function(){
    var userLang = (navigator.language || (navigator.languages && navigator.languages[0]));
    var tab = userLang.split("-");
    userLang = tab[0];
    return userLang;
  },

  getRegion: function(param,value){
    return _find(this.CONSTANTS.REGIONS, [param, value]);
  },

  getRegionJson: function(){
    var region = StorageService.getValue(localStorage, 'region-ws');
    if(region)
    {
      return JSON.parse(region);
    }
    else
    {
      region = document.location.href.split('/')[2].split('.')[0];
      for(var i = 0; i<this.CONSTANTS.REGIONS.length;i++)
      {
        if(this.CONSTANTS.REGIONS[i].region==region || (window.location.hostname.indexOf(this.CONSTANTS.REGIONS[i].url)!=-1))
          return this.CONSTANTS.REGIONS[i];
      }
      return {};
    }
  },

  getUrlFromUrl: function(){
    var region = StorageService.getValue(localStorage, 'region-ws');
    if(region)
    {
      region = JSON.parse(region);
      return (region.endpoint?region.endpoint:'https://eu.api.iotcl.pro');
    }
    else
    {
      const href = document.location.href.split('/');
      const region = _find(this.CONSTANTS.REGIONS, function(o) { 
        return o.url.indexOf(href[2])!=-1;
      });
      return (region?region.endpoint:'https://eu.api.iotcl.pro');
    }
  },

  getIndexFromUrl: function(region){
    var index = _findIndex(this.CONSTANTS.REGIONS, ['region', region]);
    if(index==-1)
    {
      const href = document.location.href.split('/');
      index = _findIndex(this.CONSTANTS.REGIONS, function(o) { 
        return o.url.indexOf(href[2])!=-1;
      });
    }
    //If region not found
    if(index==-1)
    {
      index = _findIndex(this.CONSTANTS.REGIONS, ['region', DEFAULT_REGION]);
    }

    return (index!=-1?index:0);
  },

  getBackendUrlByRegion: function(region, currentBackendUrl){
    var regionHost = undefined;
    if(region)
    {
      var r = this.getRegion('region',region);
      if(r)
      {
        regionHost = r.endpoint;
      }
    }
    return (regionHost?regionHost+'/v1.0/':currentBackendUrl);
  },

  getRegionDns: function(region){
    return region.endpoint.replace(/(^\w+:|^)\/\//, '');
  },

  getSelectedRegion: function(){
    try
    {
      let region = JSON.parse(StorageService.getValue(localStorage, 'region-ws'));
      let regions = StorageService.getValue(localStorage, 'regions');
      if(region && region.region && regions.length > 0)
      {
        return _find(regions, {'code':region.region});
      }
      return undefined;
    }
    catch(e){
        console.log(e);
        return undefined;
    }; 

  },

}
