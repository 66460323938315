import deviceInstallIdentifier from './device-install-identifier.vue';
import deviceInstall1 from './device-install1.vue';
import deviceInstall2 from './device-install2.vue';

export default {
	props: ['chooseType','qrCodeElts','homepilot', 'model', 'submodel', 'step', 'deviceObj'],
	components: {deviceInstallIdentifier,deviceInstall1,deviceInstall2},
    data: function () {
        return {
            installSentences:{
              'car' : {
                'device' : 'chime',
                'association' : [this.$gettext('You have 15 s to press for 3 seconds the call button chime: the bell to ring.')],
								'img' : require('@/assets/images/device/car/CAR-XXX.png')
              },
							'TEL-100' : {
                'device' : 'remote control',
                'association' : [this.$gettext('You have 15 s to press the left button.')],
								'img' : require('@/assets/images/device/tel/TEL-100.png')
              },
              'RC-001' : {
                'device' : 'remote control',
                'association' : [this.$gettext('You have 15 s to press the left button.')],
								'img' : require('@/assets/images/device/tel/TEL-100.png')
              },
							'TEL-010' : {
                'device' : 'remote control',
                'association' : [this.$gettext('You have 15 s to press the green number 1 button on the top left of the remote control. The remote control must be on the A position.')],
								'img' : require('@/assets/images/device/tel/TEL-010.png')
              },
              'RC-004' : {
                'device' : 'remote control',
                'association' : [this.$gettext('You have 15 s to press the green number 1 button on the top left of the remote control. The remote control must be on the A position.')],
								'img' : require('@/assets/images/device/tel/TEL-010.png')
              },
							'DEM-001' : {
                'device' : 'motion sensor',
                'association' : [this.$gettext('Press ON to turn on the device.'),
                                 this.$gettext('Then move your hand in front of the detector to turn it on.')],
								'img' : require('@/assets/images/device/dem/cl-install-DEM-001-connection_12200.png')
              },
              'C50' : {
                'device' : 'motion sensor',
                'association' : [this.$gettext('Press ON to turn on the device.'),
                                 this.$gettext('Then move your hand in front of the detector to turn it on.')],
								'img' : require('@/assets/images/device/dem/cl-install-C50-connection_12200.png')
              },
							'DMF-13' : {
                'device' : 'window sensor',
                'association' : [this.$gettext('You have 15 seconds to trigger the window sensor.')],
								'img' : require('@/assets/images/device/dmf/cl-install-DMF-13-test_12200.png')
              },
              'C30' : {
                'device' : 'window sensor',
                'association' : [this.$gettext('You have 15 seconds to trigger the window sensor.')],
								'img' : require('@/assets/images/device/dmf/cl-install-C30-test_12200.png')
              },
							'wa' : {
                'device' : 'audio sensor',
                'association' : [this.$gettext('You have 15 seconds to speak in front of the audio sensor.')],
								'img' : require('@/assets/images/device/wa/WA-XXX.png')
              },
							'CMR-100 (1 button)' : {
                'device' : 'light switch',
                'association' : [this.$gettext('You have 15 s to press for 3 seconds the top of the switch button.')],
								'img' : require('@/assets/images/device/cmr/CMR-XXX_1b.png')
              },
              'RC-002 (1 button)' : {
                'device' : 'light switch',
                'association' : [this.$gettext('You have 15 s to press for 3 seconds the top of the switch button.')],
								'img' : require('@/assets/images/device/cmr/CMR-XXX_1b.png')
              },
							'CMR-100 (3 buttons)' : {
                'device' : 'light switch',
                'association' : [this.$gettext('You have 15 s to press for 3 seconds the top of the central button of the switch.')],
								'img' : require('@/assets/images/device/cmr/CMR-XXX_3b.png')
              },
              'RC-002 (3 buttons)' : {
                'device' : 'light switch',
                'association' : [this.$gettext('You have 15 s to press for 3 seconds the top of the central button of the switch.')],
								'img' : require('@/assets/images/device/cmr/CMR-XXX_3b.png')
              },
							'trf' : {
                'device' : 'remote control',
                'association' : [this.$gettext('You have 15 s to press the number 1 button on the top left of the remote control.')],
								'img' : require('@/assets/images/device/trf/TRF-XXX.png')
              },
							'default' : {
                'association' : [
                  this.$gettext('Put the device in pairing mode')
                ]
              },
            },
            content:
            {
              device: '',
              association: [],
              img:''
            },
        }
    },
    created: function () {
      this.init();
    },
	  methods: {
      init : function(){
        var model = this.model.name;
        this.installSentences['KD-30'] = this.installSentences['C30'];
        this.installSentences['KD-50'] = this.installSentences['C50'];
        let stepper = [];
        if(model == 'cmr' || model == 'tel' || model == 'dem' || model == 'dmf'){
					model = this.submodel.name;
        }
        if(this.installSentences[model])
        {
          this.content = this.installSentences[model];
        }
        else
        {
          if(this.submodel.identifier)
          {
            stepper.push({'title' : 'Device configuration'});
          }
          this.content = this.installSentences['default'];
        }
        stepper = stepper.concat([
          {'title' : 'Device configuration'},
          {'title' : 'Association'},
          {'title' : 'Notifications'},
          {'title' : 'Name'},
          {'title' : 'Room'}
        ]);
        this.eventHub.$emit('setStepper', stepper, 1);
        //If QRCode 
        if(this.qrCodeElts[2])
        {
          this.deviceObj.identifier = this.qrCodeElts[2];
          this.eventHub.$emit('setInstallStep', 3);
        }
      }
	}
}
