import Global from './global.js'
import StorageService from '../storage.js'
import ArrayService from '../array.js'
import StringService from '../string.js'
import CordovaService from 'common/services/cordova.js'
import axios from 'axios'
import moment from 'moment'

import { v4 as uuidV4 } from 'uuid';

export default {
    waiting: 
    {
        add : false,
        edit : false,
        trigger : false
    },    
    getAll: function (section) {
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'scenario/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'?section='+section,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return resolve(error);
                    });
            }
        );
    },
    add: function (scenario) {
        var _this = this; 
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.add == true)return false;
                axios.post(Global.getBackendUrl()+'scenario/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId'),
                    scenario,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.add = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.add = false;
                        return reject(error);
                    });
            }
        );
    },
    edit: function (scenario, scenarioId) {
        var _this = this; 
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.edit == true)return false;
                axios.put(Global.getBackendUrl()+'scenario/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+scenarioId,
                    scenario,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.edit = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.edit = false;
                        return reject(error);
                    });
            }
        );
    },
    trigger: function(scenario, eventHub){
        var commandId = uuidV4();
        var json =   {
            "action": "command",
            "timestamp": moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            "uid": commandId,
            "modules": [
                {
                    "id": scenario.id,
                    "scenario":{
                        "status":[
                            {
                                "capability": "trigger"
                            }
                        ]
                    }
                }
            ]
        };
        var path = 'scenario/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+scenario.id+'/trigger';
        eventHub.$emit('mqttPublish',  path, json);
        CordovaService.triggerScenario(json).then(
          function(response) {
          }).catch(
          function() {
          });
    },
    status: function (json, value) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.trigger == true)return false;
                axios.post(Global.getBackendUrl()+'scenario/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+value,
                    json,
                    {
                         headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.trigger = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.trigger = false;
                        return reject(error);
                    });
            }
        );
    },
    findScenarioByName : function(scenarios, nameArray)
    {
        var foundScenarios = [];
        for(var i = 0; i <nameArray.length; i++)
        {
            var name = nameArray[i].replace(/ /g, '').toLowerCase();
            for(var j = 0; j < scenarios.length; j++)
            {
                var scenarioName = scenarios[j].name.replace(/ /g, '').toLowerCase();
                var accuratelyCompareScenarioName = StringService.accuratelyCompare(name,scenarioName);
                if(accuratelyCompareScenarioName.test && (ArrayService.find(foundScenarios,'id',scenarios[j].id)==undefined))
                {
                    foundScenarios.push(scenarios[j]);
                }
            }
        }
        return foundScenarios;
    }
}
