import AddDeviceUndefinedLogin from './add-device-undefined-login.vue'
import AddDeviceUndefinedGdprApprove from './add-device-undefined-gdpr-approve.vue'
import AddDeviceUndefinedFinish from './add-device-undefined-finish.vue'

export default {
	props:['wizardStep','wizardTitle', 'grdpWarning', 'brand'],
	components: {AddDeviceUndefinedLogin,AddDeviceUndefinedGdprApprove,AddDeviceUndefinedFinish},
	data:
		function () {
			return {
				    CONSTANTS:
					{
					},
					account: {
						name:'',
						pwd:'',
						authorization:''
					}
			}
		},
	created: function () {
		this.eventHub.$emit('setStepName', 'wizard-undefined');
		this.eventHub.$on('setWizardUser', this.setWizardUser);
	},
	destroyed: function () {
		this.eventHub.$off('setWizardUser', this.setWizardUser);
	},
	methods: {
		setWizardUser: function(user){
			this.user=user;
		}
	}
}

