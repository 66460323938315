
const QRCODE_PREFIX = '12;1;'

export default class{
    
    constructor() {
        this.mainLogo = 'main_logo.png';
        this.alternativeLogo = 'alternative_logo.png';
        this.hideBrandLogo = true;
        this.displayMarket = false;
        this.manufacturerName = 'philips';
        this.brandGroupName = 'philips';
        this.brandName = 'welcome_home';
        this.brandType = 'UNDEFINED';
        this.title = 'Philips WelcomeHome';
        this.scanNetworkButton = false;
        this.QRCode = 
        {
            prefix: QRCODE_PREFIX
        };
        this.font = 
        { 
            'family': 'CentraleSansLight',
            'bold':
            {
                'family': 'CentraleSansBold',
                'fontWeight': 'normal'
            }
        };

        this.defaultRoomsPath = 'images/default/rooms/philips';

        this.colors = {
            'first' : '#42145F',
            'second' : '#631d76',
            'third' : '#5b8f22',
            'fourth' : '#7d0063',
            'colorHover' : 'white',
            'selection': '#631d76',
            'btn': '#42145F'
        };

        this.subscribe = {
            'title' : 'Create a Philips WelcomeHome account'
        };

        this.menu = {
            'logo' : 'logo_menu.png',
            'color' : '#42145F',
            'hoverColor' : '#631d76',
            'fontColor' : 'white',
            'displayFooter' : true,
            'displayVersion' : false,
            'displayProfile' : false
        };
        
        this.topMenu = {
            'backgroundColor' : '#42145F',
            'color' : 'white',
            'hoverColor' : '#631D76',
        };

        this.helpMenu = {
            items:[
                'localControl',
                'faq',
                'contact',
                'downloadApp',
                'about'
            ]
        };

        this.login = {
            
            'background' : 'url("../assets/images/manufacturers/philips/brands/welcome_home/login_background.jpg")',
            'logo' :
            {
                'padding' : '8px 0'
            },
            'appTitle' : 'Philips WelcomeHome'
        };

        this.alerts = [
            {
                icon: 'push',
                name:'notification',
                id:'notification'
            },
            {
                icon: 'mail',
                name:'Email',
                id:'mail'
            }
        ];

        this.rooms =
        [
            {
                'key': 'entrance',
                'defaultName': 'Entrance',
                'image': {
                    'full': {
                        'path': this.defaultRoomsPath+'/entrance_full.jpg'
                    },
                    'thumb': {
                        'path': this.defaultRoomsPath+'/entrance_thumb.jpg'
                    }
                }
            },
            {
                'key': 'living_room',
                'defaultName': 'Living Room',
                'image': {
                    'full': {
                        'path': this.defaultRoomsPath+'/living_room_full.jpg'
                    },
                    'thumb': {
                        'path': this.defaultRoomsPath+'/living_room_thumb.jpg'
                    }
                }
            },
            {
                'key': 'gate',
                'defaultName': 'Gate',
                'image': {
                    'full': {
                        'path': this.defaultRoomsPath+'/gate_full.jpg'
                    },
                    'thumb': {
                        'path': this.defaultRoomsPath+'/gate_thumb.jpg'
                    }
                }
            }

        ];

        this.stepper = {
            'color' : 'white',
            'backgroundColor' : '#42145F',
            'hrColor' : 'white',
            'step':
            {
                'color' : 
                {
                    'complete' : '#9CCC52',
                    'active' : '#42145F',
                    'inactive' : 'white',
                }
            },
        };

        this.popup = {
            'header':
            {
                'backgroundColor' : '#42145F',
                'color' : 'white'
            },
            'crossbar':
            {
                'color' : 'white'
            }
        };
        this.installPopup = {
            'header':
            {
                'backgroundColor' : '#42145F',
                'color' : 'white'
            },
            'crossbar':
            {
                'color' : 'white'
            }
        };
        this.expansionList = {
            'backgroundColor' : '#FFF',
            'color': '#42145F',
            'header':
            {
                'backgroundColor' : '#F5F5F5',
                'color' : '#42145F'
            },
        };

        this.scrollbarButton = {
            'backgroundColor' : 'rgba(66,20,95,0.5)',
            'color': 'white'
        };
        this.tutorial = {
            hide: true,
            'logo': '',
            'background' : 'rgb(66, 20, 95)',
            'backgroundGradient' : 'linear-gradient(180deg, rgba(169, 84, 222, 1) 0%, rgba(66, 20, 95, 1) 100%)'
        };
        this.shareSplashScreen = {
            'background': require('@/assets/images/manufacturers/philips/brands/welcome_home/splash_screen_background.jpg'),
            'subtitle': 'This sharing is no longer or not yet valid...'
        };
    }
    getMenuLogo(){
        return require('@/assets/images/manufacturers/philips/brands/welcome_home/logo_menu.png');
    }
      
    getMainLogo(){
        return require('@/assets/images/manufacturers/philips/brands/welcome_home/main_logo.png');
    }
  
    getAlternativeLogo(){
        return require('@/assets/images/manufacturers/philips/brands/welcome_home/alternative_logo.png');
    }
}
