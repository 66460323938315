import ExternalResource from 'common/services/resources/external.js'
import Global from 'common/services/resources/global.js'
export default {
	props:['wizardTitle', 'grdpWarning', 'account', 'brand'],
	data:
		function () {
			return {
				CONSTANTS:
				{
					ASSOCIATE_TXT : this.$gettext('Would you like to associate your %{s} account with %{appName}?'),
					ADD_ERROR : this.$gettext('Unable to add the kit'),
					ADD_SUCCESS : this.$gettext('The kit has been successfully added'),
					LOGIN_ERROR_MAIL_OR_PASSWORD : this.$gettext('Incorrect email or password')
				},
				associateTxt:'',
				loading:false
			}
		},
	created: function () {
		this.associateTxt= this.$gettextInterpolate(this.CONSTANTS.ASSOCIATE_TXT, {s: this.brand.displayName,appName: this.$store.state.variantManager.class.title});
	},
	methods: {
		getBrandSrc: function (brand) {
			return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
		},
		approve: function(){
			var _this=this;
			this.loading = true;
			ExternalResource.add(this.account.name, this.account.pwd, this.brand.name).then(
				function(response) {
					var account = 
					{
						name: _this.account.name,
						authorization: response.data.authorization
					}
					_this.loading = false;
					_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.ADD_SUCCESS, 'success');
					_this.eventHub.$emit('addExternalAccount', account);
					_this.eventHub.$emit('nextWizardStep');
				}).catch(
				function(error) {
					if(error.response && error.response.status==401){
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.LOGIN_ERROR_MAIL_OR_PASSWORD);
					}
					else{
						_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.ADD_ERROR);
					}
					_this.loading = false;
				});
		},
		deny: function(){
			this.eventHub.$emit('addDeviceFinished');
		}
  }
}