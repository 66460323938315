import VueSlider from 'vue-slider-component'
import 'vendor/vue-slider-component/theme/default.css'
import UartService from 'common/services/uart.js'

export default {
    props: ['module', 'use', 'config'],
    components: {
      VueSlider
    },
    data () {
      return {
        switchs: [{value : 1}],
      }
    },
    created: function(){
    },
    
    methods: {

  		  setStatus : function(status){
            let capability = _find(this.module.service.status, {'capability':'thermostat'});
            capability.value = status.value;
            this.$parent.$emit('setStatusService', status, this.module.id, 'heating', this.module);
        },
  	}
}
