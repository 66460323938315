import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'

export default {
    changeCredentials: function (moduleId,data) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                axios.put(Global.getBackendUrl()+'vdp/surveillance/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+moduleId+'/credentials',
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    }
}
