import AlertIcon from 'common/components/alert-icon/alert-icon.vue'

export default {
    props:['validation','installStep'],
    components:{AlertIcon},
    data: function () {
      return {
        CONSTANTS:
        {
            'TEXT_GOOD' : this.$gettext('<strong>Installation completed</strong></br>Congratulations, the installation of your device is complete.'),
            'TEXT_BAD' : this.$gettext('<strong>Unable to add the device</strong></br>Please check your device and try again.'),
        },
        loading : false
      }
    },
    created: function () {
      this.eventHub.$emit('setDisplayTitle', true, 'Add a device');
      this.eventHub.$emit('setDisplayPrevious', false);
      this.eventHub.$emit('changeDisplayCloseIconInstallModal', (this.validation==false));
    },
      methods: { 
          finish: function(status,installType){
            switch(status){
              case 'add':
                  this.eventHub.$emit('setInstallStep', 1);
                  this.eventHub.$emit('setMainStep', 1);
                  this.eventHub.$emit('setInternalMainStep', 1);
                  this.$parent.installStep  = 1,
                  this.$parent.mainStepTable  = [],
                  this.$parent.deviceId = '',
                  this.eventHub.$emit('setModel', {});
                  this.eventHub.$emit('setSubmodel', '');
                  this.$parent.validation = '',
                  this.$parent.homepilot = undefined;
                  this.eventHub.$emit('refreshDatas');
                break;
              case 'close':
                this.eventHub.$emit('addDeviceFinished');
                break;
              case 'retry':
                this.eventHub.$emit('retryInternalInstall',installType);
                break;
            }
      }
      }
  }
  