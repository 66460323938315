import DeviceResource from 'common/services/resources/device.js'
import YCPlugResource from 'common/services/resources/ycplug.js'

export default {
	props:['img','sentences','devices','brands'],
	data:
		function () {
			return {
					CONSTANTS:
					{
						ASSOCIATE_ERROR : this.$gettext('Unable to associate the device'),
						ASSOCIATE_SUCCESS : this.$gettext('The device has been successfully associated'),
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
					},
					cases: [
						{name:'case1', value:'1' },
						{name:'case2', value:'0' },
						{name:'case3', value:'0' },
						{name:'case4', value:'0' },
						{name:'case5', value:'0' },
						{name:'caseA', value:'1' },
						{name:'caseB', value:'0' },
						{name:'caseC', value:'0' },
						{name:'caseD', value:'0' }
					],
					loading:false
			}
	},
	created: function () {
		this.eventHub.$emit('setDisplayPrevious', true);
    },
	methods: {
		associate: function(){
			this.eventHub.$emit('setDisplayPrevious', false);
			this.loading = true;
			var _this = this;
			var commandId = this.uuidV4();
			var bearerId = this.$parent.homepilot.id;
			var model = this.$parent.model;
			var submodel = this.$parent.submodel.name;
			var locationId = this.$parent.$parent.plant.zones[0].id;
			var brand =this.$parent.$parent.brand;
			if(this.$parent.model.name == 'ycb'){
				var code = this.plugCoding();
				YCPlugResource.associateTo(submodel, bearerId, code, commandId, locationId, brand, this.eventHub).then(
	 				function(response) {
						_this.eventHub.$emit('setDeviceId',response.data.id);
						_this.eventHub.$emit('nextInstallStep');
	 				}).catch(
					function(e) {
						if(e == 'Error: Network Error')
							_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
						else if(e.response && e.response.status == 401)
							_this.eventHub.$emit('logout');
						_this.eventHub.$emit('setValidation', false);
						_this.eventHub.$emit('setInternalMainStep', 6);
	 				});
			}
			else{
				DeviceResource.associateTo(model.name,model.systems[0],submodel,bearerId,commandId,locationId,brand,this.eventHub).then(
					function(response) {
						_this.loading = false;
						_this.eventHub.$emit('setDeviceId',response.data.id);
						_this.eventHub.$emit('nextInstallStep');
						_this.eventHub.$emit('addAssociateDevice', response.data);
						//setTimeout(_this.associateStatus(), 5000);
					}).catch(
					function(e) {
						if(e == 'Error: Network Error')
							_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
						else if(e.response && e.response.status == 401)
							_this.eventHub.$emit('logout');
						_this.loading = false;
						_this.eventHub.$emit('setValidation', false);
						_this.eventHub.$emit('setInternalMainStep', 6);
					});
			}
		},
		plugCoding: function(){
			var zero = '0000000';
			var result_bin = "";
			var result_hex = "";
			for (var i = 0; i < this.cases.length; i++)
			{
				result_bin += "" + this.cases[i].value;
			}
			result_bin += "" + zero;
			for (var i=0 ; i<16 ; i+=4)
			{
				result_hex += "" + parseInt(result_bin.substring(i, i+4), 2).toString(16);
			}
			return result_hex;
  		}

		/*associateStatus: function(id){
			var _this = this;
			var model = this.$parent.model;
			DeviceResource.associateStatus(model.name,model.systems[0],id).then(
				function(response) {
					if(response.data[model.name+'s'][0].associateStatus  == 'ASSOCIATED'){
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.ASSOCIATE_SUCCESS, 'success');
						_this.eventHub.$emit('nextStep');
					}
					else{
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.ASSOCIATE_ERROR);
						_this.errorDetected = true;
					}
				}).catch(
				function() {
				});
		},*/
 	}
}
