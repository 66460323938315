
import NumberService from 'common/services/number.js'

const TAG = 'light.js'

const STATUS_CAPABILITY_NAME = 'status'

const ON_VALUE = 'on'

const OFF_VALUE = 'off'

const BRIGHTNESS_CAPABILITY_NAME = 'brightness'

const COLOR_TEMPERATURE_CAPABILITY_NAME = 'color_temperature'

const RGB_CAPABILITY_NAME = 'rgb'

const OPER_TURN_ON = 
{
    oper: '41',
    nbParameters: 0,
    capability: STATUS_CAPABILITY_NAME
}

const OPER_TURN_OFF =
{
    oper: '42',
    nbParameters: 0,
    capability: STATUS_CAPABILITY_NAME
}
const OPER_SET_BRIGHTNESS = 
{
    oper: '43',
    nbParameters: 1,
    capability: BRIGHTNESS_CAPABILITY_NAME
}
const OPER_INCREASE_BRIGHTNESS =
{
    oper: '44',
    nbParameters: 0,
    capability: BRIGHTNESS_CAPABILITY_NAME
}
const OPER_DECREASE_BRIGHTNESS =
{
    oper: '45',
    nbParameters: 0,
    capability: BRIGHTNESS_CAPABILITY_NAME
}

const OPER_SET_COLOR_TEMPERATURE = 
{
    oper: '46',
    nbParameters: 1,
    capability: COLOR_TEMPERATURE_CAPABILITY_NAME
}
const OPER_INCREASE_COLOR_TEMPERATURE =
{
    oper: '47',
    nbParameters: 0,
    capability: COLOR_TEMPERATURE_CAPABILITY_NAME
}
const OPER_DECREASE_COLOR_TEMPERATURE =
{
    oper: '48',
    nbParameters: 0,
    capability: COLOR_TEMPERATURE_CAPABILITY_NAME
}
const OPER_SET_WYRGB =
{
    oper: '4F',
    nbParameters: 5,
    capability: RGB_CAPABILITY_NAME
}

const OPER_SET_RGB =
{
    oper: '4A',
    nbParameters: 3,
    capability: RGB_CAPABILITY_NAME
}

const OPER_RGB_MOVEMENT =
{
    oper: '61',
    nbParameters: 6,
    capability: RGB_CAPABILITY_NAME
}

export default {

    OPERS : 
    [
        OPER_TURN_ON,
        OPER_TURN_OFF,
        OPER_SET_BRIGHTNESS,
        OPER_INCREASE_BRIGHTNESS,
        OPER_DECREASE_BRIGHTNESS,
        OPER_SET_COLOR_TEMPERATURE,
        OPER_INCREASE_COLOR_TEMPERATURE,
        OPER_DECREASE_COLOR_TEMPERATURE,
        OPER_SET_WYRGB,
        OPER_SET_RGB,
        OPER_RGB_MOVEMENT

    ],

    /**
     * ON/OFF : 1 byte
     * Light model : 1 byte
     * Brightness : 1 byte
     * Color temperature : 3 byte
     * RGB : 1 byte
     */
    convertCallbackFrame: function (module, dataHex, allCapability) {
        var status = [];
        var oper = dataHex.substr(0,2);

        var onOff = dataHex.substr(2,2);

        if(!onOff || (['00','01'].indexOf(onOff)==-1))
        {
            if(oper==OPER_TURN_OFF.oper)
            {
                onOff = '00'; 
            }
            else if(oper==OPER_TURN_ON.oper)
            {
                onOff = '01'; 
            }
        }

        if(['00','01'].indexOf(onOff)!=-1)
        {
            status.push({
                capability: STATUS_CAPABILITY_NAME,
                value: (onOff=='00'?OFF_VALUE:ON_VALUE)
            });
        }

        var brightness = dataHex.substr(6,2);

        if(!brightness)
        {
            return status;
        }

        status.push({
            capability: BRIGHTNESS_CAPABILITY_NAME,
            value: parseInt(brightness,16).toString()
        });


        var colorTemperature = dataHex.substr(8,2);

        var c = _find(module.device.capabilities, {'capability': COLOR_TEMPERATURE_CAPABILITY_NAME});

        if(!c || !colorTemperature)
        {
            return status;
        }
        var kelvinTemperature =  NumberService.convertHexToKelvin(colorTemperature,c.min,c.max);
        status.push({
            capability: COLOR_TEMPERATURE_CAPABILITY_NAME,
            value: kelvinTemperature.toString()
        });

        var rbg = dataHex.substr(10,6);

        if(!rbg)
        {
            return status;
        }

        status.push({
            capability: RGB_CAPABILITY_NAME,
            value: '0x'+rbg
        });


        if(allCapability)
        {
            return status;
        }
        else
        {
            if(!oper)
            {
                return status;
            }
    
            var operObj = _find(this.OPERS, {'oper': oper});

            if(!operObj)
            {
                return status;
            }
            var callbackCapability = _find(status, {'capability': operObj.capability});
            if(callbackCapability)
            {
                status = [callbackCapability];
                if(callbackCapability.capability!='status')
                { 
                    var statusCapability = _find(JSON.parse(JSON.stringify(module.device.status)), {'capability': 'status'});
                    if(statusCapability && statusCapability.value!='on')
                    {
                        status.push({'capability': 'status', 'value' : 'on'});
                    }
                }
            }
            return status;
    
        }

    },

    turnOff: function (module) {
        var s = _find(module.device.status, {'capability': STATUS_CAPABILITY_NAME});
        if(!s)
        {
            return false;
        }
        var status = [
            {'capability':STATUS_CAPABILITY_NAME,'value':OFF_VALUE}
        ];

        var cmd = 'setStatus';

        var dataHex = OPER_TURN_OFF.oper;
        
        return {
            status : status,
            dataHex : dataHex,
            cmd : cmd
        }
        
    },

    turnOn: function (module) {
        var s = _find(module.device.status, {'capability': STATUS_CAPABILITY_NAME});
        if(!s)
        {
            return false;
        }
        var status = [
            {'capability':STATUS_CAPABILITY_NAME,'value':ON_VALUE}
        ];

        var cmd = 'setStatus';

        var dataHex = OPER_TURN_ON.oper;
        
        return {
            status : status,
            dataHex : dataHex,
            cmd : cmd
        }
    },


    setBrightness: function (module) {
        var s = _find(module.device.status, {'capability': BRIGHTNESS_CAPABILITY_NAME});
        if(!s)
        {
            return false;
        }
        var status = [
            {'capability':BRIGHTNESS_CAPABILITY_NAME,'value':s.value}
        ];
        
        var cmd = 'setStatus';

        var d = parseInt(s.value).toString(16).padStart(2, '0');

        if((d.length/2)!=OPER_SET_BRIGHTNESS.nbParameters)
        {
            return false;
        }

        var dataHex = OPER_SET_BRIGHTNESS.oper+d;
        
        return {
            status : status,
            dataHex : dataHex,
            cmd : cmd
        }
    },

    increaseBrightness: function (module) {
        var status = [
            {'capability':BRIGHTNESS_CAPABILITY_NAME}
        ];
        var s = _find(module.device.status, {'capability': BRIGHTNESS_CAPABILITY_NAME});
    },

    decreaseBrightness: function (module) {
        var status = [
            {'capability':BRIGHTNESS_CAPABILITY_NAME}
        ];
        var s = _find(module.device.status, {'capability': BRIGHTNESS_CAPABILITY_NAME});
    },

    setColorTemperature: function (module) {
        var s = _find(module.device.status, {'capability': COLOR_TEMPERATURE_CAPABILITY_NAME});
        if(!s)
        {
            return false;
        }

        var c = _find(module.device.capabilities, {'capability': COLOR_TEMPERATURE_CAPABILITY_NAME});

        if(!c)
        {
            return false;
        }

        var status = [
            {'capability':COLOR_TEMPERATURE_CAPABILITY_NAME,'value':s.value}
        ];
        var cmd = 'setStatus';

        var d = NumberService.convertKelvinToHex(s.value,c.min,c.max);

        if((d.length/2)!=OPER_SET_COLOR_TEMPERATURE.nbParameters)
        {
            return false;
        }

        var dataHex = OPER_SET_COLOR_TEMPERATURE.oper+d;
        
        return {
            status : status,
            dataHex : dataHex,
            cmd : cmd
        }

    },

    increaseColorTemperature: function (module) {
        var status = [
            {'capability':COLOR_TEMPERATURE_CAPABILITY_NAME}
        ];
        var s = _find(module.device.status, {'capability': COLOR_TEMPERATURE_CAPABILITY_NAME});
    },

    decreaseColorTemperature: function (module) {
        var status = [
            {'capability':COLOR_TEMPERATURE_CAPABILITY_NAME}
        ];
        var s = _find(module.device.status, {'capability': COLOR_TEMPERATURE_CAPABILITY_NAME});
    },

    setRGB: function (module) {
        var s = _find(module.device.status, {'capability': RGB_CAPABILITY_NAME});
        if(!s)
        {
            return false;
        }

        var status = [
            {'capability':RGB_CAPABILITY_NAME,'value':s.value}
        ];

        var cmd = 'setStatus';

        var d = s.value.substr(2);

        if((d.length/2)!=OPER_SET_RGB.nbParameters)
        {
            return false;
        }
        
        var dataHex = OPER_SET_RGB.oper+d;

        return {
            status : status,
            dataHex : dataHex,
            cmd : cmd
        }

    },

    setWYRGB: function (module) {
        var s = _find(module.device.status, {'capability': RGB_CAPABILITY_NAME});
        if(!s)
        {
            return false;
        }

        var status = [
            {'capability':RGB_CAPABILITY_NAME,'value':s.value}
        ];

        var cmd = 'setStatus';

        var d = '0000'+s.value.substr(2);

        if((d.length/2)!=OPER_SET_WYRGB.nbParameters)
        {
            return false;
        }
        
        var dataHex = OPER_SET_WYRGB.oper+d;

        return {
            status : status,
            dataHex : dataHex,
            cmd : cmd
        }

    },
}
