import Global from 'common/services/resources/global.js'
import LocationResource from 'common/services/resources/location.js'
import GlobalSpinner from 'common/components/global-spinner/global-spinner.vue'

export default {
    props:['plant','defaultRooms', 'device', 'noCloseDialog'],
    components: {
      GlobalSpinner
    },
    data: function () {
        return {
          displayRoomAddModal: false,
          CONSTANTS:
          {
            NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
          },
          roomSelected:undefined
        }
    },
    created: function () {
    },
	  methods: {
      img: function (zone) {
        var style = {};
        if(zone.image && zone.image.thumb && zone.image.thumb.path)
        {
          style.backgroundImage = "url("+require('@/assets/'+this.$store.state.variantManager.class.defaultRoomsPath+'/'+zone.key+'_thumb.jpg')+")";
        }
        else
        {
          style.backgroundImage = "url("+require('@/assets/images/icon-default.png')+")";
        }
        style.backgroundSize = 'cover';
          return style;
      },
      validate : function(zone){
        var _this = this;
        var id = _this.plant.id;
        var level = 'ZONE';
        var roomName = zone.defaultName;
        this.roomSelected = zone;
        LocationResource.addLocationTopology(roomName, level, id, zone.key ).then(
          function(response) {
            _this.eventHub.$emit('setLoading',  false);
            var roomId = response.data.id;
            var image = {
              full : {
                  key : zone.key,
                  path : zone.image.full.path
                },
              thumb : {
                key : zone.key,
                path : zone.image.thumb.path
              }
            };
            _this.addNewRoom(zone,roomName,roomId,image);
          }).catch(
            function(e) {
              _this.eventHub.$emit('setLoading',  false);
              _this.roomSelected = undefined;
              if(e == 'Error: Network Error')
                _this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
              else if(e.response && e.response.status == 401)
                _this.eventHub.$emit('logout');
              else if(e.response && [402].indexOf(e.response.status)!=-1)
								_this.eventHub.$emit('displayAlertKey', 'unauthorized');
              else if(e=="Error: Request failed with status code 422"){
                _this.eventHub.$emit('displayAlert',  _this.$parent.$parent.CONSTANTS.ROOM_ERROR_LEVEL);
              }
              else{
                _this.eventHub.$emit('displayAlert',  _this.$parent.$parent.CONSTANTS.ROOM_ERROR);
              }
            });
      },

  		addNewRoom : function(zone,roomName,roomId,image){
        if(this.device != undefined)
        {
            var _this = this;
            LocationResource.edit('module',this.device.id, {containerId:roomId,name:this.device.name}).then(
              function(obj) {
                _this.roomSelected = undefined;
                _this.eventHub.$emit('setValidation', true);
                _this.eventHub.$emit('nextInternalMainStep');
                _this.eventHub.$emit('closeDialog','displayRoomAddModal');
                }).catch(
              function() {
            });
        }
        else
        {
          var z = {
            id:roomId,
            modules:[],
            name: roomName,
            type: null,
            image: image
          };
          this.finish(z);
        }
      },

      finish : function(zone)
      {
        this.roomSelected = undefined;
        this.plant.zones.push(zone);
        this.eventHub.$emit('initRoomBlocks');
        this.eventHub.$emit('displayAlert',  this.$parent.$parent.CONSTANTS.ROOM_SUCCESS, 'success');
        if(!this.noCloseDialog)
          this.eventHub.$emit('closeDialog');
        this.eventHub.$emit('addRoomFinished',zone);
      }
    }
}
