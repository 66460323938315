import LinkwilPluginService from 'common/services/cordova/linkwilPlugin.js'
import CommonResource from 'common/services/resources/common.js'

export default {
    props: ['module'],
    data: function () {
            return {
              CONSTANTS: {
              }
           }
    },
    created: function () {
        this.eventHub.$on('closeCallModal', this.closeCallModal);
        this.eventHub.$on('decline_'+this.module.id, this.decline);
        this.eventHub.$on('accept_'+this.module.id, this.accept);
        this.eventHub.$on('gate_'+this.module.id, this.gate);
    },
    destroyed: function(){
        this.eventHub.$off('closeCallModal', this.closeCallModal);
        this.eventHub.$off('decline_'+this.module.id, this.decline);
        this.eventHub.$off('accept_'+this.module.id, this.accept);
        this.eventHub.$off('gate_'+this.module.id, this.gate);
    },
	methods: {
        decline: function(){
            var status = {
                "capability": "call",
                "value": "hang_up"
            }
            CommonResource.setStatus(this.module, status, undefined, this.eventHub,true);
            this.eventHub.$emit('closeModalMainControlDevice');
        },
        accept: function(){
            if(this.module.device.submodel=='vdp_100')
            {
                LinkwilPluginService.stopLinkwil();
                LinkwilPluginService.startLinkwillPlugin(this.module,this.eventHub, 'enable');
            }
            else
            {
                this.eventHub.$emit('showCamera',this.module, true, true, true);
            }
            var status = {
                "capability": "call",
                "value": "answer"
            }
            CommonResource.setStatus(this.module, status, undefined, this.eventHub,true);
            this.eventHub.$emit('closeModalMainControlDevice');
        },
        /*gate: function(){
            console.log('CALL - gate');
            var status = {
                "capability": "trigger"
            }
            CommonResource.setStatus(this.module, status, undefined, this.eventHub);
            this.eventHub.$emit('closeModalMainControlDevice');
        },*/
        closeCallModal: function(){
            this.eventHub.$emit('closeModalMainControlDevice');
        }
	}
}
