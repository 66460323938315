
import StringService from 'common/services/string.js'

const TAG = 'LinkwilPlugin';
const LINKWIL_UPGRADE_SUCCESS = 'The update was successful on the device \'%{d}\'';
const LINKWIL_UPGRADE_FAILED = 'The update failed on the device \'%{d}\'';


var upgradeLinkWilFailed = (_this) => 
{
    _this.eventHub.$emit('displayAlert', _this.$gettextInterpolate(_this.$gettext(LINKWIL_UPGRADE_FAILED), {d: _this.module.name}));
    _this.module.updateInProgess = false;
    _this.eventHub.$emit('LinkwilPlugin_upgrade_'+_this.module.id, {event:'onUpgradeFail'}, _this.module.id);
}

export default {

  /* LINKWIL PLUGINS */
  startLinkwillPlugin : function(module,eventHub, microphoneStatus)
  {
      var _this = this;
      console.log('startLinkwillPlugin');
      console.log(module);
      console.log(module.device.deviceUid);
      console.log(module.device.devicePassword);
      try {
          this.stopLinkwil();
          cordova.exec(
              function(winParam) {  
                  console.log("startLinkwill success"); 
                  console.log(winParam);     
                  try{
                      var json = StringService.toJson(winParam);
                      console.log('LinkwilPlugin_'+json.event+'_'+json.deviceUid);
                      /*if(json.event=='onQuit')
                      {
                          CommonResource.setStatus(module, {"capability": "call","value": "hang_up"}, undefined, eventHub);
                      }*/
                  }
                  catch(e)
                  {
                      console.log('startLinkwill error');
                      console.log(e)
                  }     
              },
              function(error) {
                  console.log(error);
                  console.log("startLinkwill error");
              },
              TAG,
              "startFullscreen",
              [
                  module.device.deviceUid,
                  module.device.devicePassword,
                  (microphoneStatus?microphoneStatus:'disable')
              ]
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - startLinkwill - error');
      }      
  },

  getLinkwilRecords : function(_this,date,index)
  {
      console.log('getLinkwilRecords');
      var dateSplit = date.split('-');
      console.log(dateSplit);
      try {
          //this.stopLinkwil();
          cordova.exec(
              function(winParam) {   
                  console.log("getLinkwilRecords success"); 
                  console.log(winParam); 
                  var json = StringService.toJson(winParam);  
                  if(json.event == 'onConnectFail')
                  {
                      _this.eventHub.$emit('get_linkwil_records_error'+_this.module.id,json.recordList);
                  }
                  else if(json.event == 'onDailyRecordList'){
                      _this.eventHub.$emit('get_linkwil_records'+_this.module.id,json.recordList,date,index);
                  }          
              },
              function(error) {
                  console.log(error);
                  _this.eventHub.$emit('get_linkwil_records_error'+_this.module.id,json.recordList);
                  console.log("getLinkwilRecords error");
              },
              TAG,
              "getRecords",
              [
                  _this.module.device.deviceUid,
                  _this.module.device.devicePassword,
                  dateSplit[0],
                  dateSplit[1],
                  dateSplit[2]
              ]
          );
      }
      catch (e) {
          console.log(e);
          _this.eventHub.$emit('get_linkwil_records_error'+_this.module.id,json.recordList);
          console.log('cordova.js - getLinkwilRecords - error');
      }      
  },

  playLinkwilRecords : function(module, recordName)
  {
      try {
          this.stopLinkwil();
          cordova.exec(
              function(winParam) { 
                  console.log("playLinkwilRecords success");   
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  console.log("playLinkwilRecords error");
              },
              TAG,
              "playRecord",
              [
                  module.device.deviceUid,
                  module.device.devicePassword,
                  recordName
              ]
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - playLinkwilRecords - error');
      }      
  },

  stopLinkwil : function()
  {
      try {
          cordova.exec(
              function(winParam) {   
                  console.log("stopLinkwil success");
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  console.log("stopLinkwil error");
              },
              TAG,
              "stop",
              []
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - stopLinkwil - error');
      }      
  },

  startAudioSetup : function(wifiInstallObject, r)
  {
      console.log('startAudioSetup');
      console.log(wifiInstallObject);
      try {
          cordova.exec(
              function(winParam) {   
                  console.log("startAudioSetup success");
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  console.log("startAudioSetup error");
              },
              TAG,
              "startAudioSetup",
              [
                  wifiInstallObject.wifi.ssid,
                  wifiInstallObject.wifi.password,
                  wifiInstallObject.associationToken+r.region
              ]
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - startAudioSetup - error');
      }      
  },

  stopAudioSetup : function()
  {
      try {
          cordova.exec(
              function(winParam) {   
                  console.log("stopAudioSetup success");
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  console.log("stopAudioSetup error");
              },
              TAG,
              "stopAudioSetup",
              []
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - stopAudioSetup - error');
      }      
  },

  openGate : function(module)
  {
      try {
          cordova.exec(
              function(winParam) {   
                  console.log("openGate success");
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  console.log("openGate error");
              },
              TAG,
              "openGate",
              [   
                  module.device.deviceUid,
                  module.device.devicePassword
              ]
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - openGate - error');
      }      
  },

  setDetection : function(module, detectionStatus)
  {
      try {
          cordova.exec(
              function(winParam) {   
                  console.log("setDetection success");
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  console.log("setDetection error");
              },
              TAG,
              "setDetection",
              [   
                  module.device.deviceUid,
                  module.device.devicePassword,
                  (detectionStatus?detectionStatus:'disable')
              ]
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - setDetection - error');
      }      
  },

  getDetection : function(_this)
  {
      try {
          cordova.exec(
              function(winParam) {   
                  try{
                      var json = StringService.toJson(winParam);  
                      console.log('LinkwilPlugin_getDetection_'+_this.module.id);
                      _this.eventHub.$emit('LinkwilPlugin_getDetection_'+_this.module.id, json);  
                  }
                  catch(e)
                  {
                      console.log('getDetection error');
                      console.log(e);
                  }    

                  console.log("getDetection success");
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  console.log("getDetection error");
              },
              TAG,
              "getDetection",
              [   
                  _this.module.device.deviceUid,
                  _this.module.device.devicePassword
              ]
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - getDetection - error');
      }      
  },
  /**
   * Get device infos as found in the EC_CMD_ID_GET_DEV_INFO SDK command

      Name: LinkwilPlugin
      Function: getDeviceInfos
      Parameters :
      String deviceUid (may be found on the camera itself - QRCode printed on it)
      String devicePassword (user setting for this camera)
   * @param {*} _this 
   */

  getDeviceInfos : function(_this)
  {
      console.log('getDeviceInfos');
      try {
          cordova.exec(
              function(winParam) {   
                  try{
                      var json = StringService.toJson(winParam);  
                      console.log('LinkwilPlugin_getDeviceInfos_'+_this.module.id);
                      _this.eventHub.$emit('LinkwilPlugin_getDeviceInfos_'+_this.module.id, json);  
                  }
                  catch(e)
                  {
                      console.log('getDeviceInfos error');
                      console.log(e);
                  }    
                  console.log("getDeviceInfos success");
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  console.log("getDeviceInfos error");
              },
              TAG,
              "getDeviceInfos",
              [   
                  _this.module.device.deviceUid,
                  _this.module.device.devicePassword
              ]
          );
      }
      catch (e) {
          console.log(e);
          console.log('cordova.js - getDeviceInfos - error');
      }      
  },

 /**
   * Upgrade the device with given url
      Name: LinkwilPlugin
      Function: upgrade
      Parameters:
      String deviceUid (may be found on the camera itself - QRCode printed on it)
      String devicePassword (user setting for this camera)
      String url (url endpoint to find upgrade)
   * @param {*} _this 
   * @param {*} url 
   */

  upgradeLinkWil : function(_this,url)
  {
    console.log('upgradeLinkWil');
    console.log('upgradeLinkWil - url:'+url);
      try {

          cordova.exec(
              function(winParam) {   
                  try{
                      var json = StringService.toJson(winParam);  
                      console.log('LinkwilPlugin_upgrade_'+_this.module.id);
                      console.log(json);
                      var success = false;
                      if((json.event=='onUpgradeSuccess') || (json.event=='onUpgradeFail'))
                      {
                        if(json.event=='onUpgradeSuccess')
                        {
                            _this.eventHub.$emit('displayAlert', _this.$gettextInterpolate(_this.$gettext(LINKWIL_UPGRADE_SUCCESS), {d: _this.module.name}), 'success');
                            success = true;
                        }
                        else if(json.event=='onUpgradeFail')
                        {
                            _this.eventHub.$emit('displayAlert', _this.$gettextInterpolate(_this.$gettext(LINKWIL_UPGRADE_FAILED), {d: _this.module.name}));
                        }
                        _this.eventHub.$emit('LinkwilPlugin_upgrade_'+_this.module.id, success, json);
                      }
                  }
                  catch(e)
                  {
                      console.log(e);
                  }    
                  console.log("upgrade success");
                  console.log(winParam);                
              },
              function(error) {
                  console.log(error);
                  upgradeLinkWilFailed(_this);
                  console.log("upgrade error");
              },
              TAG,
              "upgrade",
              [   
                  _this.module.device.deviceUid,
                  _this.module.device.devicePassword,
                  url
              ]
          );
      }
      catch (e) {
          console.log(e);
          upgradeLinkWilFailed(_this);
          console.log('cordova.js - upgrade - error');
      }      
  },

}