import ElementsList from 'common/components/elements-list/elements-list.vue'
import Email from 'common/components/email/email.vue'
import Push from 'common/components/push/push.vue'
import Sms from 'common/components/sms/sms.vue'
import PhoneNumberService from 'common/services/phone-number.js'
import WindowService from 'common/services/window.js'
import Language from 'common/services/language.js'
import Global from 'common/services/resources/global.js'
export default {
    components:{ElementsList,Email,Push,Sms},
    props: {
        notification: {
            type: Object,
			default:{}
        },
        user: {
			type: Object,
			default:{}
		}
    },
    data:
        function () {
            return {
            CONSTANTS : {
                NONE : this.$gettext('None'),
                VISUAL : this.$gettext('Visual message'),
                ALERT : this.$gettext('Alert notification'),
                EMAIL : this.$gettext('Email'),
                SMS : this.$gettext('SMS'),
                NOTIFICATION : this.$gettext('Display a notification'),
                TITLE : this.$gettext('Send alert notification by :'),
                UNLIMITED_CREDIT: this.$gettext('Credit : <b>unlimited</b>'),
				REMAINING_CREDIT: this.$gettext('Credit : <b>%{s}</b>'),
                ADDITIONNAL_ALERTS : [
                    {
                        icon: 'push',
                        name:'notification',
                        id:'notification'
                    },
                    {
                        icon: 'mail',
                        name:'Email'
                    },
                    {
                        icon: 'sms',
                        name:'SMS'
                    }
                ]
            },
            activeAlert : false,
            addElementsDialog : false,
            addEmailDialog : false,
            addPushDialog : false,
            addSmsDialog : false,
            addElements: []
            }
        },
    created: function (){
        this.displayAlertNotification()
        if(_findIndex(this.notification.alert, function(o) { return o.type == 'push'; }) != -1){
            this.addElements.push(this.CONSTANTS.ADDITIONNAL_ALERTS[0]);
        }
	},
      methods: {
        displayAlertNotification: function(){
            if(this.notification.notification_level == "ALERT"){
                this.activeAlert = true;
            }
            else{
                this.activeAlert = false;
            }
        },
        addElement: function(){
            this.addElementsDialog = true
        },
        chooseNotification: function(element){
            this.addElementsDialog = false
            if(element.name=='Email')
			{
				this.addEmailDialog = true;
			}
			else if(element.name=='SMS')
			{
				this.addSmsDialog = true;
            }
            else{
                if(!element.add){
                    this.addPushDialog = true;
                }
            }
        },
        closePush: function(){
            this.addPushDialog = false;
            this.addElements.push(this.CONSTANTS.ADDITIONNAL_ALERTS[0]);
        },
        formatPhoneNumber: function(m){
			return PhoneNumberService.formatStringToPhoneNumber(m);
        },
        deleteELt: function(n){
            var _this = this;
            var index = 0;
            if(n.type == 'email' || n.type == 'sms'){
                index = _findIndex(_this.notification.alert, function(o) { if(o.infos) return o.infos.to == n.infos.to; });
            }
            else{
                index = _findIndex(_this.notification.alert, function(o) { return o.type == 'push'; });
                this.addElements.splice(0,1);
            }
            this.notification.alert.splice(index, 1);
        },
        goToMarket: function(){
            var lang = (Language.getUserLanguage()=='fr'?'fr':'en');
			WindowService.open(Global.WEBSITE_STORE+'/services-'+lang+'/sms-'+lang,'_blank',this.inApp);
		}
     }
  }
  
  