export default {
    props: ['module'],
    data: function () {
            return {
           }
    },
	  methods: {
		  setStatus : function(status){
        this.$parent.$emit('set-status', status);
		}
	}
}
