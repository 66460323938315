import ExternalResource from 'common/services/resources/external.js'
import Global from 'common/services/resources/global.js'
export default {
	props:['wizardTitle','brand'],
	data: function () {
	  return {
		CONSTANTS:
		{
			'ASSOCIATE_ERROR' : this.$gettext('Unable to associate the %{s} account'),
		},
		loading : false,
		errorTxt:''
	  }
	},
	created: function () {
			this.eventHub.$emit('setDisplayPrevious', false);
	  	this.errorTxt=this.$gettextInterpolate(this.CONSTANTS.ASSOCIATE_ERROR, {s: (this.brand.displayName?this.brand.displayName:'')});
	},
	methods: {
		getBrandSrc: function (brand) {
			return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
		},
		finish: function(status){
			switch(status){
				case 'add':
					this.eventHub.$emit('setMainStep', 1);
					break;
				case 'close':
					this.eventHub.$emit('addDeviceFinished');
					break;
				case 'retry':
					if(this.brand.displayName)
					{
						if(this.brand.wizard.type=='EXTERNAL')
						{
							ExternalResource.getExternalAuthorize(this.brand.name);
						}
					}
					else
					{
						this.eventHub.$emit('setMainStep', 1);
					}
					break;
			}
		}
	  }
  }