export default {
	props: 
	{
		deviceInstallObject: {
			type: Object
		},
		txt: {
			type: String
		},
		installObject: {
			type: Object
		},
		installObject: {
			type: Object
		}
	},
	data:
		function () {
			return {
				CONSTANTS:
				{
				},
				uid:
				{
					rules: {
						required: value => value!="" || this.$gettext('Field is required')/*,
						equal: v => v.length >= 19 || this.$gettextInterpolate(this.$gettext('Field must be %{s} characters'),{s: 19})*/
					},
				},
				form:
				{
					validation:false
				},
			}
	},
	mounted: function () {
		this.$refs['form'].validate();
	},
	watch: {
		/*"deviceInstallObject.device.identifier": function (val, oldVal){
			this.wifiInstallObject.device.identifier = val.toUpperCase();
		}*/
	},
	beforeDestroy () {
	},	

	created: function () {
  	},
	methods: {
 	}
}