import HPWifi1 from './hp-wifi1.vue';
import HPWifi2 from './hp-wifi2.vue';
import HPWifi3 from './hp-wifi3.vue';
import HPWifi4 from './hp-wifi4.vue';
import HPWifi5 from './hp-wifi5.vue';

export default {
	props: ['device', 'model', 'submodel', 'step'],
	components: {HPWifi1,HPWifi2,HPWifi3,HPWifi4,HPWifi5},
    data: function () {
        return {
            result: false,
						associationToken: '',
						wifi : {
							ssid: '',
							auths: '',
							freq: '',
							smntCapable: '',
							smntIncapableReason: ''
						}
        }
    },
    created: function () {
      this.step = 1;
		  this.eventHub.$on('nextStepWifi', this.nextStepWifi);
		  this.eventHub.$on('backStepWifi', this.backStepWifi);
			this.eventHub.$on('setWifi', this.setWifi);
			this.eventHub.$emit('setStepper', [
				{'number' : 1, 'title' : 'Connection'},
				{'number' : 2, 'title' : 'Reset'},
				{'number' : 3, 'title' : 'WIFI'},
				{'number' : 4, 'title' : 'Association'},
				{'number' : 5, 'title' : 'Result'},
				{'number' : 6, 'title' : 'Notifications'},
				{'number' : 7, 'title' : 'Name'},
				{'number' : 8, 'title' : 'Room'}
			], 1);
    },
		destroyed: function () {
		  this.eventHub.$off('nextStepWifi', this.nextStepWifi);
		  this.eventHub.$off('backStepWifi', this.backStepWifi);
			this.eventHub.$off('setWifi', this.setWifi);
    },
	methods: {
		nextStepWifi: function(){
			this.eventHub.$emit('nextInstallStep');
		},
		backStepWifi: function(){
			this.eventHub.$emit('backInstallStep');
		},
		setWifi: function(wifi){
			this.wifi=wifi;
		}
	}
}
