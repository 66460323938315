export default {

	props: 
	{
		deviceObj: {
			type: Object
		},
		txt: {
			type: String
		},
	},
	data:
		function () {
			return {
				CONSTANTS:
				{
				},
				uid:
				{
					rules: {
						required: value => value!="" || this.$gettext('Field is required')
					},
				},
				form:
				{
					validation:false
				},
			}
	},
	mounted: function () {
		this.$refs['form'].validate();
	},
	beforeDestroy () {
	},	

	created: function () {
  	},
	methods: {
 	}
}