import StorageService from 'common/services/storage.js'
import WindowService from 'common/services/window.js'
import Global from 'common/services/resources/global.js'

export default {
	props:['brand'],
	data:
		function () {
			return {
				    CONSTANTS:
					{
						AVAILABLE_ASSOCIATION: this.$gettext('The association of a %{s} account is available for the moment only from a web browser.') ,
						AVAILABLE: this.$gettext('This kit will be available soon'),
						WEB_BROWSER_ASSOCIATION : this.$gettext('Click on the button to open %{appName} on a web browser and do the association.')
					},
					txt: '',
					webBrowserAssociation: ''
			}
		},
	created: function (){
		this.txt = (this.brand && this.brand.onlyAppInstall ? this.$gettextInterpolate(this.CONSTANTS.AVAILABLE_ASSOCIATION, {s: this.brand.displayName}):this.CONSTANTS.AVAILABLE);
		this.webBrowserAssociation = this.$gettextInterpolate(this.CONSTANTS.WEB_BROWSER_ASSOCIATION, {appName: this.$store.state.variantManager.class.title});
	},
	methods: {
		getBrandSrc: function (brand) {
			return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
		},
		finish: function(){
			this.eventHub.$emit('addDeviceFinished');
			if(this.brand && this.brand.onlyAppInstall){
				let url = '';
				const r = JSON.parse(StorageService.getValue(localStorage, 'region-ws'));
				if(this.$store.getters.getAppFrontendEndpoint)
				{
					url = this.$store.getters.getAppFrontendEndpoint;
				}
				else
				{
					url = r.url;
				}
				WindowService.open(url+'/#/login?username='+this.eventHub.user.username+'&region='+r.region,'_blank',this.inApp);
			}
		}
  }
}
