export default {
    timeoutStopListening: undefined,
    successStopListening: false,
    isRecognitionAvailable: function() {
        return new Promise(
            function(resolve, reject){
                cordova.exec(
                    function(bool) {
                        console.log("wincallback @isRecognitionAvailable : "+bool);
                        return resolve(bool);
                    },
                    function(error) {
                        console.log("error @isRecognitionAvailable :" + error);
                        return reject(error);

                    },
                    'SpeechRecognition',
                    'isRecognitionAvailable',
                    []);
            }
        );
    },
    /*
    options :
        language {String} used language for recognition (default "en-US")
        matches {Number} number of return matches (default 5, on iOS: maximum number of matches)
        prompt {String} displayed prompt of listener popup window (default "", Android only)
        showPopup {Boolean} display listener popup window with prompt (default true, Android only)
        showPartial {Boolean} Allow partial results to be returned (default false)
    */
    startListening: function(options, callback,callbackError) {
        options = options || {};
        this.successStopListening = false;
        clearTimeout(this.timeoutStopListening);
        console.log('startListening');
        console.log(options);
        var _this = this;
        try
        {
            cordova.exec(
                function(matches) {
                    console.log("wincallback @startListening");
                    console.log(matches);
                    clearTimeout(_this.timeoutStopListening);
                    _this.timeoutStopListening = setTimeout(function() {
                        if(_this.successStopListening){
                        return;
                        }
                        _this.successStopListening = callback(matches);
                    }, 1000);
                    //callback(matches);
                },
                function(error) {
                    console.log("error @startListening :" + error);
                    callbackError(error);
                    //callback(error);

                },
                'SpeechRecognition',
                'startListening',
                [ options.language, options.matches, options.prompt, options.showPartial, options.showPopup ]);
        }
        catch (e) {
            console.log(e);
            console.log('cordova-speech-recognition.js - startListening - error');
        }
    },

    stopListening: function() {
        return new Promise(
            function(resolve, reject){
                try
                {
                    cordova.exec(
                        function() {
                            console.log("wincallback @stopListening");
                            return resolve(true);
                        },
                        function(error) {
                            console.log("error @stopListening :" + error);
                            return reject(error);

                        },
                        'SpeechRecognition',
                        'stopListening',
                        []);
                }
                catch (e) {
                    console.log(e);
                    console.log('cordova-speech-recognition.js - stopListening - error');
                }
            }
        );
    },

    getSupportedLanguages: function() {

        return new Promise(
            function(resolve, reject){
                try
                {   
                    cordova.exec(
                        function() {
                            console.log("wincallback @getSupportedLanguages");
                            console.log(languages);
                            return resolve(languages);
                        },
                        function(error) {
                            console.log("error @getSupportedLanguages :" + error);
                            return reject(error);

                        },
                        'SpeechRecognition',
                        'getSupportedLanguages',
                        []);
                }
                catch (e) {
                    console.log(e);
                    console.log('cordova-speech-recognition.js - getSupportedLanguages - error');
                }       
            }
        );
    },

    hasPermission: function() {
        return new Promise(
            function(resolve, reject){
                try
                {   
                    cordova.exec(
                        function(bool) {
                            console.log("wincallback @hasPermission");
                            console.log(bool);
                            return resolve(bool);
                        },
                        function(error) {
                            console.log("error @hasPermission :" + error);
                            return reject(error);

                        },
                        'SpeechRecognition',
                        'hasPermission',
                        []);
                }
                catch (e) {
                    console.log(e);
                    console.log('cordova-speech-recognition.js - hasPermission - error');
                } 
            }
        );
    },

    requestPermission: function(options) {
        return new Promise(
            function(resolve, reject){
                try
                { 
                    cordova.exec(
                        function(result) {
                            console.log(result);
                            console.log("wincallback @requestPermission");
                            return resolve(result);
                        },
                        function(error) {
                            console.log("error @requestPermission :" + error);
                            return reject(error);

                        },
                        'SpeechRecognition',
                        'requestPermission',
                        []);
                }
                catch (e) {
                    console.log(e);
                    console.log('cordova-speech-recognition.js - requestPermission - error');
                } 
            }
        );
    },
    installGoogleApp: function(options) {
        return new Promise(
            function(resolve, reject){
                try
                { 
                    cordova.exec(
                        function(result) {
                            console.log(result);
                            console.log("wincallback @installGoogleApp");
                            return resolve(result);
                        },
                        function(error) {
                            console.log("error @requestPermission :" + error);
                            return reject(error);

                        },
                        'SpeechRecognition',
                        'installGoogleApp',
                        []);
                }
                catch (e) {
                    console.log(e);
                    console.log('cordova-speech-recognition.js - requestPermission - error');
                } 
            }
        );
    }
}
