import Global from 'common/services/resources/global.js'
export default {
	props:['brand','wizardTitle'],
	data:
		function () {
			return {
				    CONSTANTS:
					{
						BRAND_TXT : 
						{
							'google_home' : [
								{
									'txt' : this.$gettextInterpolate(this.$gettext('Open your <b>Google Home app</b> and follow the Google Home procedure to add a %{appName} device.'), {appName: this.$store.state.variantManager.class.title}),
									'img' : 'app-logo'
								},
								{
									'txt' : this.$gettextInterpolate(this.$gettext('Once you\'ve added %{appName} to the Google Home app, you\'ll see your Google Home icon appear in your first room.'), {appName: this.$store.state.variantManager.class.title}),
									'subTxt' : this.$gettextInterpolate(this.$gettext('Now you can control your %{appName} equipments, scenarios and kits with voice !'), {appName: this.$store.state.variantManager.class.title}),
									'img' : 'widget'
								}
							],
							'amazon_alexa' : [
								{
									'txt' : this.$gettextInterpolate(this.$gettext('Open your <b>Amazon Alexa app</b> and follow the Amazon Alexa procedure to add the %{appName} skill.'), {appName: this.$store.state.variantManager.class.title}),
									'img' : 'app-logo'
								},
								{
									'txt' : this.$gettextInterpolate(this.$gettext('Once you\'ve added %{appName} to the Amazon Alexa app, you\'ll see your Amazon Alexa icon appear in your first room.'), {appName: this.$store.state.variantManager.class.title}),
									'subTxt' : this.$gettextInterpolate(this.$gettext('Now you can control your %{appName} equipments, scenarios and kits with voice !'), {appName: this.$store.state.variantManager.class.title}),
									'img' : 'widget'
								}
							],
							'aligenie' : [
								{
									'txt' : this.$gettextInterpolate(this.$gettext('Open your <b>AliGenie app</b> and follow the AliGenie procedure to add the %{appName} skill.'), {appName: this.$store.state.variantManager.class.title}),
									'img' : 'app-logo'
								},
								{
									'txt' : this.$gettextInterpolate(this.$gettext('Once you\'ve added %{appName} to the AliGenie app, you\'ll see your AliGenie icon appear in your first room.'), {appName: this.$store.state.variantManager.
										class.title}),
									'subTxt' : this.$gettextInterpolate(this.$gettext('Now you can control your %{appName} equipments, scenarios and kits with voice !'), {appName: this.$store.state.variantManager.class.title}),
									'img' : 'widget'
								}
							],
						}
					},
					step:0,
					brandStepper: []
			}
		},
	computed: {
		validateTxt: function () {
			return (this.step==this.brandStepper.length-1?this.$gettext('Close'):this.$gettext('Next'));
		},
	},	
	created: function () {
		this.brandStepper = this.CONSTANTS.BRAND_TXT[this.brand.name];
		this.eventHub.$emit('setStepName', 'wizard-client');
		this.eventHub.$on('wizardPrevious', this.wizardPrevious);
	},
	destroyed: function () {
		this.eventHub.$off('wizardPrevious', this.wizardPrevious);
	}, 	
	methods: {
		getBrandSrc: function (image) {
			return (this.brand.images && this.brand.images[image] ? Global.getBackendUrl()+this.brand.images[image]:this.brand.imagePath);
		},
		wizardPrevious: function(){
			if(this.step == 0){
				this.eventHub.$emit('setMainStep', 2);
			}
			else
			{
				this.step--;
			}
		},
		validate: function(){
			if(this.step==this.brandStepper.length-1)
				this.close();	
			else		
				this.step = parseInt(this.step)+1;
		},
		close: function(){
			this.eventHub.$emit('addDeviceFinished');
		}
  }
}
