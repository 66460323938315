import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'


export default {
    waiting: 
    {
        addMember : false,
        editMember : false
    },      
    getFamily: function () {
      return new Promise(
        function(resolve, reject){
          axios.get(Global.getBackendUrl()+'user/family',
              {
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'Authorization': StorageService.getValue(localStorage, 'authorization')
                  },
              }).then(function (response) {
                  return resolve(response);
              })
              .catch(function (error) {
                  return reject(error);
              });
          }
       );
    },

    addMember: function (member, familyId) {
        var _this = this;      
        return new Promise(
          function(resolve, reject){
            if(_this.waiting.addMember == true)return false;
            axios.post(Global.getBackendUrl()+'user/family/'+familyId+'/member',
                member,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': StorageService.getValue(localStorage, 'authorization')
                    },
                }).then(function (response) {
                    _this.waiting.addMember = false;
                    return resolve(response);
                })
                .catch(function (error) {
                    _this.waiting.addMember = false;
                    return reject(error);
                });
          }
      );
    },

    deleteMember: function (familyId, memberId) {
        return new Promise(
            function(resolve, reject){
                axios.delete(Global.getBackendUrl()+'user/family/'+familyId+'/member/'+memberId,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },

    editMember: function (member, familyId, memberId) {
        var _this = this;      
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.editMember == true)return false;
                axios.put(Global.getBackendUrl()+'user/family/'+familyId+'/member/'+memberId,
                    member,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.editMember = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.editMember = false;
                        return reject(error);
                    });
            }
        );
    },

}
