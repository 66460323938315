export default {
    props: {
		margin: {
            type: String,
            default: '30px 0'
        },
    },
    data:
        function () {
                    return {
                                CONSTANTS:
                                {
                                },
                    }
                },
     created:
        function () {
        }

}
