
import Market from 'app/home/market/market.vue'
import { consoleError } from 'vuetify/es5/util/console';

export default {
    components: { Market },
    data:
        function () {
                    return {
                        active : false,
                        marketUrl: "https://opencart.frenchbeauty.me/"
                        //marketUrl: (window.location.protocol=='file:'?'http:':window.location.protocol)+'://market.comfortlife.me/test'
                    }
                },
     created:
        function () {
            this.eventHub.$on('displayMainModal', this.displayMainModal);
        },

    methods: {
            displayMainModal: function () {
             this.active = true;
             this.marketUrl = "https://opencart.frenchbeauty.me/";
             //this.marketUrl = (window.location.protocol=='file:'?'http:':window.location.protocol)+'//market.comfortlife.me/test';
            },
            closeMainModal: function () {
                this.active = false;
            }
        }

}