import AlT2 from './al-t/al-t.vue';

export default {
	props: ['homepilot', 'model', 'submodel', 'step'],
	components: {AlT2},
    data: function () {
        return {
			CONSTANTS:
			{
				ALARM_TYPE :
				{
					'AL-G09C':
					{
						type : 'AL-T1',
						step3 : this.$gettext('You have 15 seconds to push left bottom of alarm button.')
						//Vous avez 15 secondes pour appuyer sur le bouton en bas à gauche du clavier.<br /><br />La sirène doit sonner.
					},

					'AL-70 / AL-80':
					{
						type : 'AL-T2',
						step3 : this.$gettext('You have 15 seconds to push the * button of the keyboard during 3 seconds.')
                                //Vous avez 15 secondes pour appuyer sur le bouton * du clavier pendant 3 secondes.
					},
					'AL-50 / AL-60':
					{
						type : 'AL-T3',
						step3 : this.$gettext('Wait LED converter blink.<br/><br/>You have 15 seconds to push the SOS button at the top of the box.')
                                //Attendez que le voyant LED du convertisseur clignote.<br><br>Vous avez 15 secondes pour appuyer sur le bouton SOS en haut du boitier.<br><br>La sirène doit sonner.
					},
				}
			},

			alarm:
			{
				id: '',
				uiElts: {}
			},
			alarmRemote:
			{
				id: ''
			}
        }
    },
    created: function () {
		this.init();
    },
	methods: {
		init: function()
		{
			this.alarm.uiElts = this.CONSTANTS.ALARM_TYPE[this.submodel.name];
		}
	}
}
