export default {
    data: function () {
        return {
            loading:false
        }
    },
    created: function () {
    },
	methods: {
        next: function(){
            this.loading = true;
            this.eventHub.$emit('nextInstallStep');
        },
    }
}
