import ArrayService from 'common/services/array.js'
export default {
    props: ['module'],
    data () {
      return {
      }
    },
    created: function(){
    },
    computed: {
      buttons: function () {
        return ArrayService.groupByNumber(this.module.device.capabilities[0].states,2);
      },
    },   
    methods: {
      setStatus : function(status){
        this.$parent.$emit('set-status', status);
		  }
  	}
}
