import DeviceResource from 'common/services/resources/device.js'
import CommonResource from 'common/services/resources/common.js'


export default {
	props: ['sentenceTest'],
	data:
	function () {
		return {
	  			CONSTANTS:{
							  ADD_ERROR : this.$gettext('Unable to add the device'),
							  ADD_SUCCESS : this.$gettext('The device has been successfully added')
						  },
				loading:false
		}
	},
	created: function () {
		this.eventHub.$emit('setDisplayPrevious', false);
	},
	methods: {
    	previous : function(){
			this.eventHub.$emit('backInstallStep');
		},
		test: function(status)
		{
			var commandId = this.uuidV4();
			var model = this.$parent.model;
			if(model.name == 'mmv')
				var capability = 'position_vertical';
			else
				var capability = 'status';
			//setStatus: function (widget, status, deviceUid, eventHub)
			var homepilot = this.$parent.$parent.$parent.homepilot;
			var deviceUid = this.$parent.$parent.$parent.deviceId;
			var widget = {
				'id' : this.$parent.$parent.$parent.deviceId,
				'device':
				{
					'model' : model.name,
					'systems' : model.systems,
					'bearerId' : homepilot.id,
					'status': [
						{
						  'capability': capability,
						  'value': status
						}
					  ]
				}
			};
			CommonResource.setStatus(widget, status, deviceUid, this.eventHub);
		},
		validateDevice: function(){
				this.loading = true;
				var commandId = this.uuidV4();
				var _this = this;
				var model = this.model;
				DeviceResource.validate(this.$parent.$parent.$parent.model.name,this.$parent.$parent.$parent.model.systems[0],commandId,this.$parent.$parent.$parent.deviceId).then(
					function(response) {
						_this.loading = false;
						_this.eventHub.$emit('nextInternalMainStep');
						_this.eventHub.$emit('nextHeaderStep');
					}).catch(
					function(e) {
						_this.loading = false;
						if([402].indexOf(e.response.status)!=-1)
							_this.eventHub.$emit('displayAlertKey', 'unauthorized'); 
					});
		}
  }
}
