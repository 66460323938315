export default {
    data: function () {
        return {
            CONSTANTS:{
                MIDDLE_CONTENT_TXT: this.$gettext('Connect your Home Pilot to your internet box with an RJ45 cable (contained in the box), then connect the power cable.<br/>If you have already connected the power cable before starting the procedure, unplug it, wait a few seconds and then reconnect it.Wait a few seconds and go to the next step.')
            },
            mode : 'automatic',
            button : 'manual'
        }
    },
    created: function () {
        this.$parent.$parent.initMode('RJ45 cable');
        this.eventHub.$emit('setDisplayPrevious', true);
    },
	methods: {
		next: function(){
			this.eventHub.$emit('nextStepCable');
		}
	}
}
