import { required, email} from 'vuelidate/lib/validators'
import Language from 'common/services/language.js'
import StorageService from 'common/services/storage.js'

export default {
    props:{
        actions: {
            type: Array,
			default:[]
        },
        user: {
			type: Object,
			default:{}
        },
        noUserMail: {
			type: Boolean,
			default:false
		},
        displaySwitchDefautMail: {
			type: Boolean,
			default:true
		}
    },
    data:
        function () {
            return {
                CONSTANTS:{
                    INVALID_EMAIL: this.$gettext('Invalid E-mail'),
                    EMAIL_ALREADY_ADDED: this.$gettext('This email is already added in alerts'),
                },
                switchDefault: true,
                email: '',
                title: '',
                message: ''
            }
        },
    created: function () {
        var userMail = _find(this.actions, {type:'email',infos:{to:this.user.username}});
        if(userMail==undefined && !this.noUserMail)
        {
            this.email = this.user.username;
        }
    },
    validations: {
		email: {
			required,
			email
		},
	},
    methods: {
        close: function ()
		{
			this.$emit('close');
        },
        validateMail: function ()
		{
            var _this = this;
			var emails = _filter(this.actions, function(o) { return o.type == 'email'; });
			if(this.$v.email.$invalid)
			{
			  this.eventHub.$emit('displayAlert', this.CONSTANTS.INVALID_EMAIL);
			  return false;
			}
			if(_findIndex(emails, function(o) { return o.infos.to == _this.email; })!=-1)
			{
				this.eventHub.$emit('displayAlert', this.CONSTANTS.EMAIL_ALREADY_ADDED);
				return false;
			}
			var obj = {
				type: 'email',
				infos: {
					to: this.email,
                    language: Language.getUserLanguage(),
                    app: StorageService.getValue(localStorage, 'app')
				}
            }
			if(this.title != '' && !this.switchDefault)
				obj.infos.title = this.title;
			if(this.message != '' && !this.switchDefault)
				obj.infos.message = this.message;
            this.actions.push(obj);
            this.close();
        }
    }
}
  