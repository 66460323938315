import StorageService from 'common/services/storage.js'
import Global from 'common/services/resources/global.js'

export default {
    props:
    {
        hide: {
            type: Boolean,
            default: false
        },
        url: {
            type: String,
            default: 'about:blank'
        },
        scrollbar: {
            type: Boolean,
            default: true
        }
    },
    data:
        function () {
            return {
                    CONSTANTS:
					{
                        //MARKETPLACE_URL : (window.location.protocol=='file:'?'https:':window.location.protocol)+'//market.comfortlife.me/collection/promo'
                        MARKETPLACE_URL : Global.WEBSITE_STORE,
                        DEVICE_TXT : _upperFirst(this.$gettext('add a device')),
                        SCENARIO_TXT : _upperFirst(this.$gettext('add a scenario')),
                        GROUP_TXT : _upperFirst(this.$gettext('add a device group')),
                        ROOM_TXT : _upperFirst(this.$gettext('add a room')),
                        SERVICE_TXT : _upperFirst(this.$gettext('add a kit')),
                        USER_TXT : _upperFirst(this.$gettext('add a user'))
					},
					initHide: false,
                    home: false,
                    loading: false,
                    configMenu: {}
            }
    },
	computed:
    {
        computedUrl: function () {
            return (this.url==undefined?this.CONSTANTS.MARKETPLACE_URL:this.url);
        },
        computedHide: function () {
            this.initHide = this.hide;
            return this.hide;
        },
    },
     created: function () {
        this.init();
    },
	methods: {
        iframeOnload: function () {
            this.loading = false;
	        this.eventHub.$emit('marketOnload');
        },
        init: function () {
            if(this.$route!=undefined && this.$route.name=='marketplace')
            {
                /*this.eventHub.$emit('showNavbar');*/
                this.eventHub.$emit('setHideRightPanel',true);
                //this.eventHub.$emit('setExtendRightPanel',true);
                this.initHide = false;
                this.setMarketHome();
                this.eventHub.$emit('setCurrentLink','marketplace');
            }
        },
        setMarketHome: function () {
            this.home = true;
            this.url = this.CONSTANTS.MARKETPLACE_URL;
        }
    }
}
