import StorageService from 'common/services/storage.js'
import DeviceResource from 'common/services/resources/device.js'
import UserResource from 'common/services/resources/user.js'
import CordovaService from 'common/services/cordova.js'
import UartService from 'common/services/uart.js'
import Device from 'common/classes/Device.js';
import SmartCamera from 'common/classes/SmartCamera.js'
import moment from 'moment'
import store from 'vuex_path/store'

import { v4 as uuidV4 } from 'uuid';

export default {
    waiting: false,
    setStatus: function (widget, status, deviceUid, eventHub, noWait) {
        try{
            var _this = this;
            if(!this.waiting)
            {
                this.waiting = (!noWait);
                var commandId = uuidV4();
                var device = widget.device;
                var capability = '';
                var statusObj = undefined;
                if(deviceUid == undefined)
                    deviceUid = widget.id;
                if(device.relays || device.model == "ceiling_fan" || device.model == "wft")
                {
                    capability = 'trigger';
                }
                else
                {
                    //if status is a object ({capabilty:,value:})
                    if(typeof status === 'object'){
                        statusObj = JSON.parse(JSON.stringify(status));
                        capability = status.capability;
                        status = status.value;
                    }
                    else 
                    {
                        capability = device.status[0].capability;
                    }
                    if(device.model != 'smart_camera' && widget.device.model != 'group'
                     && widget.device.model != 'vdp'
                     && widget.device.model != 'battery_vdp'
                     && widget.device.model != 'oskkio-1'
                    ){
                        eventHub.$emit('setDeviceStatus', widget, status, 'device', false, capability);
                    }
                }
                if(device.model == 'plug' && widget.idMulti){
                    /*var binaryState = (status=='on' ? 1 : 0);
                    var relay_state = [null, null, null, null];
                    relay_state[widget.plot] = binaryState;*/
                    var jsonCordova = {
                        'action': 'command',
                        'uid':commandId,
                        'modules': [
                            {
                                'id': widget.idMulti,
                                'device':{
                                    'bearerId': widget.idMulti,
                                    'status' : [
                                        {
                                        'index':widget.plot.toString(),
                                        'capability': capability,
                                        'value': status
                                      }
                                    ],
                                }
                            }
                        ]
                    };
                    var jsonWs = {
                                        'status' : [
                                            {
                                                'capability': capability,
                                                'value': status
                                            }
                                        ],
                                        'commandId':commandId
                                };
                    eventHub.commandDeviceList.push({'uid':commandId, 'deviceUid':deviceUid, 'index':widget.plot.toString() });
                    /*var jsonCordova = {
                                        'relay_state': relay_state,
                                        'cmd': 'set',
                                        'device_id': [widget.idMulti],
                                        'id' : commandId
                                        };*/
                    DeviceResource.setStatus(jsonWs, device, deviceUid).then(
                        function() {
                        }
                    ).catch(
                        function(e) {
                            if(e.response && e.response.status == 401)
                                eventHub.$emit('logout');
                        }
                    );
                }
                else if(device.model == 'plug'){
                    var jsonCordova = {
                        'action': 'command',
                        'uid':commandId,
                        'modules': [
                            {
                                'id': widget.idPlug,
                                'device':{
                                    'bearerId': widget.idPlug,
                                    'status' : [
                                        {
                                            'index': '0',
                                            'capability': capability,
                                            'value': status
                                        }
                                    ],
                                }
                            }
                        ]
                    };
                    var jsonWs = {
                        'status' : [
                            {
                                'capability': capability,
                                'value': status
                            }
                        ],
                        'commandId':commandId
                    };
                    eventHub.commandDeviceList.push({'uid':commandId, 'deviceUid':deviceUid, 'index':'0' });
                    /*var jsonCordova = {
                                        'cmd': status,
                                        'device_id': [widget.idPlug],
                                        'id' : commandId
                                        };*/
                    DeviceResource.setStatus(jsonWs, device, deviceUid).then(
                        function() {
                        }
                    ).catch(
                        function(e) {
                            if(e.response && e.response.status == 401)
                                eventHub.$emit('logout');
                        }
                    );
                }
                else if(device.model == 'hwf' && status == 'learn'){
                    var jsonCordova = {
                        'action': 'associate',
                        'timestamp':moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        'uid':commandId,
                        'modules': [
                            {
                                'id': deviceUid,
                                'device':{
                                }
                            }
                        ]
                    };
                    var path = device.model+'/'+device.systems[0]+'/addressLocation/plants/'+store.state.selectedPlantId+'/modules/parameter/id/value/'+deviceUid;
                    eventHub.$emit('mqttPublish',  path, jsonCordova);
                }
                else if(capability == 'call' && status == "pending"){
                    var jsonCordova = {
                        "event": "alert",
                        "uid": commandId,
                        "modules": [
                          {
                            "device": {
                              "status": [
                                {
                                  "capability": capability,
                                  "value": status
                                }
                              ]
                            },
                            "id": deviceUid
                          }
                        ],
                        "clientId": device.model+"_"+deviceUid
                    };
                    var path = UserResource.getMqtt().mqttTopics[0];
                    eventHub.$emit('mqttPublish', path, jsonCordova);
                }
                else{
                    var modules = [];
                    if(device.model == 'smart_camera' || device.bearerId==undefined)
                    {
                        modules.push({
                            'id': deviceUid,
                            'device':{
                                'bearerId':deviceUid,
                                'status':[
                                    {
                                        'capability':capability
                                    }
                                ]
                            }
                        }); 
                    }
                    else
                    {
                        let allBearIds = [];
                        const modulePayload = function(deviceUid,bearerId,capability)
                        {
                            return{
                                'id': deviceUid,
                                'device':{
                                    'bearerId': bearerId,
                                    'status':[
                                        {
                                            'capability':capability
                                        }
                                    ]
                                }
                            }
                        };
                        store.state.homepilots.forEach(function(hp) { 
                            allBearIds.push(hp.id);
                            modules.push(
                                modulePayload(deviceUid,hp.id,capability)
                            ); 
                        });
                        if(allBearIds.indexOf(device.bearerId)==-1)
                        {
                            modules.push(
                                modulePayload(deviceUid,device.bearerId,capability)
                            ); 
                        }
                    };
                    var jsonCordova = {
                        'action': 'command',
                        'timestamp':moment.utc().format('YYYY-MM-DD HH:mm:ss'),
                        'uid':commandId,
                        'modules': modules
                    };
                    jsonCordova.modules.forEach(function(m,index) {
                        if(device.model == 'ceiling_fan' || device.model == 'wft')
                        {
                            jsonCordova.modules[index].device.status[0].index = status;
                        }
                        if(status && (device.model != 'ceiling_fan' && device.model != 'wft'))
                        {
                            jsonCordova.modules[index].device.status[0].value = status;
                        }
                        if(status && device.model == 'smart_camera' && capability == 'record')
                        {
                            jsonCordova.modules[index].device.status[0].port = device.recordPort;
                            jsonCordova.modules[index].device.status[0].endpoint = device.recordEndpoint;
                        }
                    });

                    
                    /*if(device.submodelObj && device.submodelObj.meshCompatible)
                    {
                        console.log('UART FRAME');
                        console.log(UartService.waitStateCallbackArray);
                        console.log(widget.id);
                        jsonCordova.modules[0].device.uartFrame = UartService.getFrame(widget,statusObj,'setStatus');
                        clearTimeout(UartService.waitStateCallbackArray[widget.id]);
                        UartService.waitStateCallbackArray[widget.id] = setTimeout(function(){
                            console.log('UART FRAME setTimeout');
                            console.log(UartService.waitStateCallbackArray);
                            console.log(widget.id);
                            UartService.waitStateCallbackArray[widget.id] = undefined;
                            widget.offline = true;
                        }, Device.WAIT_STATE_CALLBACK_TIMEOUT);
                        if(!jsonCordova.modules[0].device.uartFrame)return false;
                    }*/
                    /*if(capability=='wake_up')
                    {
                        DeviceResource.setStatus({'status' : [{'capability': capability}],'commandId': uuidV4()}, device, deviceUid).then(
                            function() {
                            }
                        ).catch(
                            function(e) {
                                if(e.response && e.response.status == 401)
                                    eventHub.$emit('logout');
                            }
                        ); 
                    }*/
                    var path = device.model+'/'+device.systems[0]+'/addressLocation/plants/'+store.state.selectedPlantId+'/modules/parameter/id/value/'+deviceUid;
                    eventHub.$emit('mqttPublish',  path, jsonCordova);
                }
                if(capability == 'call' && status != 'pending'){
                    var path = UserResource.getMqtt().mqttTopics[0];
                    eventHub.$emit('mqttPublish',  path, jsonCordova);
                }
                console.log('SEND UDP');
                console.log(jsonCordova);
                CordovaService.setStatus(jsonCordova).then(
                    function(response) {
                    }).catch(
                    function(e) {
                    });
                setTimeout(function() {
                    _this.waiting = false;
                }, 500);
            }
        }
        catch(e)
        {
            console.log(e);
            this.waiting = false;
        }
    },

    setStatusService : function(status, id, service, widget, eventHub){
        var commandId = uuidV4();
        var statusValue = {};
        if(typeof status === 'object'){
            statusValue = status;
        }
        else 
        {
            if(service=='alarm'){
                statusValue = {
                  'capability': 'protection',
                  'value': status
                }
              }
              else{
                statusValue = {
                  'capability': 'active',
                  'value': status
                }
              }
        }

        var jsonCordova = {
          'uid': commandId,
          'action': 'set',
          'modules': [
              {
                  'id': id,
                  'service': {
                      'status': [
                        statusValue
                      ]
                  }
              }
          ]
        };
        
        eventHub.$emit('setDeviceStatus', widget, statusValue.value, 'service', false, statusValue.capability);
        var path = 'service/comfort/addressLocation/plants/'+store.state.selectedPlantId+'/modules/parameter/id/value/'+id;
        eventHub.$emit('mqttPublish',  path, jsonCordova);
        CordovaService.setStatus(jsonCordova).then(
          function(response) {
              console.log(response);
          }
        ).catch(
          function() {
          }
        );
      },

      sendGroupStatus : function(eventHub, module, status, topic){
        var commandId = uuidV4();

        var modules = [];

        store.state.meshHomepilots.forEach(function(hp) { 
            modules.push({
                'id':  hp.id,
                'device':{
                    'bearerId': hp.id,
                    'status': status
                }
            }); 
        });
        
        var json = {
            'action': 'command',
            'timestamp':moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            'uid':commandId,
            'status': status
            /*'modules': modules*/
        };
        if(status && status.length>0)
        {
            eventHub.$emit('setDeviceStatus', module, status[0].value, 'device', false, status[0].capability);
        }
        
        var path = topic;

        eventHub.$emit('mqttPublish',  path, json);

        json.topic = topic;
        CordovaService.setStatus(json).then(
            function(response) {
            }).catch(
            function(e) {
            });
    },

    sendMeshStatus : function(eventHub, module, status, uartFrame){
        console.log('sendMeshStatus');
        var commandId = uuidV4();

        var modules = [];

        store.state.meshHomepilots.forEach(function(hp) { 
            modules.push({
                'id': module.id,
                'device':{
                    'bearerId': hp.id,
                    'status': status/*,
                    'uartFrame':uartFrame*/
                }
            }); 
        });
        
        var json = {
            'action': 'command',
            'timestamp':moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            'uid':commandId,
            'modules': modules
        };
        console.log(json);
        console.log(status);
        if(status && status.length>0)
        {
            eventHub.$emit('setDeviceStatus', module, status[0].value, 'device', false, status[0].capability);
        }
        
        var path = module.device.model+'/'+module.device.systems[0]+'/addressLocation/plants/'+store.state.selectedPlantId+'/modules/parameter/id/value/'+module.id;
        eventHub.$emit('mqttPublish',  path, json);

        CordovaService.setStatus(json).then(
            function(response) {
            }).catch(
            function(e) {
            });
    },

    getInfosModule : function(eventHub,moduleId, model){
        console.log('getInfosModule');

        var json = {
            'action': 'get_info',
            'timestamp':moment.utc().format('YYYY-MM-DD HH:mm:ss'),
            'uid': uuidV4(),
            'modules': [
                {
                    'id': moduleId,
                    'device':{
                    }
                }
            ]
        };
        console.log(json);

        var path = model.name+'/'+model.systems[0]+'/addressLocation/plants/'+store.state.selectedPlantId+'/modules/parameter/id/value/'+moduleId;
        eventHub.$emit('mqttPublish',  path, json);

        CordovaService.setStatus(json).then(
            function(response) {
            }).catch(
            function(e) {
            });
    },

    sendActionCommand : function(eventHub,action,modules){
        console.log('sendActionCommand');
        if(modules.lenght==0)return false;
        let modulesPayload = modules.map(function(m) {
            let mp = {
                'id': m.id,
                'device': {}
            };
            return mp;
        });
        var json = {
            'action': action,
            'uid': uuidV4(),
            'modules': modulesPayload
        };
        console.log(json);

        var path = modules[0].device.model+'/'+modules[0].device.systems[0]+'/addressLocation/plants/'+store.state.selectedPlantId+'/modules/parameter/id/value/'+modules[0].id;
        
        console.log(json);
        console.log(path);
        
        eventHub.$emit('mqttPublish',  path, json);

        CordovaService.setStatus(json).then(
            function(response) {
            }).catch(
            function(e) {
            });
    },  
}
