
import Vue from 'vue'

import CordovaService from 'common/services/cordova.js'
import CordovaSpeechRecognitionService from 'common/services/cordova-speech-recognition.js'
import SpeechRecognitionService from 'common/services/speech-recognition.js'
import ScenarioResource from 'common/services/resources/scenario.js'

import RBResource from 'common/services/resources/rb.js'
import ExternalResource from 'common/services/resources/external.js'
import CommonResource from 'common/services/resources/common.js'

export default {
    props: {
		plant: {
			type: Object
		},
		devicesByCapability: {
			type: Object
        },
        scenarios: {
			type: Array
        },
        sharesLocations: {
			type: Array,
			default: () => []
        },
        externalAccounts: {
			type: Object
		}
	},
    components: {},
    data: function () {
        return {
            CONSTANTS: {
                NO_ACTION_RESPOND: this.$gettext('No action respond to your request' ),
                LANGUAGE_PACK_REQUIRED: this.$gettext('The installation of language pack "%{s}" is required to use speech recognition in the current language.'),
                LANGUAGE_PACK_REQUIRED_CORDOVA_CALLBACK_ERROR: 'error from server',
                NOT_AVAILABLE: 'Speech recognition service is not available on the system.',
                NO_SPEECH: 'No speech input',
                NO_MATCH: 'No match'
            },
            speechRecognitionModal:false,
            requirementsConfigModal:false,
            icon: 'cl-icon-mic',
            languagePackRequiredWarning:'',
            speechRecognitionOptions:
            {
                language:'en-GB',
                prompt:'',
                showPopup:false,
                showPartial:true
            }
        }
    },
    created: function () {
        var navigatorLanguage = ((navigator.language || (navigator.languages && navigator.languages[0])));
        if(Vue.config.language == 'zh-CN')
        {
            this.speechRecognitionOptions.language = (mobileSystem=='android'?'cmn-Hans-CN':Vue.config.language);
        }
        else
        {
            this.speechRecognitionOptions.language = (Vue.config.language=='fr-FR'?Vue.config.language:(Vue.config.language.substr(0, 2)=='en'?navigatorLanguage:'en-US'));
        }
        this.eventHub.$on('startSpeechRecognition', this.startSpeechRecognition);
    },
    destroyed: function () {
        this.eventHub.$off('startSpeechRecognition', this.startSpeechRecognition);
    },
    methods: {
        closeModal: function () {
            this.speechRecognitionModal=false;
            CordovaSpeechRecognitionService.stopListening();
        },
        installGoogleApp: function () {
            CordovaSpeechRecognitionService.installGoogleApp();
        },
        startSpeechRecognition: function () {
            /*var matches = ["show me visitor"];
            this.speechRecognitionCallBack(matches);
            return false;
            console.log('startSpeechRecognition');
            this.requirementsConfigModal = true;
            return false;*/
            if(this.inApp){
                //var matches = ["éteindre plug 1", "éteindre plug.", "éteindre prise", "éteindre plug un", "éteindre blog."]
                //var matches = ["éteindre prf", "éteindre plug.", "éteindre prîse", "éteindre plug un", "éteindre blog."];
                //var matches = ["allumer kangtai"];

                var options = this.speechRecognitionOptions;
                var _this = this;
                this.languagePackRequiredWarning='';
                this.speechRecognitionModal=true;
                /*//var matches = ["开厨房", "开除房"];
                var matches = ["button number 1 gate"];
                _this.speechRecognitionModal=true;
                //var triggerCommand = SpeechRecognitionService.getTriggerCommand(matches,app.devicesByCapability,app.scenarios,'zh-CN');
                var triggerCommand = SpeechRecognitionService.getTriggerCommand(matches,app.devicesByCapability,app.scenarios,'en');
                console.log(triggerCommand);
                if(triggerCommand.modules.length>0)
                {
                    var _this = this;
                    triggerCommand.modules.forEach(function(m) {
                        _this.executeVoiceCommand(m,triggerCommand);
                    });
                }
                return false;*/

                CordovaSpeechRecognitionService.hasPermission().then(
                    function(bool) {
                        if(bool)
                        {
                            console.log('hasPermission - launch');
                            _this.openSpeechRecognitionModal(options);
                        }
                        else
                        {
                            console.log('hasPermission - requestPermission');
                            CordovaSpeechRecognitionService.requestPermission().then(
                            function(bool) {
                                _this.openSpeechRecognitionModal(options);
                            }).catch(
                            function() {
                                _this.closeModal();
                            });
                        }
                    }).catch(
                    function() {
                        _this.closeModal();
                    });
            }
            else{
                var message = this.$gettext('This action is only available on mobil app')
                this.eventHub.$emit('displayAlert', message);
            }
            
		},
		openSpeechRecognitionModal: function (options) {
            console.log('openSpeechRecognitionModal');
            this.icon = 'cl-icon-mic';
            //if(mobileSystem!='android')
			this.speechRecognitionModal = true;
            //CordovaSpeechRecognitionService.isRecognitionAvailable();
			CordovaSpeechRecognitionService.startListening(options,this.speechRecognitionCallBack,this.speechRecognitionCallBackError);
		},
		speechRecognitionCallBack: function (matches) {
            console.log('speechRecognitionCallBack');
            console.log(matches);
            console.log(app.plant);
            var triggerCommand = SpeechRecognitionService.getTriggerCommand(matches,
                this.devicesByCapability,
                this.scenarios,
                this.scenariosByCapability,
                this.plant,
                Vue.config.language.substr(0,2));
            console.log('triggerCommand.modules');
            console.log(triggerCommand.modules);
            var speechRecognitionSuccess = false;
            if(triggerCommand.modules.length>0)
            {
                var _this = this;
                var findAtLeastOneModule = false;
                triggerCommand.modules.forEach(function(m) {
                    if(_this.executeVoiceCommand(m,triggerCommand,matches))
                    {
                        findAtLeastOneModule = true;
                    } 
                });
                if(!findAtLeastOneModule)
                {
                    this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_ACTION_RESPOND);
                    //this.eventHub.$emit('displayAlert', matches.toString());
                }
                speechRecognitionSuccess = true;
            }
            else
            {
                /*var matchesHtml = '<ul>';
                matchesHtml+='<li>'+matches[0]+'</li>';
                matchesHtml+= '</ul>';*/
                this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_ACTION_RESPOND);
                //this.eventHub.$emit('displayAlert', matches.toString());
            }
            this.closeModal();
            return speechRecognitionSuccess;
        },
        speechRecognitionCallBackError: function (error) {
            console.log('speechRecognitionCallBackError');
            console.log(error);
            if(error == this.CONSTANTS.NO_SPEECH || error == this.CONSTANTS.NO_MATCH){
                this.eventHub.$emit('displayAlert', this.CONSTANTS.NO_ACTION_RESPOND);
                //this.eventHub.$emit('displayAlert', error);
            }
            else if(mobileSystem=='android')
            {
                this.requirementsConfigModal = true;
                this.eventHub.$emit('displayAlert', error);
                //this.languagePackRequiredWarning = this.$gettextInterpolate(this.CONSTANTS.LANGUAGE_PACK_REQUIRED, {s: this.speechRecognitionOptions.language});
            }
            this.closeModal();
		},      
		executeVoiceCommand: function (module,triggerCommand,matches) {
            var modules = module.elements;
            var _this = this;
            console.log('executeVoiceCommand');
            console.log(modules);
            if(modules.length>0)
            {
                // Trigger first module
                this.triggerElementByVoiceCommand(modules[0],triggerCommand,module.capabilityObject);
                if(modules.length>1)
                {
                    for(var i = 1; i<modules.length; i++)
                    {
                        var m = modules[i];
                        this.launchTriggerElementByVoiceCommand(m,triggerCommand,module.capabilityObject);
                    }
                }
                var vc = (modules[0].actions?'':triggerCommand.voiceCommand);
                //Check if a group is detected with the voice command
                var index = _findIndex(modules, function(o) { return o.findType == 'group'; });
                this.eventHub.$emit('displayAlert',vc +' '+(index !=-1 ? modules[index].zoneName : modules[0].name), 'success');
                //this.eventHub.$emit('displayAlert', matches.toString(), 'success');
                return true;
            }
            else
            {
                return false;
            }
        },
		launchTriggerElementByVoiceCommand: function (module,triggerCommand,capabilityObject) {
            var _this = this;
            setTimeout(function() {
                _this.triggerElementByVoiceCommand(module,triggerCommand,capabilityObject);
            }, 500);
        },

		triggerElementByVoiceCommand: function (m,triggerCommand,capabilityObject) {
            console.log('triggerElementByVoiceCommand');
            console.log(triggerCommand);
            console.log(m);
            console.log(capabilityObject);
            if(m.device)
            {
                var deviceUid = undefined;
                //If Camera
                if(m.device.model=='camera')
                {
                    var ea = ExternalResource.getExternalAccountsByModule(m.id, app.sharesLocations, app.externalAccounts);
                    CordovaService.launchFullscreen(this.eventHub, ea ,m,'landscape',this.uuidV4());
                }
                else
                {
                    console.log(capabilityObject);
                    if(capabilityObject.capability=='show')
                    {
                        console.log('show_'+m.id);
                        this.eventHub.$emit('showCamera',m);
                    }
                    else
                    {
                        var capabilityObjectValue = JSON.parse(JSON.stringify(capabilityObject.value));
                        //If Relay
                        if(m.device.model=='rb')
                        {
                            var index = (triggerCommand.buttonIndex && Number.isInteger(triggerCommand.buttonIndex)?(triggerCommand.buttonIndex-1):undefined);
                            var relay = RBResource.getRelayByIndex(m.device,index);
                            if(relay)
                                deviceUid = relay.id;
                        }
                        CommonResource.setStatus(m, capabilityObjectValue , deviceUid, eventHub, true);
                    }
                }

            }
            else if(m.service)
            {
                CommonResource.setStatusService(capabilityObject.value, m.id, m.service.model, m, eventHub);
            }
            else
            {
                ScenarioResource.trigger(m, eventHub);
            }
		}
    }
}