import DeviceResource from 'common/services/resources/device.js'
export default {
	props: {
		rooms: {
			type: Array,
			default: false
		},
		action: {
			type: String,
			default: ''
		},
		moduleType: {
			type: String,
			default: 'device'
		},
		verifyCapability: {
			type: String,
			default: ''
		},
		verifyModels: {
			type: Array,
			default: ''
		},
		unavailableModels: {
			type: String,
			default: ''
		},
		hideTitle: {
			type: Boolean,
			default: false
		},
		showCheckboxs: {
			type: Boolean,
			default: false
		},
		/***
		 * devicesList : the filtered array directly passed as an argument of the component
		 */
		devicesList: {
			type: Array,
			default: false
		},
	},
	data:
		function () {
			return {
				selectedItem: undefined,
				title : {
					'device' : this.$gettext('Choose a device'),
					'service' : this.$gettext('Choose a kit')
				},
				unavailableModelsArray: []
			}
		},
	computed: {
		filterRooms: function () {
			return (this.devicesList ? this.devicesList : DeviceResource.filterDevicesInRooms(this.rooms,this.moduleType,this.verifyCapability,this.unavailableModelsArray,this.verifyModels));
		},
	},
	created: function ()
	{
		this.initUnavailableModels();
	},
	methods: {
		initUnavailableModels: function () {
			if(this.unavailableModels)
			{
				this.unavailableModelsArray = (Array.isArray(this.unavailableModels)?this.unavailableModels:JSON.parse(this.unavailableModels));
			}
		},
		clickOnItem: function(item,zone){
			
			this.selectedItem = item;
			if(this.showCheckboxs)
			{
				item.selected = !item.selected; 
				this.checkItem(zone,item);
			}
			this.$emit('clickOnItem', this.action, this.selectedItem, this.moduleType);
		},

		checkZone: function(zone){
			//zone.selected = !zone.selected; 
			if(zone.modules)
			{
				zone.modules.forEach(function(module) {
					module.selected = zone.selected;
				});
			}
		},

		checkItem: function(zone,module){

			//module.selected = !module.selected; 

			var checkAllZoneModuleChecked = _filter(zone.modules, function(o) { return o.selected; });

			zone.selected = (checkAllZoneModuleChecked.length == zone.modules.length); 

		},

	}
}
