import RoomsSelect from 'common/components/rooms-select/rooms-select.vue'
import Global from 'common/services/resources/global.js'
export default {
	props:['wizardTitle','brand','plant','defaultRooms','selectedModule'],
	components: {RoomsSelect},
	data:
		function () {
			return {
				    CONSTANTS:
					{
					},
					loading:false,
			}
		},
	created: function () {
		this.init();
	},
	destroyed: function () {
	},
	methods: {
		getBrandSrc: function (brand) {
			return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
		},
		init: function(){
			this.eventHub.$emit('setDisplayPrevious', true);
		},
		changeDeviceRoom: function(zone){
			this.$emit('editModule',zone);
		}	
	}
}

