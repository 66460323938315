
import CommonResource from 'common/services/resources/common.js'
import LinkwilPluginService from 'common/services/cordova/linkwilPlugin.js'
import SystemService from 'common/services/system.js'


export default {
    executeOnCall: function(module, eventHub, json, callbackEvent){
        if(callbackEvent=='onNotificationTap')
        {
          eventHub.$emit('closeModalMainControlDevice');
          if(module.device.submodel=='vdp_100')
          {
            LinkwilPluginService.startLinkwillPlugin(module,eventHub, 'enable');
          }
          else
          {
              eventHub.$emit('showCamera',module, true, true, true);
          }
          var status = {
              "capability": "call",
              "value": "answer"
          }
          CommonResource.setStatus(module, status, undefined, eventHub,true);
        }
        else
        {
          if(module.notification_level != 'NONE'){
            //For mobiles with Android greater than 9, the popup will be displayed by pressing the push
            if(SystemService.checkifAndroidLower10() || eventHub.foreground)
            {
                eventHub.$emit('displayMainControlDevice', module, json.modules[0].device.status[0]);
            }
            else
            {   
                const index = eventHub.checkCommandIdListExists(json.uid);
                if(index != -1)
                {
                    eventHub.commandIdList.splice(index,1);

                }
            }
          }
        }
      }
  
}