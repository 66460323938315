import VueSlider from 'vue-slider-component'
import 'vendor/vue-slider-component/theme/default.css'
import UartService from 'common/services/uart.js'

export default {
    props: ['module', 'use', 'config'],
    components: {
      VueSlider
    },
    data () {
      return {
        switchs: [{value : 1}],
        classModule: 'plug',
        speedIndex:-1,
        colorTemperature:undefined,
        brightness:undefined,
        sliderValue:0,
        sliderOptions: {
          dotSize: 14,
          eventType: 'auto',
          min: 0,
          max: 3,
          interval: 1,
          clickable: true,
          disabled: false,
          show: true,
          tooltip: false,
          piecewise: true,
          speed: 0.5,
          processStyle: {
            "backgroundColor": "#3C3C3C"
          },
          piecewiseActiveStyle: {
            "backgroundColor": "#fff"
          }
        },
        
        brightnessSlider: 
        {
          value: 0,
          options: {
            height: 14,
            min: 0,
            max: 100,
            interval: 1,
            clickable: true,
            tooltip: true,
            railStyle:{
              backgroundImage: 'linear-gradient(to right, black, white)'
            }
          }
        },
        colorTemperatureSlider: 
        {
          value: 0,
          options: {
            height: 14,
            interval: 1,
            clickable: true,
            tooltip: true,
            railStyle:
            {
            }
          }
        }
      }
    },
    created: function(){
      this.switch();
      this.class();
      if(this.module.device)
      {
        this.speedIndex = _findIndex(this.module.device.status, {'capability':'speed'});

        this.initSlider('colorTemperature', {'capability':'color_temperature'});
        this.initSlider('brightness', {'capability':'brightness'});
        
      }
    },
    
    methods: {
        initSlider : function(name, capability){
          this[name] = _find(this.module.device.status, capability);
          if(this[name])
          {
            var c = JSON.parse(JSON.stringify(_find(this.module.device.capabilities, capability)));
            if(c)
            {
              this[name].value = (this[name].value!=null && this[name].value!=undefined ?this[name].value:parseInt(JSON.parse(JSON.stringify(c.default_value))));
              this[name+'Slider'].options.min = c.min;
              this[name+'Slider'].options.max = c.max;
              if(capability.capability=='color_temperature')
              {
                this[name+'Slider'].options.railStyle.backgroundImage = 'linear-gradient(to right, '+chroma.temperature(c.min).hex()+', '+chroma.temperature(c.max).hex()+')';
              }
            }
          }
        },

  		  setStatus : function(status, number){
          if(this.module.device && this.module.device.switchs)
            var swichChannel = this.module.device.switchs[number-1];
          else
            var swichChannel = this.module;
          if(this.use == "scenario")
          {
            this.eventHub.$emit('telTrigger', this.module, status, swichChannel);
          }
          else if(this.use == "service")
          {
            this.eventHub.$emit('telAction', this.module, status, swichChannel);
          }
          else if(this.module.service){
            var statusCordo = (status=='on'?'true':'false');
            var statusWS = (status=='on'?'enable':'disable');
            this.module.service.status[0].value = statusWS;
            this.$parent.$emit('setStatusService', statusCordo, this.module.id, 'heating', statusWS);
          }
          else
          {
            /*if(this.module.device.submodelObj && this.module.device.submodelObj.meshCompatible)
            {
              UartService.sendCommand(this.module, (status=='on'?'turnOn':'turnOff'),this.eventHub);
            }
            else{*/
              this.$parent.$emit('set-status', {capability:'status',value:status}, swichChannel.id);
            //}
          }
        },

        setColorTemperature : function(){
          UartService.sendCommand(this.module, 'setColorTemperature',this.eventHub);
          //this.$parent.$emit('set-status', {'capability': 'color_temperature','value': this.colorTemperatureSlider.value});
          /*var ct = UartService.convertKelvinToHex(this.colorTemperatureSlider.value,this.colorTemperatureSlider.options.min,this.colorTemperatureSlider.options.max);
          this.$parent.$emit('set-status', {'capability': 'color_temperature', 'value': this.colorTemperatureSlider.value, 'uartData' :'46'+ct});*/
        },

        setBrightness : function(){
          UartService.sendCommand(this.module, 'setBrightness',this.eventHub);
          //this.$parent.$emit('set-status', {'capability': 'brightness','value': this.brightnessSlider.value});
          /*var b = parseInt(this.brightnessSlider.value).toString(16).padStart(2, '0');*/
          //this.$parent.$emit('set-status', {'capability': 'brightness', 'value': this.brightnessSlider.value, 'uartData' :'43'+b});
        },

        switch : function(){
          if(this.module.device && this.module.device.submodel == 'CMR-100 (3 buttons)' || this.module.device && this.module.device.submodel == 'RC-002 (3 buttons)'){
            this.switchs = [{value:1},{value:2},{value:3}];
          }
          else if(this.module.device && this.module.device.model == 'trf'){
            this.switchs = [{value:1},{value:2},{value:3},{value:4}];
          }
        },
        class : function(){
          if(this.module.device && this.module.device.submodel == 'CMR-100 (3 buttons)' || this.module.device && this.module.device.submodel == 'RC-002 (3 buttons)'){
           this.classModule = 'CMR-3';
          }
          if(this.module.device && this.module.device.submodel == 'CMR-100 (1 button)' || this.module.device && this.module.device.submodel == 'RC-002 (1 button)'){
            this.classModule = 'CMR-1';
          }
        }
  	}
}
