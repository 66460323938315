import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'

export default {
    waiting: 
    {
        associateTo : false,
        setStatus : false,
        validate : false
    },
    associateTo: function (submodel, bearerId, code, commandId, locationId, brand, eventHub) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.associateTo == true)return false;
                var body = {
                    'bearerId' : bearerId,
                    'submodel' : submodel,
                    'code': code,
                    'commandId': commandId
                };
                if(locationId)
                    body.locationId = locationId;  
                if(brand)
                    body.brand = brand.name;  
                axios.post(Global.getBackendUrl()+'ycb/energy/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/association',
                    body,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.associateTo = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.associateTo = false;
                        if(error.response){
                            if(error.response.status==402)
                            {
                                eventHub.$emit('displayAlertKey', 'unauthorized');
                                return reject('associationFailed');
                            }
                            else if(error.response.status==403)
                            {
                                eventHub.$emit('displayAlertKey', 'cannot_installed');
                                return reject('associationFailed');
                            }
                        }
                        return reject(error);
                    });
            }
        );
    },
    diagnostic: function (coal, key, value) {
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'ycb/energy/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+coal+'/parameter/'+key+'/value/'+value+'/diagnostic/',
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    setStatus: function (key, value, commandId, status) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.setStatus == true)return false;
                status = (status ? 'on' : 'off');
                axios.post(Global.getBackendUrl()+'ycb/energy/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/'+key+'/value/'+value,
                    {
                        'status' : [
                            {
                                "capability": "status",
                                "value": status
                            }

                        ],
                        'commandId' : commandId
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.setStatus = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.setStatus = false;
                        return reject(error);
                    });
            }
        );
    },
    validate: function (value) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.validate == true)return false;
                axios.post(Global.getBackendUrl()+'ycb/energy/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+value+'/validation',
                    {
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.validate = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.validate = false;
                        return reject(error);
                    });
            }
        );
    }
}
