import SystemResource from 'common/services/resources/system.js'
import Global from 'common/services/resources/global.js'
import ArrayService from 'common/services/array.js'
import store from 'vuex_path/store'

export default {
    props:['models','availableModels', 'externalAccounts'],
    data: function () {
        return {
          submodels: [],
          width: 0,
          selected: [],
					search:'',
          CONSTANTS:
          {
              'TITLE' : this.$gettext('Which <strong>model</strong> do you want to add ?')
          }
        }
    },
    computed: {
      filteredSubmodels() {
        return this.submodels.filter(submodel => {
          return submodel.displayName.toLowerCase().includes(this.search.toLowerCase());
          });
      }
    },	
    created: function () {
      this.$parent.mainStepTable = [1,2];
      this.submodels = _orderBy(SystemResource.getAllSubModels(this.models,this.$parent.$parent.brand.name,this.availableModels),'displayName');
      this.eventHub.$emit('setDisplayTitle', true, 'Add a device');
      this.eventHub.$emit('initDeviceObj');
      this.eventHub.$emit('initInstallObject');
      this.eventHub.$emit('initQRCodeElts');
      this.width = window.innerWidth;
    },
	  methods: {
      getBrandLogoSrc: function(){
        return (this.$parent.brand.images && this.$parent.brand.images.default ? Global.getBackendUrl()+this.$parent.brand.images.default:this.$parent.brand.imagePath)
      },
      getSubmodelSrc: function(submodel){
        return (submodel.images && submodel.images.default ? Global.getBackendUrl()+submodel.images.default:
        (submodel.backgroundImage));
      },
      chooseSubmodel: function(submodel){
        var model = ArrayService.find(this.models,'name',submodel.modelName);
        if(!this.$parent.isInstallOnlyApp(model,submodel))
        {
          return false;
        }
        this.eventHub.$emit('setModel',model);
        this.eventHub.$emit('setSubmodel',submodel);
        this.eventHub.$emit('nextInternalMainStep');
        this.eventHub.$emit('setDisplayPrevious', true);
      }
	  }
}
