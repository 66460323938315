import { required, email} from 'vuelidate/lib/validators'
import Global from 'common/services/resources/global.js'
export default {
	props:['wizardTitle', 'account', 'brand'],
	data:
		function () {
			return {
				    CONSTANTS:
					{
						LOGIN_TXT : this.$gettext('Enter your %{s} login and password'),
						VALIDATE_ERROR : this.$gettext('Unable to validate')
					},
					hideUserPwd:true,
					loginTxt:''
			}
		},
	validations: {
		account: {
			name: {
				required,
				email
			},
			pwd: {
				required
			},
		}
	},
	created: function () {
		this.loginTxt = this.$gettextInterpolate(this.CONSTANTS.LOGIN_TXT, {s: this.brand.displayName});
	},
	methods: {
		getBrandSrc: function (brand) {
			return (brand.images && brand.images.default ? Global.getBackendUrl()+brand.images.default:brand.imagePath);
		},
		login: function(){
			if(this.$v.$invalid)
			{
			  this.eventHub.$emit('displayAlert', this.CONSTANTS.VALIDATE_ERROR);
			  return false;
			}
			this.eventHub.$emit('setWizardUser', this.user);
			this.eventHub.$emit('nextWizardStep');
		}
  }
}
