export default {

    /**
     * Returns a random number between min (inclusive) and max (exclusive)
     */
    
    getRandomArbitrary: function(min, max) {
        return Math.random() * (max - min) + min;
    },

    /**
     * Returns a random integer between min (inclusive) and max (inclusive).
     * The value is no lower than min (or the next integer greater than min
     * if min isn't an integer) and no greater than max (or the next integer
     * lower than max if max isn't an integer).
     * Using Math.round() will give you a non-uniform distribution!
     */

    getRandomInt: function(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    convertHex: function(number) {
        if( number==='' || number===undefined  || number===null )return '';
        var n = '00';
        if(typeof number == 'string')
        {
            number = number.replace('0x','');
            var re = /[0-9A-Fa-f]+$/g;
            if(re.test(number))
            {
                n = number;
            }
        }
        else
        {
            n = number.toString(16);
        }
        if(n.length<2)
        {
            n = n.padStart(2, '0');
        }
        return n.toUpperCase();
    },

    checkHex: function(str) {
        var re = /[0-9A-Fa-f]+$/g;
        return re.test(str);
    },

    substract: function(n1, n2) {
        if(typeof n1 == 'number' && typeof n2 == 'number' )
        {
            return (n1 - n2);
        }
        return false;
    },

    convertKelvinToHex: function(value, min, max){
        var delta = (parseInt(max) - parseInt(min));
        var deltaValue = (parseInt(value) - parseInt(min));
        var percent = Math.round((deltaValue*100/delta));
        return this.convertHex(percent);
    },

    convertHexToKelvin: function(value, min, max){

        var delta = (parseInt(max) - parseInt(min));
        var kelvin = (parseInt(value,16) * delta / 100) + parseInt(min);
        return kelvin;
    },

    convertBytetoBits: function(byte)
    {
        return parseInt(byte, 16).toString(2).padStart(8, '0');
    },

}