import CordovaSocket from 'common/classes/CordovaSocket.js';
import DeviceResource from 'common/services/resources/device.js'
import UserResource from 'common/services/resources/user.js'
import VdpResource from 'common/services/resources/vdp.js'
import CommonResource from 'common/services/resources/common.js'
import Language from 'common/services/language.js'
import StringService from 'common/services/string.js'
import StorageService from 'common/services/storage.js'
import CordovaService from 'common/services/cordova.js'
import LinkwilPluginService from 'common/services/cordova/linkwilPlugin.js'
import LogService from 'common/services/log.js'
import DeleteModal from 'common/components/delete-modal/delete-modal.vue'
import CloudSpinner from 'common/components/cloud-spinner/cloud-spinner.vue'
import QrcodeVue from 'qrcode.vue'

import axios from 'axios'

import EspPlugin from 'common/classes/cordova/plugins/EspPlugin.js'

const EXECUTE_TIMEOUT = 5000;

const TRY_NUMBER = 3;

const SOCKET_SENDING_MESSAGE_TIMEOUT = 1000;

const SOCKET_TRY_TIMEOUT = 5000;

// Duration before display of the "yes / no" popup to confirm that the LED flashes quickly in smart connect
const SMART_CONFIG_TIMEOUT = 7000;

// Duration before checking the association of the device from the BE
const CHECK_DEVICE_ASSOCIATED = 5000;

// Maximum waiting time for the UDP broadcast install payload
const BROADCAST_UDP_INSTALL_PAYLOAD_TIMEOUT = 40000;

// Maximum wait time for Hello payload UDP / MQTT
const EVENT_INFOS_TIMEOUT = 30000;

// Main maximum installation time
const MAIN_INSTALLATION_TIMEOUT = 180000;

// Maximum duration of TCP communication between the app and the device
const SOCKET_CLOSE_TIMEOUT = 15000;

// Duration before retrying in the TCP socket if still no reception of the ACK
const SOCKET_WRITE_TIMEOUT = 5000;

// Duration before new API call to find out if the device is associated
const ASSOCIATION_TIMEOUT = 5000;

// Maximum number of attempts to call the API to find out if the device is associated
const ASSOCIATION_TRY_NUMBER = 12;

// Maximum number of attempts to retrieve the SSID of the user's wifi
const RECONNECTING_WIFI_TRY_NUMBER = 80;

// Duration before retry to retrieve the SSID of the user's wifi
const RECONNECTING_WIFI_TIMEOUT = 500;

// Maximum number of tries to ensure that the application does not have ip broadcast to null
const UDP_RESTART_TRY_NUMBER = 12;

// Duration before new test to ensure that the application does not have ip broadcast to null
const UDP_RESTART_TIMEOUT = 500;

// Maximum number of attempts to connect to the device's wifi in smart AP
const AUTOCONNECT_TRY_NUMBER = 10;

// Duration before new test to connect to the device's wifi in smart AP
const AUTOCONNECT_TIMEOUT = 500;

// Duration before new sending of a payload ("action": "get_info") to the device
const GET_INFO_MODULE_TIMEOUT = 500;

// Maximum number of attempts to connect to the device's wifi in smart AP
const SOCKET_RESTART_TRY_NUMBER = 5;

const PLUGIN_RETRY_ERRORS = ['START_SMART_CONFIG_ERROR','SMART_CONFIG_SDK_ERROR'];

import {UNABLE_ADD_DEVICE_TXT} from 'common/constants/global'
import { consoleError } from 'vuetify/es5/util/console';

export default {
	props: 
	{
		txt: {
			type: String
		},
		steps:{
			type: Array,
			default:[]
		},
		lastStep:{
			type: Object
		},
		model: {
			type: Object
		},
		submodel:{
			type: Object
		},
		installType:{
			type: String
		},
		installObject: {
			type: Object
		}
	},
	components:{QrcodeVue,DeleteModal,CloudSpinner},
	data:
		function () {
			return {
				CONSTANTS:
				{
					TAG: 'WIFI-INSTALL-ASSOC'
				},
				stepNumber: 1,

				cordovaSocket: new CordovaSocket(),
				socketCommunicationTimeout: undefined,
				stepTimeout: undefined,
				sendingMessageTimeout: undefined,
				launchRestartProcessTimeout: undefined,
				cordovaSocketErrorTimeout: undefined,
				cordovaSocketWriteTimeout: undefined,
				cordovaSocketOnDataReceived: false,

				cancelSource:undefined,
				nbTries: 0,
				nbSocketTries: 0,
				beCreated: false,
				region: {},
				qrcodeNext : false,

				plugin: null,
				smartConfigConfirm: false,

				broadcastReceived: false,
				broadcastTimeout: undefined,

				helloPayloadReceived: false,
				helloPayloadTimeout: undefined,

				checkDeviceAssociatedTimeout: undefined,

				displaySmartConfigConfirmTimeout: undefined,

				autoreconnectTimeout: undefined,

				getInfoModuleTimeout: undefined,

				nbRestartUDPTries: 0,
				clearProcessIsExecuted: false,

				currentInstallType: ''

			}
	},

	beforeDestroy () {
		LogService.consoleLogTag(this.CONSTANTS.TAG,'beforeDestroy');
		this.clearProcess(true);
		this.beCreated = false;
		this.eventHub.$off('udpServerPlugin_onError',this.onRestartUDPError);
		if(this.plugin)
		{
			this.eventHub.$off('EspPlugin_onSmartConfigSuccess', this.onPluginSuccess);
			this.eventHub.$off('EspPlugin_onSmartConfigFailure', this.onPluginFailure);
			this.eventHub.$off('EspPlugin_onError', this.onPluginFailure);
			this.plugin.stopSmartConfig();
		}
		if(this.installObject.device.uid && this.installObject.device.uid!='')
		{
			this.eventHub.$off('device_event_infos_'+this.installObject.device.uid.toLowerCase(),this.onDeviceEventInfosReceived);
			this.eventHub.$off('device_event_install_'+this.installObject.device.uid.toLowerCase(),this.onDeviceEventInstallReceived);
		}
	},	

	created: function () {
		LogService.consoleLogTag(this.CONSTANTS.TAG,'created');
		var _this = this;
		this.beCreated = true;
		this.currentInstallType = JSON.parse(JSON.stringify(this.installType));
		//FOR DEV PORTAL TEST
		this.region = Language.getRegionJson();
		LogService.consoleLogTag(this.CONSTANTS.TAG,'created','installType',this.installType);
		
		this.eventHub.$on('udpServerPlugin_onError',this.onRestartUDPError);

		if(this.installObject.device.uid && this.installObject.device.uid!='')
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'created','device_event_infos_'+this.installObject.device.uid.toLowerCase());
			this.eventHub.$on('device_event_infos_'+this.installObject.device.uid.toLowerCase(),this.onDeviceEventInfosReceived);
			LogService.consoleLogTag(this.CONSTANTS.TAG,'created','device_event_install_'+this.installObject.device.uid.toLowerCase());
			this.eventHub.$on('device_event_install_'+this.installObject.device.uid.toLowerCase(),this.onDeviceEventInstallReceived);
		}

		
		if(this.installType != 'qrcode')
		{
			this.eventHub.$emit('setDisplayPrevious', false);
			if(this.installType == 'ap')
			{
				if(this.region.region=='sandbox')
				{
					this.executeDevPortal();
				}
				else
				{
					/*if(this.model.name=='smart_camera')
					{
						this.executeSmartCamera();
					}
					else
					{*/
						this.associate();
					//}
					
				}
			}
			else if(this.installType == 'smart_ap')
			{
				clearTimeout(this.broadcastTimeout);
				this.broadcastTimeout = setTimeout(() => {
					_this.eventHub.$emit('retryWifiInternalInstall', 'ap');
				}, BROADCAST_UDP_INSTALL_PAYLOAD_TIMEOUT);
				this.executeAutoconnect();
			}
			else if(this.installType == 'audio')
			{
				try{
					LogService.consoleLogTag(this.CONSTANTS.TAG,'created','LinkwilPluginService.startAudioSetup - this.installObject',this.installObject);
					LogService.consoleLogTag(this.CONSTANTS.TAG,'created','LinkwilPluginService.startAudioSetup - this.region',this.region);
					LinkwilPluginService.startAudioSetup(this.installObject,this.region);
					this.executeRegistringOnCloud();
				}
				catch(e)
				{
					LogService.consoleLogTag(this.CONSTANTS.TAG,'created','LinkwilPluginService.startAudioSetup - error',e);
				}		
			}
			else if(this.installType == 'smart_config')
			{
				this.initSmartConfig();	
				this.startSmartConfig();
			}
			this.executeSocketCommunicationTimeout();
		}

  },
	methods: {

		executeDevPortal: function(){;
			var _this = this;
			UserResource.getAssocCode().then(
				function(response) {
					_this.installObject.associationToken=response.data.associationToken;
					DeviceResource.registration(_this.model.name,_this.model.systems[0],response.data.associationToken, _this.submodel.name).then(
						function(response) {
							_this.executeRegistringOnCloud();
					}).catch(
						function() {
					});
			}).catch(
				function() {
					_this.installObject.associationToken='';
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS.ASSOCIATION_TOKEN_ERROR);
					_this.eventHub.$emit('setValidation', false);
					_this.eventHub.$emit('setInternalMainStep', 6);
			});
		},

		executeSmartCamera: function(){;
			var _this = this;
			UserResource.getAssocCode().then(
				function(response) {
					_this.installObject.associationToken=response.data.associationToken;
					_this.associate();
			}).catch(
				function() {
					_this.installObject.associationToken='';
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS.ASSOCIATION_TOKEN_ERROR);
					_this.eventHub.$emit('setValidation', false);
					_this.eventHub.$emit('setInternalMainStep', 6);
			});
		},

		associate: function()
		{
			var _this = this;
			if(this.$parent.checkIfV2Install(this.submodel))
			{
				clearTimeout(this.broadcastTimeout);
				this.broadcastTimeout = setTimeout(() => {
					_this.associateFailed();
				}, BROADCAST_UDP_INSTALL_PAYLOAD_TIMEOUT);
			}
			this.executeWifiDeviceConnection();
		},

		executeAutoconnect : function(){
			var _this = this;
			LogService.consoleLogTag(this.CONSTANTS.TAG,'executeAutoconnect');
			this.steps[this.stepNumber-1].loading = true;
			window.WifiWizard.scan().then(
				function(lists){
					if(lists.length>0)
					{
						var reg = new RegExp('^'+_this.submodel.name);
						LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.scan() - success - _this.submodel.name',_this.submodel.name);
						LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.scan() - success - lists',lists);
						var w = _find(lists, function(l) { 
							return reg.test(l.SSID);
						});
						LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.scan() - success - wifi',w);
						if(w)
						{
							LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.scan() - success - wifi',w.SSID);
							window.WifiWizard.connect(w.SSID).then(function(){
								LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.connect(w.SSID) - success - w.SSID',w.SSID);
								window.WifiWizard.getConnectedSSID().then(
									function(ssid){
										ssid = (StringService.regexQuote(ssid)?ssid.substr(1, ssid.length-2):ssid);
										LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.getConnectedSSID() - success - ssid',ssid);
										
										var reg = new RegExp('^'+_this.submodel.name);

										if(reg.test(ssid))
										{
											_this.installObject.plug.ssid = ssid;
											_this.executeWifiDeviceConnection();
										}
										else
										{
											LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.getConnectedSSID() - reg.test(ssid) - error',ssid);
											clearTimeout(_this.autoreconnectTimeout);
											if(_this.clearProcessIsExecuted)return false;
											_this.autoreconnectTimeout = setTimeout(function() {
												_this.executeAutoconnect();
											},AUTOCONNECT_TIMEOUT);
											//_this.executeTimeout('executeAutoconnect',AUTOCONNECT_TIMEOUT,AUTOCONNECT_TRY_NUMBER);
										}
									}).catch(
										function(e){
											LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.getConnectedSSID() - error',e);
											clearTimeout(_this.autoreconnectTimeout);
											if(_this.clearProcessIsExecuted)return false;
											_this.autoreconnectTimeout = setTimeout(function() {
												_this.executeAutoconnect();
											},AUTOCONNECT_TIMEOUT);
											//_this.executeTimeout('executeAutoconnect',AUTOCONNECT_TIMEOUT,AUTOCONNECT_TRY_NUMBER);
									});	
							}).catch(
								function(e){
									LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.connect(w.SSID) - error',e);
									clearTimeout(_this.autoreconnectTimeout);
									if(_this.clearProcessIsExecuted)return false;
									_this.autoreconnectTimeout = setTimeout(function() {
										_this.executeAutoconnect();
									},AUTOCONNECT_TIMEOUT);
									//_this.executeTimeout('executeAutoconnect',AUTOCONNECT_TIMEOUT,AUTOCONNECT_TRY_NUMBER);
								});
						}
						else
						{
							LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.scan() - wifi - success - error',w);
							clearTimeout(_this.autoreconnectTimeout);
							if(_this.clearProcessIsExecuted)return false;
							_this.autoreconnectTimeout = setTimeout(function() {
								_this.executeAutoconnect();
							},AUTOCONNECT_TIMEOUT);
							//_this.executeTimeout('executeAutoconnect',AUTOCONNECT_TIMEOUT,AUTOCONNECT_TRY_NUMBER);
						}

					}
					else
					{
						LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.scan() - lists - error',lists);
						clearTimeout(_this.autoreconnectTimeout);
						if(_this.clearProcessIsExecuted)return false;
						_this.autoreconnectTimeout = setTimeout(function() {
							_this.executeAutoconnect();
						},AUTOCONNECT_TIMEOUT);
						//_this.executeTimeout('executeAutoconnect',AUTOCONNECT_TIMEOUT,AUTOCONNECT_TRY_NUMBER);
					}
				}).catch(
				function(e){
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeAutoconnect','window.WifiWizard.scan() - error',e);
					clearTimeout(_this.autoreconnectTimeout);
					if(_this.clearProcessIsExecuted)return false;
					_this.autoreconnectTimeout = setTimeout(function() {
						_this.executeAutoconnect();
					},AUTOCONNECT_TIMEOUT);
					//_this.executeTimeout('executeAutoconnect',AUTOCONNECT_TIMEOUT,AUTOCONNECT_TRY_NUMBER);
				});
		},
		
		executeWifiDeviceConnection: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'executeWifiDeviceConnection');
			this.steps[this.stepNumber-1].loading = true;
			LogService.consoleLogTag(this.CONSTANTS.TAG,'executeWifiDeviceConnection', 'this.installObject.plug.ssid',this.installObject.plug.ssid);
			if(this.installObject.plug.ssid != '')
			{
				if(!this.$parent.checkIfV2Install(this.submodel))
				{
					clearTimeout(this.broadcastTimeout);
					this.nextStep('executeSendingInformation');
				}
				else
				{
					this.nbRestartUDPTries=0;
					//CordovaService.restartUdp(this.eventHub);
				}
			}
			else
			{
				LogService.consoleLogTag(this.CONSTANTS.TAG,'executeWifiDeviceConnection');
				return this.associateFailed();
			}
		},
		
		executeSendingInformation: function()
		{
			var _this = this;
			LogService.consoleLogTag(this.CONSTANTS.TAG,'executeSendingInformation');
			_this.nbTries = 0;
			setTimeout(function() {
				clearTimeout(_this.launchRestartProcessTimeout);
				_this.launchRestartProcessTimeout = setTimeout(() => {
					_this.associateFailed();
				}, SOCKET_CLOSE_TIMEOUT);
				_this.initSocket();
			},SOCKET_TRY_TIMEOUT);
		},

		initSocket: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'initSocket');
			var _this = this;
			//console.logthis.nbSocketTries+'=='+this.CONSTANTS.SOCKET_TRY_NUMBER);
			/*if(this.nbSocketTries==this.CONSTANTS.SOCKET_TRY_NUMBER)
			{
				return this.associateFailed();
			}*/
			
			//this.nbSocketTries++;
			//this.cordovaSocket = new CordovaSocket();
			if(!this.cordovaSocket.socketExists())
			{
				LogService.consoleLogTag(_this.CONSTANTS.TAG,'initSocket','if(!this.cordovaSocket.socketExists())');
				return this.associateFailed();
			}
			this.cordovaSocketOnDataReceived = false;
			this.cordovaSocket.onData(
				function(data)
				{
					data = String.fromCharCode.apply(null, data).trim().toLowerCase();
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'this.cordovaSocket.onData','onData - success',data+'=='+_this.submodel.name);
					if( data ==_this.submodel.name.toLowerCase() || data == 'ok')
					{
						_this.cordovaSocketOnDataReceived = true;
						_this.nbTries = 0;
						_this.clearSocketProcess();

						if(_this.$parent.checkIfV2Install(_this.submodel))
						{
							if(_this.installType == 'smart_config')
							{
								_this.nextStep('waitHelloPayload');
							}
							else
							{
								_this.nextStep('executeReconnectingWifi');
							}
							
						}
						else
						{
							_this.nextStep('executeReconnectingWifi');
						}
					}
				}
			);
			this.cordovaSocket.onError(
				function(errorMessage)
				{
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'this.cordovaSocket.onError','onError',errorMessage);
					clearTimeout(_this.cordovaSocketErrorTimeout);
					_this.cordovaSocketErrorTimeout = setTimeout(function() {
						if(!_this.cordovaSocketOnDataReceived )
						{
							LogService.consoleLogTag(_this.CONSTANTS.TAG,'this.cordovaSocket.onError','onError - cordovaSocketErrorTimeout');
							_this.nbTries++;
							_this.restartInitSocket();
						}
					}, SOCKET_TRY_TIMEOUT);
				});

			this.cordovaSocket.onClose(
				function(hasError)
				{
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'this.cordovaSocket.onClose','onClose',hasError);
				});
			this.socketOpen();
		},


		executeTimeout: function(execFunc, duration, tries)
		{
			var _this = this;
			duration = (duration==undefined?EXECUTE_TIMEOUT:duration);
			tries = (tries==undefined?TRY_NUMBER:tries);

			LogService.consoleLogTag(this.CONSTANTS.TAG,'executeTimeout','execFunc',execFunc);
			this.nbTries++;
			LogService.consoleLogTag(this.CONSTANTS.TAG,'executeTimeout','this.nbTries==TRY_NUMBER',this.nbTries+'=='+tries);
			if(this.nbTries==tries)
      		{
				if(execFunc == 'executeAutoconnect')
				{
					return _this.eventHub.$emit('retryWifiInternalInstall', 'ap');
				}
				else
				{
					return _this.associateFailed();
				}
			}		
			clearTimeout(_this.stepTimeout);
			_this.stepTimeout = setTimeout(() => {
				LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeTimeout','stepTimeout',execFunc);
				_this[execFunc]();
			}, duration);
		},

		socketOpen: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'socketOpen','this.installObject.plug.host',this.installObject.plug.host);
			LogService.consoleLogTag(this.CONSTANTS.TAG,'socketOpen','this.installObject.plug.port',this.installObject.plug.port);
			var _this = this;
			this.cordovaSocket.open(
				this.installObject.plug.host,
				this.installObject.plug.port,
				function() {
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'socketOpen','this.cordovaSocket.open - success');
					clearTimeout(_this.sendingMessageTimeout);
					_this.sendingMessageTimeout = setTimeout(function() {
						LogService.consoleLogTag(_this.CONSTANTS.TAG,'socketOpen','this.cordovaSocket.open - success - sendingMessageTimeout');
						_this.sendSocketMessage();
					}, SOCKET_SENDING_MESSAGE_TIMEOUT);
				},
				function(errorMessage) {
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'socketOpen','this.cordovaSocket.open - error',errorMessage);
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'socketOpen','this.cordovaSocket.open - error - _this.beCreated',_this.beCreated);
					//console.logerrorMessage);
					//console.log_this.beCreated);
					if(_this.beCreated)
					{
						clearTimeout(_this.cordovaSocketErrorTimeout);
						_this.cordovaSocketErrorTimeout = setTimeout(function() {
							LogService.consoleLogTag(_this.CONSTANTS.TAG,'this.cordovaSocket.open','onError - cordovaSocketErrorTimeout');
							_this.nbTries++;
							_this.restartInitSocket();
						}, SOCKET_TRY_TIMEOUT);
					}
			});
		},

		sendSocketMessage: function(){
			var _this = this;
			var msg = {
				ssid : this.installObject.wifi.ssid,
				password: this.installObject.wifi.password,
				associationToken: this.installObject.associationToken,
				region:this.region.region,
				api: 
				{
					dns: Language.getRegionDns(this.region)
				},
				timezone: this.moment.tz.guess()
			}
			
			if(this.model.name =='vdp')
			{
				msg.device = this.installObject.device;
			}
			LogService.consoleLogTag(_this.CONSTANTS.TAG,'sendSocketMessage','msg',msg);
			if(!this.cordovaSocket.write(JSON.stringify(msg),
			function() {
				LogService.consoleLogTag(_this.CONSTANTS.TAG,'sendSocketMessage','this.cordovaSocket.write - success');
			},
			function(errorMessage) {
				LogService.consoleLogTag(_this.CONSTANTS.TAG,'sendSocketMessage','this.cordovaSocket.write - error',errorMessage);

			}))
			{
				LogService.consoleLogTag(_this.CONSTANTS.TAG,'sendSocketMessage','if(!this.cordovaSocket.write)');
				this.nbTries++;
				this.restartInitSocket();
			}
			else{
				clearTimeout(_this.cordovaSocketWriteTimeout);
				/*if(!_this.cordovaSocketOnDataReceived)
				{*/
					_this.cordovaSocketWriteTimeout = setTimeout(function() {
						LogService.consoleLogTag(_this.CONSTANTS.TAG,'sendSocketMessage','this.cordovaSocket.write - cordovaSocketWriteTimeout');
						_this.sendSocketMessage();
					}, SOCKET_WRITE_TIMEOUT);
				//}
			}
		},

		executeReconnectingWifi: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'executeReconnectingWifi');
			clearTimeout(this.socketCommunicationTimeout);
			var _this = this;
			window.WifiWizard.getConnectedSSID().then(
				function(ssid){
					var reg = new RegExp('^'+_this.submodel.name);
					if(!reg.test(ssid))
					{
						_this.nbTries = 0;
						LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeReconnectingWifi','executeReconnectingWifi - success');
						_this.nbRestartUDPTries=0;
						//CordovaService.restartUdp(_this.eventHub);
						clearTimeout(_this.plugWifiTimeout);
						_this.plugWifiTimeout = setTimeout(() => {
							LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeReconnectingWifi','plugWifiTimeout',_this.submodel.name);
							if(_this.$parent.checkIfV2Install(_this.submodel))
							{
								_this.waitHelloPayload();
							}
							else
							{
								_this.executeRegistringOnCloud();
							}
						}, 1);
					}
					else
					{
						_this.executeTimeout('executeReconnectingWifi',RECONNECTING_WIFI_TIMEOUT, RECONNECTING_WIFI_TRY_NUMBER);
					}
				}).catch(
				function(e){
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeReconnectingWifi','executeReconnectingWifi - error',e);
					_this.executeTimeout('executeReconnectingWifi',RECONNECTING_WIFI_TIMEOUT, RECONNECTING_WIFI_TRY_NUMBER);
				});
		},
		
		executeRegistringOnCloud: function()
		{
			
			var _this = this;
			var json = {
						  'timezone': this.moment.tz.guess()
			};

			json.associationToken = this.installObject.associationToken;

			/*if(this.installType == 'smart_config' && this.installObject.device.uid)
			{
				json.mac = this.installObject.device.uid.toLowerCase();
			}
			else
			{
				json.associationToken = this.installObject.associationToken;
			}*/
			LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','json',json);
			var CancelToken = axios.CancelToken;
			this.cancelSource = CancelToken.source(); 
			LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','this.model.name',json);
			LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','this.model.systems[0]',_this.model.systems[0]);
			LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','this.cancelSource',_this.cancelSource);
			DeviceResource.associate(this.model.name,this.model.systems[0],json,this.cancelSource,this.eventHub).then(
			  function(response) {
				LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','DeviceResource.associate - success',response);  
				_this.nbTries = 0;
				_this.eventHub.$emit('setDeviceId',response.data.id);
				_this.eventHub.$emit('setDeviceObj',response.data);
				_this.eventHub.$emit('addAssociateDevice', response.data);
				if(_this.submodel.name =='vdp_100')
				{
					var json = 
					{
						'deviceUid': _this.installObject.device.uid,
						/*'devicePassword': _this.installObject.device.password*/
						'devicePassword': _this.installObject.associationToken
					}
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','DeviceResource.associate - success - json',json);  
					VdpResource.changeCredentials(response.data.id,json).then(
					function(response) {
						LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','DeviceResource.associate - VdpResource.changeCredentials - success',response);  
					  _this.finishStep();
					}).catch(
						function(error) {
							LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','DeviceResource.associate - VdpResource.changeCredentials - error',error); 
							_this.associateFailed();
						});
				}
				else
				{
					_this.finishStep();
				}	
			}).catch(
			  function(error) {
					LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeRegistringOnCloud','DeviceResource.associate - error',error); 
					if(error.message=='Operation canceled.')return false;
					if(error == 'associationFailed' && !_this.$parent.checkIfV2Install(_this.submodel)){
						_this.associateFailed();
					}
					else{
						_this.executeTimeout('executeRegistringOnCloud',ASSOCIATION_TIMEOUT, ASSOCIATION_TRY_NUMBER);
					}
			  });
		},

		nextStep: function(execFunc)
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'nextStep','execFunc',execFunc); 
			this.steps[this.stepNumber-1].loading = false;
			this.stepNumber++;
			this.steps[this.stepNumber-1].loading = true;
			LogService.consoleLogTag(this.CONSTANTS.TAG,'nextStep','execFunc2',execFunc); 
			if(execFunc)
			{
				LogService.consoleLogTag(this.CONSTANTS.TAG,'nextStep','execFunc3',execFunc); 
				LogService.consoleLogTag(this.CONSTANTS.TAG,'nextStep','execFunc - EXEC',execFunc); 
				this[execFunc]();
			}
		},

		finishStep: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'finishStep'); 
			this.steps[this.stepNumber-1].loading = false;
			this.clearProcess(true);
			this.eventHub.$emit('nextInternalMainStep');
			this.eventHub.$emit('nextHeaderStep')
		},

		detectedError: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'detectedError'); 
			this.steps[this.stepNumber-1].isValid = false;
			this.steps[this.stepNumber-1].loading = false;
			return false;
		},

		restartInitSocket: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'restartInitSocket'); 
			LogService.consoleLogTag(this.CONSTANTS.TAG,'restartInitSocket','this.nbTries==SOCKET_RESTART_TRY_NUMBER',this.nbTries+'=='+SOCKET_RESTART_TRY_NUMBER);
			if(this.nbTries == SOCKET_RESTART_TRY_NUMBER)
			{
				LogService.consoleLogTag(this.CONSTANTS.TAG,'restartInitSocket','associateFailed');
				this.associateFailed();
			}
			else
			{
				this.clearSocketProcess();
				this.initSocket();
			}
		},

		clearSocketProcess: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'clearSocketProcess'); 

			try{
				LogService.consoleLogTag(this.CONSTANTS.TAG,'clearSocketProcess','this.cordovaSocket.close()'); 
				this.cordovaSocket.close();
			}
			catch(e)
			{
				LogService.consoleLogTag(this.CONSTANTS.TAG,'clearSocketProcess','this.cordovaSocket.close() - error',e); 
			}
			clearTimeout(this.stepTimeout);
			clearTimeout(this.sendingMessageTimeout);
			clearTimeout(this.launchRestartProcessTimeout);
			clearTimeout(this.cordovaSocketErrorTimeout);
			clearTimeout(this.cordovaSocketWriteTimeout);
		},

		clearProcess: function(clearAll)
		{
			this.clearProcessIsExecuted = true;	
			LogService.consoleLogTag(this.CONSTANTS.TAG,'clearProcess'); 
			//this.cordovaSocket.shutdownWriteAndClose();
			try{
				LogService.consoleLogTag(this.CONSTANTS.TAG,'clearProcess','this.cordovaSocket.close()'); 
				this.cordovaSocket.close();
			}
			catch(e)
			{
				LogService.consoleLogTag(this.CONSTANTS.TAG,'clearProcess','this.cordovaSocket.close() - error',e); 
			}
			LogService.consoleLogTag(this.CONSTANTS.TAG,'this.currentInstallType',this.currentInstallType); 
			if(this.currentInstallType == 'audio')
			{
				try{
					LogService.consoleLogTag(this.CONSTANTS.TAG,'clearProcess','LinkwilPluginService.stopAudioSetup()'); 
					LinkwilPluginService.stopAudioSetup();
				}
				catch(e)
				{
					LogService.consoleLogTag(this.CONSTANTS.TAG,'clearProcess','LinkwilPluginService.stopAudioSetup() - error',e); 
				}
			}

			if(this.cancelSource)
			{
				LogService.consoleLogTag(this.CONSTANTS.TAG,'clearProcess','Operation canceled.'); 
				this.cancelSource.cancel('Operation canceled.');
			}
			clearTimeout(this.stepTimeout);
			clearTimeout(this.sendingMessageTimeout);
			clearTimeout(this.launchRestartProcessTimeout);
			clearTimeout(this.cordovaSocketErrorTimeout);
			clearTimeout(this.cordovaSocketWriteTimeout);
			clearTimeout(this.displaySmartConfigConfirmTimeout);

			clearTimeout(this.broadcastTimeout);
			clearTimeout(this.helloPayloadTimeout);
			clearTimeout(this.checkDeviceAssociatedTimeout);
			clearTimeout(this.autoreconnectTimeout);
			
			clearInterval(this.getInfoModuleTimeout);

			this.broadcastReceived = false;
			this.helloPayloadReceived = false;
			//this.cordovaSocketOnDataReceived = false;

			if(clearAll)
			{
				clearTimeout(this.socketCommunicationTimeout);
			}

			this.qrcodeNext = false;
		},

		associateFailed: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'associateFailed'); 
			this.nbTries = 0;
			//this.nbSocketTries = 0;
			this.clearProcess(true);
			this.eventHub.$emit('setValidation', false);
			this.eventHub.$emit('setInternalMainStep', 6);
			return false;
		},

		executeSocketCommunicationTimeout: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'executeSocketCommunicationTimeout');
			var _this = this;
			this.socketCommunicationTimeout = setTimeout(() => {
				LogService.consoleLogTag(_this.CONSTANTS.TAG,'executeSocketCommunicationTimeout','socketCommunicationTimeout');
				return _this.associateFailed();
			}, MAIN_INSTALLATION_TIMEOUT);
		},

		/**
		 * QR Code Installation
		 */
		qrCodeInstallationNext: function()
		{
			this.qrcodeNext = true;
			LogService.consoleLogTag(this.CONSTANTS.TAG,'qrCodeInstallationNext');
			this.eventHub.$emit('setDisplayPrevious', false);
			this.executeRegistringOnCloud();
			this.executeSocketCommunicationTimeout();
		},

		/**
		 * SMART CONFIG Installation
		 */

		initSmartConfig: function()
		{
			this.plugin = new EspPlugin(this.eventHub,this.installObject.wifi.ssid,this.installObject.wifi.bssid,this.installObject.wifi.password);
			this.eventHub.$on('EspPlugin_onSmartConfigSuccess', this.onPluginSuccess);
			this.eventHub.$on('EspPlugin_onSmartConfigFailure', this.onPluginFailure);
			this.eventHub.$on('EspPlugin_onError', this.onPluginFailure);
			this.displaySmartConfigConfirm();
		},

		displaySmartConfigConfirm: function()
		{
			var _this = this;
			clearTimeout(_this.displaySmartConfigConfirmTimeout);
			_this.displaySmartConfigConfirmTimeout = setTimeout(() => {
				_this.smartConfigConfirm = true;
			}, SMART_CONFIG_TIMEOUT);
		},

		startSmartConfig: function()
		{
			var _this = this;
			this.steps[this.stepNumber-1].loading = true;
			if(this.plugin)
			{

				this.plugin.startSmartConfig();
				//this.nextStep('executeRegistringOnCloud');
				clearTimeout(this.broadcastTimeout);
				this.broadcastTimeout = setTimeout(() => {
					_this.eventHub.$emit('retryWifiInternalInstall', 'smart_ap');
				}, BROADCAST_UDP_INSTALL_PAYLOAD_TIMEOUT);
			}
			else
			{
				this.onPluginFailure();
			}
		},

		confirmSmartConfig: function(b)
		{
			if(b)
			{
				this.smartConfigConfirm = false;
				if(this.plugin)
				{
					this.plugin.stopSmartConfig();
				}
			}
			else{
				this.eventHub.$emit('retryWifiInternalInstall','smart_ap');
			}
		},


		waitHelloPayload: function()
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'waitHelloPayload');
			var _this = this;
			clearTimeout(this.helloPayloadTimeout);
			clearTimeout(this.checkDeviceAssociatedTimeout);
			LogService.consoleLogTag(this.CONSTANTS.TAG,'waitHelloPayload','getInfosModule',this.installObject.device.id);

			CommonResource.getInfosModule(_this.eventHub, _this.installObject.device.id, _this.model);
			
			this.getInfoModuleTimeout = setInterval(() => {
				LogService.consoleLogTag(this.CONSTANTS.TAG,'waitHelloPayload','getInfosModule - getInfoModuleTimeout',this.installObject.device.id);
				CommonResource.getInfosModule(_this.eventHub, _this.installObject.device.id, _this.model);
			}, GET_INFO_MODULE_TIMEOUT);

			this.checkDeviceAssociatedTimeout = setTimeout(() => {
				LogService.consoleLogTag(this.CONSTANTS.TAG,'waitHelloPayload','executeRegistringOnCloud');
				_this.executeRegistringOnCloud();
			}, CHECK_DEVICE_ASSOCIATED);

			this.helloPayloadTimeout = setTimeout(() => {
				LogService.consoleLogTag(this.CONSTANTS.TAG,'waitHelloPayload','associateFailed');
				_this.associateFailed();
			}, EVENT_INFOS_TIMEOUT);
		},

		onPluginFailure: function(json)
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'onPluginFailure','json',json);
			if(json && json.error != 'SMART_CONFIG_SDK_ERROR')
			{
				this.eventHub.$emit('displayAlert', UNABLE_ADD_DEVICE_TXT+(json && json.error?' ('+json.error+')':''));
				this.associateFailed();
			}
		},

		onPluginSuccess: function(json)
		{
			this.plugin.stopSmartConfig();
			/*LogService.consoleLogTag(this.CONSTANTS.TAG,'onPluginSuccess','json',json);
			this.nbTries = 0;
			this.nextStep('executeRegistringOnCloud');*/
		},

		onDeviceEventInstallReceived: function(module)
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'onDeviceEventInstallReceived','module',module);
			LogService.consoleLogTag(this.CONSTANTS.TAG,'onDeviceEventInstallReceived','this.broadcastReceived',this.broadcastReceived);
			if(module.device.socket==undefined || (module.device.socket && (!module.device.socket.ip || !module.device.socket.port)))
			{
				this.associateFailed();
			}
			if(this.broadcastReceived)return false;
			clearTimeout(this.broadcastTimeout);
			this.broadcastReceived = true;
			this.installObject.device.id = module.id;
			this.installObject.plug.host = module.device.socket.ip;
			this.installObject.plug.port = module.device.socket.port;
			this.nextStep('executeSendingInformation');
			//this.executeSendingInformation();

		},
		
		onDeviceEventInfosReceived: function(module)
		{
			LogService.consoleLogTag(this.CONSTANTS.TAG,'onDeviceEventInfosReceived','module',module);
			LogService.consoleLogTag(this.CONSTANTS.TAG,'onDeviceEventInfosReceived','this.helloPayloadReceived',this.helloPayloadReceived);

			var associationToken = (module.device && module.device.associationToken?module.device.associationToken.toLowerCase():'');
			
			if(this.helloPayloadReceived || (associationToken != this.installObject.associationToken.toLowerCase()))return false;
			clearTimeout(this.helloPayloadTimeout);
			clearTimeout(this.checkDeviceAssociatedTimeout);
			clearTimeout(this.stepTimeout);
			this.helloPayloadReceived = true;
			this.eventHub.$emit('setDeviceId',module.id);
			var deviceObj = {
				"id": module.id,
				"name": '',
				"icon": null,
				"notificationLevel": 'INFO',
				"alert" : []
			}
			LogService.consoleLogTag(this.CONSTANTS.TAG,'onDeviceEventInfosReceived','deviceObj',deviceObj);
			this.eventHub.$emit('setDeviceObj',deviceObj);
			this.finishStep();
		},


		onRestartUDPError: function()
		{
			var _this = this;
			LogService.consoleLogTag(this.CONSTANTS.TAG,'onRestartUDPError');
			this.nbRestartUDPTries++;
			LogService.consoleLogTag(this.CONSTANTS.TAG,'onRestartUDPError','this.nbRestartUDPTries==UDP_RESTART_TRY_NUMBER',this.nbRestartUDPTries+'=='+UDP_RESTART_TRY_NUMBER);
			if(this.nbRestartUDPTries==UDP_RESTART_TRY_NUMBER)
			{
				return false;
			}		
			setTimeout(() => {
				LogService.consoleLogTag(_this.CONSTANTS.TAG,'onRestartUDPError','restartUdp');
				//CordovaService.restartUdp(_this.eventHub);
			}, UDP_RESTART_TIMEOUT);
		},
 	}
}