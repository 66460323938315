import Global from 'common/services/resources/global.js'
import LanguageService from 'common/services/language.js'

export default {
    userLang: LanguageService.getUserLanguage(),
    open: function(url,target,inApp) {
        if(inApp==undefined)inApp=false;
        if(target==undefined)target='_blank';
        if(inApp)
        {
            if(target=='_blank')target='_system';
            cordova.InAppBrowser.open(url, target);
        }
        else
        {
            window.open(url, target);
        }
    },
    openRedirectUrl: function(section,target,inApp) {
        //While the pages are just in french and english
        if(this.userLang!='fr' && this.userLang!='en'){this.userLang = 'en';}
        this.open(Global.REDIRECT_URI_PROD+'/'+section+'-'+this.userLang+'/',target,inApp);
    },
    openDownloadApp: function(target,inApp) {
        var userLang = (this.userLang=='fr'?'-'+this.userLang:'');
        this.open(Global.REDIRECT_URI_PROD+'/'+'app'+userLang+'/',target,inApp);
    },
    openSection: function(section,target,inApp) {
        this.open(Global.REDIRECT_URI_PROD+'/'+this.userLang+'/'+section,target,inApp);
    }
}
