import HPChoose from './hp-choose/hp-choose.vue';
import HPCable from './hp-cable/hp-cable.vue';
import HPWifi from './hp-wifi/hp-wifi.vue';

export default {
	props: ['model', 'submodel', 'installStep', 'prefilledMac'],
	components: {HPChoose,HPCable,HPWifi},
    data: function () {
        return {
			CONSTANTS:{
				INSTALLATION_BUTTON: this.$gettext('manual mode')
			},
            step: 'cable',
			wifi : {
						ssid: '',
						auths: '',
						freq: '',
						smntCapable: '',
						smntIncapableReason: ''
					},
			device : {
						id: '',
						model:-1
					},
			title: 'add a Home Pilot - '+this.step,
			mode : 'automatic',
			button : 'manual',
			installationTitle: '',
			installationButton: ''
        }
    },
    created: function () {
			this.eventHub.$on('setWifi', this.setWifi);
			this.eventHub.$on('setDevice', this.setDevice);
    },
	methods: {
		setWifi: function(wifi){
			this.wifi=wifi;
		},
		setDevice: function(device){
			this.device=device;
		},
		previousStep: function(){
			if(this.step == 'choose')
				this.eventHub.$emit('setShowEditor',false);
			else if(this.step == 'cable')
				this.eventHub.$emit('backStepCable');
			else if(this.step == 'wifi')
				this.eventHub.$emit('backStepWifi');
		},
		mobil: function(){
			if(window.innerWidth<=1024)
			{
				return true;
			}
		},
		
		initMode: function(mode){
			this.changeLabels(mode);
		},

		switchDesktop: function(mode){
			this.mode = (this.mode == 'automatic'?'manual':'automatic');
			this.button = (this.button == 'automatic'?'manual':'automatic');
			this.changeLabels(mode);
		},
		switchMobil: function(mode){
			if(this.button == 'manual'){
				this.step = 'choose';
				this.eventHub.$emit('setInstallStep', 100);
			}
			else {
				this.step = 'cable';
				this.mode = (this.mode == 'automatic'?'manual':'automatic');
				this.button = (this.button == 'automatic'?'manual':'automatic');
				this.changeLabels(mode);
			}
		},
		changeLabels: function(mode){
			this.installationTitle = this.$gettext('Installation : '+this.mode+' - '+mode);
			this.installationButton = this.$gettext(this.button+' mode');
		}
	}
}
