
import DeviceResource from 'common/services/resources/device.js'
import Language from 'common/services/language.js'

import axios from 'axios'

export default {
	props: 
	{
		txt: {
			type: String
		},
		deviceInstallObject: {
			type: Object
		},
		steps:{
			type: Array,
			default:[]
		},
		model: {
			type: Object
		},
		installObject: {
			type: Object
		},
		homepilot: {
			type: Object
		}
	},
	data:
		function () {
			return {
				CONSTANTS:
				{
					SOCKET_COMMUNICATION_TIMEOUT : 180000,
					SOCKET_SENDING_MESSAGE_TIMEOUT : 1000,
					SOCKET_TRY_NUMBER : 12,
					SOCKET_TRY_TIMEOUT : 5000
				},
				stepNumber: 1,
				socketCommunicationTimeout: undefined,
				stepTimeout: undefined,
				cancelSource:undefined,
				nbTries: 0,
			}
	},
	beforeDestroy () {
		this.clearProcess(true);
	},	

	created: function () {
		this.eventHub.$emit('setDisplayPrevious', false);
		this.executeRegistringOnCloud();
		var _this = this;
		this.socketCommunicationTimeout = setTimeout(() => {
			console.log('executeSendingInformation - socketCommunicationTimeout');
			return _this.associateFailed();
		}, _this.CONSTANTS.SOCKET_COMMUNICATION_TIMEOUT);
  },
	methods: {

		executeTimeout: function(execFunc)
		{
			var _this = this;
			console.log('executeTimeout');
			console.log(_this.stepTimeout);
			//console.logexecFunc);
			//console.logthis.nbTries+'=='+this.CONSTANTS.SOCKET_TRY_NUMBER);
			this.nbTries++;
			if(this.nbTries==this.CONSTANTS.SOCKET_TRY_NUMBER)
      		{
				return _this.associateFailed();
			}		
			clearTimeout(_this.stepTimeout);
			_this.stepTimeout = setTimeout(() => {
				_this[execFunc]();
			}, _this.CONSTANTS.SOCKET_TRY_TIMEOUT);
		},
		
		executeRegistringOnCloud: function()
		{
			console.log('executeRegistringOnCloud');
			console.log(this.homepilot);
			var _this = this;
			var json = {
					'identifier' : this.installObject.device.identifier,
					'bearerId' : this.homepilot.id
			};
			console.log(json);
			var CancelToken = axios.CancelToken;
			this.cancelSource = CancelToken.source(); 
			var r = Language.getRegionJson();
			DeviceResource.associate(this.model.name,this.model.systems[0],json,this.cancelSource,this.eventHub).then(
			  function(response) {
				console.log(response);
				if(response.data.device.associateStatus=='ASSOCIATED')
				{
					_this.nbTries = 0;
					_this.eventHub.$emit('setDeviceId',response.data.id);
					_this.eventHub.$emit('setDeviceObj',response.data);
					_this.eventHub.$emit('addAssociateDevice', response.data);
					_this.finishStep();
				}
				else
				{
					_this.executeTimeout('executeRegistringOnCloud');
				}

			}).catch(
			  function(error) {
					console.log('executeRegistringOnCloud - error');
					console.log(error);
					if(error.message=='Operation canceled.')return false;
					if(error == 'associationFailed'){
						_this.associateFailed();
					}
					else{
						_this.executeTimeout('executeRegistringOnCloud');
					}
			  });
		},

		finishStep: function()
		{
			console.log('finishStep');
			this.steps[this.stepNumber-1].loading = false;
			this.clearProcess(true);
			this.eventHub.$emit('nextInternalMainStep');
			this.eventHub.$emit('nextHeaderStep')
		},

		clearProcess: function(clearAll)
		{
			console.log('clearProcess');

			if(this.cancelSource)
			{
				this.cancelSource.cancel('Operation canceled.');
			}
			clearTimeout(this.stepTimeout);
			if(clearAll)
			{
				clearTimeout(this.socketCommunicationTimeout);
			}
		},

		associateFailed: function()
		{
			console.log('associateFailed');
			this.nbTries = 0;
			this.clearProcess(true);
			this.eventHub.$emit('setValidation', false);
			this.eventHub.$emit('setInternalMainStep', 6);
			return false;
		}
 	}
}