export default {
  props:['result'],
  data: function () {
    return {
    }
  },
  created: function () {
    this.eventHub.$emit('setDisplayPrevious', false);
  },
	methods: {
		next : function(status){
      if(status){
        this.eventHub.$emit('nextInternalMainStep');
        this.eventHub.$emit('nextHeaderStep');
      }
      else
        this.$parent.step = 1;
    }
	}
}
