var stringSimilarity = require('string-similarity');

export default {
    toJson: function(str) {
        if(!str) return false;
        if(typeof str === 'object') return str;
        try {
            return JSON.parse(str); 
        } catch (e) {
            console.log(e);
            return false; 
        }
    },
    toUppercaseFirstCharacter: function(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    },
    /*Compare 2 strings by removing spaces, removing accents and to lowercase 
      Use lodash _deburr to remove accents and string-similarity to Dice's Coefficient
    */
    accuratelyCompare: function(str1, str2) {
        str1 = _deburr(str1.replace(/ /g, '').toLowerCase());
        str2 = _deburr(str2.replace(/ /g, '').toLowerCase());
        return {test:stringSimilarity.compareTwoStrings(str1,str2) > 0.91,fraction:stringSimilarity.compareTwoStrings(str1,str2)};
    },

    regexIndexOf: function(str, regex, startpos) {
        var indexOf = str.substring(startpos || 0).search(regex);
        return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf;
    },

    regexQuote: function(str) {
        if(str.match(/"[^]*"/) != null)
            return true;
        else
            return false;
    },
    randomString : function(length,type) {
        var result           = '';
        var characters       = (type=='hexa'?'ABCDEF0123456789':'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789');
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
      },

    toPascalCase : function(str) {
        return str.match(/[a-z]+/gi)
        .map(function (s) {
          return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase()
        })
        .join('');
    }
}
