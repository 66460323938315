import AddServiceWizardFinish from './add-service-wizard-finish.vue'

import AddServiceWizardAlarmDetectors from './models/alarm/add-service-wizard-alarm-detectors.vue'

import AddServiceWizardCentralHeatingHeater from './models/central_heating/add-service-wizard-central-heating-heater.vue'

import AddServiceWizardAccessControlTriggers from './models/access_control/add-service-wizard-access-control-triggers.vue'

import ServiceResource from 'common/services/resources/service.js'

import ServiceAlarm from 'common/classes/ServiceAlarm.js';
import ServiceHeating from 'common/classes/ServiceHeating.js';

import ServiceAccessControl from 'common/classes/ServiceAccessControl.js';
import LocationResource from 'common/services/resources/location.js'
import { consoleError } from 'vuetify/es5/util/console'
export default {
	props: {
		plant:{
			type: Object,
			default:{}
		},
		defaultRooms:{
			type: Array,
			default:[]
		},
		service:{
			type: Object,
			default:{}
		},
		editedService:{
			type: Object,
			default:undefined
		},
		devices:{
			type: Array,
			default:[]
		},
		appConstants:{
			type: Array
		},
		user:{
			type: Object,
			default:{}
		},
		services:{
			type: Array,
			default:undefined
		},
		versionInfos:{
			type: Object,
			default:{}
		},
		capabilities:{
			type: Object,
			default: {}
		},
		models:{
			type: Object,
			default: {}
		},
		devicesByCapability:{
			type: Array,
			default:[]
		},
	},
	components: {
		AddServiceWizardAlarmDetectors,
		AddServiceWizardCentralHeatingHeater,
		AddServiceWizardAccessControlTriggers,
		AddServiceWizardFinish
	},
	data:
		function () {
			return {
					CONSTANTS:
					{
						SERVICE_ADD_SUCCESS: this.$gettext('The kit has been successfully added'),
						SERVICE_ADD_ERROR : this.$gettext('Unable to add the kit'),
						SERVICE_EDIT_SUCCESS: this.$gettext('The kit has been successfully edited'),
						SERVICE_EDIT_ERROR : this.$gettext('Unable to edit the kit'),
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
					},
					elements:
					{
						'alarm' : 
						{ 
							name: 'alarm',
							detectorsConfig : 
							{
								title: 'Detectors',
								uiElements:
								[
									{
										name: 'arm',
										selected: false,
										capability:{
											"capability": "protection",
											"value": "arm"
										},
										uiIcon: 
										{
											icon: 'alarm-arm',
											text: _upperFirst(this.$gettext('Complete mode'))
										},
										devices: [],
										selectedItem: undefined
									},
									{
										name: 'partial',
										selected: false,
										capability:{
											"capability": "protection",
											"value": "partial"
										},
										uiIcon: 
										{
											icon: 'alarm-partial',
											text: _upperFirst(this.$gettext('Partial mode'))
										},
										devices: [],
										selectedItem: undefined
									},
								],
								availableModels : _union(this.capabilities.access.models, this.capabilities.detect.models),
								availableBrands : _union(this.capabilities.access.brands, this.capabilities.detect.brands)
							},
							alertsConfig : 
							{
								title: 'Alerts',
								uiElements:
								[
									{
										name: 'arm',
										capability:{
											"capability": "protection",
											"value": "arm"
										},
										uiIcon: 
										{
											icon: 'alarm-arm',
											text: _upperFirst(this.$gettext('Complete mode'))
										},
										devices: [],
										email:[],
										sms:[],
										notif: []
									},
									{
										name: 'partial',
										capability:{
											"capability": "protection",
											"value": "partial"
										},
										uiIcon: 
										{
											icon: 'alarm-partial',
											text: _upperFirst(this.$gettext('Partial mode'))
										},
										devices: [],
										email:[],
										sms:[],
										notif: []
									},
								],
								availableModels : this.capabilities.status.models,
								availableBrands : this.capabilities.status.brands
							}
						},
						'heating' : 
						{
							name: 'heating',
							sensorsConfig : 
							{
								availableModels : this.capabilities.temperature.models,
								availableBrands : this.capabilities.temperature.brands
							},
							heatersConfig : 
							{
								availableModels : this.getModelsFilterBySystems(this.capabilities.status.models, ['energy'], 'heating'),
								availableBrands : this.capabilities.status.brands
							},
						},
						'access_control' : 
						{
							name: 'access_control',
							triggers: [],
							actions: []
						}
					},
					stepper : [],
					wizardStep: 1,
					validation: {result: false},
					displayPrevious: true,
					loading: false,
					serviceObj: undefined
			}
		},		
	created: function () {
		this.eventHub.$on('setServiceWizardStep', this.setServiceWizardStep);
		this.eventHub.$on('nextServiceWizardStep', this.nextServiceWizardStep);
		this.eventHub.$on('backServiceWizardStep', this.backServiceWizardStep);
		this.eventHub.$on('addEditServiceWizard', this.addEditServiceWizard);
		this.eventHub.$on('addServiceWizardFinished', this.addServiceWizardFinished);
		this.eventHub.$on('initServiceTitleStepAt', this.initServiceTitleStepAt);
		this.eventHub.$on('setServiceTitleStepAt', this.setServiceTitleStepAt);
		this.eventHub.$on('concatServiceTitleStepAt', this.concatServiceTitleStepAt);

		this.eventHub.$on('serviceInit', this.serviceInit);
		this.stepperInit();
		this.serviceInit();
	},
	mounted() {
	},	
	destroyed: function () {
		this.eventHub.$off('setServiceWizardStep', this.setServiceWizardStep);
		this.eventHub.$off('nextServiceWizardStep', this.nextServiceWizardStep);
		this.eventHub.$off('backServiceWizardStep', this.backServiceWizardStep);
		this.eventHub.$off('addEditServiceWizard', this.addEditServiceWizard);
		this.eventHub.$off('addServiceWizardFinished', this.addServiceWizardFinished);
		this.eventHub.$off('initServiceTitleStepAt', this.initServiceTitleStepAt);
		this.eventHub.$off('setServiceTitleStepAt', this.setServiceTitleStepAt);
		this.eventHub.$off('concatServiceTitleStepAt', this.concatServiceTitleStepAt);
		this.eventHub.$off('serviceInit', this.serviceInit);
	},
	methods: {
		stepperInit: function(){
			this.stepper= [];
			this.stepper= this.stepper.concat(this.getSpecificServiceSteps());
			this.stepper= this.stepper.concat([
				{
					component:
					{
						name: 'add-service-wizard-finish',
						ref: 'addServiceWizardFinish',
						props:
						{
							'validation':this.validation,
						}
					}
				}
			]);
		},

		serviceInit: function(){
			this.eventHub.$emit('setServiceDisplayPrevious', true);
			this.wizardStep=1;
			this.validation.result=false;
		},		

		getSpecificServiceSteps: function(){

			switch(this.service.name)
			{
				case 'alarm' : 
					this.serviceObj = new ServiceAlarm();
					this.serviceObj.notificationLevel = 'ALERT';
					if(this.editedService)
					{
						this.serviceObj.setAttributs(this.editedService.name,this.editedService.zoneId,this.editedService.notification_level);
						//this.serviceObj.setUIElementsObj(this.elements['alarm'],this.editedService,this.devices);
					}
					return [
						{
							component:
							{
								name: 'add-service-wizard-alarm-detectors',
								ref : 'addServiceWizardAlarmDetectors',
								props :
								{
									'detectors-config':this.elements[this.service.name].detectorsConfig,
									'alerts-config':this.elements[this.service.name].alertsConfig,
									'app-constants':this.appConstants,
									'plant':this.plant,
									'defaultRooms':this.defaultRooms
								}
							},
							title : this.$gettext('Detector(s)'),
							originalTitle : this.$gettext('Detector(s)'),
							previous: function(_this)
							{
								_this.eventHub.$emit('backServiceWizardStep');
							}
						},
					];
				case 'electric_heating' : 
				case 'central_heating' : 
				case 'heating' : 
					this.serviceObj = new ServiceHeating();
					if(this.editedService)
					{
						if(this.service.name=='central_heating')
						{
							this.serviceObj.setAttributs(this.editedService.name,this.editedService.zoneId,this.editedService.notification_level);
							if(this.editedService.service.infos && this.editedService.service.infos.heaters.length>0)
							{
								this.serviceObj.pushHeater(this.editedService.service.infos.heaters[0]);
							}
						}
						else
						{
							this.serviceObj.setAttributs(this.editedService.name,this.editedService.zoneId,this.editedService.notification_level);
							//this.serviceObj.setUIElementsObj(this.elements['heating'] ,this.editedService,this.devices);
						}

					}
					else
					{
						this.elements['heating'].sensors=[];
						this.serviceObj.addHeater('');
					}
					this.serviceObj.submodel=this.service.name;
					return [
						{
							component:
							{
								name: 'add-service-wizard-central-heating-heater',
								ref : 'addServiceWizardCentralHeatingHeater',
								props :
								{
									'heaters-config':this.elements['heating'].heatersConfig,
									'sensors-config':this.elements['heating'].sensorsConfig,
									'plant':this.plant,
									'defaultRooms':this.defaultRooms,
								}
							},
							title : this.$gettext('Central heating'),
							originalTitle : this.$gettext('Central heating'),
						}
					];		
				case 'electric_heating_old' : 
					this.serviceObj = new ServiceHeating();
					if(this.editedService)
					{	
						this.serviceObj.setAttributs(this.editedService.name,this.editedService.zoneId,this.editedService.notification_level);
						//this.serviceObj.setUIElementsObj(this.elements['heating'] ,this.editedService,this.devices);
					}
					else
					{
						this.elements['heating'].sensors=[];
					}		
					this.serviceObj.submodel=this.service.name;
					return [
						{
							component:
							{
								name: 'add-service-wizard-electric-heating-sensors',
								ref : 'addServiceWizardElectricHeatingSensors',
								props :
								{
									'sensors-config':this.elements['heating'].sensorsConfig,
									'heaters-config':this.elements['heating'].heatersConfig,
								}
							},
							title : this.$gettext('Temperature sensor(s)'),
							originalTitle : this.$gettext('Temperature sensor(s)'),
						},
					];
				case 'access_control' : 
					this.serviceObj = new ServiceAccessControl();	
					if(this.editedService)
					{	
						this.serviceObj = new ServiceAccessControl(this.editedService.name,this.editedService.zoneId,this.editedService.notification_level,(this.editedService.service?this.editedService.service.infos:{}));
					}
					else{
						this.serviceObj = new ServiceAccessControl();
					}
					return [
						{
							component:
							{
								name: 'add-service-wizard-access-control-triggers',
								ref : 'addServiceWizardAccessControlTriggers',
								props :
								{
									'triggers':this.elements['access_control'].triggers,
									'actions':this.elements['access_control'].actions,
									'plant':this.plant,
									'defaultRooms':this.defaultRooms
								}
							},
							title : this.$gettext('Service configuration'),
							originalTitle : this.$gettext('Service configuration'),
						}
					];							
			}
		},		
		setServiceWizardStep: function(value){
			this.wizardStep = value;
		},
		nextServiceWizardStep: function(){
			this.wizardStep=parseInt(this.wizardStep)+1;
		},
		backServiceWizardStep: function(){
			if(this.wizardStep==1)
			{
				this.eventHub.$emit('backServiceMainStep');
			}
			else
			{
				this.wizardStep=parseInt(this.wizardStep)-1;
			}
		},
		addEditServiceWizard: function(){
			var _this = this;
			this.loading=true;
			var parameterValue = '';
			var type = '';
			if(this.editedService)
			{
				parameterValue = this.editedService.id;
				type = 'edit';
			}
			else
			{
				parameterValue = (this.service.serviceParent?this.service.serviceParent.name:this.service.name);
				type = 'add';
			}
			this.eventHub.$emit('setServiceDisplayPrevious',  false);
			
			ServiceResource[type](parameterValue,this.serviceObj).then(
				function(response) {
					let idModule = (_this.editedService?_this.editedService.id:response.data.id);
					
					LocationResource.edit('module',idModule, {'alert':(_this.serviceObj.notificationLevel!="ALERT"?[]:_this.serviceObj.alert),'notificationLevel':_this.serviceObj.notificationLevel}).then(
						function(obj) {
							if(_this.editedService)
							{
								_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SERVICE_EDIT_SUCCESS, 'success');	
								_this.eventHub.$emit('getAllTopology', 'all');
								_this.eventHub.$emit('addServiceFinished',_this.serviceObj);
							}
							else
							{
								_this.eventHub.$emit('displayAlert',  _this.CONSTANTS.SERVICE_ADD_SUCCESS, 'success');
								_this.eventHub.$emit('getAllTopology', 'all');
								_this.addServiceWizardFinished(true);	
							}
						}).catch(
						function(e) {
							if(e == 'Error: Network Error')
							_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
							else if(e.response && e.response.status == 401)
								_this.eventHub.$emit('logout');
							else if(e.response && [402].indexOf(e.response.status)!=-1)
								_this.eventHub.$emit('displayAlertKey', 'unauthorized');  
							else
								_this.eventHub.$emit('displayAlert',  (_this.editedService?_this.CONSTANTS.SERVICE_EDIT_ERROR:_this.CONSTANTS.SERVICE_ADD_ERROR));
							_this.addServiceWizardFinished(false);
					});

				}).catch(
				function(e) {
					if(e == 'Error: Network Error')
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
					else if(e.response && e.response.status == 401)
						_this.eventHub.$emit('logout');
					else if(e.response && [402].indexOf(e.response.status)!=-1)
						_this.eventHub.$emit('displayAlertKey', 'unauthorized');  
					else
						_this.eventHub.$emit('displayAlert',  (_this.editedService?_this.CONSTANTS.SERVICE_EDIT_ERROR:_this.CONSTANTS.SERVICE_ADD_ERROR));
					_this.addServiceWizardFinished(false);
				});
		},		
		addServiceWizardFinished: function(validation){
			this.loading=false;
			this.setServiceDisplayPrevious(false);
			this.validation.result = validation;
			this.nextServiceWizardStep();
			
		},

		setServiceDisplayPrevious: function(displayPrevious){
			this.displayPrevious = displayPrevious;
		},

		next: function(){
			this.eventHub.$on('nextWizardStep', this.nextWizardStep);
		},

		initServiceTitleStepAt: function(index){
			this.stepper[index].title = this.stepper[index].title.substring(0,this.stepper[index].title.lastIndexOf(' -'));
		},

		setServiceTitleStepAt: function(index, title){
			this.stepper[index].title = title;
		},
		concatServiceTitleStepAt: function(index, subTitle){
			this.stepper[index].title += subTitle;
		},
		getModelsFilterBySystems: function(models, systems, service){
			var newModels = [];
			this.models.forEach(function(elt){
				if(models.includes(elt.name) && systems.includes(elt.systems[0])){
					newModels.push(elt.name);
				}
			});
			if(service == 'heating')
				newModels.push('ecr');
			return newModels
		}
	}
}
