export default {
    data: function () {
        return {
            loading:false
        }
    },
    created: function () {
      this.eventHub.$emit('setDisplayPrevious', true);
    },
	methods: {
        next: function(){
                this.loading = true;
                this.eventHub.$emit('nextInstallStep');
            },
        }
}
