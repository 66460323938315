import Global from './global.js'
import StorageService from 'common/services/storage.js'
import CordovaService from 'common/services/cordova.js'
import axios from 'axios'
import moment from 'moment'
export default {  

    getInvite: function(inviteId,source) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
          function(resolve, reject){
              axios.get(Global.getBackendUrl()+'invite/'+inviteId,
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                      cancelToken: source.token
                  }).then(function (response) {
                      return resolve(response);
                  })
                  .catch(function (error) {
                    if(error.response && error.response.status == 404)
                    {
                        return resolve(false);
                    }
                    return reject(error);
                  });
          }
        );
    },


    getSharesLocations: function(source) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
          function(resolve, reject){
              axios.get(Global.getBackendUrl()+'invite/topology/locations',
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                      cancelToken: source.token
                  }).then(function (response) {
                      return resolve(response);
                  })
                  .catch(function (error) {
                    if(error.response && error.response.status == 404)
                    {
                        return resolve(false);
                    }
                    return reject(error);
                  });
          }
        );
    },

    getSharesNotifications: function(plantId,newOnly,source) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        
        return new Promise(
          function(resolve, reject){
            axios.get(Global.getBackendUrl()+'invite/plants/'+plantId+'/notifications?newOnly='+newOnly+'&timezone='+moment.tz.guess(),
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                      cancelToken: source.token
                  }).then(function (response) {
                    return resolve((response.data && Array.isArray(response.data.modules)?response.data.modules:[]));
                  })
                  .catch(function (error) {
                    if(error.response && error.response.status == 404)
                    {
                        return resolve([]);
                    }
                    return resolve(false);
                  });
          }
        );
    },

    getOwnShares: function(shareId, forStatus, source) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
          function(resolve, reject){
              axios.get(Global.getBackendUrl()+'shares/'+(shareId?shareId:'')+(forStatus?'?'+forStatus:''),
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                      cancelToken: source.token
                  }).then(function (response) {
                      return resolve(response);
                  })
                  .catch(function (error) {
                    if(error.response && error.response.status == 404)
                    {
                        return resolve(false);
                    }
                    return reject(error);
                  });
          }
        );
    },

    addShare: function (data) {
        if(data)
        {
            data.app = StorageService.getValue(localStorage, 'app');
        }
        return new Promise(
            function(resolve, reject){
                axios.post(Global.getBackendUrl()+'shares/',
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },


    deleteShare: function (shareId) {
        return new Promise(
            function(resolve, reject){
                axios.delete(Global.getBackendUrl()+'shares/'+shareId,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },

    editShare: function(shareId, body) {
        return new Promise(
            function(resolve, reject){
                axios.put(Global.getBackendUrl()+'invite/'+shareId,
                    body,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
                }
        );
    },

    getSharesLocationsPlanId: function(sharesLocations) {
        return sharesLocations.map(function(sl) {
            if(sl.plants)
            {
                for(var i = 0;i<sl.plants.length;i++)
                {
                    return sl.plants[i].id;
                }
            }
          });
    },

    getSharesLocationFromPlanId: function(sharesLocations, plantId) {
        for(var i = 0;i<sharesLocations.length;i++)
        {
            if(sharesLocations[i].plants && sharesLocations[i].plants[0].id == plantId)
            {
                return sharesLocations[i];
            }
        }
        return null;
    },

    getSubMenuSharesLocationsFromZoneId: function(subMenuSharesLocations, zoneId) {
        for(var i = 0;i<subMenuSharesLocations.length;i++)
        {
            if(subMenuSharesLocations[i].zones)
            {
                if(subMenuSharesLocations[i].zones.indexOf(zoneId)!=-1)
                {
                    return subMenuSharesLocations[i]
                }
            }
        }
        return null;
    },

    subscribeMqttPlant: function(sharesLocations,eventHub, actualSharesLocationsPlanId = []) {
        var arr = [];
        arr = arr.concat(actualSharesLocationsPlanId);
        sharesLocations.forEach(function(sl){
            if(sl.plants)
            {
                sl.plants.forEach(function(p){
                    eventHub.$emit('mqttTopicAdd', 'addressLocation/plants/'+p.id);
                    const index = arr.indexOf(p.id);
                    if (index > -1) {
                        arr.splice(index, 1);
                    }
                });
            }
          });
        if(arr.length>0)
        {
            arr = _map(arr, function(plantId) { return 'addressLocation/plants/'+plantId; });
            eventHub.$emit('mqttControllerUnsubscribe', arr);
        }
    },

    subscribePushPlant: function(_this,actualSharesLocationsPlanId = []) {
        var arr = [];
        arr = arr.concat(actualSharesLocationsPlanId);
        _this.sharesLocations.forEach(function(sl){
            if(sl.plants)
            {
                sl.plants.forEach(function(p){
                    if(!_this.pushRegister.checkSubscriptionExists(p.id))
                    {
                        CordovaService.pushRegister(_this,p.id);
                        const index = arr.indexOf(p.id);
                        if (index > -1) {
                            arr.splice(index, 1);
                        }
                    }
                });
            }
          });
        if(arr.length>0)
        {
            arr.forEach(function(plantId){
                CordovaService.pushUnregister(_this, undefined, undefined, plantId);
            });
        }
    },

    checkIfShareIsValid: function(share) {
        if(share.weekly || share.calendars)
        {
            if(share.calendars && share.calendars.length>0)
            {
                const startDate = share.calendars[0].startDate;
                const endDate = share.calendars[0].endDate;
                if(startDate && endDate)
                {
                    if(!moment().isBetween(startDate, endDate))
                    {
                        return false;
                    }
                }
                else
                {
                    return false;
                }
            }
            if(share.weekly)
            {
                if(share.weekly.days && share.weekly.days.length>0 && share.weekly.days.indexOf('ALL')==-1 && share.weekly.days.indexOf(moment().locale('en').format('dddd').toUpperCase())==-1) 
                {
                    return false;
                }
                const startTime = share.weekly.startTime;
                const endTime = share.weekly.endTime;
                if(startTime && endTime)
                {
                    const date = moment().format('YYYY-MM-DD') + ' ';
                    const startTimeDate = moment(date + startTime);
                    const endTimeDate = moment(date + endTime);
                    if(!moment().isBetween(startTimeDate, endTimeDate))
                    {
                        return false;
                    }
                }
                else
                {
                    return false;
                }
            }
        }
        return true;

    }
}