
import AddShareSelectItem from './add-share-select-item.vue'
import AddShareAddReceiver from './add-share-add-receiver.vue'
import AddShareAddSchedule from './add-share-add-schedule.vue'
import ShareResource from 'common/services/resources/share.js'
import LanguageService from 'common/services/language.js'

export default {
	props:['plant','ownShares'],
	components: {AddShareSelectItem,AddShareAddReceiver,AddShareAddSchedule},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						SHARE_SUCCESS : this.$gettext('Sharing has been successfully added'),
						SHARE_ERROR : this.$gettext('Failed to add sharing')
                    },
					step : 1,
					loading: false,
					stepper : [],
					settings: 
					{
						type:'PRIVATE'
					},
					locationIds:[],
					receivers: [],
					schedule:
					{
						"definePeriods" : false,	
					}
			}
		},
	created: function () {
		if(this.inApp){
			window.plugins.insomnia.keepAwake();
		}
		this.init();
	},
	destroyed: function () {

	},

	mounted: function () {

	},
	
	watch: {
  	},
	methods: {
		init: function(){
			this.stepper = [
				{
					component:
					{
						name: 'add-share-select-item',
						ref: 'addShareSelectItem',
						enable:true,
						props:
						{
						}
					},
					title : ''
				},
				{
					component:
					{
						name: 'add-share-add-receiver',
						ref: 'AddShareAddReceiver',
						enable:true,
						props:
						{
						}
					},
					title : ''
				},
				{
					component:
					{
						name: 'add-share-add-schedule',
						ref: 'AddShareAddSchedule',
						enable:true,
						props:
						{
						}
					},
					title : ''
				}
			];
		},

		setEnableStep: function(ref, enable){
			let step = _find(this.stepper, function(s){ return s.component.ref == ref;});
			if(step)
			{
				step.component.enable = enable;
			}
		},

		nextStep: function(step){
			step = (step?step:JSON.parse(JSON.stringify(this.step)));
			if(!this.stepper[step].component.enable)
			{
				step++;
				this.nextStep(step);
			}
			else
			{
				step++;
				this.step = step;
			}
		},
		backStep: function(step){
			step = (step?step:JSON.parse(JSON.stringify(this.step)));
			if(!this.stepper[step-2].component.enable)
			{
				step--;
				this.backStep(step);
			}
			else
			{
				step--;
				this.step = step;
			}
		},

		share: function(){
			var _this = this;

			var data = {
				'locationIds': this.locationIds,
				'type': this.settings.type

			};
			if(this.schedule.definePeriods)
			{
				if(this.schedule.weekly)
				{
					data.weekly = this.schedule.weekly;
				}
				if(this.schedule.calendars)
				{
					data.calendars = this.schedule.calendars;
				}
			}
			this.loading = true;
			var allshares = {
				"shares" : []
			};
			var receivers = this.receivers;
			if(receivers.length>0)
			{
				for(var i=0; i<receivers.length; i++){
					var receiverShare = JSON.parse(JSON.stringify(data));
					receiverShare.receiver = 
					{
						'email': receivers[i]
					}
					receiverShare.language = LanguageService.getUserLanguage();
					allshares.shares.push(receiverShare);
				}
			}
			else
			{
				var receiverShare = JSON.parse(JSON.stringify(data));
				receiverShare.language = LanguageService.getUserLanguage();
				allshares.shares.push(receiverShare);
			}
			ShareResource.addShare(allshares).then(
				function(response){
					_this.loading = false;
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS.SHARE_SUCCESS, 'success');
					if(response.data.shares && response.data.shares.length > 0)
					{
						_this.eventHub.$emit('addOwnShares', response.data.shares);
					}
					_this.$emit('close');
				}).catch(
				function(e) {
					console.log(e);
					_this.loading = false;
					if(e.response && e.response.status == 401)
					{
						_this.eventHub.$emit('logout');
					}
					else
					{
						_this.eventHub.$emit('displayAlert', _this.CONSTANTS.SHARE_ERROR+' ('+e.response.status+')');
					}
			});
		},
  	}
}
