import AddDeviceExternalList from './add-device-external-list.vue'
import AddDeviceExternalEdit from './add-device-external-edit.vue'
import LocationResource from 'common/services/resources/location.js'
import DeviceResource from 'common/services/resources/device.js'

export default {
	props:['wizardTitle','brand','wizardStep','devices','plant','defaultRooms'],
	components: {AddDeviceExternalList,AddDeviceExternalEdit},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						EDIT_DEVICE_ERROR : this.$gettext('Unable to edit the device'),
						EDIT_DEVICE_SUCCESS : this.$gettext('The device has been successfully edited'),
						EDIT_MULTI_TEXT : this.$gettext('If you move <b>%{s1}</b> :<br/><b>%{s2}</b>, <b>%{s3}</b> and <b>%{s4}</b> will also move to <b>%{s5}</b>.')
					},
					externalDevices:undefined,
					otherSockets : [],
					selectedModule:'',
					selectedZone:{},
					moveMultiModal:{
						visible: false
					  },
					multiText : '',
					loading:false
			}
		},
	created: function () {
		this.eventHub.$emit('setStepName', 'wizard-external');
		this.eventHub.$emit('initTxt', this.brand);
		this.init();
	},
	destroyed: function () {
	},
	watch: {
		devices: function (val, oldVal) {
			this.externalDevices = _filter(this.devices, 
				{
					device:
					{
						brand: this.brand.name
					}
				});
		}
  	},
	methods: {
		init: function(){
			this.externalDevices = _filter(this.devices, 
				{
					device:
					{
						brand: this.brand.name
					}
				});
		},
		setSelectedModule: function(selectedModule){
			this.selectedModule = selectedModule;
		},

		checkMultiSocket : function(zone){
			//We look if it's a multisocket to show a message if it's one and the location change
			var _this = this;
			this.otherSockets = [];
			if(this.selectedModule.idMulti && this.selectedModule.zoneId != zone.id){
			  var sockets = _filter(this.externalDevices, function(o) { return o.idMulti == _this.selectedModule.idMulti; });
			  for(var i=0; i<sockets.length; i++){
				if(sockets[i].id != this.selectedModule.id){
					this.otherSockets.push(sockets[i]);
				}
			  }
			  var obj = {
				's1' : this.selectedModule.name,
				's2' : this.otherSockets[0].name,
				's3' : this.otherSockets[1].name,
				's4' : this.otherSockets[2].name,
				's5' : this.$gettext(zone.name)
			  };
			  this.multiText = this.$gettextInterpolate(this.CONSTANTS.EDIT_MULTI_TEXT, obj);
			  this.moveMultiModal.visible = true;
			  this.selectedZone = zone;
			}
			else
			{
				this.editModule(zone);
			}
			return false;
		},

		
		editModule: function(zone){
			var _this = this;
			var id='';
			if(this.selectedModule.idModule)
				id = this.selectedModule.idModule;
			else
				id = this.selectedModule.id;	
			LocationResource.edit('module', id, {containerId:zone.id}).then(
				function(response) {
					DeviceResource.moveModuleIntoZone(_this.selectedModule, _this.plant, zone);
					_this.selectedModule.zoneId = zone.id;
					_this.selectedModule.zoneName = zone.name;
					_this.otherSockets.forEach(function(obj,index) {
						DeviceResource.moveModuleIntoZone(obj, _this.plant, zone);
						obj.zoneId = zone.id;
						obj.zoneName = zone.name;
					});
					_this.editModuleFinished();
					_this.moveMultiModal.visible = false;
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS.EDIT_DEVICE_SUCCESS, 'success');
				}).catch(
				  function() {
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS.EDIT_DEVICE_ERROR);
			});
		},

		editModuleFinished: function(zone){
			this.eventHub.$emit('backWizardStep');

		}

	}
}

