import store from 'vuex_path/store'
export default {
    persist: function(storage, key, value) {
      store.state[key]=value;
      if(store.state.noCache)return false;
      try{
        storage.setItem(key, value);
      }
      catch(e)
      {
        
      }
    },

    getValue: function(storage, key) {
      var s = (sessionStorage.getItem('authorization')?sessionStorage:localStorage);
      var value = s.getItem(key);
      return (store.state[key]?store.state[key]:value);
    },

    getKeys: function(storage) {
      if(store.state.noCache)return [];
      var keys = [];
      for (var i = 0; i < storage.length; i++) {
        keys.push(storage.key(i));
      }
      return keys;
    },

    clear: function(storage) {
      storage.clear();
    },

    removeItem: function(storage, key) {
      storage.removeItem(key);
    },

    keyExists: function(storage, key) {
      return (storage.getItem(key) !== null);
    }
}
