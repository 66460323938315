import UserResource from 'common/services/resources/user.js'
import StringService from 'common/services/string.js'
import StorageService from 'common/services/storage.js'

import {SYSTEM_ERROR_TXT} from 'common/constants/global'
import { consoleError } from 'vuetify/es5/util/console'
export default {
	props: 
	{
		txt1: {
			type: String
		},
		txt2: {
			type: String
		},
		model: {
			type: Object
		},
		installObject: {
			type: Object
		},
		installType:{
			type: String
		},

	},
	data:
		function () {
			return {
				CONSTANTS:
				{
				  TRY_NUMBER : 2,
				  TRY_TIMEOUT : 5000,
				  GLOBAL_TIMEOUT : 180000,
				  ASSOCIATION_TOKEN_ERROR: this.$gettext('Can not connect to the server.'),
				  CURRENT_SSID_ERROR: this.$gettext('Please check the activation of the wifi and the location permission of your mobile.</br>Then press the \'refresh\' button')
				},
				showPassword: false,
				form:
				{
					validation:false
				},
				ssid:
				{
					loading:true,
					rules: {
						noSSID: value => !!value || this.$gettext('No wifi detected! Please check that a wireless network is selected in your settings.')
					},
				},
				password:
				{
					rules: {
						required: value => !!value || this.$gettext('Field is required'),
						min: v => v.length >= 8 || this.$gettext('Password must contain at least 8 characters')
					},
				},
				stepTimeout: undefined,
				globalTimeout: undefined,
				nbTries:0,
				bOpenLocationSettingsMenu: false
			}
	},
	beforeDestroy () {
		document.removeEventListener('pause', this.pauseCordova.bind(this), false);
        document.removeEventListener('resume', this.resumeCordova.bind(this), false);
		//this.eventHub.$off('pauseCordova', this.pauseCordova);
		//this.eventHub.$off('resumeCordova', this.resumeCordova);
		this.clearProcess();
	},	

	created: function () {
		document.addEventListener('pause', this.pauseCordova.bind(this), false);
        document.addEventListener('resume', this.resumeCordova.bind(this), false);
		//this.eventHub.$on('pauseCordova', this.pauseCordova);
		//this.eventHub.$on('resumeCordova', this.resumeCordova);
		this.eventHub.$emit('setDisplayPrevious', true);
		var r = JSON.parse(StorageService.getValue(localStorage, 'region-ws'));
		if(r.region=='sandbox')
		{ 
			//FOR DEV PORTAL TEST
			this.installObject.wifi.ssid='';
			this.installObject.wifi.password='';
			this.installObject.associationToken='';
		}
		else
		{
			//this.installObject.wifi.ssid='Area51';
			//this.installObject.wifi.password='fffffffff';

			this.checkLocationPermission();
			/*this.ssid.loading = false;
			this.setAssocToken();*/
		}
	},
	mounted: function () {
		this.$refs['form'].validate();
	},
	methods: {

		pauseCordova : function(){

		},

		resumeCordova : function(){
			if(this.bOpenLocationSettingsMenu)
			{
				this.bOpenLocationSettingsMenu =false;
				this.getCurrentSSID();
			}
		},

		checkLocationPermission : function(){
			var _this = this;
			if(window.WifiWizard==undefined)
			{
				_this.clearProcess();
				_this.eventHub.$emit('displayAlert', _this.$gettext(SYSTEM_ERROR_TXT));
				return false;
			}
			window.WifiWizard.hasLocationPermission( 
				function(bEnable){
					if(bEnable)
					{
						_this.isLocationEnabled();
					}
					else
					{
						window.WifiWizard.askLocationPermission( 
							function(winParam) {
								try{
									var json = StringService.toJson(winParam);
									if(json.event && json.event=='askLocationPermission')
									{
										if(json.status=='accepted')
										{
											_this.isLocationEnabled();
										}
										else
										{
											_this.eventHub.$emit('displayAlert', _this.CONSTANTS.CURRENT_SSID_ERROR);
										}
									}  
								}
								catch(e)
								{
									console.log('askLocationPermission - success : error');
									console.log(e);
									_this.eventHub.$emit('displayAlert', _this.CONSTANTS.CURRENT_SSID_ERROR);
								}
							},
							function(e){
								console.log('askLocationPermission - failed');
								console.log(e);
								_this.eventHub.$emit('displayAlert', _this.CONSTANTS.CURRENT_SSID_ERROR);
							}
						);
					}
					
				},
				function(e){
					console.log('hasLocationPermission - failed');
					console.log(e);
				}
			);
		},


		isLocationEnabled : function(){
			var _this = this;
			if(window.WifiWizard==undefined)
			{
				_this.clearProcess();
				_this.eventHub.$emit('displayAlert', _this.$gettext(SYSTEM_ERROR_TXT));
				return false;
			}
			window.WifiWizard.isLocationEnabled( 
				function(bEnable) {
					if(bEnable)
					{
						_this.executeGlobalTimeout();
						_this.getCurrentSSID();
					}
					else
					{
						window.WifiWizard.openLocationSettingsMenu( 
							function(bEnable) {
								if(bEnable)
								{
									setTimeout(function(){_this.bOpenLocationSettingsMenu = true;}, 500);
								}
								else
								{
									_this.eventHub.$emit('displayAlert', _this.CONSTANTS.CURRENT_SSID_ERROR);
								}
							},
							function(e){
								console.log('openLocationSettingsMenu - failed');
								console.log(e);
								_this.eventHub.$emit('displayAlert', _this.CONSTANTS.CURRENT_SSID_ERROR);
							}
						);
					}
				},
				function(e){
					console.log('isLocationEnabled - failed');
					console.log(e);
					_this.eventHub.$emit('displayAlert', _this.CONSTANTS.CURRENT_SSID_ERROR);
				}
			);
		},


		refreshSSID : function(){
			this.nbTries = 0;
			//this.installObject.wifi.ssid='';
			//this.installObject.wifi.password='';
			this.bOpenLocationSettingsMenu =false;
			this.checkLocationPermission();
		},

		getCurrentSSID : function(){
			if(window.WifiWizard==undefined)
			{
				_this.clearProcess();
				_this.eventHub.$emit('displayAlert', _this.$gettext(SYSTEM_ERROR_TXT));
				return false;
			}
			var _this = this;
			this.ssid.loading = true;
			window.WifiWizard.getConnectedSSID().then(
				function(ssid){
					_this.installObject.wifi.ssid = (StringService.regexQuote(ssid)?ssid.substr(1, ssid.length-2):ssid);
					window.WifiWizard.getConnectedBSSID().then(
						function(bssid){
							console.log('getCurrentSSID - getConnectedBSSID - success');
							console.log(bssid);
							_this.installObject.wifi.bssid = (StringService.regexQuote(bssid)?bssid.substr(1, bssid.length-2):bssid);
							_this.ssid.loading = false;
							_this.setAssocToken();
						}).catch(
						function(e){
							console.log('getCurrentSSID - getConnectedBSSID - failed');
							console.log(e);
							_this.executeTimeout('getCurrentSSID');
						});
				}).catch(
				function(e){
					console.log('getCurrentSSID - getConnectedSSID - failed');
					console.log(e);
					_this.executeTimeout('getCurrentSSID');
				});
		},

		setAssocToken: function(){
			var _this = this;
			var associationToken = StringService.randomString(16,'hexa');
			this.installObject.associationToken=associationToken;
			this.installObject.device.password=associationToken;
			UserResource.setAssocCode(associationToken).then(
				function(response) {
						_this.clearProcess();
			}).catch(
				function() {
					/*_this.installObject.associationToken='';
					_this.installObject.device.password='';
					_this.executeTimeout('setAssocToken',0);*/
					
			});
		},
		clearProcess: function()
		{
			clearTimeout(this.stepTimeout);
			clearTimeout(this.globalTimeout);
		},

		executeTimeout: function(execFunc, duration)
		{
			var _this = this;
			this.nbTries++;
			if(this.nbTries==this.CONSTANTS.TRY_NUMBER)
      		{
				this.eventHub.$emit('displayAlert', this.CONSTANTS.ASSOCIATION_TOKEN_ERROR);  
				this.eventHub.$emit('setValidation', false);
				this.eventHub.$emit('setInternalMainStep', 7, this.installType);
                return false;
			}		
			clearTimeout(this.stepTimeout);
			this.stepTimeout = setTimeout(() => {
				_this[execFunc]();
			}, (duration!=undefined?duration:this.CONSTANTS.TRY_TIMEOUT));
		},
		
		executeGlobalTimeout: function()
		{
			var _this = this;
			clearTimeout(this.globalTimeout);
			this.globalTimeout = setTimeout(() => {
				_this.nbTries = 0;
				_this.clearProcess();
				_this.eventHub.$emit('displayAlert', this.CONSTANTS.ASSOCIATION_TOKEN_ERROR);  
				_this.eventHub.$emit('setValidation', false);
				_this.eventHub.$emit('setInternalMainStep', 7, this.installType);
			}, _this.CONSTANTS.GLOBAL_TIMEOUT);
		},

		next: function()
		{
			this.$refs['form'].validate();
			if(this.form.validation && this.installObject.associationToken!='')
			{
				this.clearProcess();
				this.eventHub.$emit('nextInstallStep');
			}
		}
 	}
}