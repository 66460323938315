export default {
    data: function () {
        return {
            CONSTANTS:{
                MIDDLE_CONTENT_TXT: this.$gettext('Then, use a paper clip or a similar object to press and hold the reset button for at least 5 seconds within the small reset hole and release: the LED flashes.<br/>Then, click Next.')
            }
        }
    },
    created: function () {
    },
	methods: {
		next: function(){
			this.eventHub.$emit('nextStepCable');
		}
	}
}
