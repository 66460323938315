import StringService from 'common/services/string.js'
import Global from 'common/services/resources/global.js'

export default {
    props:['brands','brandGroups','externalAccounts', 'brandsName', 'multichoiceInstall', 'devices', 'versionInfos'],
    data: function () {
        return {
            CONSTANTS:
            {
                'TITLE' : this.$gettext('What is the <strong>brand</strong> of the device ?'),
                'ACCOUNT_ALREADY_EXIST' : this.$gettext('You have already associated this account') 
            },
            scanOptions : [],
            selected: [],
            search:''
        }
    },
    created: function () {
        this.scanOptions = [{
            name: 'qrcode',
            displayName: 'Scan a QR Code',
            icon: 'cl-icon cl-icon-qrcode',
            click: ()=>{
                this.eventHub.$emit('barcodeScan');
            }
        }];
        if(this.$store.state.variantManager.class.scanNetworkButton)
        {
            this.scanOptions.push({
                name: 'scan-network',
                displayName: 'Scan the Network',
                icon: 'cl-icon cl-icon-scan-network',
                click: ()=>{
                    this.launchDiscoverInstallation();
                }
            });
        }
        this.eventHub.$emit('initSteps');
        this.eventHub.$emit('setStepName', 'choose-brand');
        this.eventHub.$emit('setDisplayPrevious', false);
        this.eventHub.$emit('setDisplayTitle', true, 
        StringService.toUppercaseFirstCharacter(this.$gettext('Add a device'))
        );
        this.eventHub.$emit('changeDisplayCloseIconInstallModal', true);
        /*if(store.state.variantManager.getBrandGroupName() != 'comfortlife'){
            this.chooseBrand(_find(this.brandGroups, function(b) { return b.name == store.state.variantManager.getBrandGroupName() }));
        }*/
    },
    computed: {
        filteredBrandGroups() {
          return this.brandGroups.filter(brand => {
            return brand.displayName.toLowerCase().includes(this.search.toLowerCase());
          });
        }
    },
    methods: {

        getBrandSrc: function(brand){
            if(brand.images)
            {
                if(brand.images.group)
                {
                    return Global.getBackendUrl()+brand.images.group
                }
                else if(brand.images.default)
                {
                    return Global.getBackendUrl()+brand.images.default;
                }
                else if(brand.imagePath)
                {
                    return brand.images.default;
                }
            }
            return '';
        },
        chooseBrand: function(brand){
            if(brand.brands.length>1)
            {
                
                this.eventHub.$emit('setBrandGroup', brand);
                this.eventHub.$emit('setBrand', brand);
                this.eventHub.$emit('setMainStep', 2);
                this.eventHub.$emit('setDisplayPrevious', true);
            }
            else if(this.multichoiceInstall.indexOf(brand.name)==-1 && _find(this.externalAccounts.externalAccounts, {external:{name:brand.name}}))
            {
                this.eventHub.$emit('displayAlert', this.CONSTANTS.ACCOUNT_ALREADY_EXIST, 'info');
            }
            else if(this.versionNotAllow(brand))
            {
                this.eventHub.$emit('displayAlertKey', 'unauthorized');
            }
            else
            {
                this.eventHub.$emit('setBrand', brand);
                this.eventHub.$emit('setMainStep', (brand.wizard.type=='INTERNAL'?3:2));
                this.eventHub.$emit('setChooseWizard', brand.wizard.type);
                this.eventHub.$emit('setDisplayPrevious', true);
                this.eventHub.$emit('initTxt', brand);
            }
        },

        launchDiscoverInstallation: function()
        {
            this.$emit('onClickDiscoverButton','discover')
        },

        versionNotAllow : function(brand){
			var devices = _filter(this.devices, function(o) { return o.device.brandObj && o.device.brandObj.wizard.type == 'INTERNAL'; });
            var hcDevices = _filter(this.devices, function(o) { return o.device.brandObj && o.device.brandObj.name == 'home_confort'; });
            if(this.versionInfos.deviceCount != 'INF' && devices.length+hcDevices.length >= this.versionInfos.deviceCount && brand.wizard.type == 'INTERNAL'){
                return true
            }
            else if(_find(this.externalAccounts.externalAccounts, {external:{name:brand.name}}) && this.versionInfos.deviceCount != 'INF' && devices.length+hcDevices.length >= this.versionInfos.deviceCount && brand.name == 'home_confort'){
                return true
            }
            else if (brand.wizard.type == 'CLIENT'){
                if(brand.name == 'amazon_alexa' && !this.versionInfos.amazonAlexaAvailability)
                    this.eventHub.$emit('displayAlertKey', 'unauthorized');
                else if(brand.name == 'google_home' && !this.versionInfos.googleHomeAvailability)
                    this.eventHub.$emit('displayAlertKey', 'unauthorized');
                return false
            }
            else{
                return false
            }
		},
        brandInBrandName: function(brand){
            var _this = this;
            if(this.brandsName){
                if(_findIndex(_this.brandsName, function(o) { return o == brand.name; }) != -1){
                    return true
                }
                return false
            }
            else{
                return true
            }
        },
        ifTwoBrands: function(){
            var _this = this;
            if(this.brandsName && this.brandsName.length <= 3){
                if(this.brandsName.length == 1){
                    var brand = _find(_this.brands, function(o) { return o.name == _this.brandsName; })
                    this.chooseBrand(brand);
                }
                else
                    return 'justifyContent: center;'
            }
            else{
                return ''
            }
        }
    }
}
