import VariantPhilipsWelcome from './VariantPhilips.js';
import VariantComfortlife from './VariantComfortlife.js';
import StringService from 'common/services/string.js'
import cssVars from 'css-vars-ponyfill';

const COMFORTLIFE_BRAND = 'comfortlife';

export default class{
    
    constructor() {
        const variantClasses = {
          VariantComfortlife,
          VariantPhilipsWelcome
        };
        var variant = (window.VARIANT?'Variant'+StringService.toPascalCase(window.VARIANT):'VariantComfortlife');
        // CALL PLUGIN TO GET THE VARIANT AND INSTANTIATE THE RIGHT CLASS
        this.class = new variantClasses[variant]();
        this.class.manufacturerName = (this.class.manufacturerName?this.class.manufacturerName:COMFORTLIFE_BRAND);
        this.class.brandName = (this.class.brandName?this.class.brandName:COMFORTLIFE_BRAND);
        this.imgPath = '../assets/images/manufacturers/'+this.class.manufacturerName+'/brands/'+this.class.brandName;
        var variables = {
          '--font' : this.class.font.family,
          '--font-bold-family' : this.class.font.bold.family,
          '--font-bold-weight' : this.class.font.bold.fontWeight,
          '--background-login' : this.class.login.background,
          '--btn-hover-background-color' : this.class.colors.btn,
          '--menu-font-color' : this.class.menu.fontColor,
          '--menu-color' : this.class.menu.color,
          '--menu-hover-color' : this.class.menu.hoverColor,
          '--menu-width' : (this.displayMarket()?'85%':'100%'),
          '--color-selection' : (this.class.colors.selection?this.class.colors.selection:'#7d7d7d'),
          '--login-logo-padding' : this.class.login.logo.padding,
          '--top-menu-background-color' : this.class.topMenu.backgroundColor,
          '--top-menu-color' : this.class.topMenu.color,
          '--top-menu-hover-color' : this.class.topMenu.hoverColor,
          '--scrollbar-button-background-color' : this.class.scrollbarButton.backgroundColor,
          '--scrollbar-button-color' : this.class.scrollbarButton.color,
        };
        
        if(this.class.colors.first)
        {
          variables['--color-first'] = this.class.colors.first;
          variables['--color-second'] = (this.class.colors.second?this.class.colors.second:this.class.colors.first);
          variables['--color-third'] = (this.class.colors.third?this.class.colors.third:this.class.colors.first);
          variables['--v-input--switch-color'] = (this.class.colors.second?this.class.colors.second:this.class.colors.first);
        }

        if(this.class.colors.second)
        {
          variables['--v-list--v-list__tile--link-background-hover'] = (this.class.colors.second?this.class.colors.second:'rgba(0,0,0,.04)');
          variables['--v-btn-hover-background-color'] = (this.class.colors.second?this.class.colors.second:(this.class.colors.first?this.class.colors.first:'#2A3C6B'));
          variables['--v-btn-hover-background-color-before'] = (this.class.colors.second?this.class.colors.second:'currentColor');

        }  
        if(this.class.colors.fourth)
        {
          variables['--color-fourth'] = this.class.colors.fourth;
        }
        if(this.class.expansionList)
        {
          variables['--expansion-list-background-color'] = (this.class.expansionList.backgroundColor?this.class.expansionList.backgroundColor:'#FFF');
          variables['--expansion-list-color'] = (this.class.expansionList.color?this.class.expansionList.color:'#3C3C3C');
          if(this.class.expansionList.header)
          {
            variables['--expansion-list-header-background-color'] = (this.class.expansionList.header.backgroundColor?this.class.expansionList.header.backgroundColor:'#F5F5F5');
            variables['--expansion-list-header-color'] = (this.class.expansionList.header.color?this.class.expansionList.header.color:'#3C3C3C');
          }
        }
        if(this.class.stepper)
        {
          variables['--stepper-color'] = (this.class.stepper.color?this.class.stepper.color:'white');
          variables['--stepper-hr-color'] = (this.class.stepper.hrColor?this.class.stepper.hrColor:'white');
          variables['--stepper-background-color'] = (this.class.stepper.backgroundColor?this.class.stepper.backgroundColor:'#2A3C6B');
          if(this.class.stepper.step)
          {
            variables['--step-color-complete'] = (this.class.stepper.step.complete?this.class.stepper.step.complete:'#9CCC52');
            variables['--step-color-active'] = (this.class.stepper.step.active?this.class.stepper.step.active:'#4364AC');
            variables['--step-color-inactive'] = (this.class.stepper.step.inactive?this.class.stepper.step.inactive:'white');
          }
        }
        if(this.class.popup)
        {
          if(this.class.popup.header)
          {
            variables['--popup-header-background-color'] = (this.class.popup.header.backgroundColor?this.class.popup.header.backgroundColor:'#C5C5C5');
            variables['--popup-header-color'] = (this.class.popup.header.color?this.class.popup.header.color:'#3C3C3C');
          }
          if(this.class.popup.crossbar)
          {
            variables['--popup-crossbar-color'] = (this.class.popup.crossbar.color?this.class.popup.crossbar.color:'#3C3C3C');
          }
        }
        if(this.class.popup)
        {
          if(this.class.popup.header)
          {
            variables['--install-popup-header-background-color'] = (this.class.installPopup.header.backgroundColor?this.class.installPopup.header.backgroundColor:'#2A3C6B');
            variables['--install-popup-header-color'] = (this.class.installPopup.header.color?this.class.installPopup.header.color:'white');
          }
          if(this.class.popup.crossbar)
          {
            variables['--install-popup-crossbar-color'] = (this.class.installPopup.crossbar.color?this.class.installPopup.crossbar.color:'white');
          }
        }
        if(this.class.tutorial)
        {
          if(this.class.tutorial.background)
          {
            variables['--tutorial-background'] = (this.class.tutorial.background?this.class.tutorial.background:'rgb(126,156,209)');
          }
          if(this.class.tutorial.backgroundGradient)
          {
            variables['--tutorial-background-gradient'] = (this.class.tutorial.backgroundGradient?this.class.tutorial.backgroundGradient:'linear-gradient(180deg, rgba(126,156,209,1) 0%, rgba(42,60,107,1) 100%)');
          }
        }
        variables['--color-hover'] = (this.class.colors.colorHover?this.class.colors.colorHover:'rgba(0, 0, 0, 0.87)');

        //this.class = COMFORTLIFE_BRAND;
        cssVars({
            // Targets
            rootElement   : document,
            shadowDOM     : false,
          
            // Sources
            include       : 'link[rel=stylesheet],style',
            exclude       : '',
            variables     : variables,
            // Options
            onlyLegacy    : true,
            preserveStatic: true,
            preserveVars  : false,
            silent        : false,
            updateDOM     : true,
            updateURLs    : true,
            watch         : false,
          
            // Callbacks
            onBeforeSend(xhr, elm, url) {
              // ...
            },
            onWarning(message) {
              // ...
            },
            onError(message, elm, xhr, url) {
              // ...
            },
            onSuccess(cssText, elm, url) {
              // ...
            },
            onComplete(cssText, styleElms, cssVariables, benchmark) {
              // ...
            }
        });
    }

    getSubscribe(){
      return (this.class.subscribe?this.class.subscribe:{});
    }

    displayMenuFooter(){
      return (this.class.menu.displayFooter === false ?false:true);
    }

    displayMenuVersion(){
      return (this.class.menu.displayVersion === false ?false:true);
    }
    
    getDisplayProfile(){
      return [true,undefined].indexOf(this.class?.menu?.displayProfile)!=-1;
    }

    displayMarket(){ 
        return (this.class.displayMarket === false ?false:true);
    }

    getBrandName(){
        return (this.class.brandName?this.class.brandName:COMFORTLIFE_BRAND);
    }

    getBrandGroupName(){
      return (this.class.brandGroupName?this.class.brandGroupName:COMFORTLIFE_BRAND);
    }

    getColorMenu(){
        return (this.class.colors.menu?this.class.colors.menu:'#2A3C6B');
    }

    isComfortlife(){
      return (this.getBrandGroupName() == COMFORTLIFE_BRAND);
    }
}
