import StorageService from 'common/services/storage.js'
import CordovaService from 'common/services/cordova.js'
import { v4 as uuidV4 } from 'uuid';

export default {
    props: ['module'],
    data: function () {
            return {
           }
    },
    methods: {
        setStatus : function(status){
            if(this.module.device)
                this.$parent.$emit('set-status', status);
            else{
                this.module.service.status[0].value = status;
                this.$parent.$emit('setStatusService', status, this.module.id, 'alarm',this.module);
            }
        },

        activePanic: function(){
            var commandId = uuidV4();
            var jsonCordova = {
              "action": "command",
              "uid": commandId,
              "modules": [
                  {
                      "id": this.module.id,
                      "service":{
                          "status":[
                              {
                                "capability": "trigger"
                              }
                          ]
                      }
                  }
              ]
            }
            var path = 'service/comfort/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/parameter/id/value/'+this.module.id;
            this.eventHub.$emit('mqttPublish',  path, jsonCordova);
            CordovaService.setStatus(jsonCordova).then(
              function(response) {
              }
            ).catch(
              function() {
              }
            );
        }
	}
}
