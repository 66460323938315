import AlertIcon from 'common/components/alert-icon/alert-icon.vue'
export default {
    components:{AlertIcon},
    props:
      {
        title: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        subText: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    data:
        function () {
            return {
                CONSTANTS:{
                },
            loading: false
            }
        },
    created: function () { 
        this.eventHub.$on('setLoading',this.setLoading);
    },
    destroyed: function () {
        this.eventHub.$off('setLoading',this.setLoading);
    },
    methods: {
        onclickItem: function ()
		{
            this.loading = true;
			this.$emit('onclickItem');
        },
        close: function ()
		{
			this.$emit('close');
        },
        setLoading: function(value){
            this.loading = value;
        }
    }
}
  