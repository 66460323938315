import Global from 'common/services/resources/global.js'
import Camera from 'common/classes/Camera.js'
import UpDownControl from './up-down/up-down.vue';
import ArmDisarmPartialControl from './arm-disarm-partial/arm-disarm-partial.vue';
import EliotPlugControl from './eliot-plug/eliot-plug.vue';
import TelControl from './tel-control/tel-control.vue';
import OnOffControl from './on-off/on-off.vue'
import RelayControl from './relay/relay.vue'
import CameraControl from './camera/camera.vue'
import DynamicControl from './dynamic/dynamic.vue'
import RgbControl from './rgb/rgb.vue'
import HigherWayFanControl from './higher-way-fan/higher-way-fan.vue'
import OutdoorlightControl from './outdoorlight/outdoorlight.vue'
import SmartCameraControl from './smart_camera/smart_camera.vue'
import PatronallRobotControl from './patronall-robot/patronall-robot.vue'
import CallControl from './call/call.vue'
import AccessControlControl from './access_control/access_control.vue'
import HeatingControl from './heating/heating.vue'
import CustomControl from './custom/custom.vue'

export default {
	components: { SmartCameraControl,CustomControl, OnOffControl, EliotPlugControl, UpDownControl, ArmDisarmPartialControl, TelControl, RelayControl, CameraControl, DynamicControl, RgbControl, HigherWayFanControl, OutdoorlightControl, CallControl, PatronallRobotControl,AccessControlControl,HeatingControl},
	props: ['eliotPlug', 'models','module','config','use','subTitle','selectedCapability','devices','settings','sharesLocations'],
	data:
		function () {
			return {
					CONSTANTS:
					{
					},
					styleObject: {
						backgroundImage:'',
						textAlign:''
					  }
			}
		},
	mounted: function () {
		if(this.module.device && this.module.type!='custom')
		{
			//this.styleObject.backgroundImage  = (this.module.device.brand ? 'url('+require('@/assets/images/modal/skin/'+this.module.device.brand.toLowerCase()+'/background.png')+')':'');
			this.styleObject.textAlign  = 'center';
		}
		else if(this.module.service)
		{
			//this.styleObject.backgroundImage  = 'url("assets/images/modal/skin/comfortlife/background.png")';
		};
	},
	created: function () {
		this.eventHub.$on('getAllTopologyFinished',this.getAllTopologyFinished);
		if(this.isCustom())
		{
			this.module.type = 'custom';
		}
	},
	destroyed: function () {
		this.eventHub.$off('getAllTopologyFinished',this.getAllTopologyFinished);
	},
	methods: {

		getBrandSrc: function () {
			if(this.module.device.brandObj)
			{
				if(this.module.device.brandObj.images && this.module.device.brandObj.images.default)
				{
					return  Global.getBackendUrl()+this.module.device.brandObj.images.default;
				}
				else if(this.module.device.brandObj.imagePath)
				{
					return this.module.device.brandObj.imagePath;
				}
			}
			return require('@/assets/images/manufacturers/comfortlife/brands/comfortlife/logo.png');
		},

		getAllTopologyFinished : function(){
			//this.eventHub.$emit('setSelectedModule_'+this.module.id);
		},
		isRgb : function(){
			return _find(this.module.device.capabilities, { 'capability': 'rgb' })!=undefined;
		},
		isOnOff : function(){
			return (this.module.device.status && 
				(this.module.device.status[0].capability == 'status' ||
				this.module.device.status[0].capability == 'color_temperature')
				|| this.module.device.model == 'cmr' || this.module.device.model == 'trf');
		},
		isUpDown : function(){
			return (this.module.device.status && this.module.device.status[0].capability == 'position_vertical');
		},
		isArmDisarmPartial : function(){
			return (this.module.device.status && this.module.device.status[0].capability == 'protection');
		},
		isDynamic : function(){
			return (this.module.device.model == 'hz');
		},
		isCameraModel : function(){
			return (Camera.CUSTOM_CAMERA_MODELS.indexOf(this.module.device.model)!=-1)	;
		},
		isCustom : function(){
			return (this.module.device && this.isCameraModel());
		},
		clickOnItem : function(value){
			this.$emit('clickOnItem', value);
		}
  }
}
