import { render, staticRenderFns } from "./navbar-drawer.vue?vue&type=template&id=10c6194a&scoped=true&"
import script from "./navbar-drawer.js?vue&type=script&lang=js&"
export * from "./navbar-drawer.js?vue&type=script&lang=js&"
import style0 from "./navbar-drawer.css?vue&type=style&index=0&id=10c6194a&scoped=true&lang=css&"
import style1 from "./navbar-drawer-vuetify.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10c6194a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VDialog,VDivider,VList,VListGroup,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemTitle,VNavigationDrawer})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
