import DevicesList from 'common/components/devices-list/devices-list.vue'
import moment from 'moment'
export default {
	props:['loading','schedule','plant', 'user', 'appConstants'],
	components: {DevicesList},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						SELECT_DAY_ERROR: this.$gettext('Please select a day of the week'),
						DATE_FROM_TO_ERROR: this.$gettext('The end date is less than the start date'),
						TIME_FROM_TO_ERROR: this.$gettext('The end time is less than the start time')
					},
					selectedDays: ['SUNDAY','MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY'],
					days: [
						{ text: this.$gettext('sun'), value: 'SUNDAY'},
						{ text: this.$gettext('mon'), value: 'MONDAY'},
						{ text: this.$gettext('tue'), value: 'TUESDAY'},
						{ text: this.$gettext('wed'), value: 'WEDNESDAY'},
						{ text: this.$gettext('thu'), value: 'THURSDAY'},
						{ text: this.$gettext('fri'), value: 'FRIDAY'},
						{ text: this.$gettext('sat'), value: 'SATURDAY'}
					],
					timeCheckbox: false,
					dateCheckbox: false,
					menuStartDate: false,
					menuEndDate: false,
					menuStartTime: false,
					menuEndTime: false,

					startDate: moment().format('YYYY-MM-DD'),
					endDate: moment().format('YYYY-MM-DD'),
					startTime:moment().format('00:00'),
					endTime:moment().format('23:59'),
					date: new Date().toISOString().substr(0, 10),
     					 menu: false,
			}
		},
	created: function () {
	},
	destroyed: function () {
		
	},
	mounted: function () {
	},
	computed: {
	},

	methods: {

		share: function(){
			if(this.schedule.definePeriods)
			{
				if(this.selectedDays.length==0)
				{
					this.eventHub.$emit('displayAlert',  this.CONSTANTS.SELECT_DAY_ERROR);
					return false;
				}
				this.schedule.weekly = {
					'days': (this.selectedDays.length==7?['ALL']:this.selectedDays)
				}
				if(this.timeCheckbox)
				{
					if(this.endTime<this.startTime)
					{
						this.eventHub.$emit('displayAlert',  this.CONSTANTS.TIME_FROM_TO_ERROR);
						return false;
					}
					this.schedule.weekly.startTime=this.startTime+':00';
					this.schedule.weekly.endTime=this.endTime+':00';
				}
				else
				{
					this.schedule.weekly.startTime='00:00:00';
					this.schedule.weekly.endTime='23:59:59';
				}
				if(this.dateCheckbox)
				{
					if(this.endDate<this.startDate)
					{
						this.eventHub.$emit('displayAlert',  this.CONSTANTS.DATE_FROM_TO_ERROR);
						return false;
					}
					this.schedule.calendars = [
						{
							"startDate": this.startDate+' 00:00:00',
							"endDate": this.endDate+' 23:59:59',
						}
					]
				}
			}
			this.$emit("share");
		},
		setDefinePeriods: function(definePeriods){
			if(definePeriods)
			{
				this.schedule.definePeriods = definePeriods;
			}
			if(!this.schedule.definePeriods)
			{
				this.initDefinePeriods();
			}
		},

		initDefinePeriods: function(){
			this.timeCheckbox= false;
			this.dateCheckbox= false;
			this.menuStartDate= false;
			this.menuEndDate= false;
			this.menuStartTime= false;
			this.menuEndTime= false;
			this.startDate= moment().format('YYYY-MM-DD');
			this.endDate= moment().format('YYYY-MM-DD');
			this.startTime=moment().format('00:00');
			this.endTime=moment().format('23:00');
		},

		checkDate: function(){
			if(!this.dateCheckbox)
			{
				this.startDate= moment().format('YYYY-MM-DD');
				this.endDate= moment().format('YYYY-MM-DD');
			}
		},
		
		checkTime: function(){
			if(!this.timeCheckbox)
			{
				this.startTime=moment().format('00:00');
				this.endTime=moment().format('23:00');
			}
		},

  	}
}
