import Vue from 'vue'
import Vuex from 'vuex'
var stringify = require('json-stringify-safe');
import VariantManager from '../common/classes/VariantManager.js'
import StorageService from 'common/services/storage.js'

Vue.use(Vuex)


// the root, initial state object
const getDefaultState = () => {
  return {
    /* Demo HP-200 */
    outdoorlights: {
      'value': '0000000000000000',
      'devices': []
    },
    variantManager: new VariantManager(),

    devices: [],

    systems: [],
    // Current selected plant id
    selectedPlantId: '',

    // Current selected plant user
    plantUser: {},

    // Current display user mail (main account or shared)
    userMail: '',

    bMainAccount: false,

    routeUid: undefined,

    selectedExternalAccounts: [],

    homepilots: [],

    meshHomepilots: [],

    allDevices: [],

    services: [],

    noCache: false,

    startupTimeout: 3000,

    mqttIsConnected: false,

    app: undefined,

    regions: [],

    selectedRegion: undefined,

    cancelTokenSource : undefined,

    userClientIdByUsername : {},

    /* Cache variable */
    authorization: null,
    plantId: null,
    username: null,
    mode: null,
    role: null,
    dashboardId: null,
    familyId: null,
    mqtt: null,
    p2p: null,
    availableSmsCount: null,
    resourcesCount: null,
    "region-ws": null,
    systemInfos: null,

  }
}

const state = getDefaultState()

// define the possible mutations that can be applied to our state
const mutations = {
  setDevices (state) {
    var s = (sessionStorage.getItem('authorization')?sessionStorage:localStorage);
    StorageService.persist(s, 'storage - devices', stringify(state.devices));
    StorageService.persist(s, 'storage - plant', stringify(state.plant));
  },
  initStartupTimeout (state) {
    state.startupTimeout = 3000;
  },
  incrementStartupTimeout (state) {
    state.startupTimeout += 3000;
  },
  resetState (state) {
    //state = getDefaultState();
    var reg = new RegExp('^storage - ');
    for (let property in state) {
      if(reg.test(property))
      {
        delete state[property];
      }
    }
    Object.assign(state, getDefaultState())
  },

  setHomepilots (state,homepilots) {
    state.homepilots = homepilots;
  },

  setDevices (state,devices) {
    state.devices = devices;
  },

  setAllDevices (state,allDevices) {
    state.allDevices = allDevices;
  },

  setServices (state,services) {
    state.services = services;
  },

  setModels (state,models) {
    state.models = models;
  },
  
  setAppObj (state,app) {
    state.appObj = app;
  },

  setRegions (state,regions) {
    state.regions = regions;
  },

  setSelectedRegion (state,selectedRegion) {
    state.selectedRegion = selectedRegion;
  },

  setUserClientIdByUsername (state,userClientIdByUsername) {
    state.userClientIdByUsername = userClientIdByUsername;
  }

}

const actions = {
  setHomepilots ({ commit }, homepilots) {
    commit('setHomepilots',homepilots);
  },

  setDevices ({ commit }, devices) {
    commit('setDevices',devices);
  },

  setAllDevices ({ commit }, allDevices) {
    commit('setAllDevices',allDevices);
  },

  setServices ({ commit }, services) {
    commit('setServices',services);
  },

  setModels ({ commit }, models) {
    commit('setModels',models);
  },

  setRegions ({ commit }, regions) {
    commit('setRegions',regions);
  },

  setSelectedRegion ({ commit }, selectedRegion) {
    commit('setSelectedRegion',selectedRegion);
  },

  setUserClientIdByUsername ({ commit }, userClientIdByUsername) {
    commit('setUserClientIdByUsername',userClientIdByUsername);
  },
  
  setAppObj ({ commit }, app)  {
    commit('setAppObj',app);
  }
}

const getters = {
  getAppFrontendEndpoint: state => {
    if(state?.appObj?.frontendEndpoint)
    {
      return (window.location.protocol=='file:'?'https:':window.location.protocol)+'//'+state.appObj.frontendEndpoint;
    }
    return undefined;
  }
}

// create the Vuex instance by combining the state and mutations objects
// then export the Vuex store for use by our components
export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})