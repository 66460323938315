import { render, staticRenderFns } from "./access_control.vue?vue&type=template&id=14b96e37&scoped=true&"
import script from "./access_control.js?vue&type=script&lang=js&"
export * from "./access_control.js?vue&type=script&lang=js&"
import style0 from "./access_control.css?vue&type=style&index=0&id=14b96e37&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14b96e37",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VList,VListItem,VListItemAvatar,VListItemContent,VListItemTitle,VSwitch})
