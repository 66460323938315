import AlarmResource from 'common/services/resources/alarm.js'
export default {
    data: function () {
        return {
            CONSTANTS:
            {
                ASSOCIATE_ERROR : this.$gettext('Unable to associate the remote alarm'),
                ASSOCIATE_SUCCESS : this.$gettext('The remote alarm has been successfully associated'),
                TRY_NUMBER : 8,
                TRY_TIMEOUT : 5000
            },
            diagnosticInterval: {},
            diagnosticStatus: {},
            numberTry: 0,
            association: this.$gettext('Wait LED converter blink and push ARM bottom of remote control during 3 seconds.'),
            wait:
            {
                percent: 0,
                time: 0,
            },
            waiting: false
        }
    },
    beforeDestroy () {
      clearInterval(this.diagnosticInterval);
      clearInterval(this.diagnosticStatus);
    },
    created: function () {
        this.associate();
        this.diagnosticInterval = setInterval(() => {
            if (this.wait.percent >= 100) {
                this.waiting = true;
                clearInterval(this.diagnosticInterval);
                this.association = this.$gettext('Association in progress');
            }
            this.wait.percent += 6.7;
            this.wait.time += 1;
        }, 1000);
    },
	methods: {
        associate: function(){
			var _this = this;
            var commandId = this.uuidV4();
            var bearerId = this.$parent.homepilot.id;
            AlarmResource.associateRemoteAlarm(bearerId,commandId,this.$parent.alarm).then(
                function(response) {
                    _this.diagnostic();
                    _this.$parent.alarmRemote.id = response.data.id;
                }).catch(
                function() {
                  _this.eventHub.$emit('setValidation', false);
                  _this.eventHub.$emit('setInternalMainStep', 6);
                });
		},
    diagnostic : function(){
      this.diagnosticStatus = setInterval(() => {
          if (this.numberTry == this.CONSTANTS.TRY_NUMBER) {
            this.waiting = false;
            clearInterval(this.diagnosticStatus);
            this.eventHub.$emit('setValidation', false);
            this.eventHub.$emit('setInternalMainStep', 6);
          }
          else{
            this.associateStatus()
            this.numberTry +=1
          }
      }, this.CONSTANTS.TRY_TIMEOUT);
    },
    associateStatus: function(){
        var _this = this;
        var model = this.$parent.model;
        AlarmResource.diagnostic('modules', 'id' , this.$parent.alarm.id).then(
          function(response) {
            if(response.data[model.name+'s'][0].remote.associateStatus  == 'ASSOCIATED'){
              _this.waiting = false;
              clearInterval(_this.diagnosticStatus);
              _this.eventHub.$emit('nextInstallStep');
            }
        }).catch(
          function() {
          });
  },
	}
}
