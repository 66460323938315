import Service from './Service.js'
export default class extends Service {

    constructor(name, locationId, notificationLevel, infos, submodel) {
        super(name, locationId, notificationLevel, infos);
        this.submodel = submodel || '';
        this.infos.heaters = [];
        this.infos.sensors = [];
    }
    getHeaters() {
        return this.infos.heaters;
    }

    getSensors() {
        return this.infos.sensors;
    }

    setHeaterModuleIdAt(moduleId,index) {
        this.infos.heaters[index].moduleId = moduleId;
    }

    addHeater(moduleId,associatedSensors) {
        if(associatedSensors==undefined)associatedSensors=[];
        this.infos.heaters.push(
            {
                "moduleId": moduleId,
                "associatedSensors": associatedSensors
            }
        );
    }
    
    pushHeater(heater) {
      this.infos.heaters.push(heater);
    }

    deleteHeaters() {
      delete this.infos.heaters;
    }

    deleteSensors() {
      delete this.infos.sensors;
    }

    addAssociatedSensors(moduleId,associatedSensors) {
        var heater = _find(this.infos.heaters, {'moduleId': moduleId});
        heater.associatedSensors = heater.associatedSensors.concat(associatedSensors);
    }

    addAssociatedSensorAt(index,associatedSensor) {
        this.infos.heaters[index].associatedSensors.push(associatedSensor);
    }

    addAssociatedSensorsAtFromElements(index,elements) {
        var associatedSensors = _map(elements, 'id');
        this.infos.heaters[index].associatedSensors = associatedSensors;
    }

    addSensor(moduleId,associatedHeaters) {
        if(associatedHeaters==undefined)associatedHeaters=[];
        this.infos.sensors.push(
            {
                "moduleId": moduleId,
                "associatedHeaters": associatedHeaters
            }
        );
    }

    addSensorsFromElements(element, devices) {
        var _this = this;
        var associatedHeaters = _map(devices, 'id');
        this.addSensor(element.id,associatedHeaters);
    }

    setAssociatedSensorsAtFromElements(index,elements) {
        var associatedSensors = _map(elements, 'id');
        this.infos.heaters[index].associatedSensors = this.infos.heaters[index].associatedSensors.concat(associatedSensors);
    }
    addAssociatedHeaters(moduleId,associatedHeaters) {
        var sensor = _find(this.infos.sensors, {'moduleId': moduleId});
        sensor.associatedHeaters.concat(associatedHeaters);
    }

    setUIElementsObj(uiElement,service,devices) {
        service.service.infos.sensors.forEach(function(sensor,index) {
          var d = _find(devices, { 'id': sensor.moduleId });
          uiElement.sensors.push(d);
          uiElement.sensors[uiElement.sensors.length-1].associatedHeaters = _filter(devices, function(d) { 
            return (sensor.associatedHeaters.indexOf(d.id)!=-1);
          });
        });
    }
}

/*{
  "name": "My Service",
  "type": "string",
  "submodel": "central_heating",
  "locationId": "1234567890ABCDEF",
  "infos": {
    "sensors": [
      {
        "moduleId": "string",
        "associatedHeaters": [
          "string"
        ]
      }
    ],
    "heaters": [
      {
        "moduleId": "string",
        "associatedSensors": [
          "string"
        ]
      }
    ]
  }
}*/
