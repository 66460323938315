import Observer from './Observer'
import Emitter from './Emitter'

export default {

    install(Vue, connection, options) {
        //if (!connection) throw new Error("[Vue-Mqtt] cannot locate connection");
        //let observer = new Observer(connection, options);
        //Vue.prototype.$mqtt = observer.Mqtt;

        Vue.mixin({
            data: function () {
                return {
                    mqttTopics : {}
                }
            },

            created() {
            },
            methods: {
                mqttConnect(connection, options, eventHub)
                {
                    let observer = new Observer(connection, options, eventHub);
                    Vue.prototype.$mqtt = observer.Mqtt;
                },
                
                mqttTopicsCreate(topics)
                {
                    let mqtt = topics;
                    this.mqttTopics = new Proxy({}, {
                        set: (target, key, value) => {
                            Emitter.addListener(key, value, this);
                            target[key] = value;
                            return true;
                        },
                        deleteProperty: (target, key) => {
                            Emitter.removeListener(key, this.mqttTopics[key], this);
                            delete target.key;
                            return true;
                        }
                    });
                    if (mqtt) {
                        Object.keys(mqtt).forEach((key) => {
                            this.mqttTopics[key] = mqtt[key];
                        });
                    }
                },

                mqttTopicsAdd(topics)
                {
                    let mqtt = topics;
                    if (mqtt) {
                        Object.keys(mqtt).forEach((key) => {
                            this.mqttTopics[key] = mqtt[key];
                        });
                    }
                },
                
                mqttDisconnect()
                {

                    this.mqttUnsubscribe();
                    if(Vue.prototype.$mqtt)
                        Vue.prototype.$mqtt.end();
                },

                mqttUnsubscribe()
                {
                    let mqtt = this.mqttTopics;
                    if (mqtt) {
                        Object.keys(mqtt).forEach((key) => {
                            delete this.mqttTopics[key];
                        });
                    }
                },

            },           
            beforeDestroy() {
            }
        })

    }

}
