import { consoleError } from 'vuetify/es5/util/console'

export default {
    props:['models','externalAccounts','brands'],
    data: function () {
        return {
          CONSTANTS:
          {
              TITLE : this.$gettext('How would you like to install your device?'),
              SCAN_PROMPT : this.$gettext('Place a QRCODE inside the scan area'),
              SCAN_ERROR : this.$gettext('Unrecognized product, please select it from the list'),
              TYPES : [
                {
                    label: 'by QRcode',
                    name: 'qrcode'
                },
                {
                    label: 'by selection',
                    name: 'list'
                }
              ]
          }
        }
    }, 

    created: function () {
        //this.eventHub.$emit('setDisplayPrevious', true);
        this.$parent.mainStepTable.splice(0);
        this.eventHub.$emit('setDisplayTitle',true, 'Add a device');
        this.eventHub.$emit('changeDisplayCloseIconInstallModal', true);
        this.eventHub.$emit('setInternalMainStep', 2);
    },

    destroyed: function () {
  	},
    methods: {
        chooseType: function(type){
            this.eventHub.$emit('initQRCodeElts');
            if(type.name=='qrcode')
            {
                this.eventHub.$emit('barcodeScan');
            }
            else
            {
                
                this.eventHub.$emit('setInternalMainStep', 2);
                this.eventHub.$emit('setDisplayPrevious', true);
            }
            this.$parent.mainStepTable.push(1);
        }
    }
}
