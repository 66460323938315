import CloudSpinner from 'common/components/cloud-spinner/cloud-spinner.vue'
import CommonResource from 'common/services/resources/common.js'
import SystemResource from 'common/services/resources/system.js'
import Global from 'common/services/resources/global.js'

const DISCOVER_EXECUTE_TIMEOUT = 5000;

const DISCOVER_TRY_NUMBER = 6;

let discoverTimeout = undefined;
let nbTries = 0;

export default {
    components: { CloudSpinner },
    props: {
      models: {
        type: Array,
        default: []
      }
    },
    data: function () {
        return {
          CONSTANTS:
          {
            'SCANNING_DEVICES_NEARBY' : this.$gettext('Scanning for devices nearby...'),
            'NO_DEVICE_NEARBY' : this.$gettext('No device found nearby'),
            'DEVICES_FOUND_NUMBER_TXT' : this.$gettext('<b>%{n}</b> devices found')
          },
          submodels: [],
          scanning:true,
          discoverDevices: []
        }
    },

    computed: {
      devicesFoundNumberText: function () {
        return this.$gettextInterpolate(this.CONSTANTS.DEVICES_FOUND_NUMBER_TXT, {n: this.discoverDevices.length});
      }
    },

    created: function () {
      this.eventHub.$emit('setDisplayPrevious', true);
      this.eventHub.$on('discoverEvent', this.discoverEvent);
      this.submodels = SystemResource.getAllSubModels(this.models);
      this.sendDiscover();
    },

    destroyed: function () {
      this.eventHub.$off('discoverEvent', this.discoverEvent);
      clearTimeout(discoverTimeout);
    },
    
	  methods: {
      executeTimeout: function()
      {
        nbTries++;
        if(nbTries==DISCOVER_TRY_NUMBER)
        {
          return this.scanningFinished();
        }		
        clearTimeout(discoverTimeout);
        discoverTimeout = setTimeout(() => {
          this.sendDiscover();
        }, DISCOVER_EXECUTE_TIMEOUT);
      },

      sendDiscover: function()
      {
        CommonResource.sendActionCommand(this.eventHub,'discover',this.$store.state.homepilots);
        this.executeTimeout();
      },

      discoverEvent: function(modules)
      {
        modules.forEach((module) => {
          if(module.device && module.device.identifier)
          {
            if (module.device.bearerId)
            {
              let h = _find(this.$store.state.homepilots, {
                id: module.device.bearerId
              });
              if(h)
              {
                let m = _find(this.discoverDevices, {
                  device:{
                    identifier:module.device.identifier
                  }
                }); 
                if(m==undefined)
                {
                  let submodel = _find(this.submodels, {
                    name: module.device.submodelName
                  }); 
                  if(submodel)
                  {
                    module.submodel = submodel;
                  }
                  else
                  {
                    module.submodel = {};
                  }
                  this.discoverDevices.push(module);
                }
              }
            }
          }
        });
      },

      getSubmodelSrc: function(submodel){
        return (submodel.images && submodel.images.default ? Global.getBackendUrl()+submodel.images.default:
        (submodel.backgroundImage));
      },

      scanningFinished: function()
      {
        this.scanning = false;
      },

      chooseDevice: function(device)
      {
        let d = device.device;
        if (d && d.bearerId)
        {
          let h = _find(this.$store.state.homepilots, {
            id: d.bearerId
          });
          if(h)
          {
            this.eventHub.$emit('setSelectedHomepilot', h);
          }
        }
        let text = '';
        if(d.brandName)
        {
          text+=d.brandName;
        }
        text+=';';
        if(d.submodelName)
        {
          text+=d.submodelName;
        }
        text+=';';
        if(d.identifier)
        {
          text+=d.identifier;
        }
        text+=';';
        this.eventHub.$emit('barcodeScanSuccess', {text: text});
      },

      retry: function()
      {
        nbTries = 0;
        this.discoverDevices.splice(0);
        this.scanning = true;
        this.sendDiscover();
      }

    }
}
