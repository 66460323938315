import DeviceResource from 'common/services/resources/device.js'

export default {
	data:
		function () {
			return {
					CONSTANTS:
					{
						ASSOCIATE_ERROR : this.$gettext('Unable to associate the device'),
						ASSOCIATE_SUCCESS : this.$gettext('The device has been successfully associated'),
						ADD_ERROR : this.$gettext('Unable to add the device'),
						TRY_NUMBER : 8,
						TRY_TIMEOUT : 5000,
						NO_NETWORK: this.$gettext('Inactive internet connection! Please activate your internet connection in order to execute this action')
					},
					diagnosticInterval: {},
					diagnosticStatus: {},
					association: this.$gettext('You have 15 seconds to press for 3 seconds on the button at the bottom of the weather sensor on the rubber edge'),
					numberTry: 0,
					wait:
					{
						percent: 0,
						time: 0,
					},
					waiting: false,
					device: {}
			}
	},
    beforeDestroy () {
	  clearInterval(this.diagnosticInterval);
	  clearInterval(this.diagnosticStatus);
    },
	created: function () {
		this.eventHub.$emit('setDisplayPrevious', false);
        this.associate();
        this.diagnosticInterval = setInterval(() => {
            if (this.wait.percent >= 100) {
			  			this.waiting = true;
              clearInterval(this.diagnosticInterval);
							this.association = this.$gettext('Association in progress');
            }
            this.wait.percent += 6.7;
            this.wait.time += 1;
        }, 1000);
    },
	methods: {
		associate: function(){
				var _this = this;
				var commandId = this.uuidV4();
				var bearerId = this.$parent.homepilot.id;
				var model = this.$parent.model;
				var submodel = this.$parent.submodel.name;
				var locationId = this.$parent.$parent.plant.zones[0].id;
				var brand =this.$parent.$parent.brand;
				DeviceResource.associateTo(model.name,model.systems[0],submodel,bearerId,commandId,locationId,brand,this.eventHub).then(
					function(response) {
						_this.eventHub.$emit('setDeviceId',response.data.id);
						_this.diagnostic(response.data.id);
						_this.device = response.data;
				}).catch(
					function(e) {
						if(e == 'Error: Network Error')
							_this.eventHub.$emit('displayAlert', _this.CONSTANTS.NO_NETWORK);
						else if(e.response && e.response.status == 401)
							_this.eventHub.$emit('logout');
						_this.eventHub.$emit('setValidation', false);
						_this.eventHub.$emit('setInternalMainStep', 6);
					});
		},
		diagnostic : function(id){
			this.diagnosticStatus = setInterval(() => {
					if (this.numberTry == this.CONSTANTS.TRY_NUMBER) {
						this.waiting = false;
						clearInterval(this.diagnosticStatus);
						this.eventHub.$emit('setValidation', false);
						this.eventHub.$emit('setInternalMainStep', 6);
					}
					else{
						this.associateStatus(id)
						this.numberTry +=1
					}
			}, this.CONSTANTS.TRY_TIMEOUT);
		},
		associateStatus: function(id){
				var _this = this;
				var model = this.$parent.model;
				DeviceResource.associateStatus(model.name,model.systems[0],id).then(
					function(response) {
						if(response.data[model.name+'s'][0].associateStatus  == 'ASSOCIATED'){
							_this.waiting = false;
							clearInterval(_this.diagnosticStatus);
							_this.eventHub.$emit('nextInternalMainStep');
							_this.eventHub.$emit('nextHeaderStep');
							_this.eventHub.$emit('addAssociateDevice', _this.device);
						}
				}).catch(
					function() {
					});
	},
 	}
}
