import EspPlugin from 'common/classes/cordova/plugins/EspPlugin.js'

const PLUGIN_PROCESS_TIMEOUT_ERROR = 'PLUGIN_PROCESS_TIMEOUT_ERROR'

const LAUNCH_PLUGIN_PROCESS_TIMEOUT = 40000;


export default {
	props: 
	{
		sentences:{
			type: Array,
			default:{}
		},
		img: {
			type: Array,
			default:{}
		},
		submodel:{
			type: Object
		},
		installObject: {
			type: Object
		},
		
		nextStepTimeout: {
			type: Number,
			default: 0
		},
		
		installType:{
			type: String
		},
	},
	data:
		function () {
			return {
				CONSTANTS:
				{
					SYNCHRONIZATION_ERROR: this.$gettext('The synchronization request could not be sent'),
					SYNCHRONIZATION_SUCCESS: this.$gettext('The synchronization request has been sent'),
				},
				loadingStep: false,
				launchNextStepTimeout: undefined,
			}
	},

	beforeDestroy () {
		clearTimeout(this.launchNextStepTimeout);
	},	

	created: function () {
		this.eventHub.$emit('setDisplayPrevious', true);
	}, 
	
	methods: {

		launchNextStep: function()
		{
			var _this = this;	
			this.loadingStep = true;
			clearTimeout(this.launchNextStepTimeout);
			this.launchNextStepTimeout = setTimeout(() => {
				_this.loadingStep = false;	
				_this.eventHub.$emit('nextInstallStep');
			}, this.nextStepTimeout);
		},
 	}
}