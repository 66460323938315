
import DeviceControl from 'common/components/widget/control-device/control-device.vue';
import CommonResource from 'common/services/resources/common.js'

export default {
    props: {},
    components: {DeviceControl},
    data: function () {
        return {
            show: true,
            module : 
            {
                device: {
                    model:''
                }
            },
            models:[],
            devices:[]
        }
    },
    created: function () {
        this.eventHub.$on('showMainControlDevice', this.showMainControlDevice);
    },
    destroyed: function () {
        this.eventHub.$off('showMainControlDevice', this.showMainControlDevice);
    },
    methods: {
        showMainControlDevice : function(module,devices,models){;
            this.show = true;
            this.module = module;
            this.devices = devices;
            this.models = models;
        },
        mainControlDeviceModalSetStatus : function(status, subItem){
            var deviceUid = subItem;
            if(deviceUid)
              deviceUid = (typeof subItem === 'string'?subItem:subItem.id);
            CommonResource.setStatus(this.module, status, deviceUid, this.eventHub);
        }
    }
}