import NotificationsSelect from 'common/components/notifications-select/notifications-select.vue'
import RoomsSelect from 'common/components/rooms-select/rooms-select.vue'
var stringify = require('json-stringify-safe');

export default {
	props: {
		service:{
			type: Object,
			default:{}
		},
		detectorsConfig:{
			type: Object,
			default:{}
		},
		alertsConfig:{
			type: Object,
			default:{}
		},
		appConstants:{
			type: Array,
		},
		devices:{
			type: Array,
		},
		step:{
			type: Number,
		},
		plant:{
			type: Object,
			default:{}
		},
		user:{
			type: Object,
			default:{}
		},
		defaultRooms:{
			type: Array,
			default:[]
		},
		editedService:{
			type: Object,
			default:undefined
		}
	},
	components: {NotificationsSelect,RoomsSelect},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						TITLE : this.$gettext('Configure your alarm kit'),
						ADD_DETECTOR_ALERT : this.$gettext('Please add a detector'),
						ADD_ALERT_ALERT : this.$gettext('Please add an alert'),
						NOTIFICATION_TITLE : _upperFirst(this.$gettext('notification')),
						ROOMS_TITLE : _upperFirst(this.$gettext('rooms')),
						INPUT_ERROR : this.$gettext('Please enter a name for your kit'),
						ROOM_ERROR : this.$gettext('Please select a room'),
					},
					title:'',
					detectorsExpansionList: 
					{
						title: this.$gettext(this.detectorsConfig.title),
						active: false,
						onClick:(d)=> {
							d.selected = !d.selected;
						},
						list: 
						[
							{
								title: this.$gettext('Add a new device'),
								icon:'cl-icon cl-icon-plus',
								onClick: ()=> {
									this.eventHub.$emit('addDevice');
								}	
							}
						]
					},
					alertsExpansionList: 
					{
						title: this.$gettext(this.alertsConfig.title),
						active: false,
						onClick:(d)=> {
							d.selected = !d.selected;
						},
						list: 
						[
							{
								title: this.$gettext('Add a new device'),
								icon:'cl-icon cl-icon-plus',
								onClick: ()=> {
									this.eventHub.$emit('addDevice');
								}	
							}
						]
					},
					name: '',
					notification : {
						"notification_level" : "INFO",
						"alert" : []
					}
			}
		},
	created: function () {
		this.init();
	},
	watch: {
		devices: function (val, oldVal) {
			this.initElements();
		},
	},
	destroyed: function () {
	}, 	
	methods: {
		init: function(){
			this.title = this.CONSTANTS.TITLE;

			if(this.service.name)
			{
				this.name = this.service.name;
			}

			if(_isEmpty(this.editedService)){
				this.notification.notification_level = 'ALERT'
				this.notification.alert = [];
			}
			else{
				this.eventHub.$emit('setServiceDisplayPrevious', false);
				this.notification.notification_level = this.editedService.notification_level;
				this.notification.alert = (this.editedService.alert?this.editedService.alert:[]);
			}
			this.initElements();
			this.setDetectorDevices();
			this.setAlertDevices();
		},

		initElements: function(){
			let _this = this;
			let detectorsDevices = _filter(this.devices, function(d) { 
				if(d.device && _this.detectorsConfig.availableModels.indexOf(d.device.model)!=-1)
				{
					return true;
				}
				return false; 
			});
			detectorsDevices.forEach(function(s){
				s.selected = false;
			});
			let alertsDevices = _filter(this.devices, function(d) { return (d.device && _this.alertsConfig.availableModels.indexOf(d.device.model)!=-1); });
			let alertsSecurityDevices = _filter(alertsDevices, function(a){
				if(_findIndex(a.device.systems, function(s){return s == 'security';})!=-1)
					return a
			});
			alertsSecurityDevices.forEach(function(s){
				s.selected = false; 
			});

			var detectorsDevicesCopy = JSON.parse(stringify(detectorsDevices));
			detectorsDevicesCopy.forEach(function(d) {
				var deviceFrom = _find(_this.detectorsConfig.uiElements[0].devices, {'id':d.id});  
				if(deviceFrom)
				{
					d.selected = deviceFrom.selected;
				}
			});

			this.detectorsConfig.uiElements[0].devices = detectorsDevicesCopy;

			detectorsDevicesCopy = JSON.parse(stringify(detectorsDevices));
			detectorsDevicesCopy.forEach(function(d) {
				var deviceFrom = _find(_this.detectorsConfig.uiElements[1].devices, {'id':d.id});  
				if(deviceFrom)
				{
					d.selected = deviceFrom.selected;
				}
			});

			this.detectorsConfig.uiElements[1].devices = detectorsDevicesCopy;


			var alertsSecurityDevicesCopy = JSON.parse(stringify(alertsSecurityDevices));
			alertsSecurityDevicesCopy.forEach(function(d) {
				var deviceFrom = _find(_this.alertsConfig.uiElements[0].devices, {'id':d.id});  
				if(deviceFrom)
				{
					d.selected = deviceFrom.selected;
				}
			});

			this.alertsConfig.uiElements[0].devices = alertsSecurityDevicesCopy;

			alertsSecurityDevicesCopy = JSON.parse(stringify(alertsSecurityDevices));
			alertsSecurityDevicesCopy.forEach(function(d) {
				var deviceFrom = _find(_this.alertsConfig.uiElements[1].devices, {'id':d.id});  
				if(deviceFrom)
				{
					d.selected = deviceFrom.selected;
				}
			});

			this.alertsConfig.uiElements[1].devices = alertsSecurityDevicesCopy;

		},

		setDetectorDevices: function(){
			if(this.editedService)
			{
				this.service.setUIElementsTriggers(this.editedService,this.detectorsConfig.uiElements[0]);
				this.service.setUIElementsTriggers(this.editedService,this.detectorsConfig.uiElements[1]);
			}
			
		},
		setAlertDevices: function(){
			if(this.editedService)
			{
				this.service.setUIElementsActions(this.editedService,this.alertsConfig.uiElements[0]);
				this.service.setUIElementsActions(this.editedService,this.alertsConfig.uiElements[1]);
			}
		},

        onClickRoom : function(zone){
			this.service.locationId = zone.id;
		},

		validate: function(){

			if(this.name=='')
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.INPUT_ERROR);
				return false;
			}
			this.service.name = this.name;
			
			this.service.infos.triggers = [];
			this.service.setTriggers(this.detectorsConfig.uiElements[0]);
			this.service.setTriggers(this.detectorsConfig.uiElements[1]);
			if(this.service.getTriggers().length==0)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.ADD_DETECTOR_ALERT);
				return false;
			}
			
			this.service.infos.actions = [];
			this.service.setActions(this.alertsConfig.uiElements[0]);
			this.service.setActions(this.alertsConfig.uiElements[1]);
			if(this.service.getActions().length==0)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.ADD_ALERT_ALERT);
				return false;
			}

			this.service.notificationLevel = this.$refs["notifications-select"].notification.notification_level;
			if(this.$refs["notifications-select"].notification.notification_level == 'ALERT')
			{
				this.service.alert = this.$refs["notifications-select"].notification.alert;
			}
			if(this.service.locationId=='')
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.ROOM_ERROR);
				return false;
			}
			this.eventHub.$emit('addEditServiceWizard');
		},	
  	}
}