import AddServiceChoose from './add-service-choose.vue'
import AddServiceWizard from './wizard/add-service-wizard.vue'

export default {
	props: {
		plant:{
			type: Object,
			default:{}
		},
		defaultRooms:{
			type: Array,
			default:[]
		},
		devices:{
			type: Array,
			default:[]
		},
		serviceModels:{
			type: Array,
			default:[]
		},
		appConstants:{
			type: Object,
			default:{}
		},
		user:{
			type: Object,
			default:{}
		},
		editedService:{
			type: Object,
			default:undefined
		},
		services:{
			type: Array,
			default:undefined
		},
		versionInfos:{
			type: Object,
			default:{}
		},
		brands:{
			type: Object,
			default:{}
		},
		capabilities:{
			type: Object,
			default: {}
		},
		models:{
			type: Object,
			default: {}
		},
		devicesByCapability:{
			type: Array,
			default:[]
		},
	},	
	components: {AddServiceChoose,AddServiceWizard},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						TITLE: _upperFirst(this.$gettext(this.editedService ? 'Edit a kit':'add a kit')),
					},
					title:'',
					stepName: '',
					mainStep : 1,
					selectedService:undefined,
					displayPrevious:false
			} 
		},
	created: function () {
		this.eventHub.$on('setServiceMainStep', this.setServiceMainStep);
		this.eventHub.$on('nextServiceMainStep', this.nextServiceMainStep);
		this.eventHub.$on('backServiceMainStep', this.backServiceMainStep);
		this.eventHub.$on('setServiceDisplayPrevious', this.setServiceDisplayPrevious);
		this.eventHub.$emit('changeOrientation', 'portrait');
		if(this.inApp){
			window.plugins.insomnia.keepAwake();
		}
		if(this.editedService)
		{
			this.initEditedService();
		}
	},
	destroyed: function () {
		this.eventHub.$off('setServiceMainStep', this.setServiceMainStep);
		this.eventHub.$off('nextServiceMainStep', this.nextServiceMainStep);
		this.eventHub.$off('backServiceMainStep', this.backServiceMainStep);
		this.eventHub.$off('setServiceDisplayPrevious', this.setServiceDisplayPrevious);
		//this.eventHub.$emit('changeOrientation', 'user');
		if(this.inApp){
			window.plugins.insomnia.allowSleepAgain();
		}
	},
	mounted: function () {
	},
	methods: {
		setServiceMainStep: function(value){
			this.mainStep = value;
		},
		nextServiceMainStep: function(){
			this.mainStep=parseInt(this.mainStep)+1;
		},
		backServiceMainStep: function(){
			this.mainStep=parseInt(this.mainStep)-1;
		},
		chooseService: function(s){
			this.selectedService=s;
			this.displayPrevious = true;
			this.nextServiceMainStep();
		},
		initEditedService: function(){
			this.selectedService=_find(this.serviceModels, { 'name': this.editedService.service.model });
			if(this.selectedService.submodels)
			{
				this.selectedService=_find(this.selectedService.submodels, { 'name': this.editedService.service.submodel});
			}
			this.nextServiceMainStep();
		},

		setServiceDisplayPrevious: function(displayPrevious){
			this.displayPrevious = displayPrevious;
		},
		servicePrevious: function(){
			this.backServiceMainStep();
		},
	}
}
