export default class{
    
    constructor() {
        this.mainLogo = 'main_logo.png';
        this.alternativeLogo = 'alternative_logo.png';
        this.displayMarket = true;
        this.manufacturerName = 'comfortlife';
        this.brandName = 'comfortlife';
        this.brandType = 'UNDEFINED';
        this.title = 'Comfort Life';
        this.scanNetworkButton = true;
        this.font = 
        {
            'family': 'Calibri',
            'bold':
            {
                'family': 'Calibri',
                'fontWeight': 'bold'
            }
        };

        this.defaultRoomsPath = 'images/default/rooms';

        this.colors = {
            'first' : '#2A3C6B',
            'second' : '#2A3C6B',
            'third' : '#2A3C6B',
            'selection': '#7d7d7d',
            'colorHover' : 'white',
            'btn': '#4364AC'
        };
        this.menu = {
            'logo' : 'logo_menu.png',
            'color' : '#1C2541',
            'hoverColor' : '#1C2541',
            'fontColor' : 'white',
            'displayFooter' : true,
            'displayVersion' : true
        };

        this.topMenu = {
            'backgroundColor' : '#2A3C6B',
            'color' : 'white',
            'hoverColor' : 'transparent',
        };

        this.helpMenu = {
            items:[
                'localControl',
                'faq',
                'contact',
                'suggestions',
                'downloadApp',
                'about'
            ]
        };

        this.login = {
            'background' : 'url(../assets/images/manufacturers/comfortlife/brands/comfortlife/login_background.jpg")',
            'logo' :
            {
                'padding' : '0'
            }
        };

        this.addRoom = {
            'selectDefaultRooms': true
        };

        this.stepper = {
            'color' : 'white',
            'backgroundColor' : '#2A3C6B',
            'hrColor' : 'white',
            'step':
            {
                'color' : 
                {
                    'complete' : '#9CCC52',
                    'active' : '#4364AC',
                    'inactive' : 'white',
                }
            },
        };

        this.popup = {
            'header':
            {
                'backgroundColor' : '#C5C5C5',
                'color' : '#3C3C3C'
            },
            'crossbar':
            {
                'color' : '#3C3C3C'
            }
        };

        this.installPopup = {
            'header':
            {
                'backgroundColor' : '#2A3C6B',
                'color' : 'white'
            },
            'crossbar':
            {
                'color' : 'white'
            }
        };

        this.expansionList = {
            'backgroundColor' : '#FFF',
            'color': '#2A3C6B',
            'header':
            {
                'backgroundColor' : '#F5F5F5',
                'color' : '#2A3C6B'
            },
        };

        this.scrollbarButton = {
            'backgroundColor' : 'rgba(42,60,107,0.5)',
            'color': 'white'
        };
        this.tutorial = {
            'logo': require('@/assets/images/manufacturers/comfortlife/brands/comfortlife/logo_splashscreen.png'),
            'background' : 'rgb(126,156,209)',
            'backgroundGradient' : 'linear-gradient(180deg, rgba(126,156,209,1) 0%, rgba(42,60,107,1) 100%)'
        };
        this.shareSplashScreen = {
            'background': require('@/assets/images/manufacturers/comfortlife/brands/comfortlife/login_background.jpg'),
            'logo': require('@/assets/images/manufacturers/comfortlife/brands/comfortlife/logo_splashscreen.png'),
            'title': 'Comfort Life',
            'subtitle': 'This sharing is no longer or not yet valid...'
        };
    }
    getMenuLogo(){
        return require('@/assets/images/manufacturers/comfortlife/brands/comfortlife/logo_menu.png');
    }
      
    getMainLogo(){
        return require('@/assets/images/manufacturers/comfortlife/brands/comfortlife/main_logo.png');
    }
  
    getAlternativeLogo(){
        return require('@/assets/images/manufacturers/comfortlife/brands/comfortlife/alternative_logo.png');
    }

} 
