import Global from './global.js'
import StorageService from '../storage.js'
import axios from 'axios'

export default {
    waiting: 
    {
        editPlant : false,
        addLocationTopology : false,
        editLocationTopology : false,
        edit : false,
        changeVisibility : false,
        associateImage : false
    },     
    getDefaultRooms: function () {
        return new Promise(
          function(resolve, reject){
              axios.get(Global.getBackendUrl()+'default/rooms/',
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                  }).then(function (response) {
                      return resolve(response);
                  })
                  .catch(function (error) {
                      return reject(error);
                  });
          }
        );
    }, 
    editPlant: function (name) {
      var _this = this;        
      return new Promise(
          function(resolve, reject){
            if(_this.waiting.editPlant == true)return false;
              axios.put(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId'),
                    {
                      "name": name
                    },
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                  }).then(function (response) {
                      _this.waiting.editPlant = false;
                      return resolve(response);
                  })
                  .catch(function (error) {
                      _this.waiting.editPlant = false;
                      return reject(error);
                  });
          }
      );
    },
    getPlantTopology: function (section,source,ignoreCache) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/topology?section='+section+(ignoreCache===true?'&ignoreCache='+ignoreCache:''),
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        cancelToken: source.token
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        if(error.response && error.response.status == 404)
                        {
                            return resolve({});
                        }
                        return reject(error);
                    });
            }
        );
    },
    addLocationTopology: function (name, level, containerId, key) {
        var _this = this;     
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.addLocationTopology == true)return false;
                var data ={
                    "containerId": containerId,
                    "level": level,
                    "name": name,
                  };
                if(key)
                {
                    data.key = key
                };
                axios.post(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/topology',
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.addLocationTopology = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.addLocationTopology = false;
                        return reject(error);
                    });
            }
        );
    },
    deleteLocationTopology: function (coal, coalId) {
        return new Promise(
            function(resolve, reject){
                axios.delete(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+coal+'/'+coalId+'/topology',
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    getLocationTopology: function (coal, coalId) {
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+coal+'/'+coalId+'/topology',
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },
    editLocationTopology: function (coal, coalId, name) {
        var _this = this;      
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.editLocationTopology == true)return false;
                axios.put(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+coal+'/'+coalId+'/topology',
                      {
                        "name": name
                        /*"level": level,
                        "containerId": containerId*/
                      },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.editLocationTopology = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.editLocationTopology = false;
                        return reject(error);
                    });
            }
        );
    },
    edit: function (coal, coalId, editBodyObj) {
        var _this = this;     
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.edit == true)return false;
                axios.put(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+coal+'/'+coalId+'/topology',
                    editBodyObj,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT
                    }).then(function (response) {
                        _this.waiting.edit = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.edit = false;
                        return reject(error);
                    });
            }
        );
    },
    changeVisibility: function (visible, section) {
        var _this = this;     
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.changeVisibility == true)return false;
                axios.put(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/modules/visibility/'+section,
                    visible,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        _this.waiting.changeVisibility = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.changeVisibility = false;
                        return reject(error);
                    });
            }
        );
    },
    /*Return all devices by type*/
    getAllDevicesByModel: function (devices, model) {
        var arr = [];
        if(model==undefined)
            return devices;
        devices.forEach(function(d) {
            if(d.device.model == model)
                arr.push(d);
        });
        return arr;
    },

    getAllDevicesModelObjByProperty: function (devices, property, value) {
        var arr = [];
        devices.forEach(function(d) {
            if(d.device.modelObj[property] == value)
                arr.push(d);
        });
        return arr;
    },

    associateImage: function (coal, coalId, image, eventHub) {
        var _this = this;
        return new Promise(
            function(resolve, reject){
                if(_this.waiting.associateImage == true)return false;
                if(typeof image === 'string')
                {
                    var data = {
                        'key' : image
                    }
                }
                else
                {
                    var data = new FormData();
                    data.append('image', image);
                }
                axios.post(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/'+coal+'/'+coalId+'/image',
                    data,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        onUploadProgress: function (e) {
                            eventHub.$emit('associateImageProgress', e);
                        }
                    }).then(function (response) {
                        _this.waiting.associateImage = false;
                        return resolve(response);
                    })
                    .catch(function (error) {
                        _this.waiting.associateImage = false;
                        return reject(error);
                    });
            }
        );
    },

    getIcons: function () {
        return new Promise(
          function(resolve, reject){
              axios.get(Global.getBackendUrl()+'icons',
                  {
                      headers: {
                          'Content-Type': 'multipart/form-data',
                          'Authorization': StorageService.getValue(localStorage, 'authorization')
                      },
                  }).then(function (response) {
                      return resolve(response);
                  })
                  .catch(function (error) {
                      return reject(error);
                  });
          }
        );
      },

     /* for themoment it's just an array with the key of the icon which can be choose for a light or an outlet*/
      getIconsName: function(icons){
        var iconsName = [];
        iconsName.push('outlet');
        iconsName.push('heating');
        for (var properties in icons) {
          for(var i=0; i<icons[properties].length; i++){
            if(properties!='comfort' && properties!='connectedObjects' && properties!='security')
                iconsName.push(icons[properties][i].name);
          }
        }
        return iconsName;
      },

      getKeyForModel: function(){
          var array = {
              'ecr' : 'heating',
              'bulb' : 'bulb',
              'prf' : 'outlet',
              'prfx' : 'outlet',
              'plug' : 'outlet',
              'ycb' : 'outlet',
              'frx' : 'outlet'
          }
          return array;
      },

    /**
     * Find a module in Plant with module uid*
     * @param {*} plant 
     * @param {*} moduleUid 
     */
    findModuleInPlant: function (plant,moduleUid) 
    {
        if(plant.zones)
        {
            for (var i=0;i<plant.zones.length;i++) 
            {
                if(plant.zones[i].modules)
                {
                    for (var j=0;j<plant.zones[i].modules.length;j++) 
                    {
                        if(plant.zones[i].modules[j].id==moduleUid)
                        {
                            return {module:plant.zones[i].modules[j], zone:plant.zones[i]};
                        }
                    }
                }
            }
        }
        return undefined;
    },

} 

/*const Location = {

  install(Vue, options) {
        Vue.setPlantTopology= function (t) {
            return new Promise(
                function(resolve, reject){
                    axios.get(Global.getBackendUrl()+'plants/'+StorageService.getValue(localStorage, 'plantId')+'/topology',
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                                'Authorization': StorageService.getValue(localStorage, 'autorization')
                            },
                        }).then(function (response) {
                            t.zones=response.data.plant.zones;
                            return resolve(response);
                        })
                        .catch(function (error) {
                            return reject(error);
                        });
                }
            );
    }
  }
};

export default Location;*/
