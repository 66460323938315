import DevicesList from 'common/components/devices-list/devices-list.vue'

export default {
	props:['receivers','settings','locationIds','plant', 'user', 'appConstants'],
	components: {DevicesList},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						SELECT_ITEM_ERROR: this.$gettext('Please select an item to share'),
						PRIVATE: _upperFirst(this.$gettext('private')),
						PUBLIC: _upperFirst(this.$gettext('public')),
						SHARE_EVERYTHING: _upperFirst(this.$gettext('Share everything')),
						SELECT_ITEMS: _upperFirst(this.$gettext('Select items')),
					},
					selectedItems: [],
					selectAll: true

			}
		},
	created: function () {
		this.init();
	},
	destroyed: function () {
		
	},
	mounted: function () {
	},
	computed: {
		/*filterSelectedItems: function () {
			this.selectedItems = [];
			var _this = this;
			this.plant.zones.forEach(function(zone) {
				if(zone.modules)
				{
					var z = {
						name: zone.name,
						id: zone.id,
						selected: zone.selected,
                        modules: _filter(zone.modules, function(o) { return o.device; })
					}
					_this.selectedItems.push(z);
				}
			});
			console.log('selectedItems');
			console.log(this.selectedItems);
			return this.selectedItems;
		},*/
	},
	watch: {
  	},
	methods: {

		next: function(){
			this.addLocationIds();
			if(this.locationIds.length==0)
			{
				this.eventHub.$emit('displayAlert',  this.CONSTANTS.SELECT_ITEM_ERROR);
				return false;
			}
			this.$emit('setEnableStep','AddShareAddReceiver',(this.settings.type=='PRIVATE'));
			if(this.settings.type=='PUBLIC')
			{
				this.receivers.splice(0);
			}
			this.$emit('nextStep');
		},

		init: function(){

			this.selectedItems.splice(0);
			var _this = this;
			this.plant.zones.forEach(function(zone) {
				var z = {
					name: zone.name,
					id: zone.id,
					selected: zone.selected
				}
				if(zone.modules)
				{
					z.modules = _filter(zone.modules, function(o) { 
						return o.device || o.service || o.scenario ; 
					});
				}
				_this.selectedItems.push(z)
			});

			this.selectedItems.forEach(function(zone) {
				zone.selected = false;
				zone.modules.forEach(function(module) {
					module.selected = false;		
				});
			});
		},

		addLocationIds: function(){
			var _this = this;
			this.locationIds.splice(0);
			if(this.selectAll)
			{
				this.locationIds.push(this.plant.id);
			}
			else
			{
				this.selectedItems.forEach(function(zone) {
					if(zone.selected)
					{
						_this.locationIds.push(zone.id);
					}
					else{
						if(zone.modules)
						{
							zone.modules.forEach(function(module) {
								if(module.selected)
								{
									_this.locationIds.push(module.id);
								}
							});
						}
					}
				});
			}

		}
  	}
}
