import { render, staticRenderFns } from "./up-down.vue?vue&type=template&id=9fe23876&scoped=true&"
import script from "./up-down.js?vue&type=script&lang=js&"
export * from "./up-down.js?vue&type=script&lang=js&"
import style0 from "./up-down.css?vue&type=style&index=0&id=9fe23876&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fe23876",
  null
  
)

export default component.exports