import ExternalResource from 'common/services/resources/external.js'
import NumberService from 'common/services/number.js'
import CordovaService from 'common/services/cordova.js'
import SystemResource from 'common/services/resources/system.js'
import AddDeviceChooseType from './add-device-choose-type.vue'
import AddDeviceChooseBrand from './add-device-choose-brand.vue'
import AddDeviceGdpr from './add-device-gdpr.vue'
import AddDeviceWizard from './wizard/add-device-wizard.vue'
import AddDeviceSoonAvailable from './add-device-soon-available.vue'
import AddDeviceError from './add-device-error.vue'
import AddDeviceDiscoverInstallation from './add-device-discover-installation.vue'
import ScrollbarButton from 'common/components/scrollbar-button/scrollbar-button.vue'
import store from 'vuex_path/store'

export default {
	props:['plant','models','homepilots', 'brands', 'brandGroups', 'defaultRooms', 'systems', 'externalAccounts', 'externalName', 'externalRoute', 'devices', 'icons', 
	'homepilot',
	'brandsName',
	'availableModels',
	'user',
	'versionInfos'
	],
	components: {AddDeviceDiscoverInstallation,AddDeviceChooseType,AddDeviceChooseBrand,AddDeviceGdpr,AddDeviceWizard,AddDeviceSoonAvailable,AddDeviceError,ScrollbarButton},
	data:
		function () {
			return {
				    CONSTANTS:
					{
						NOT_AVAILABLE_SERVICES: ['samsung'],
						MULTICHOICE_INSTALL: ['home_confort'],
						EXTERNAL_TXT:
						{
						  TITLE : this.$gettext('Add your %{s} devices to %{appName}'),
						  INTRODUCTORY : this.$gettext('Link your %{s} Account to %{appName} using your <strong>%{s} login and password</strong>. Get a list of your equipment, optionally associate rooms, and enjoy all the features of %{appName}!'),
						  VALIDATE_BUTTON : this.$gettext('start the association')
						},
						CLIENT_TXT:
						{
						  TITLE : this.$gettext('Link your %{appName} devices to %{s}'),
						  INTRODUCTORY : this.$gettext('We\'ll explain step by step how to pair your %{appName} devices with the %{s} app.'),
						  VALIDATE_BUTTON : this.$gettext('see the procedure')
						},
						UNDEFINED_TXT:
						{
						  TITLE : this.$gettext('Add your %{s} devices to %{appName}'),  
						  INTRODUCTORY : this.$gettext('Link your %{s} Account to %{appName} using your <strong>%{s} login and password</strong>. Get a list of your equipment, optionally associate rooms, and enjoy all the features of %{appName}!'),
						  VALIDATE_BUTTON : this.$gettext('start the association')
						},
						COMMON_TXT : this.$gettext('<strong>From the %{appName} app</strong>, you\'ll be able to manage your %{s} devices.'),
						GDPR_WARNING : this.$gettext('as part of the GDPR, once the association of your account made, %{appName} can no longer guarantee the protection of your data made by %{s}.'),
                        SCAN_PROMPT : this.$gettext('Place a QRCODE inside the scan area'),
                        SCAN_ERROR : this.$gettext('Unrecognized product, please select it from the list')
                    
                    },
					displayTitle: true,
					title:'',
					wizardTitle:'',
					grdpWarning:'',
					commonTxt:'',
					introductory:'',
					validateButton:'',
					brand: {},
					brandGroup: {},
					stepName: '',
					mainStep : 1,
					wizardStep : 1,
					headerStep : 1,
					stepper : [],
					displayPrevious: true,
					noPreviousForWizard: false,
					chooseType: '',
                    chooseWizard: '', 
                    model: {},
                    submodel: '',
					prefilledMac : '',
					selectedHomepilot: undefined,
					qrCodeElts : []
			}
		},
	created: function () {
		this.eventHub.$on('initTxt', this.initTxt);
		this.eventHub.$on('addExternalAccount', this.addExternalAccount);
		this.eventHub.$on('initSteps', this.initSteps);
		this.eventHub.$on('setWizardStep', this.setWizardStep);
		this.eventHub.$on('nextWizardStep', this.nextWizardStep);
		this.eventHub.$on('backWizardStep', this.backWizardStep);
		this.eventHub.$on('initMainStepFromInternal', this.initMainStepFromInternal);
		this.eventHub.$on('setMainStep', this.setMainStep);
		this.eventHub.$on('nextMainStep', this.nextMainStep);
		this.eventHub.$on('backMainStep', this.backMainStep);
		this.eventHub.$on('nextHeaderStep', this.nextHeaderStep);
		this.eventHub.$on('backHeaderStep', this.backHeaderStep);
		this.eventHub.$on('setHeaderStep', this.setHeaderStep);
		this.eventHub.$on('setStepper', this.setStepper);
		this.eventHub.$on('unshiftStepper', this.unshiftStepper);
		this.eventHub.$on('addDeviceFinished', this.addDeviceFinished);
		this.eventHub.$on('previous', this.previous);
		this.eventHub.$on('setDisplayPrevious', this.setDisplayPrevious);
		this.eventHub.$on('setTitle', this.setTitle);
		this.eventHub.$on('setWizardTitle', this.setWizardTitle);
		this.eventHub.$on('setGrdpWarning', this.setGrdpWarning);
		this.eventHub.$on('setDisplayTitle', this.setDisplayTitle);
		this.eventHub.$on('setBrand', this.setBrand);
		this.eventHub.$on('setBrandGroup', this.setBrandGroup);
		this.eventHub.$on('setStepName', this.setStepName);
        this.eventHub.$on('setChooseWizard', this.setChooseWizard);
        this.eventHub.$on('barcodeScan', this.barcodeScan);
		this.eventHub.$on('initQRCodeElts', this.initQRCodeElts);
		this.eventHub.$on('setQRCodeElts', this.setQRCodeElts);
        this.eventHub.$on('setModel', this.setModel);
		this.eventHub.$on('setSubmodel', this.setSubmodel);
		this.eventHub.$on('setHomepilots', this.setHomepilots);
		this.eventHub.$on('setSelectedHomepilot', this.setSelectedHomepilot);
        this.eventHub.$on('barcodeScanSuccess', this.barcodeScanSuccess);
		this.eventHub.$emit('changeOrientation', 'portrait');
		this.eventHub.$emit('changeDisplayCloseIconInstallModal', true);
		if(this.inApp){
			window.plugins.insomnia.keepAwake();
		}
		if(this.homepilot != null){
			this.homepilots = [this.homepilot];
		}
	},
	destroyed: function () {
		this.eventHub.$off('initTxt', this.initTxt);
		this.eventHub.$off('addExternalAccount', this.addExternalAccount);
		this.eventHub.$off('initSteps', this.initSteps);
		this.eventHub.$off('setWizardStep', this.setWizardStep);
		this.eventHub.$off('nextWizardStep', this.nextWizardStep);
		this.eventHub.$off('backWizardStep', this.backWizardStep);
		this.eventHub.$off('initMainStepFromInternal', this.initMainStepFromInternal);
		this.eventHub.$off('setMainStep', this.setMainStep);
		this.eventHub.$off('nextMainStep', this.nextMainStep);
		this.eventHub.$off('backMainStep', this.backMainStep);
		this.eventHub.$off('nextHeaderStep', this.nextHeaderStep);
		this.eventHub.$off('backHeaderStep', this.backHeaderStep);
		this.eventHub.$off('setHeaderStep', this.setHeaderStep);
		this.eventHub.$off('setStepper', this.setStepper);
		this.eventHub.$off('unshiftStepper', this.unshiftStepper);
		this.eventHub.$off('addDeviceFinished', this.addDeviceFinished);
		this.eventHub.$off('previous', this.previous);
		this.eventHub.$off('setDisplayPrevious', this.setDisplayPrevious);
		this.eventHub.$off('setTitle', this.setTitle);
		this.eventHub.$off('setWizardTitle', this.setWizardTitle);
		this.eventHub.$off('setGrdpWarning', this.setGrdpWarning);
		this.eventHub.$off('setDisplayTitle', this.setDisplayTitle);
		this.eventHub.$off('setBrand', this.setBrand);
		this.eventHub.$off('setBrandGroup', this.setBrandGroup);
		this.eventHub.$off('setStepName', this.setStepName);
        this.eventHub.$off('setChooseWizard', this.setChooseWizard);
        this.eventHub.$off('barcodeScan', this.barcodeScan);
		this.eventHub.$off('initQRCodeElts', this.initQRCodeElts);
		this.eventHub.$off('setQRCodeElts', this.setQRCodeElts);
        this.eventHub.$off('setModel', this.setModel);
		this.eventHub.$off('setSubmodel', this.setSubmodel);
		this.eventHub.$off('setHomepilots', this.setHomepilots);
		this.eventHub.$off('setSelectedHomepilot', this.setSelectedHomepilot);
        this.eventHub.$off('barcodeScanSuccess', this.barcodeScanSuccess);
		//this.eventHub.$emit('changeOrientation', 'user');
		if(this.inApp){
			window.plugins.insomnia.allowSleepAgain();
		}
	},
	mounted: function () {
		if(this.$route.name=='redirect')
		{
			this.goToExternal();
		}
	},
	watch: {
		wizardStep: function (val, oldVal) {
			this.$refs['scrollable-button'].init();
		},
		mainStep: function (val, oldVal) {
			this.$refs['scrollable-button'].init();
		},
		installStep: function (val, oldVal) {
			this.$refs['scrollable-button'].init();
		},
		headerStep: function (val, oldVal) {
			this.$refs['scrollable-button'].init();
		}
  	},
	methods: {
		goToExternal: function(){
			if(this.externalName!='' && this.externalName!='error')
			{
				var brand = _find(this.brands, {'name':this.externalName});
				if(brand)
				{
					this.setBrand(brand);
				}
				this.mainStep = (brand && this.externalRoute!='error' ? 3 : 4);
				this.eventHub.$emit('setChooseWizard', brand.wizard.type);
			}
			else
			{
				this.mainStep = 4;
			}
		},
		initTxt: function(brand){
			if(brand.wizard.type=='INTERNAL')return false;
			var txtType = brand.wizard.type+'_TXT';
			this.wizardTitle= this.$gettextInterpolate(this.CONSTANTS[txtType].TITLE, {s: brand.displayName, appName: this.$store.state.variantManager.class.title});
			this.commonTxt= this.$gettextInterpolate(this.CONSTANTS.COMMON_TXT, {s: brand.displayName, appName: this.$store.state.variantManager.class.title});
			this.introductory=this.$gettextInterpolate(this.CONSTANTS[txtType].INTRODUCTORY, {s: brand.displayName, appName: this.$store.state.variantManager.class.title});
			this.grdpWarning=this.$gettextInterpolate(this.CONSTANTS.GDPR_WARNING, {s: brand.manufacturer.displayName, appName: this.$store.state.variantManager.class.title});
			this.validateButton=this.CONSTANTS[txtType].VALIDATE_BUTTON;
        },
        
		initSteps: function(){
			this.mainStep = 1,
			this.wizardStep = 1,
			this.headerStep = 1,
			this.stepper = [
				{'title' : ''},
				{'title' : ''}
			],
			this.chooseType = '';
            this.brandGroup = {};

            this.initQRCodeElts();
			
        },

        initQRCodeElts: function(){
            
            this.model = {};
            this.submodel = '';
            this.prefilledMac = '';
			this.qrCodeElts = [];
			this.selectedHomepilot = undefined;					
		},
		
        setQRCodeElts: function(qrCodeElts){
            this.qrCodeElts = qrCodeElts;
		},
		
		initMainStepFromInternal: function(){
			//If multi choice install
			var oneMoreBrand = (this.brandGroup.brands && this.brandGroup.brands.length>1);
			if(oneMoreBrand)
			{
				this.setBrand(this.brandGroup);
				this.setMainStep(2);
			}
			else if(this.CONSTANTS.MULTICHOICE_INSTALL.indexOf(this.brand.name)!=-1)
			{
				this.setMainStep(this.chooseType=='deviceInstall'?2:1);
				this.chooseType = '';
			}
			else
			{
				this.setMainStep(1);
			}
		},
		setMainStep: function(value){
			this.mainStep = value;
		},
		nextMainStep: function(){
			this.mainStep=parseInt(this.mainStep)+1;
		},
		backMainStep: function(){
			this.mainStep=parseInt(this.mainStep)-1;
		},
		setWizardStep: function(value){
			this.wizardStep = value;
		},
		nextWizardStep: function(){
			this.wizardStep=parseInt(this.wizardStep)+1;
		},
		backWizardStep: function(){
			this.wizardStep=parseInt(this.wizardStep)-1;
		},
		nextHeaderStep: function(){
			this.headerStep=parseInt(this.headerStep)+1;
		},
		backHeaderStep: function(){
			this.headerStep=parseInt(this.headerStep)-1;
		},
		setHeaderStep: function(value){
			this.headerStep = value;
		},
		setStepper: function(value,headerStep){
			this.stepper = value;
			if(headerStep!=undefined)
			{
				this.$nextTick(function() {
					this.headerStep = headerStep;
				});
			}
		},

		setHomepilots: function(homepilots){
			this.homepilots = homepilots;
		},

		setSelectedHomepilot: function(homepilot){
			this.selectedHomepilot = homepilot;
		},

		unshiftStepper: function(step){
			this.stepper.unshift(step);
		},
		
		addDeviceFinished: function(){
			if(this.$route.name=='device-install')
				this.$router.push('/home/rooms');
			else
				this.eventHub.$emit('closeInstallModal','installModal');
		},

		previous: function(){
			// If the brand proposes association and installation of equipment
			if ([2].indexOf(this.mainStep)!=-1)
			{
				var oneMoreBrand = (!store.state.variantManager.isComfortlife() && this.brandGroup.brands && this.brandGroup.brands.length>1);
				if(this.chooseType=='associate')
				{
					if(oneMoreBrand)
					{
						this.setBrand(this.brandGroup);
					}
					this.chooseType = '';
				}
				else
				{
					if(this.$refs['add-device-choose-type']==undefined && this.brandGroup.brands)
					{
						this.setBrand(this.brandGroup);
					}
					else
					{
						this.backMainStep();
					}
				}
			}
			else
			{
				if(this.stepName=='wizard-internal' || this.stepName=='wizard-client')
				{
					if(this.wizardStep < 2 && (this.CONSTANTS.MULTICHOICE_INSTALL.indexOf(this.brand.name)!=-1))
					{
						var e = _find(this.externalAccounts.externalAccounts, {external:{name:this.brand.name}});
						if(this.availableModels !=undefined){
							e = true;
						}
						if(e==undefined)
						{
							this.chooseType = '';
						}
						if(this.inApp)
						{
							if(this.wizardStep==1)
							{
								this.setMainStep(e?1:2)
							}
							else
							{
								this.eventHub.$emit('wizardPrevious');
							}
							
						}
						else
						{
							this.setMainStep(e?1:2)
						}		
					}
					else{
						//Call to specific wizard previous
						this.eventHub.$emit('wizardPrevious');
					}
					
				}
				else
				{
					if(this.wizardStep==1)
					{
						this.backMainStep();
					}
					else
					{
						this.backWizardStep();
					}
				}
			}
		},
		setDisplayPrevious : function(value){
			this.displayPrevious = value;
		},
		setBrand : function(brand){		
			brand.available = (this.CONSTANTS.NOT_AVAILABLE_SERVICES.indexOf(brand.name)==-1);
			brand.onlyAppInstall = (this.inApp && brand.wizard && brand.wizard.type=='EXTERNAL' && brand.available);
			this.brand = brand;
		},

		setBrandGroup : function(brandGroup){		
			this.brandGroup = brandGroup;
        },
        
		setStepName : function(stepName){
			this.stepName = stepName;
        },
        
		setModel : function(model){
			this.model = model;
        },

        setSubmodel : function(submodel){
			this.submodel = submodel;
        },
        
		setDisplayTitle : function(value,title){
			this.displayTitle = value;
			this.setTitle((title?title:''));
		},

		setTitle : function(title){
			this.title = this.$gettext(title);
		},

		setWizardTitle : function(wizardTitle){
			this.wizardTitle = wizardTitle;
		},

		setGrdpWarning : function(grdpWarning){
			this.grdpWarning = grdpWarning;
		}, 

		addExternalAccount : function(account){
			var _this = this;
			var externalAccount = {
				'account': account,
				'external': this.brand
			}
			ExternalResource.getExternalDisplay(externalAccount.external.name).then(
				function(response) {
					externalAccount.external.url = response.data.url;
					_this.externalAccounts.externalAccounts.push(externalAccount);
				}).catch(
				function() {
				});
		},
		chooseInstallType : function(type){
			this.chooseType = type;
			if (type == 'deviceInstall')
			{
				var devices = _filter(this.devices, function(o) { return o.device.brandObj && o.device.brandObj.wizard.type == 'INTERNAL'; });
				var hcDevices = _filter(this.devices, function(o) { return o.device.brandObj && o.device.brandObj.name == 'home_confort'; });
				if(this.versionInfos.deviceCount != 'INF' && devices.length+hcDevices.length >= this.versionInfos.deviceCount){
					this.eventHub.$emit('displayAlertKey', 'unauthorized');
					this.chooseType = '';
				}
				else{
					this.chooseWizard = 'INTERNAL';
					this.setMainStep(3);
				}
			}
			else if(type == 'discover')
			{
				this.setMainStep(2);
			}
		},
		setChooseWizard : function(chooseWizard){
			this.chooseWizard = chooseWizard;
        },
        
        /*QR CODE */

        barcodeScan: function(type){
            CordovaService.barcodeScan(this.eventHub,this.CONSTANTS.SCAN_PROMPT);
			//this.barcodeScanSuccess({text: '16;23;AAAAAAAAAAAA'});
			//this.barcodeScanSuccess({text: '12;1;XXXX-666666-XXXXXXX'});
        },
		
        barcodeScanSuccess: function(result){
			//var result = {text: 'Semac;HomePilot;AAAAAAAAAAAA;'};
            this.model = {};
            this.submodel = '';
            this.prefilledMac = '';
            this.qrCodeElts = [];
            
            if(result.text)
            {
				var qrCodeElts = result.text.split(';');
				//If the QR Code has only one element, it will be prefixed if the prefix exists
				if(qrCodeElts.length==1)
				{
					if(!(/(\w{4})-(\d{6})-(\w{7})/.test(result.text)))
					{
						this.eventHub.$emit('displayAlert',  this.CONSTANTS.SCAN_ERROR);
						return false;	
					}
					result.text = (this.$store.state.variantManager.class.QRCode? this.$store.state.variantManager.class.QRCode.prefix: '')+result.text;
					qrCodeElts = result.text.split(';');
				}
                if(qrCodeElts.length<2)
                {
                    this.eventHub.$emit('displayAlert',  this.CONSTANTS.SCAN_ERROR);
                    return false;
                }
				this.qrCodeElts = qrCodeElts;


                var brand = qrCodeElts[0].toLowerCase();
                var subModel = qrCodeElts[1].toLowerCase();
				var brandObj = undefined;

                if(NumberService.checkHex(brand))
                {
                    brandObj = SystemResource.getBrandByIndex(this.brands,brand);
                    
				}
				
				if(brandObj==undefined)
				{
					brandObj = _find(this.brands, {'name': brand});
				}

				brand = (brandObj?brandObj.name:brand);

                if(NumberService.checkHex(subModel))
                {
                    var subModelObj = SystemResource.getSubmodelBySubmodelIndexAndBrandName(this.models,brand,subModel);
                    subModel = (subModelObj?subModelObj.name:subModel);
				}
                if(typeof subModel == 'string')
                {
					var subModelObj = SystemResource.getSubmodelBySubmodelNameAndBrandName(this.models,brand,subModel);
                    subModel = subModelObj;
				}
				var model = undefined;
				if(subModel)
				{
					model = SystemResource.searchModelBySubModel(this.models,subModel);
				}
                if(model)
                {
                    this.model = model;
                    this.submodel = subModel;
                    if(model.name=='homepilot')
                    {
                        var mac = qrCodeElts[2];
                        if(mac)
                        {
                            this.prefilledMac = mac;
						}
					}
                    this.setBrand(brandObj);
                    this.setMainStep((brandObj.wizard.type=='INTERNAL'?3:2));
                    this.setChooseWizard(brandObj.wizard.type);
                    this.setDisplayPrevious(true);
                    this.initTxt(brandObj);
                    this.setWizardStep(3);
                }
                else
                    this.eventHub.$emit('displayAlert',  this.CONSTANTS.SCAN_ERROR);
            }
		},


  }
}
