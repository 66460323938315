import CommonResource from 'common/services/resources/common.js'
import DeviceResource from 'common/services/resources/device.js'

export default {
	data:
        function () {
            return {
                    CONSTANTS:{
                                ADD_ERROR : this.$gettext('Unable to add the device'),
                                ADD_SUCCESS : this.$gettext('The device has been successfully added')
                            },
                    loading:false
            }
    },
	created: function () {
	},
	methods: {
    	previous : function(){
			this.eventHub.$emit('backInstallStep');
		},
		test: function(relayUid)
		{
			CommonResource.setStatus(this.$parent.deviceObj, undefined, relayUid,this.eventHub);
		},
		validateDevice: function(){
            this.loading = true;
            var commandId = this.uuidV4();
            var _this = this;
            DeviceResource.validate(this.$parent.deviceObj.device.model,this.$parent.deviceObj.device.systems[0],commandId,this.$parent.deviceObj.id).then(
                function(response) {
                    _this.loading = false;
                    _this.eventHub.$emit('nextInternalMainStep');
                    _this.eventHub.$emit('nextHeaderStep');
                }).catch(
                function(e) {
                    _this.loading = false;
                    if([402].indexOf(e.response.status)!=-1)
						_this.eventHub.$emit('displayAlertKey', 'unauthorized'); 
                });
		}
  }
}
