import WindowService from 'common/services/window.js'
import LanguageService from 'common/services/language.js'
import Global from 'common/services/resources/global.js'
import StorageService from 'common/services/storage.js'

import {
	DEVICE_TXT,
	SCENARIOS_TXT,
	VOICE_ASSISTANT_TXT,
	REMAINING_CREDIT,
	REFRESH_DATA,
	FAQ_MENU,
	CONTACT_MENU,
	SUGGESTIONS_MENU,
	VIEWS_MENU,
	ROOMS_MENU,
	HELP_MENU
} from 'common/constants/menu'

export default {
    props:
      {
      	configMenu: {
			type: Object,
			default: () => {}
		},
		refreshButton:{
			type: Boolean,
			default:null
		},
		notificationsModules:{
			type: Array,
			default:() => []
		},
		location:{
			type: String,
			default:'main-panel'
		},
		subMenuSharesLocations:{
			type: Array,
			default:() => []
		},
	},
    data:
        function () {
            return {
    					CONSTANTS:
                		{
									DEVICE_TXT : this.$gettext(DEVICE_TXT),
									VOICE_ASSISTANT_TXT : this.$gettext(VOICE_ASSISTANT_TXT),
									REMAINING_CREDIT:  this.$gettext(REMAINING_CREDIT),
									REFRESH_DATA:  this.$gettext(REFRESH_DATA),
									FAQ_MENU:  this.$gettext(FAQ_MENU),
									CONTACT_MENU:  this.$gettext(CONTACT_MENU),
									SUGGESTIONS_MENU:  this.$gettext(SUGGESTIONS_MENU),
									VIEWS_MENU:  _upperFirst(this.$gettext(VIEWS_MENU)),
									ROOMS_MENU:  _upperFirst(this.$gettext(ROOMS_MENU)),
									HELP_MENU:  _upperFirst(this.$gettext(HELP_MENU)),
									SCENARIOS_TXT:  _upperFirst(this.$gettext(SCENARIOS_TXT)),
						  },
    					  displaySubMenuModal: false,
    					  speechRecognitionModal:false,
    					  showMenu: true,
    					  speechRecognitionOptions:
    					  {
    						  language:'fr-FR',
    						  prompt:'',
    						  showPopup:false,
    						  showPartial:false
							},
							refreshDatasInProgress:false,
							marketOpen: false,
							route: '',
							mobile: false,
							activeMenu:'',
							clickOnMenu: false,
							clickOnAdd: false,
							clickOnHome: false
            			}
		},
		computed: {
			total : function(){
				var count = 0;
				let notifications = _filter(this.notificationsModules, (n) => { return n.plantId == this.$store.state.selectedPlantId; });
				if(notifications){
					for(var i=0; i < notifications.length; i++){
						count = count + notifications[i].notifications.length;
					}
				}
				return count;
			}/*,
			homesList : function(){
				var arr = [];
				console.log('HOMESLIST');
				console.log(this.subMenuSharesLocations);
				if(this.$route.name=='rooms')
				{
					arr.push(
						{
							text: this.CONSTANTS.VIEWS_MENU,
							class: 'cl-cp-menu-content__main-btn__dashboard',
							icon: 'cl-icon cl-icon-screen',
							onClick: function(){
								this.eventHub.$emit('goTo','view');
							}
						}
					);
				}
				else
				{
					arr.push(
						{
							text: this.CONSTANTS.ROOMS_MENU,
							class: 'cl-cp-menu-content__main-btn__rooms',
							icon: 'cl-icon cl-icon-home',
							onClick: function(){
								this.goToSelectView();
							}
						}
					);
				}
				console.log(arr);
				return arr;
			}*/
	},
    watch: {
        displaySubMenuModal: function (val, oldVal) {
			this.eventHub.$emit('launchDisplaySubMenuModal', val);
        },
    },
    created: function () {
		this.launchResize();
		this.eventHub.addEvent('setMenu', this.setMenu);
		this.eventHub.$on('executeVoiceCommand', this.executeVoiceCommand);
		this.eventHub.$on('displayMarket', this.displayMarket);
		this.eventHub.$on('refreshDatas', this.refreshDatas);
		this.eventHub.$on('getAllTopologyFinished', this.refreshDatasFinished);
		this.eventHub.$on('displaySubMenu', this.displaySubMenu);
		this.eventHub.$on('setClickOnMenu', this.setClickOnMenu);
		this.eventHub.$on('setClickOnAdd', this.setClickOnAdd);
		this.eventHub.$on('resetOnClickButtons', this.resetOnClickButtons);
		window.addEventListener('resize', this.launchResize);
	},
    destroyed: function ()
    {
        this.eventHub.$off('executeVoiceCommand', this.executeVoiceCommand);
		this.eventHub.$off('displayMarket', this.displayMarket);
		this.eventHub.$off('refreshDatas', this.refreshDatas);
		this.eventHub.$off('getAllTopologyFinished', this.refreshDatasFinished);
		this.eventHub.$off('displaySubMenu', this.displaySubMenu);
		this.eventHub.$off('setClickOnMenu', this.setClickOnMenu);
		this.eventHub.$off('setClickOnAdd', this.setClickOnAdd);
		this.eventHub.$off('resetOnClickButtons', this.resetOnClickButtons);
		window.removeEventListener('resize', this.launchResize);
	},
	methods: {

		resetOnClickButtons: function(){
			this.clickOnAdd = false;
			this.clickOnMenu = false;
			this.clickOnHome = false;
		},
		setClickOnAdd: function(b){
			this.clickOnAdd = b;
		},
		setClickOnMenu: function(b){
			this.clickOnMenu = b;
		},
		
		launchResize : function(){
			if(window.innerWidth<=1024)
			{
				this.mobile = true;
			}
			else
			{
				this.mobile = false;
			}
		},
		setMenu : function(b){
			this.showMenu = b;
		},
		displayMenu : function(){
			this.eventHub.$emit('showMenu');
		},
		displaySubMenu: function (menuType) {
			if(!_isEmpty(this.configMenu)){
				this.eventHub.$emit('init'+menuType+'Menu');
				this.displaySubMenuModal = true;
				this.route = this.$route.name;
			}
		},
		selectSubMenu: function (clickFunc) {
			this.displaySubMenuModal = false;
			clickFunc();
		},
		startSpeechRecognition: function () {
			this.eventHub.$emit('startSpeechRecognition');
		},
		startSnipsSpeechRecognition: function () {
			this.eventHub.$emit('startSnipsSpeechRecognition');
		},
		displayMarket: function (){
			var isIphone = ((navigator.userAgent.indexOf("iPhone") != -1) || navigator.userAgent=='ComfortLife-iOS');
			if(isIphone){
				if(this.inApp)
					cordova.InAppBrowser.open(Global.WEBSITE_STORE, '_system');
				else
					window.open(Global.WEBSITE_STORE);
			}
			else{
				window.open(Global.WEBSITE_STORE);
				//this.eventHub.$emit('goTo','marketplace');
			}
		},
		marketIcon: function(){
			if(window.innerWidth<1024 && this.$route.name != 'marketplace' && this.$store.state.variantManager.displayMarket())
				return true;
			else
				return false;
		},
		loadArticle: function(){
			this.marketOpen = (this.marketOpen?false:true);
			this.eventHub.$emit('setExtendRightPanel', this.marketOpen);
		},

		//Refresh all datas
		refreshDatas: function(){
			if(!this.refreshDatasInProgress)
			{
				this.refreshDatasInProgress = true;
				this.eventHub.$emit('launchAllElements',true);
			}
		},
		refreshDatasFinished: function(){
			if(this.refreshButton)
			{
				this.refreshDatasInProgress = false;
			}
		},
    	openSection: function(section) {
			var lang = LanguageService.websiteUserLanguage();
			WindowService.open(Global.REDIRECT_URI_PROD+'/'+lang+'/'+section+'-'+lang,'_blank',this.inApp);
		},
		
    	goToSelectView: function() {
			var defaultView = StorageService.getValue(localStorage, 'defaultView');
			this.eventHub.$emit('initSwiperRoom');
			this.eventHub.$emit('goTo',(defaultView?'view':'rooms'));
		},
		
		launchClickOnMenu: function() {
			this.clickOnAdd=false;
			this.clickOnMenu=!this.clickOnMenu;
			/*if((this.$route.name=='sharesLocations'||this.$route.name=='rooms'||this.$route.name=='actions')&&this.clickOnMenu)
			{
				this.clickOnMenu=false;
			}
			else{
				this.clickOnMenu=true;
			};*/
			this.displayMenu();
		},


		onClickOutsideMenu: function() {
			this.clickOnHome = false;
		}




	}
}
