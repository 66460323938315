export default {
    props: ['module', 'config','use'],
    data () {
      return {
      }
    },
    created: function(){
    },
    methods: {
  		  setStatus : function(relay){
          if(this.use == "scenario-action")
          {
            this.eventHub.$emit('createScenarioItem', this.module, undefined, relay, this.use);
          }
          else
          {
            this.$parent.$emit('set-status',undefined, relay.id);
          }
  		  }
  	}
}
