import Global from './global.js'
import StorageService from '../storage.js'
import ArrayService from '../array.js'
import axios from 'axios'
import { consoleError } from 'vuetify/es5/util/console';

function getAllEntities(systems)
{
    var entities = [];
    var result = systems.map(function(s) {return s.entities;});
    for(var i = 0; i < result.length; i++)
    {
        entities = entities.concat(result[i]);
    }
    entities.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
    return entities;
}

function addCapabilitiesStatesForList(models)
{
    for(var i = 0; i < models.length ; i++ )
    {
        var model = models[i];
        var arr = [];
        for(var j = 0; j < model.capabilities.length ; j++ )
        {
            arr = addCapabilityStatesForList(arr,model.capabilities[j]);

        }
        if(model.subdeviceModels)
        {
            for(var j = 0; j < model.subdeviceModels.length ; j++ )
            {
                for(var k = 0; k < model.subdeviceModels[j].capabilities.length ; k++ )
                {
                    arr = addCapabilityStatesForList(arr,model.subdeviceModels[j].capabilities[k]);
                }
            }
        }
        models[i].capabilitiesStatesForList = arr;
    }
}

function addCapabilityStatesForList(arr,capability)
{
    var capabilityName = capability.capability;
    var states = capability.states;
    if(states)
    {
        states = states.map(function(obj){
            return {
                'capabilityName': capabilityName,
                'value' : obj
            };
          });
        arr = arr.concat(states);
    }
    else{
        arr.push({
            'capabilityName': capabilityName,
            'value' : capabilityName
        });
    }
    return arr;
}

export default {
    //Temporary constant to manage service capabilities states before implementation BE
    CONSTANTS:
    {
        servicesModels:
        [
            {
                name:'alarm',
                capabilitiesStatesForList:[
                    {
                        'capabilityName': 'trigger',
                        'value': 'trigger'
                    },
                    {
                        'capabilityName': 'protection',
                        'value': 'arm'

                    },
                    {
                        'capabilityName': 'protection',
                        'value': 'disarm'
                    },
                    {
                        'capabilityName': 'protection',
                        'value': 'partial'
                    },                          
                ] 
            },
            {
                name:'heating',
                capabilitiesStatesForList:[
                    {
                        'capabilityName': 'active',
                        'value': 'enable'

                    },
                    {
                        'capabilityName': 'active',
                        'value': 'disable'
                    }                        
                ] 
            },
        ],
        LOGO_DEVICE_BRAND : ['semac','iringer'],
        BRAND_INDEX_LENGTH : 4,
        SUBMODEL_INDEX_LENGTH : 4
    },
    getAll: function (systemName, getElt, brand, source) {
        if(systemName==undefined)systemName='';
        brand = (brand==undefined?'':'?forBrand='+brand);
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'systems/'+systemName+brand,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        timeout: Global.WS_TIMEOUT,
                        cancelToken: source.token
                    }).then(function (response) {
                        if(getElt == 'entities')
                        {
                            return resolve(getAllEntities(response.data.systems));
                        }
                        return resolve(response);
                    })
                    .catch(function (error) {
                        if(error.response && error.response.status == 404)
                        {
                            return resolve([]);
                        }
                        return reject(error);
                    });
            }
        );
    },
    getModels: function (modelName,source) {
        if(modelName==undefined)modelName='';
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        var _this = this;
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'models/'+modelName,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        cancelToken: source.token
                    }).then(function (response) {
                        //Sort by label
                        response.data.models.sort(function(a,b) {return (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0);} );
                        addCapabilitiesStatesForList(response.data.models);
                        if(response.data.models)
                            response.data.models = response.data.models.concat(_this.CONSTANTS.servicesModels);
                        return resolve(response);
                    })
                    .catch(function (error) {
                        if(error.response && error.response.status == 404)
                        {
                            return resolve([]);
                        }
                        return reject(error);
                    });
            }
        );
    },
    getManufacturers: function (manufacturerName,source) {
        if(manufacturerName == undefined){
            manufacturerName = '';
        }
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'manufacturers/'+manufacturerName,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        cancelToken: source.token
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        if(error.response && error.response.status == 404)
                        {
                            return resolve([]);
                        }
                        return reject(error);
                    });
            }
        );
    },

    getBrands: function (brandName) {
        if(brandName == undefined){
          brandName = '';
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'brands/'+brandName,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return reject(error);
                    });
            }
        );
    },

    getApps: function (appName = '', source = undefined) {
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'apps/'+appName,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        cancelToken: source.token
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        if(error?.response?.status == 404)
                        {
                            return resolve([]);
                        }
                        return reject(error);
                    });
            }
        );
    },
    
    getRegions: function (region) {
        if(region == undefined){
            region = '';
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'regions/'+region,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        return resolve([]);
                    });
            }
        );
    },

    getBrandGroups: function (brandGroups,source) {
        if(brandGroups == undefined){
            brandGroups = '';
        }
        if(source==undefined) 
        {
            var CancelToken = axios.CancelToken;
            source = CancelToken.source(); 
        }
        return new Promise(
            function(resolve, reject){
                axios.get(Global.getBackendUrl()+'brandGroups/'+brandGroups,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': StorageService.getValue(localStorage, 'authorization')
                        },
                        cancelToken: source.token
                    }).then(function (response) {
                        return resolve(response);
                    })
                    .catch(function (error) {
                        if(error.response && error.response.status == 404)
                        {
                            return resolve([]);
                        }
                        return reject(error);
                    });
            }
        );
    },

    getDevicesSystem: function (system) {
      return new Promise(
        function(resolve, reject){
            axios.get(Global.getBackendUrl()+'device/'+system+'/addressLocation/plants/'+StorageService.getValue(localStorage, 'plantId'),
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Authorization': StorageService.getValue(localStorage, 'authorization')
                    },
                }).then(function (response) {
                    return resolve(response);
                })
                .catch(function (error) {
                    return reject(error);
                });
        }
      );
    },
    getAllEntities: function (systems)
    {
        return getAllEntities(systems);
    },
    getEntityByName: function (systems,name)
    {
        for(var i = 0; i < systems.length ; i++ )
        {
            var system = systems[i];
            var availableCapabilities = systems[i].availableCapabilities;
            var entity = ArrayService.find(system.entities,'name',name);
            if(entity)
            {
                var capabilities = [];
                for(var k = 0; k < entity.capabilities.length ; k++ )
                {
                    var capabilityName = entity.capabilities[k];
                    var capability = ArrayService.find(availableCapabilities,'capability',capabilityName);
                    var states = capability.states;
                    if(states)
                    {
                        states = states.map(function(obj){
                            return {
                                'capabilityName': capabilityName,
                                'value' : obj
                            };
                          });
                        capabilities = {
                            'states' : capabilities.concat(states)
                        }
                    }
                }
                return{
                    'name' : name,
                    'capabilities' : capabilities
                };
            }
        }
        return{
            'name' : 'default',
            'capabilities' :
            {
                'states' :
                [
                    {'capabilityName': 'status', 'value': 'on'},
                    {'capabilityName': 'status', 'value': 'off'}
                ]
            }
        };
    },
    getAllSubModels: function (models, brand, availableModels)
    {
        var submodels = [];
        if(availableModels==undefined)
            availableModels = [];
        for(var h = 0; h < models.length; h++)
        {
            var model = models[h];
            if(model.submodels)
            {
                for(var i = 0; i < model.submodels.length; i++)
                {
                    var submodel = model.submodels[i];
                    if(submodel.brand == brand || brand == undefined)
                    {
                        //submodel.backgroundImage = 'url("'+(model.imagePath?model.imagePath:'assets/images/submodels/'+submodel.name.replace(/ /g,'').replace(/\//g,'_')+'.png')+'")';
                        submodel.backgroundImage = (model.imagePath?model.imagePath:'');
                        submodel.modelName = model.name;
                        submodels.push(submodel);
                    }
                }
            }
        }
        if(availableModels.length>0)
        {
            submodels = _filter(submodels, function(o) { return availableModels.indexOf(o.modelName)!=-1; });
        }
        submodels.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        return submodels;
    },

    getAllManufacturersBrands: function (manufacturers) {
        var brands = [];
        var _this = this;
        manufacturers.forEach(function(m) {
            if(m.brands)
            {
                var mBrands = m.brands;
                mBrands = mBrands.map(function(b){
                    //Add local imagePath if not exist
                    b.manufacturer = {
                        'name': m.name,
                        'displayName': m.displayName
                        
                    };
                    b.imagePath = (b.imagePath?b.imagePath:undefined);

                    try {
                        b.widgetPath = (b.images && b.images.banner ? Global.getBackendUrl()+b.images.banner:undefined);
                    }
                    catch (ex) 
                    {
                    }
                    
                    if(_this.CONSTANTS.LOGO_DEVICE_BRAND.indexOf(b.name)!=-1)
                    {
                        b.logoDevice = (b.images && b.images.popup ? Global.getBackendUrl()+b.images.popup:undefined);
                    }
                    return b;
                });
                brands = brands.concat(m.brands);
            }
        });
        brands.sort(function(a,b) {return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0);} );
        return brands;
    },

    getBrandByIndex: function(brands,index) {
        index = (index.length<this.CONSTANTS.BRAND_INDEX_LENGTH?index.padStart(this.CONSTANTS.BRAND_INDEX_LENGTH, '0'):index);
        index = (index.substr(0,2)=='0x'?index:'0x'+index);
        var brand = _find(brands, {'index':index});
        return brand;
    },
    
    getSubmodelBySubmodelIndexAndBrandName: function(models,brandName,index) {
        index = (index.length<this.CONSTANTS.SUBMODEL_INDEX_LENGTH?index.padStart(this.CONSTANTS.SUBMODEL_INDEX_LENGTH, '0'):index);
        index = (index.substr(0,2)=='0x'?index:'0x'+index);
        var submodel = undefined;
        for(var i = 0; i< models.length; i++)
        {
            if(models[i].submodels)
            {
                submodel = _find(models[i].submodels, {index: index, brand: brandName.toLowerCase()}); 
            }
            if(submodel)
            {
                break;
            }
        }
        return submodel;
    },

    getSubmodelBySubmodelNameAndBrandName: function(models,brandName,name) {
        var submodel = undefined;
        for(var i = 0; i< models.length; i++)
        {
            if(models[i].submodels)
            {
                submodel = _find(models[i].submodels, {name: name, brand: brandName.toLowerCase()}); 
            }
            if(submodel)
            {
                break;
            }
        }
        return submodel;
    },

    searchModelBySubModel: function(models,subModel){
        for(var i=0; i<models.length; i++){
            if(models[i].submodels)
            {
                for(var j=0; j<models[i].submodels.length; j++)
                {
                    if(models[i].submodels[j].name == subModel.name)
                    {
                        return models[i];
                    }
                }
            }
        }
        return undefined;
    }
}
