
import NumberService from 'common/services/number.js'

const TAG = 'energy.js'

const STATUS_CAPABILITY_NAME = 'status'

const ON_VALUE = 'on'

const OFF_VALUE = 'off'

const OPER_TURN_ON = 
{
    oper: '41',
    nbParameters: 0,
    capability: STATUS_CAPABILITY_NAME
}

const OPER_TURN_OFF =
{
    oper: '42',
    nbParameters: 0,
    capability: STATUS_CAPABILITY_NAME
}

export default {

    OPERS : 
    [
        OPER_TURN_ON,
        OPER_TURN_OFF
    ],

    /**
     * ON/OFF : 1 byte
     */
    convertCallbackFrame: function (module, dataHex, allCapability) {
        var status = [];

        var oper = dataHex.substr(0,2);

        var onOff = undefined;

        if(oper==OPER_TURN_OFF.oper)
        {
            onOff = '00'; 
        }
        else if(oper==OPER_TURN_ON.oper)
        {
            onOff = '01'; 
        }
        else
        {
            var relay = dataHex.substr(2,2);
            if(relay)
            {
                var relayBits = NumberService.convertBytetoBits(relay);
                var firstRelayValue = relayBits.charAt(relayBits.length-1);
                if(firstRelayValue=='0')
                {
                    onOff = '00'; 
                }
                else if(firstRelayValue=='1')
                {
                    onOff = '01';
                }
            }
        }

        if(['00','01'].indexOf(onOff)!=-1)
        {
            status.push({
                capability: STATUS_CAPABILITY_NAME,
                value: (onOff=='00'?OFF_VALUE:ON_VALUE)
            });
        }

        if(allCapability)
        {
            return status;
        }
        else
        {
            var oper = dataHex.substr(0,2);

            if(!oper)
            {
                return status;
            }
    
            var operObj = _find(this.OPERS, {'oper': oper});

            if(!operObj)
            {
                return status;
            }

            var callbackCapability = _find(status, {'capability': operObj.capability});
            return (callbackCapability?[callbackCapability]:status)
    
        }

    },

    turnOff: function (module) {
        var s = _find(module.device.status, {'capability': STATUS_CAPABILITY_NAME});
        if(!s)
        {
            return false;
        }
        var status = [
            {'capability':STATUS_CAPABILITY_NAME,'value':OFF_VALUE}
        ];

        var cmd = 'setStatus';
        // UART V2 OPER(1B)+RELAY 1-15(1B)+TIMER(4B)
        var dataHex = OPER_TURN_OFF.oper+'010000';
        
        return {
            status : status,
            dataHex : dataHex,
            cmd : cmd
        }
        
    },

    turnOn: function (module) {
        var s = _find(module.device.status, {'capability': STATUS_CAPABILITY_NAME});
        if(!s)
        {
            return false;
        }
        var status = [
            {'capability':STATUS_CAPABILITY_NAME,'value':ON_VALUE}
        ];

        var cmd = 'setStatus';
        // UART V2 OPER(1B)+RELAY 1-15(1B)+TIMER(4B)
        var dataHex = OPER_TURN_ON.oper+'010000';
        
        return {
            status : status,
            dataHex : dataHex,
            cmd : cmd
        }
    },
}
