export default {
	props:['brand'],
	data:
		function () {
			return {
				    CONSTANTS:
					{
						FINISH_TXT : this.$gettext('Your %{s} devices are added to your %{appName} app!'),
						FINISH_EXPLANATION_TXT : this.$gettext('A \"%{s}\" widget is displayed in your first room. From here you can control all your %{s} devices'),
						LOGIN_BTN : this.$gettext('Access my equipment<br/>%{s}'),
					},
					finishTxt:'',
					finishExplanationTxt:'',
					loginBtn:'',
			}
		},
	created: function () {
		this.eventHub.$emit('setDisplayPrevious', false);
		this.finishTxt= this.$gettextInterpolate(this.CONSTANTS.FINISH_TXT, {s: this.brand.displayName, appName: this.$store.state.variantManager.class.title});
		this.finishExplanationTxt= this.$gettextInterpolate(this.CONSTANTS.FINISH_EXPLANATION_TXT, {s: this.brand.displayName, appName: this.$store.state.variantManager.class.title});
		this.loginBtn= '<span class="v-btn__content"><span>'+this.$gettextInterpolate(this.CONSTANTS.LOGIN_BTN, {s: this.brand.displayName})+'</span></span>';
	},
	methods: {
		login: function(){
			this.eventHub.$emit('goToExternalPage',this.brand.url);
			this.eventHub.$emit('addDeviceFinished');
		}
  }
}
