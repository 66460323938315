
export const DEVICE_TXT = 'Add'

export const VOICE_ASSISTANT_TXT = 'Push-to-Talk'

export const REMAINING_CREDIT = 'Credit : <b>%{s}</b>'

export const REFRESH_DATA = 'Refresh the data'

export const FAQ_MENU = 'FAQ'

export const CONTACT_MENU = 'Contact us'

export const SUGGESTIONS_MENU = 'Suggestions'

export const VIEWS_MENU = 'views'

export const ROOMS_MENU = 'rooms'

export const HELP_MENU = 'help'

export const SCENARIOS_TXT = 'Scenarios'