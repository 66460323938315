export default {
	props: ['device'],
	data:
		function () {
			return {
			}
	},
	created: function () {
		this.eventHub.$emit('setDisplayPrevious', true);
    },
	methods: {
 	}
}
