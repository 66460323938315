import StringService from 'common/services/string.js'
import StorageService from 'common/services/storage.js'

export default {
	props: 
	{
		model: {
			type: Object
		},
		submodel:{
			type: Object
		},
		txt1: {
			type: String
		},
		txt2: {
			type: String
		},
		img: {
			type: String
		},
		installObject: {
			type: Object
		}
	},
	data:
		function () {
			return {
				CONSTANTS:
				{
					TRY_NUMBER : 80,
					// Duration before new test to connect to the device's wifi in AP
					AP_DEVICE_CONNECT_TIMEOUT : 500,
				},
				plugWifiTimeout: undefined,
				nbTries : 0,
				waiting: false,
				showWifiSsid: false,
			}
	},
	beforeDestroy () {
		clearTimeout(this.plugWifiTimeout);
	},	
	created: function () {
		this.waiting = true;
		var r = JSON.parse(StorageService.getValue(localStorage, 'region-ws'));
		if(r.region=='sandbox')
		{
			//FOR DEV PORTAL TEST
			var _this = this;
			setTimeout(() => {
				_this.installObject.plug.ssid = this.submodel.name+'-'+this.installObject.device.uid;
				_this.waiting = false;
				_this.eventHub.$emit('nextInstallStep');
			}, 5000);
		}
		else
		{
			if(window.WifiWizard)
			{
				this.getCurrentPlugSSID()
			}
			else
			{
				this.waiting = false;
                this.eventHub.$emit('setValidation', false);
				this.eventHub.$emit('setInternalMainStep', 6);
			}
		}
    },
	methods: {
		img: function(img)
		{
			try
			{
				return require(img);
			}
			catch(e)
			{
				this.showWifiSsid = true;
				return require('@/assets/images/device/cw/cl-install-wifi.png');
			}
		},
		imgError : function()
		{
			this.$refs['wifiSsidImg'].src = require('@/assets/images/device/cw/cl-install-wifi.png');
			
		},
		launchPlugWifiTimeout : function()
		{
			var _this = this;
			if(this.nbTries==this.CONSTANTS.TRY_NUMBER)
            {
				this.waiting = false;
                this.eventHub.$emit('setValidation', false);
				this.eventHub.$emit('setInternalMainStep', 6);
                return false;
			}		
			this.nbTries++;
			this.plugWifiTimeout = setTimeout(() => {
				_this.getCurrentPlugSSID();
			}, this.CONSTANTS.AP_DEVICE_CONNECT_TIMEOUT);
		},

		getCurrentPlugSSID : function(){
			var _this = this;
			window.WifiWizard.getConnectedSSID().then(
				function(ssid){
					ssid = (StringService.regexQuote(ssid)?ssid.substr(1, ssid.length-2):ssid);
					var reg = new RegExp('^'+_this.submodel.name);
					if(reg.test(ssid))
					{
						_this.installObject.plug.ssid = ssid;
						_this.waiting = false;
						_this.eventHub.$emit('nextInstallStep');
					}
					else
					{
						_this.launchPlugWifiTimeout();
					}
				}).catch(
					function(e){
						console.log('getCurrentPlugSSID - failed');
						console.log(e);
						_this.launchPlugWifiTimeout();
					});
		}
 	}
}