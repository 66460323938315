import { render, staticRenderFns } from "./menu.vue?vue&type=template&id=070f3f7c&scoped=true&"
import script from "./menu.js?vue&type=script&lang=js&"
export * from "./menu.js?vue&type=script&lang=js&"
import style0 from "./menu.css?vue&type=style&index=0&id=070f3f7c&scoped=true&lang=css&"
import style1 from "./menu-vuetify.css?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070f3f7c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VOverflowBtn } from 'vuetify/lib/components/VOverflowBtn';
installComponents(component, {VBadge,VBtn,VCard,VCardText,VCardTitle,VDialog,VList,VListItem,VListItemAction,VListItemAvatar,VListItemContent,VListItemTitle,VOverflowBtn})


/* vuetify-loader */
import installDirectives from "!../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import ClickOutside from 'vuetify/lib/directives/click-outside'
installDirectives(component, {ClickOutside})
