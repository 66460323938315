import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const View = () => import(/* webpackChunkName: "view" */ 'app/home/views/view.vue');
const Page = () => import(/* webpackChunkName: "page" */ 'common/components/page/page.vue');
const Login = () => import(/* webpackChunkName: "login" */ 'app/login/login.vue');
const Subscribe = () => import(/* webpackChunkName: "subscribe" */ 'app/login/subscribe/subscribe.vue');
const Activate = () => import(/* webpackChunkName: "activate" */ 'app/login/activate/activate.vue');
const ResetPassword = () => import(/* webpackChunkName: "reset-password" */ 'app/login/reset-password/reset-password.vue');
const ForgotPassword = () => import(/* webpackChunkName: "forgot-password" */ 'app/login/forgot-password/forgot-password.vue');

const Section = () => import(/* webpackChunkName: "section" */ 'app/home/components/section/section.vue');
const Settings = () => import(/* webpackChunkName: "settings" */ 'app/home/settings/settings.vue');
const Alerts = () => import(/* webpackChunkName: "alerts" */ 'app/home/alerts/alerts.vue');
const Actions = () => import(/* webpackChunkName: "actions" */ 'app/home/actions/actions.vue');
const Market = () => import(/* webpackChunkName: "market" */ 'app/home/market/market.vue');

const Webview = () => import(/* webpackChunkName: "webview" */ 'common/components/webview/webview.vue');
const PrivatePolicy = () => import(/* webpackChunkName: "private-policy" */ 'app/private-policy/private-policy.vue');
const Invite = () => import(/* webpackChunkName: "invite" */ 'app/invite/invite.vue');

const routes = [
  
  { path: '/', component: View },
  { path: '/page', component: Page },
  { name: 'login', path: '/login', component: Login, props: true },
  { name: 'subscribe', path: '/subscribe', component: Subscribe },
  { name: 'activate', path: '/activate', component: Activate },
  { name: 'forgot-password', path: '/forgot-password', component: ForgotPassword },
  { name: 'reset-password', path: '/reset-password', component: ResetPassword },
  { name: 'home', path: '/home', component: View },
  { name: 'view', path: '/view', component: View },
  { name: 'rooms', path: '/home/rooms', component: Section , props: true},
  { name: 'sharesLocations', path: '/home/sharesLocations/:uid', component: Section , props: true},
  { name: 'settings', path: '/home/settings', component: Settings, props: true},
  { name: 'alerts', path: '/home/alerts', component: Alerts, props: true},
  { name: 'actions', path: '/home/actions', component: Actions, props: true},
  { name: 'marketplace', path: '/home/marketplace', component: Market },
  { name: 'webview', path: '/webview', component: Webview, props: true },
  { name: 'private-policy', path: '/private_policy', component: PrivatePolicy, props: true },
  { name: 'redirect', path: '/external/redirect/:externalName/:externalRoute?', component: Section },
  //{ name: 'public-share', path: '/invite/:uid?', component: Invite , props: true}
  { name: 'invite', path: '/invite/:uid?', component: Section , props: true},
  { name: 'privateInvite', path: '/invite/:uid?', component: Invite , props: true}
];

const router = new VueRouter({
  routes
})

export default router
