import StorageService from 'common/services/storage.js'
import LanguageService from 'common/services/language.js'
import store from 'vuex_path/store'
LanguageService.initRegions();
export default {

    WS_HOST: (window.location.protocol=='file:'?'https:':window.location.protocol)+'//be.comfortlife.me/v1.0/',
    WEBSITE_STORE: LanguageService.CONSTANTS.WEBSITE_STORE,

    REDIRECT_URI_PROD: LanguageService.CONSTANTS.WEBSITE_URL,

    CLIENT_ID: '00758da2-768c-11e7-b5a5-be2e44b06b34',
    REDIRECT_URI: 'https://www-dev.comfortlife.local',
    RESPONSE_TYPE: 'code',
    
    WS_TIMEOUT: 20000,
    VERSION: '2.5.0_rev_0db837f9',
    BUILD: '25000',
    wsUrl: LanguageService.getUrlFromUrl()+'/v1.0/',
    
    getBackendUrl: function(){
        //return this.WS_HOST;
        if(this.wsUrl.indexOf('://')==-1)
        {
            this.wsUrl = 'https://'+this.wsUrl;
        }
        return this.wsUrl;
    },
    
    setBackendUrl: function(region){
        var s = (sessionStorage.getItem('authorization')?sessionStorage:localStorage);
        store.state['region-ws'] = JSON.stringify(region);
        StorageService.persist(s, 'region-ws', JSON.stringify(region));
        this.wsUrl = region.endpoint+'/v1.0/';
    },
}
