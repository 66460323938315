export default {
    formatStringToMac: function(str) {
        str = str.toUpperCase();
        //if (str.length >= 3 && str.length <= 16) {
            str = str.replace(/\W/ig, '');
            str = str.replace(/(.{2})/g, "$1-");
        //}
        return str.substr(0, 17);
    },
    checkMac: function(str) {
        var mac = str.replace(/-/g,'');
        return (mac.match(/[0-9A-Fa-f]{12}/g)?mac:false);
    },
    generate: function(){
        var hexDigits = "0123456789ABCDEF";
        var macAddress = "";
        for (var i = 0; i < 6; i++) {
            macAddress+=hexDigits.charAt(Math.round(Math.random() * 15));
            macAddress+=hexDigits.charAt(Math.round(Math.random() * 15));
            if (i != 5) macAddress += ":";
        }
        return macAddress;
    }
}
