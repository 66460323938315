export default {
    props: ['module', 'use', 'config'],
    data () {
      return {
        channels: 4,
        swiperOption: {}
      }
    },
    created: function(){
      this.channelsNumber();
    },
    /*computed:
    {
      swiperOption: function(){
        var _this = this;
        var so = {
          letter: 'A',
          index: 0,
          pagination: '.swiper-pagination',
          paginationClickable: true,
          nextButton: '.swiper-button-next',
          prevButton: '.swiper-button-prev',
          simulateTouch: true,
          slidesPerView: 1,
          paginationBulletRender(swiper, index, className) {
            var render = `<span class="${className} swiper-pagination-bullet-custom">${this.letter}</span>`;
            this.letter = String.fromCharCode(this.letter.charCodeAt(0) + 1);
            if(index == (_this.channels - 1))
              this.letter = 'A';
            return render;
          },
          onSlideChangeEnd:function(swiper){
            this.index = swiper.realIndex;
          }
        }
        return so;
      }
    },*/
    methods: {
  		  setStatus : function(status, number){
          if(this.module.device.channels)
            var swichChannel = this.module.device.channels[this.swiperOption.index].switchs[number-1];
          else
            var swichChannel = this.module.device.switchs[number-1];

          if(this.use == "scenario")
          {
            this.eventHub.$emit('telTrigger', this.module, status, swichChannel);
          }
          else if(this.use == "service")
          {
            this.eventHub.$emit('telAction', this.module, status, swichChannel);
          }
          else
          {
            this.$parent.$emit('set-status', {capability:'status',value:status}, swichChannel.id);
          }
  		},
      channelsNumber : function(){
        if(this.module.device.submodel == 'TEL-100' || this.module.device.submodel == 'RC-001' )
        {
          this.channels = 1;
        }
        var _this = this;
        this.swiperOption = {
            letter: 'A',
            index: 0,
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
              renderBullet:function (index, className) {
                var render = `<span class="${className} swiper-pagination-bullet-custom">${_this.swiperOption.letter}</span>`;
                _this.swiperOption.letter = String.fromCharCode(_this.swiperOption.letter.charCodeAt(0) + 1);
                if(index == (_this.channels - 1))
                  _this.swiperOption.letter = 'A';
                return render;
              }
            },
            mousewheel: true,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            simulateTouch: true,
            slidesPerView: 1,
            on: {
              slideChange: function (swiper) {
                _this.swiperOption.index = _this.$refs['telSwiper'].$swiper.realIndex;

              },
            }
          }
        this.$nextTick(function() {
          this.$refs['telSwiper'].$swiper.update();
        });
      }
  	}
  }
